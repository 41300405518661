import { HorizontalView } from '../Layout'
import styled, { css } from 'styled-components'
import React, { FC, ReactNode, useMemo } from 'react'
import { Box } from '@mui/material'
import {
  IoCheckmarkCircle,
  IoAlertCircle,
  IoInformationCircle
} from 'react-icons/io5'
import { elevation } from '../../styles/elevation'
import { colors } from '../../styles/variables'

export interface AlertProps {
  children?: ReactNode
  info?: boolean
  success?: boolean
  warning?: boolean
  vertical?: boolean
}

export const Alert: FC<AlertProps> = ({
  info = true,
  success = false,
  warning = false,
  vertical = false,
  children,
  ...props
}) => {
  const Icon = useMemo(() => {
    if (success) {
      return IoCheckmarkCircle
    }
    if (warning) {
      return IoAlertCircle
    }
    if (info) {
      return IoInformationCircle
    }
    return null
  }, [info, success, warning])

  const color = useMemo(() => {
    if (success) {
      return colors.success
    }
    if (warning) {
      return colors.warn
    }
    if (info) {
      return colors.main
    }
    return '#fff'
  }, [info, success, warning])

  return (
    <AlertStyled {...props} color={color} vertical={vertical}>
      <Box mr={2}>
        <Icon size={24} color={color} />
      </Box>
      {children}
    </AlertStyled>
  )
}

const AlertStyled = styled(HorizontalView)<{
  color: string
  vertical: boolean
}>`
  margin: 8px 8px 16px 0;
  padding: 16px;
  box-shadow: ${elevation(3)};
  border-radius: 5px;
  font-size: 15px;
  background: #fff;
  border: 1px solid ${(p) => p.color};

  ${(p) =>
    p.vertical &&
    css`
      flex-direction: column;
      align-items: flex-start;
    `}
`
