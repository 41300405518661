import { useField } from 'formik'
import React, { FC } from 'react'
import { Checkbox, CheckboxProps } from '@mui/material'

export const CheckboxField: FC<CheckboxProps & { name: string }> = ({
  name
}) => {
  const [field] = useField(name)
  return <Checkbox {...field} checked={!!field.value} color="primary" />
}
