import { Box } from '@mui/material'
import { TextRegular } from '../../../../Text/TextRegular'
import { IoBulbOutline } from 'react-icons/io5'
import { View } from '../../../../Layout'
import React, { FC } from 'react'
import { StatusBadge } from './StatusBadge'
import { colors } from '../../../../../styles/variables'
import styled, { keyframes } from 'styled-components'

export const StatusWaitingForConfirmation: FC = () => {
  return (
    <View alignItems="flex-start">
      <StatusBadge color={colors.mainDark}>
        <Box display="flex" mr={1}>
          <PulsingBulbIcon size={24} />
        </Box>
        <TextRegular variant="medium">Oczekuje na potwierdzenie</TextRegular>
      </StatusBadge>
    </View>
  )
}

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  5% {
    transform: scale(1.1) rotate(45deg);
  }
  10% {
    transform: scale(1.2) rotate(-40deg);
  }
  15% {
    transform: scale(1.3) rotate(36deg);
  }
  20% {
    transform: scale(1.2) rotate(-32deg);
  }
  25% {
    transform: scale(1.1) rotate(29deg);
  }
  30% {
    transform: scale(1) rotate(-27deg);
  }
  40%, 100% {
    transform: none;
  }
`

const PulsingBulbIcon = styled(IoBulbOutline)`
  animation: ${pulseAnimation} 2s infinite forwards;
`
