import React, { PropsWithChildren } from 'react'
import { BackgroundModal } from 'components/BackgroundModal'
import styled, { keyframes } from 'styled-components'
import fog1 from '../Login/assets/fog1.png'
import { PagePaper } from '../Layout'
import { Helmet } from 'react-helmet'
import { colors } from '../../styles/variables'

export interface AuthPageProps {
  title: string
}

export function AuthPage({
  title,
  children
}: PropsWithChildren<AuthPageProps>) {
  return (
    <BackgroundModal>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Fog />
      <PagePaperStyled variant="small">{children}</PagePaperStyled>
    </BackgroundModal>
  )
}

const fogAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100vw);
  }
`

const Fog = styled.div`
  background-image: url(${fog1});
  position: absolute;
  background-size: cover;
  z-index: 0;
  top: 0;
  left: 0;
  right: -100vw;
  bottom: 0;
  opacity: 1;
  animation-duration: 100s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-fill-mode: both;
  animation-name: ${fogAnimation};
`

const PagePaperStyled = styled(PagePaper)`
  background: #1c1f1e;
  background-image: radial-gradient(black, transparent);
  color: ${colors.offWhite};
  border-radius: 16px;
  position: relative;
  z-index: 1;

  input {
    color: ${colors.offWhite};
  }

  button:not(:disabled) {
    &,
    &:hover,
    &:focus {
      background: ${colors.offWhite};
      border-color: ${colors.offWhite};
      color: #000;
    }
  }
`
