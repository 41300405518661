import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { RouteLinks, RouteNames } from '../Routes/RouteNames'
import { MergeAccount } from './MergeAccount'
import { MergeAccountSuccess } from './MergeAccountSuccess'

export function MergeAccountRoutes() {
  return (
    <Routes>
      <Route
        path={RouteNames.MergeAccountSuccess}
        element={<MergeAccountSuccess />}
      />
      <Route path={RouteNames.MergeAccountToken} element={<MergeAccount />} />
      <Route
        path="*"
        element={<Navigate to={RouteLinks.MergeAccountToken} replace />}
      />
    </Routes>
  )
}
