import { networkDataDuckFactory, NetworkDataState } from './network-data.duck'
import { ApiInterceptor } from '../components/Api/ApiInterceptor'

export interface ClubDTO {
  id: number
  name: string
  shortName: string
  code: string
  hostUrl: string
  createdTime: number
  email: string
  avatarUrl: string
  primaryStampUrl: string
  secondaryStampUrl: string
}

export interface CurrentClubStateForRoot {
  currentClub: NetworkDataState<ClubDTO, Error>
}

export const currentClubDuck = networkDataDuckFactory<ClubDTO, Error>(
  'currentClub',
  () => ApiInterceptor.get('/api/current-club')
)
