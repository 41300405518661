import { PageView } from '../Layout'
import { Loading } from './index'
import React, { FC } from 'react'
import { PageViewProps } from '../Layout/PageView'
import styled from 'styled-components'

export const PageLoading: FC<PageViewProps> = (props) => (
  <PageViewStyled centered {...props}>
    <Loading size="small" />
  </PageViewStyled>
)

const PageViewStyled: FC<PageViewProps> = styled(PageView)`
  margin-top: 12px;
`
