import React, { useEffect } from 'react'
import {
  NetworkRequestStatus,
  useNetworkRequest
} from '../../../common/network-state'
import { getRangesWithLanes } from '../../Ranges/ranges.service'
import { PagePaper, View } from '../../Layout'
import Helmet from 'react-helmet'
import { Box } from '@mui/material'
import { TextRegular } from '../../Text/TextRegular'
import { Link } from 'react-router-dom'
import { RouteLinks } from '../../Routes/RouteNames'
import { formatRoute } from '../../../helpers/formatRoute'
import Button from '@mui/material/Button'

export function BookingsManager() {
  const [fetch, rangesResponse, status] = useNetworkRequest(
    () => getRangesWithLanes(),
    []
  )

  useEffect(() => {
    fetch()
  }, [fetch])

  return (
    <PagePaper
      title="Rezerwacje"
      loading={status === NetworkRequestStatus.LOADING}
    >
      <Helmet>
        <title>Rezerwacje</title>
      </Helmet>
      <View component={Box} mb={2} alignItems="center">
        <TextRegular size="small">Wybierz strzelnicę</TextRegular>
      </View>
      {rangesResponse?.data?.length > 0 ? (
        <View alignItems="center">
          {rangesResponse.data.map((range) => (
            <Box mr={1} key={range.id}>
              <Button
                component={Link}
                to={formatRoute(RouteLinks.BookingsManagerList, {
                  rangeId: range.id
                })}
              >
                {range.name}
              </Button>
            </Box>
          ))}
        </View>
      ) : (
        <Box mt={4}>
          Nie znaleziono strzelnic z dostępnymi stanowiskami do rezerwacji.
        </Box>
      )}
    </PagePaper>
  )
}
