import { ApiInterceptor } from '../Api/ApiInterceptor'
import { PaymentOrder } from '../Payments/types'
import { PaymentOrderType } from '../Payments/enums'
import { BookingPaymentOrderDTO, ModifyBookingOperation } from './types'
import { DateTime } from 'luxon'
import { RangeDTO } from '../Ranges/types'

export interface CreateBookingPayload {
  laneId: string
  laneNumber: number
  startDate: number
  durationMinutes: number
  continueUrl: string
}

export interface CreateBookingResponse {
  redirectUrl: string
}

export interface BookingPaymentDetails {
  laneId: string
  laneNumber: number
  startDate: string
  durationMinutes: number
}

interface BookingDTO extends PaymentOrder {
  paymentOrderType: PaymentOrderType.BOOKING
  paymentDetails: BookingPaymentDetails
}

export const API_URL = '/api/bookings'

export function createBookingOrder(payload: CreateBookingPayload) {
  return ApiInterceptor.post<CreateBookingResponse>(API_URL, payload)
}

export function getBooking(bookingId: string) {
  return ApiInterceptor.get<BookingDTO>(`${API_URL}/${bookingId}`)
}

export function getBookingsInDateRange(
  range: RangeDTO,
  date: DateTime,
  rangePeriod: 'day' | 'month'
) {
  const startDate = date
    .startOf(rangePeriod)
    .setZone(range.timeZone, { keepLocalTime: true })
    .toISO()
  const endDate = date
    .endOf(rangePeriod)
    .setZone(range.timeZone, { keepLocalTime: true })
    .toISO()
  return ApiInterceptor.get<BookingPaymentOrderDTO[]>(
    `/api/bookings-admin/${range.id}?startDate=${encodeURIComponent(
      startDate
    )}&endDate=${encodeURIComponent(endDate)}`,
    {
      headers: {
        'Content-Type': 'application/vnd.bookings.v1.dailyBookings+json'
      }
    }
  )
}

export function modifyBooking(
  orderId: string,
  operation: ModifyBookingOperation
) {
  return ApiInterceptor.post(
    `/api/bookings-admin/${orderId}`,
    {
      operation
    },
    {
      headers: {
        'Content-Type': 'application/vnd.bookings.v1.modifyBooking+json'
      }
    }
  )
}
