export enum MembershipFeeWizardSteps {
  COMMON,
  ENTRY_FEE_CONFIG,
  ENTRY_FEE_AGE_RANGES,
  ENTRY_FEE_UNITS,
  MEMBER_FEE_CONFIG,
  MEMBER_FEE_PERIODS,
  MEMBER_FEE_AGE_RANGES,
  MEMBER_FEE_UNITS,
  SUMMARY
}

export enum MembershipFeeWizardActionType {
  SET_CURRENT_STEP = 'membershipFeeWizard/setCurrentStep',
  SET_FORM_VALUES = 'membershipFeeWizard/setFormValues',
  RESET = 'membershipFeeWizard/reset'
}
