import React from 'react'
import { Navigate, Routes, Route } from 'react-router-dom'
import { RouteLinks, RouteNames } from '../Routes/RouteNames'
import { AccountPaymentRoutes } from './Account'
import { useSelector } from 'react-redux'
import { hasRoleSelector } from '../Auth/auth.duck'
import { Role } from '../Account/enums'
import { PagePaper, View } from '../Layout'
import { LinkTab, SubMenu } from '../Header/SubMenu'
import { AdminPaymentRoutes } from './Admin'
import { currentClubDuck } from '../../ducks/currentClub.duck'
import { TextRegular } from '../Text/TextRegular'
import Box from '@mui/material/Box'
import { Helmet } from 'react-helmet'

export const PaymentsRoutes = () => {
  const isPaymentAdmin = useSelector(hasRoleSelector(Role.PAYMENT_ADMIN))
  const club = useSelector(currentClubDuck.selectors.data)

  if (!club) {
    return (
      <PagePaper>
        <Helmet>
          <title>Płatności nieaktywne</title>
        </Helmet>
        <TextRegular size="large">System płatności nieaktywny.</TextRegular>
        <Box mt={2}>
          <TextRegular size="medium">
            Płatności są dostępne tylko w ramach klubu sportowego.
          </TextRegular>
        </Box>
        <Box mt={2}>
          Jeśli należysz do klubu, upewnij się żeby zalogować się przy pomocy
          tych samych danych, których użyłeś do rejestracji w klubie.
        </Box>
      </PagePaper>
    )
  }

  return (
    <View>
      <Helmet>
        <title>Płatności</title>
      </Helmet>
      {isPaymentAdmin && (
        <SubMenu>
          <LinkTab label="Lista" to={RouteLinks.AdminPaymentOrdersList} />
          <LinkTab
            label="Konfiguracja składek"
            to={RouteLinks.AdminPaymentConfig}
          />
          <LinkTab
            label="Ustawienia PayU"
            to={RouteLinks.AdminPaymentPayUSettings}
          />
          <LinkTab
            label="Ustawienia P24"
            to={RouteLinks.AdminPaymentP24Settings}
          />
          <LinkTab label="Moje składki" to={RouteLinks.AccountPayments} />
        </SubMenu>
      )}
      <Routes>
        <Route
          path={RouteNames.AccountPayments}
          element={<AccountPaymentRoutes />}
        />
        {isPaymentAdmin && (
          <Route
            path={RouteNames.AdminPayments}
            element={<AdminPaymentRoutes />}
          />
        )}

        <Route
          path="*"
          element={
            <Navigate
              to={
                isPaymentAdmin
                  ? RouteLinks.AdminPayments
                  : RouteLinks.AccountPayments
              }
              replace
            />
          }
        />
      </Routes>
    </View>
  )
}
