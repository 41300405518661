import React, { useEffect, useState } from 'react'
import { HorizontalView, PagePaper, View } from '../Layout'
import Button from '@mui/material/Button'
import { ApiInterceptor } from '../Api/ApiInterceptor'
import { RouteLinks } from '../Routes/RouteNames'
import { useNetworkRequest } from '../../common/network-state'
import { GoogleAuthorizationUrlResponse, RangeCombinedEventsDTO } from './types'
import { Alert } from '../Alert'
import { TextRegular } from '../Text/TextRegular'
import Box from '@mui/material/Box'
import styled from 'styled-components'
import { DateTime } from 'luxon'
import { CgCalendarToday } from 'react-icons/cg'
import { VscDebugDisconnect } from 'react-icons/vsc'
import {
  IoArrowBackCircleOutline,
  IoArrowForwardCircleOutline
} from 'react-icons/io5'
import { Link } from '../Link'
import { colors } from '../../styles/variables'
import { CalendarDay } from './CalendarDay'

export function Calendar() {
  const [selectedDate, setMonthDate] = useState<DateTime>(DateTime.local())
  const startOfMonth = selectedDate.startOf('month')
  const firstWeekDay = startOfMonth.weekday
  const firstDayInCalendar = startOfMonth.minus({ days: firstWeekDay - 1 })

  const endOfMonth = selectedDate.endOf('month')
  const lastDayInCalendar = endOfMonth.plus({ days: endOfMonth.weekday })
  const weeks = Array(6)
    .fill(0)
    .map((_, w) =>
      Array(7)
        .fill(0)
        .map((_, d) => firstDayInCalendar.plus({ days: d, weeks: w }))
    )

  const goToCurrentDate = () => {
    setMonthDate(DateTime.local())
  }
  const goToNextDate = () => {
    setMonthDate((date) => date.plus({ month: 1 }))
  }
  const goToPrevDate = () => {
    setMonthDate((date) => date.minus({ month: 1 }))
  }

  const [fetchEvents, eventsResponse] = useNetworkRequest(
    () =>
      ApiInterceptor.get<RangeCombinedEventsDTO>('/api/range-events', {
        params: {
          from: firstDayInCalendar.toISO(),
          to: lastDayInCalendar.toISO()
        }
      }),
    [selectedDate]
  )

  const signIn = async () => {
    const response = await ApiInterceptor.post<GoogleAuthorizationUrlResponse>(
      '/api/calendar/initiate',
      {
        state: RouteLinks.Calendar,
        redirectUri: `${window.location.origin}${RouteLinks.CalendarAuthRedirect}`
      }
    )
    window.location.href = response.data.redirectUrl
  }

  const revoke = async () => {
    await ApiInterceptor.post('/api/calendar/revoke')
    fetchEvents()
  }

  useEffect(() => {
    fetchEvents()
  }, [fetchEvents])

  return (
    <PagePaper variant="large">
      {!eventsResponse?.data.isGoogleAuthenticated && (
        <Box mb={3}>
          <Alert>
            <View alignItems="flex-start">
              <TextRegular size="medium">Połącz kalendarz Google</TextRegular>
              <Box my={1}>
                <TextRegular>
                  Połącz kalendarz Google z kontem CelPal.
                </TextRegular>
              </Box>
              <Button variant="contained" color="primary" onClick={signIn}>
                Połącz
              </Button>
            </View>
          </Alert>
        </Box>
      )}

      <Box mb={2}>
        <HorizontalView justify="space-between">
          <TextRegular size="large" strong>
            {selectedDate.monthLong} {selectedDate.year} {/*<Button*/}
            {/*  color="primary"*/}
            {/*  variant="contained"*/}
            {/*  endIcon={<AiFillPlusCircle />}*/}
            {/*>*/}
            {/*  Nowe wydarzenie*/}
            {/*</Button>*/}
          </TextRegular>
          <HorizontalView align="center">
            <NavLink onClick={goToPrevDate} title="Poprzedni miesiąc">
              <IoArrowBackCircleOutline size={32} />
            </NavLink>
            <Box mx={1}>
              <NavLink onClick={goToCurrentDate} title="Dzisiaj">
                <CgCalendarToday size={34} />
              </NavLink>
            </Box>
            <NavLink onClick={goToNextDate} title="Następny miesiąc">
              <IoArrowForwardCircleOutline size={32} />
            </NavLink>
          </HorizontalView>
        </HorizontalView>
      </Box>
      <Table>
        <thead>
          <tr>
            <Td>Pon</Td>
            <Td>Wt</Td>
            <Td>Śr</Td>
            <Td>Czw</Td>
            <Td>Pt</Td>
            <Td>Sob</Td>
            <Td>Nd</Td>
          </tr>
        </thead>
        <tbody>
          {weeks.map((weekDays, i) => (
            <tr key={i}>
              {weekDays.map((day, i) => (
                <Td key={i}>
                  <CalendarDay
                    day={day}
                    selectedDate={selectedDate}
                    events={eventsResponse?.data.events}
                    onEventChange={fetchEvents}
                  />
                </Td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>

      {eventsResponse?.data.isGoogleAuthenticated && (
        <Box mt={2}>
          <Button
            onClick={revoke}
            variant="contained"
            size="small"
            endIcon={<VscDebugDisconnect />}
          >
            Odłącz konto Google
          </Button>
        </Box>
      )}
    </PagePaper>
  )
}

const Table = styled.table`
  border-spacing: 0;
  border-collapse: collapse;
  table-layout: fixed;
`

const Td = styled.td`
  width: 14.2857%;
  max-width: 0;
  height: 80px;
  border: 1px solid #000;
  padding: 4px;

  thead & {
    vertical-align: bottom;
    text-align: right;
    height: 50px;
  }
`

const NavLink = styled(Link)`
  color: ${colors.tar};
`
