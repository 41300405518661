import { colors } from '../../../../../styles/variables'
import { Box } from '@mui/material'
import { TextRegular } from '../../../../Text/TextRegular'
import { IoMdDoneAll } from 'react-icons/io'
import moment from 'moment'
import { View } from '../../../../Layout'
import React, { FC } from 'react'
import { StatusBadge } from './StatusBadge'
import { MembershipP24PaymentOrder, MembershipPayUPaymentOrder } from '../../../types'
import { PaymentFeeType } from '../../../enums'

export interface StatusCompletedProps {
  order: MembershipPayUPaymentOrder | MembershipP24PaymentOrder
}

export const StatusCompleted: FC<StatusCompletedProps> = ({ order }) => {
  const hasMemberFee = order.paymentItems.some(
    (item) => item.paymentItemDetails.feeType === PaymentFeeType.MEMBER_FEE
  )

  return (
    <View alignItems="flex-start">
      <StatusBadge background={colors.success} color="#fff">
        <Box display="flex" mr={1}>
          <IoMdDoneAll size={24} />
        </Box>
        <TextRegular variant="medium">
          Twoja płatność została zaksięgowana!
        </TextRegular>
      </StatusBadge>
      {hasMemberFee && (
        <Box mt={3}>
          <TextRegular variant="medium">
            Ważność konta została przedłużona do{' '}
            <TextRegular strong>
              {moment(order.paymentDetails.endDate).format('DD.MM.YYYY')}
            </TextRegular>
          </TextRegular>
        </Box>
      )}
    </View>
  )
}
