import React from 'react'
import { TextRegular } from '../../../Text/TextRegular'
import { useSelector } from 'react-redux'
import { currentStepSelector } from './duck'
import { Box } from '@mui/material'

export const CurrentStepLabel = () => {
  const currentStep = useSelector(currentStepSelector)

  return (
    <Box mt={2}>
      <TextRegular light>Krok {currentStep + 1}</TextRegular>
    </Box>
  )
}
