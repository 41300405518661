import { Box } from '@mui/material'
import { TextRegular } from '../../../../Text/TextRegular'
import { BiErrorAlt } from 'react-icons/bi'
import { View } from '../../../../Layout'
import React, { FC, ReactNode } from 'react'
import { StatusBadge } from './StatusBadge'
import { colors } from '../../../../../styles/variables'

export const StatusErrored: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <View alignItems="flex-start">
      <StatusBadge color={colors.error}>
        <Box display="flex" mr={1}>
          <BiErrorAlt size={32} />
        </Box>
        <TextRegular variant="medium">{children}</TextRegular>
      </StatusBadge>
    </View>
  )
}
