import React from 'react'
import { PagePaper } from '../Layout'
import { UserSelect } from '../UserSelect'
import { useState } from 'react'
import { AccountDTO } from '../Account/types'
import Box from '@mui/material/Box'
import { Button } from '@mui/material'
import { ApiInterceptor } from '../Api/ApiInterceptor'
import { LoginResponse } from '../Login/login.service'
import { useNavigate } from 'react-router'
import { RouteLinks, RouteNames } from '../Routes/RouteNames'
import { formatRoute } from '../../helpers/formatRoute'

export const SuperAdmin = () => {
  const [user, setUser] = useState<AccountDTO>(null)
  const navigate = useNavigate()

  const impersonate = async () => {
    const response = await ApiInterceptor.post<LoginResponse>(
      `/api/login/impersonate/${user.id}`
    )
    navigate(
      `${formatRoute(RouteLinks.TokenAuth, {
        token: response.data.token
      })}?redirectTo=${RouteLinks.Home}`
    )
  }

  return (
    <PagePaper>
      <Box mb={0.5}>Użytkownik</Box>
      <UserSelect
        allClubs
        onChange={setUser}
        inputProps={{
          placeholder: 'Nazwisko użytkownika'
        }}
      />

      {user && (
        <Box mt={2}>
          <Button onClick={impersonate} color="primary" variant="contained">
            Zaloguj się jako #{user.id}
          </Button>
        </Box>
      )}
    </PagePaper>
  )
}
