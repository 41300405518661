import { ApiInterceptor } from '../Api/ApiInterceptor'
import { ApplicationType } from '../Account/enums'

export interface LoginResponse {
  token: string
}

export interface LoginPayload {
  username: string
  password: string
  applicationType: ApplicationType
}

export function login(
  username: string,
  password: string,
  applicationType: ApplicationType
) {
  return ApiInterceptor.post<LoginResponse, LoginPayload>('/api/login', {
    username,
    password,
    applicationType
  })
}

export function loginViaFacebook(token: string) {
  return ApiInterceptor.post<LoginResponse>('/api/login/facebook', {
    token,
    applicationType: 'REACT'
  })
}
