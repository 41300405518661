import {
  MemberFeePeriodType,
  MemberFeeUnitDTO,
  MembershipApplicableConfigDTO
} from '../../types'
import moment from 'moment'
import { DateTime } from 'luxon'

const RISKY_DATE_PERCENT = 0.4

export const licenseFeeApplicable = (
  licenseFeeYears: number[],
  memberFeeUnit: MemberFeeUnitDTO
): boolean => {
  return licenseFeeYears.includes(moment(memberFeeUnit.startDate).get('year'))
}

export const getFeeAmount = (
  memberFeeUnit: MemberFeeUnitDTO,
  config: MembershipApplicableConfigDTO
): number => {
  return (
    memberFeeUnit.amount +
    (config.entryFeeApplicable ? config.entryFeeUnit.amount : 0) +
    (licenseFeeApplicable(config.licenseFeeYears, memberFeeUnit) ? 75 : 0)
  )
}

export const getFeeTotalAmount = (
  memberFeeUnit: MemberFeeUnitDTO,
  config: MembershipApplicableConfigDTO
): number => {
  return (
    getFeeAmount(memberFeeUnit, config) +
    getProvisionAmount(memberFeeUnit, config)
  )
}

export const getProvisionAmount = (
  memberFeeUnit: MemberFeeUnitDTO,
  config: MembershipApplicableConfigDTO
): number => {
  return Math.ceil(
    (getFeeAmount(memberFeeUnit, config) * config.provisionPercent) / 100
  )
}

export const isPaymentDateRisky = (
  memberFeeUnit: MemberFeeUnitDTO
): boolean => {
  if (
    DateTime.fromFormat(memberFeeUnit.startDate, 'YYYY-MM-DD') > DateTime.now()
  ) {
    return false
  }

  const daysLeft = getDaysTillPeriodEnd(memberFeeUnit)
  const periodDays = getDaysInPeriod(memberFeeUnit)

  return daysLeft / periodDays <= RISKY_DATE_PERCENT
}

export const getDaysInPeriod = (memberFeeUnit: MemberFeeUnitDTO): number => {
  const startDate = getPeriodStartDate(memberFeeUnit)
  const endDate = getPeriodEndDate(memberFeeUnit, startDate)

  return endDate.diff(startDate, 'days').days
}
export const getDaysTillPeriodEnd = (
  memberFeeUnit: MemberFeeUnitDTO
): number => {
  const startDate = getPeriodStartDate(memberFeeUnit)
  const endDate = getPeriodEndDate(memberFeeUnit, startDate)

  return parseInt(endDate.diff(DateTime.now(), 'days').days.toString(), 10)
}

export const getPeriodStartDate = (
  memberFeeUnit: MemberFeeUnitDTO
): DateTime => {
  const startDate = DateTime.fromFormat(memberFeeUnit.startDate, 'YYYY-MM-DD')

  switch (memberFeeUnit.periodType) {
    case MemberFeePeriodType.MONTH:
      return startDate.startOf('month')
    case MemberFeePeriodType.QUARTER:
      return startDate.startOf('quarter')
    case MemberFeePeriodType.HALF_YEAR:
      if (
        startDate.valueOf() < startDate.set({ month: 7, day: 1 }).startOf('day').valueOf()
      ) {
        return startDate.startOf('year')
      }
      return startDate.set({ month: 7 }).startOf('month')
    case MemberFeePeriodType.YEAR:
      return startDate.startOf('year')
  }
}

export const getPeriodEndDate = (
  memberFeeUnit: MemberFeeUnitDTO,
  startDate: DateTime
): DateTime => {
  switch (memberFeeUnit.periodType) {
    case MemberFeePeriodType.MONTH:
      return startDate.plus({ month: 1, day: -1 })
    case MemberFeePeriodType.QUARTER:
      return startDate.plus({ month: 3, day: -1 })
    case MemberFeePeriodType.HALF_YEAR:
      return startDate.plus({ month: 6, day: -1 })
    case MemberFeePeriodType.YEAR:
      return startDate.plus({ month: 12, day: -1 })
  }
}
