import { PagePaper } from '../../Layout'
import { RangeDTO } from '../types'
import React, { FC, useEffect, useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import styled from 'styled-components'
import {
  NetworkRequestStatus,
  useNetworkRequest
} from '../../../common/network-state'
import { ApiInterceptor } from '../../Api/ApiInterceptor'
import { RangeLane } from './types'
import { FaEdit } from 'react-icons/fa'
import { IoMdTrash } from 'react-icons/io'
import { IoAdd } from 'react-icons/io5'
import { useToggle } from '../../../hooks/useToggle'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { AddLaneDialog } from './AddLaneDialog'
import { DeleteLaneDialog } from './DeleteLaneDialog'

export interface RangeLanesProps {
  range: RangeDTO
}

export const RangeLanes: FC<RangeLanesProps> = ({ range }) => {
  const [isAddModalOpen, openAddModal, closeAddModal] = useToggle()
  const [editLane, setEditLane] = useState<RangeLane>(null)
  const [deleteLaneId, setDeleteLaneId] = useState<string>(null)
  const [fetchLanes, lanesResponse, status] = useNetworkRequest(
    () => ApiInterceptor.get<RangeLane[]>(`/api/range/${range.id}/lanes`),
    [range?.id]
  )

  const afterAddLane = () => {
    closeAddModal()
    fetchLanes()
  }
  const afterEditLane = () => {
    setEditLane(null)
    fetchLanes()
  }
  const afterDeleteLane = () => {
    setDeleteLaneId(null)
    fetchLanes()
  }

  useEffect(() => {
    fetchLanes()
  }, [fetchLanes])

  return (
    <PagePaper
      title="Tory strzeleckie"
      loading={!range || status === NetworkRequestStatus.LOADING}
      sideContent={
        <Button
          onClick={openAddModal}
          color="primary"
          variant="contained"
          startIcon={<IoAdd />}
        >
          Dodaj
        </Button>
      }
    >
      <AddLaneDialog
        isOpen={isAddModalOpen}
        range={range}
        onClose={afterAddLane}
        onCancel={closeAddModal}
      />
      <AddLaneDialog
        isOpen={!!editLane}
        range={range}
        lane={editLane}
        onClose={afterEditLane}
        onCancel={() => setEditLane(null)}
      />
      <DeleteLaneDialog
        isOpen={!!deleteLaneId}
        rangeId={range?.id}
        laneId={deleteLaneId}
        onClose={afterDeleteLane}
        onCancel={() => setDeleteLaneId(null)}
      />
      <TableStyled>
        <TableHead>
          <TableRow>
            <TableCell>Nazwa</TableCell>
            <TableCell>Długość osi</TableCell>
            <TableCell>Ilość stanowisk</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {lanesResponse?.data.length > 0 ? (
            lanesResponse.data.map((lane) => (
              <TableRow key={lane.id} hover>
                <TableCell>{lane.name}</TableCell>
                <TableCell>{lane.length} m</TableCell>
                <TableCell>{lane.lanesAmount}</TableCell>
                <TableCell align="right">
                  <IconButton color="primary" onClick={() => setEditLane(lane)}>
                    <FaEdit />
                  </IconButton>
                  <IconButton
                    color="primary"
                    onClick={() => setDeleteLaneId(lane.id)}
                  >
                    <IoMdTrash />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={3} style={{ textAlign: 'center' }}>
                Brak torów
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </TableStyled>
    </PagePaper>
  )
}

const TableStyled = styled(Table)`
  && {
    width: auto;
  }

  td {
    border: none;
  }
`
