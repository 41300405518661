import React, { ElementType, HTMLProps } from 'react'
import { colors, fontSize } from '../../styles/variables'
import styled, { css } from 'styled-components'
import { WithCustomComponent } from '../../common/types'

export type TextRegularProps<T extends ElementType = 'span'> =
  WithCustomComponent<T> &
    TextStyledProps &
    Omit<HTMLProps<HTMLSpanElement>, 'size'>

type ColorKeys = keyof typeof colors
type Color = typeof colors[ColorKeys]

interface TextStyledProps {
  variant?: 'small' | 'medium' | 'large' | 'xlarge'
  size?: 'small' | 'medium' | 'normal' | 'large' | 'xlarge'
  light?: boolean
  strong?: boolean
  centered?: boolean
  oneLine?: boolean
  color?: Color
}

const getFontSize = (
  size: TextRegularProps['size'] | TextRegularProps['variant']
): number => {
  switch (size) {
    case 'xlarge':
      return fontSize.xlarge
    case 'large':
      return fontSize.large
    case 'medium':
      return fontSize.medium
    case 'small':
      return fontSize.small
    case 'normal':
    default:
      return fontSize.regular
  }
}

export function TextRegular<T extends ElementType = 'span'>({
  component,
  ...props
}: TextRegularProps<T>) {
  return <Text {...props} as={component || 'span'} />
}

const Text = styled.span<TextRegularProps>`
  text-align: ${(p) => (p.centered ? 'center' : 'left')};

  ${(p) =>
    (p.size || p.variant) &&
    `font-size: ${getFontSize(p.size || p.variant)}px`};
  ${(p) => p.light && !p.color && `color: ${colors.smoke};`}
  ${(p) => p.color && `color: ${p.color};`}
  ${(p) => p.strong && `font-weight: bold;`}

  ${(p) =>
    p.oneLine &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `}
`
