import { AccountBasicDTO } from '../Account/types'
import {
  GatewayType,
  ManualPaymentReason,
  ManualPaymentStatus,
  P24OrderStatus,
  PaymentFeeType,
  PaymentOrderType,
  PayUCreateOrderResponseStatus,
  PayUOrderStatus
} from './enums'

export interface PaymentItemDetails {
  id: string
}

export interface PaymentItem {
  id: string
  createdTime: string
  createdById: number
  name: string
  amount: number
  paymentItemDetails: PaymentItemDetails
}

export interface PaymentOrder {
  id: string
  createdTime: string
  createdById: number
  lastModifiedTime: string
  lastModifiedById: number
  clubId: number
  accountId: number
  totalAmount: number
  gatewayType: GatewayType
  gateway: AnyGateway
  paymentItems: PaymentItem[]
  paymentOrderType: PaymentOrderType
  paymentDetails: unknown
}

export enum MemberFeePeriodType {
  YEAR = 'YEAR',
  QUARTER = 'QUARTER',
  HALF_YEAR = 'HALF_YEAR',
  MONTH = 'MONTH'
}

export enum FirstPaymentPeriodDensity {
  QUARTER = 'QUARTER',
  HALF_YEAR = 'HALF_YEAR',
  MONTH = 'MONTH'
}

export enum MembershipPurpose {
  SPORT = 'SPORT',
  COLLECTION = 'COLLECTION',
  BOTH = 'BOTH'
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE'
}

export interface CreateMemberFeeAgeRange {
  from: number
  to: number
}
export type CreateEntryFeeAgeRange = CreateMemberFeeAgeRange

export interface FeeAgeRange {
  from: number
  to: number
}

export interface FirstPaymentUnit {
  periodIndex: number
  amount: number
}

export interface FeeUnit {
  id: string
  amount: number
  firstPaymentUnits: FirstPaymentUnit[]
  purpose: MembershipPurpose
  isService: boolean
  gender: Gender
  hasIntroductory: boolean
  ageRange: FeeAgeRange
}

export interface EntryFeeUnit extends FeeUnit {}

export interface MemberFeeUnit extends FeeUnit {
  periodType: MemberFeePeriodType
}

export interface MemberFeeUnitDTO extends MemberFeeUnit {
  startDate: string
}

export interface CreateMembershipFeeConfigPayload {
  startTime: string
  paymentDelayDays: number
  entryFeeConfig: EntryFeeConfig
  memberFeeConfig: MemberFeeConfig
  entryFeeUnits: EntryFeeUnit[]
  memberFeeUnits: MemberFeeUnit[]
}

export interface FeeConfig {
  firstPaymentPeriodDensity: FirstPaymentPeriodDensity
  ageApplies: boolean
  purposeApplies: boolean
  serviceApplies: boolean
  genderApplies: boolean
  introductoryApplies: boolean
}

export interface EntryFeeConfig extends FeeConfig {}

export interface MemberFeeConfig extends FeeConfig {
  periodTypes: MemberFeePeriodType[]
}

export interface MembershipFeeConfigDTO {
  id: string
  paymentDelayDays: number
  startTime: string
  endTime: string | null
  createdTime: number
  createdBy: AccountBasicDTO
  entryFeeConfig: EntryFeeConfig
  memberFeeConfig: MemberFeeConfig
  entryFeeUnits: EntryFeeUnit[]
  memberFeeUnits: MemberFeeUnit[]
}

export interface MembershipApplicableConfigDTO {
  entryFeeApplicable: boolean
  entryFeeConfig: EntryFeeConfig
  memberFeeConfig: MemberFeeConfig
  provisionPercent: number
  entryFeeUnit: EntryFeeUnit
  memberFeeUnits: MemberFeeUnitDTO[]
  licenseFeeYears: number[]
}

export interface MembershipBatchAccountDTO {
  configId: string
  entryFeeApplicable: boolean
  memberFeeApplicable: boolean
  licenseFeeApplicable: boolean
  account: AccountBasicDTO
  expirationDate: string
  entryFeeUnit: EntryFeeUnit
  memberFeeUnits: MemberFeeUnitDTO[]
}

export type MembershipBatchApplicableConfigResponse =
  MembershipBatchAccountDTO[]

export interface CreateMembershipOnlinePaymentPayload {
  memberFeeUnitId: string
  continueUrl: string
}

export interface CreateMembershipManualPaymentPayload {
  accountId: number
  memberFeeUnitId: string
}

export interface CreateMembershipExemptManualPaymentPayload {
  accountId: number
  year: number
}

export interface CreateMembershipPayUPaymentResponse {
  orderId: string
  status: {
    statusCode: PayUCreateOrderResponseStatus
  }
  redirectUri: string
  extOrderId: string
}

export interface CreateMembershipP24PaymentResponse {
  redirectUri: string
}

/**
 * Used to be CreateMembershipPayUPaymentResponse
 * Switch if needed
 */
export type CreateMembershipPaymentResponse = CreateMembershipP24PaymentResponse

export interface GatewayPayU {
  id: string
  payUId: string
  redirectUri: string
  extOrderId: string
  provisionPercent: number
  status: PayUOrderStatus
}

export interface GatewayP24 {
  id: string
  token: string
  redirectUri: string
  extOrderId: number
  provisionPercent: number
  status: P24OrderStatus
}

export interface GatewayManual {
  reason: ManualPaymentReason
  status: ManualPaymentStatus
}

export type AnyGateway = GatewayPayU | GatewayP24 | GatewayManual

export interface MembershipPaymentItemDetails extends PaymentItemDetails {
  feeUnitId: string
  feeType: PaymentFeeType
}

export interface MembershipPaymentDetails {
  startDate: string
  endDate: string
}

export interface MembershipPaymentItem extends PaymentItem {
  paymentItemDetails: MembershipPaymentItemDetails
}

export interface MembershipPaymentOrder extends PaymentOrder {
  paymentOrderType: PaymentOrderType.MEMBERSHIP
  paymentItems: MembershipPaymentItem[]
  paymentDetails: MembershipPaymentDetails
}

export interface MembershipPaymentOrderDTO
  extends Omit<MembershipPaymentOrder, 'account' | 'paymentItems'> {
  account: AccountBasicDTO
  paymentItems: {
    id: string
    name: string
    amount: number
    paymentItemDetails: {
      id: string
      feeUnit: FeeUnit
      feeType: PaymentFeeType
    }
  }[]
}

export interface MembershipPayUPaymentOrder extends MembershipPaymentOrder {
  gatewayType: GatewayType.PAY_U
  gateway: GatewayPayU
}

export interface MembershipP24PaymentOrder extends MembershipPaymentOrder {
  gatewayType: GatewayType.P24
  gateway: GatewayP24
}

export interface CreateLicenseOnlinePaymentPayload {
  year: number
  continueUrl: string
}

export interface CreateLicenseManualPaymentPayload {
  accountId: number
  year: number
}
