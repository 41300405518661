export class AuthStore {
  private static jwt: string

  static getJwt() {
    return AuthStore.jwt
  }

  static setJwt(jwt: string) {
    AuthStore.jwt = jwt
  }
}
