import { Box } from '@mui/material'
import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'
import { HorizontalView } from '../../../Layout'
import { muiTheme } from '../../../../common/muiTheme'
import { IoIosCheckmarkCircleOutline } from 'react-icons/io'

export const Criteria: FC<{ children?: ReactNode }> = ({ children }) => (
  <CriteriaWrapper>
    <Box mr={0.5}>
      <CriteriaIcon />
    </Box>
    {children}
  </CriteriaWrapper>
)

const CriteriaWrapper = styled(HorizontalView)`
  & + & {
    margin-top: ${muiTheme.spacing(0.5)};
  }
`

const CriteriaIcon = styled(IoIosCheckmarkCircleOutline)`
  display: block;
`
