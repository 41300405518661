import { useNetworkRequest } from '../../common/network-state'
import { downloadDocument, getAccountDocuments } from './documents.service'
import React, { useEffect, useState } from 'react'
import { PagePaper, HorizontalView } from '../Layout'
import {
  Alert,
  Box,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material'
import { UserSelect } from '../UserSelect'
import { AccountDTO } from '../Account/types'
import { DateTime } from 'luxon'
import IconButton from '@mui/material/IconButton'
import { IoMdCloudDownload } from 'react-icons/io'
import { Document } from './types'
import FileSaver from 'file-saver'
import { TextRegular } from '../Text/TextRegular'
import { DeleteDocument } from './DeleteDocument'
import { AdminDocumentsDownload } from './AdminDocumentsDownload'
import { AdminDocumentsUpload } from './AdminDocumentsUpload'

export function AdminDocuments() {
  const [account, setAccount] = useState<AccountDTO>(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')

  const [fetch, documentsResponse] = useNetworkRequest(
    () => getAccountDocuments(account.id),
    [account]
  )

  const handleDownload = async (document: Document) => {
    const response = await downloadDocument(account.id, document.id)
    FileSaver.saveAs(response.data, document.name)
  }

  useEffect(() => {
    if (account) {
      fetch()
    }
  }, [fetch, account])

  return (
    <PagePaper
      title="Dokumenty"
      sideContent={
        <Box sx={{ display: 'flex', gap: 1 }}>
          <AdminDocumentsDownload />
          <AdminDocumentsUpload />
        </Box>
      }
    >
      <Box my={2} maxWidth={280}>
        <UserSelect
          inputProps={{ label: 'Wybierz klubowicza' }}
          onChange={setAccount}
          value={account}
        />
      </Box>

      <Snackbar
        open={!!errorMessage}
        autoHideDuration={3000}
        onClose={() => setErrorMessage('')}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <Snackbar
        open={!!successMessage}
        autoHideDuration={3000}
        onClose={() => setSuccessMessage('')}
      >
        <Alert severity="success">{successMessage}</Alert>
      </Snackbar>

      {account ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nazwa</TableCell>
              <TableCell>Data dodania</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documentsResponse?.data?.length ? (
              documentsResponse.data.map((document) => (
                <TableRow key={document.id}>
                  <TableCell>{document.name}</TableCell>
                  <TableCell>
                    {DateTime.fromISO(document.createdTime).toFormat(
                      'yyyy-MM-dd HH:mm'
                    )}
                  </TableCell>
                  <TableCell>
                    <HorizontalView gap={2}>
                      <DeleteDocument
                        account={account}
                        document={document}
                        onRefresh={fetch}
                        onSuccess={setSuccessMessage}
                        onError={setErrorMessage}
                      />
                      <IconButton
                        onClick={() => handleDownload(document)}
                        title="Pobierz"
                      >
                        <IoMdCloudDownload />
                      </IconButton>
                    </HorizontalView>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3}>
                  Brak dokumentów na koncie tego klubowicza.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      ) : (
        <Box mt={3}>
          <TextRegular>
            Wybierz klubowicza z listy, żeby zobaczyć dokumenty.
          </TextRegular>
        </Box>
      )}
    </PagePaper>
  )
}
