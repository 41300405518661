import { GenericLoginPage } from '../Login'
import React, { useEffect, useState } from 'react'
import { login } from '../Login/login.service'
import { ApplicationType } from '../Account/enums'
import { verifySsoConnectPayload } from './sso.service'
import Box from '@mui/material/Box'
import { useSsoConnectPayload } from './useSsoConnectPayload'
import { jwtSelector } from '../Auth/auth.duck'
import { AccountView } from './AccountView'
import { Link as LinkRouter } from 'react-router-dom'
import { RouteLinks } from '../Routes/RouteNames'
import { useSelector } from 'react-redux'
import { Link } from '@mui/material'
import { SsoVendor } from './enums'
import { InvalidSsoLink } from './InvalidSsoLink'

export interface SsoAuthProps {
  vendor: SsoVendor
  vendorUrl: string
  title: string
  proceedText: string
  redirectSoonText: string
}

export function SsoAuth({
  vendor,
  vendorUrl,
  title,
  proceedText,
  redirectSoonText
}: SsoAuthProps) {
  const { sso, sig } = useSsoConnectPayload()
  const storageJwt = useSelector(jwtSelector)
  const [jwt, setJwt] = useState(storageJwt)
  const [isValid, setValid] = useState<boolean>(null)

  const handleLogin = async (username, password) => {
    const loginResponse = await login(username, password, ApplicationType.FORUM)

    setJwt(loginResponse.data.token)
  }

  useEffect(() => {
    const validatePayload = async () => {
      if (sso && sig) {
        const response = await verifySsoConnectPayload(vendor, sso, sig)
        setValid(response.data.isValid)
      }
    }

    validatePayload()
  }, [vendor, sso, sig])

  return (
    <GenericLoginPage
      title={title}
      handleLogin={handleLogin}
      subTitle={
        <Box mt={2}>
          Nie posiadasz konta?&nbsp;
          <Link component={LinkRouter} to={RouteLinks.SsoSignup}>
            Zarejestruj się
          </Link>
        </Box>
      }
      customError={
        (!sso || !sig || isValid === false) && (
          <InvalidSsoLink vendorUrl={vendorUrl} />
        )
      }
      customContent={
        jwt || storageJwt ? (
          <AccountView
            vendor={vendor}
            vendorUrl={vendorUrl}
            proceedText={proceedText}
            redirectSoonText={redirectSoonText}
            temporaryJwt={jwt}
          />
        ) : null
      }
    />
  )
}
