import React, { useCallback, useEffect, useState } from 'react'
import { PagePaper, View } from '../../../Layout'
import { TextRegular } from '../../../Text/TextRegular'
import {
  NetworkRequestStatus,
  useNetworkRequest
} from '../../../../common/network-state'
import { ApiInterceptor } from '../../../Api/ApiInterceptor'
import { Box, Button, InputAdornment } from '@mui/material'
import { Formik, FormikHelpers } from 'formik'
import { FormError, InputField } from '../../../Form'
import { networkError } from '../../../../helpers/networkError'
import { PaymentSettings, PaymentSettingsFormValues } from './types'
import { getProvisionWithCorrectComma, validate } from './helpers'
import { Helmet } from 'react-helmet'
import { GatewayType } from '../../enums'

const initialValues: PaymentSettingsFormValues = {
  provisionPercent: '0',
  posId: '',
  secondKey: '',
  clientId: '',
  clientSecret: ''
}

export interface AdminPaymentSettingsProps {
  gatewayType: GatewayType
  title: string
  posIdLabel: string
  secondKeyLabel: string
  clientIdLabel: string
  clientSecretLabel: string
}

export const AdminPaymentSettings = ({
  gatewayType,
  title,
  posIdLabel,
  secondKeyLabel,
  clientIdLabel,
  clientSecretLabel
}: AdminPaymentSettingsProps) => {
  const [saveError, setSaveError] = useState(null)
  const [fetch, response, status] = useNetworkRequest(() =>
    ApiInterceptor.get<PaymentSettings>(`/api/payment-settings/${gatewayType}`)
  )

  const handleSubmit = useCallback(
    async (
      values: PaymentSettingsFormValues,
      { resetForm }: FormikHelpers<PaymentSettingsFormValues>
    ) => {
      try {
        await ApiInterceptor.post<PaymentSettingsFormValues>(
          '/api/payment-settings',
          {
            ...values,
            gatewayType,
            provisionPercent: getProvisionWithCorrectComma(
              values.provisionPercent
            )
          }
        )
        resetForm({ values })
      } catch (e) {
        networkError(e).on4xx5xx(() => setSaveError(e))
      }
    },
    [gatewayType]
  )

  useEffect(() => {
    fetch()
  }, [fetch])

  return (
    <>
      <Helmet>
        <title>Składki - ustawienia ogólne</title>
      </Helmet>
      <PagePaper loading={status === NetworkRequestStatus.LOADING}>
        <TextRegular variant="large">Ustawienia płatności {title}</TextRegular>
        <Formik<PaymentSettingsFormValues>
          initialValues={response ? response.data : initialValues}
          onSubmit={handleSubmit}
          validate={validate}
        >
          {({ submitForm, dirty }) => (
            <Box mt={4}>
              <TextRegular variant="medium">Prowizja</TextRegular>
              <Box mt={1}>
                <TextRegular>
                  Ustal prowizję procentową, jaka będzie automatycznie doliczana
                  do każdej płatności i widoczna dla klubowicza.
                  <br />
                  Jeśli chcesz pokryć koszt prowizji w kwocie składki, wpisz 0.
                </TextRegular>
              </Box>
              <Box mt={1}>
                <InputField
                  name="provisionPercent"
                  label="Wysokość prowizji"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    )
                  }}
                />
                <Box mt={4}>
                  <TextRegular variant="medium">
                    Klucze konfiguracji {title}
                  </TextRegular>
                </Box>
                <Box mt={1} maxWidth={400}>
                  <View>
                    {posIdLabel && (
                      <InputField name="posId" label={posIdLabel} fullWidth />
                    )}
                    {secondKeyLabel && (
                      <InputField
                        name="secondKey"
                        type="password"
                        label={secondKeyLabel}
                        fullWidth
                      />
                    )}
                    {clientIdLabel && (
                      <InputField
                        name="clientId"
                        label={clientIdLabel}
                        fullWidth
                      />
                    )}
                    {clientSecretLabel && (
                      <InputField
                        name="clientSecret"
                        type="password"
                        label={clientSecretLabel}
                        fullWidth
                      />
                    )}
                  </View>
                </Box>
              </Box>
              <Box mt={3}>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={!dirty}
                  onClick={submitForm}
                >
                  {dirty ? 'Zapisz zmiany' : 'Brak zmian'}
                </Button>
              </Box>
              {saveError && (
                <FormError>
                  Wystąpił błąd. Spróbuj ponownie lub skontaktuj się z
                  administratorem.
                </FormError>
              )}
            </Box>
          )}
        </Formik>
      </PagePaper>
    </>
  )
}
