import { PagePaper } from '../../../Layout'
import React, { useEffect } from 'react'
import { TextRegular } from '../../../Text/TextRegular'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material'
import {
  NetworkRequestStatus,
  useNetworkRequest
} from '../../../../common/network-state'
import { ApiInterceptor } from '../../../Api/ApiInterceptor'
import { MembershipPaymentOrder } from '../../types'
import moment from 'moment'
import { AxiosResponse } from 'axios'
import { getFeeTypeLabel, getPaymentStatusLabel } from '../../helpers'
import { IoIosArrowRoundForward } from 'react-icons/io'
import { Button } from '@mui/material'
import { Link } from 'react-router-dom'
import { RouteLinks } from '../../../Routes/RouteNames'
import { FaMoneyCheckAlt } from 'react-icons/fa'
import { Helmet } from 'react-helmet'

export const AccountPaymentOrdersList = () => {
  const [fetch, data, status] = useNetworkRequest<
    AxiosResponse<MembershipPaymentOrder[]>
  >(() =>
    ApiInterceptor.get('/api/membership/payment-order', {
      headers: {
        Accept: 'application/vnd.membership.v2.getOrdersList+json'
      }
    })
  )

  useEffect(() => {
    fetch()
  }, [fetch])

  return (
    <>
      <Helmet>
        <title>Moje składki</title>
      </Helmet>
      <PagePaper loading={status === NetworkRequestStatus.LOADING}>
        <TextRegular variant="large">Moje składki członkowskie</TextRegular>
        <Box my={2}>
          <Link tabIndex={-1} to={RouteLinks.AccountPaymentCreator}>
            <Button
              color="primary"
              variant="contained"
              endIcon={<FaMoneyCheckAlt size={24} />}
            >
              Opłać nową składkę
            </Button>
          </Link>
        </Box>
        {status === NetworkRequestStatus.LOADED && data.data.length > 0 ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Data</TableCell>
                <TableCell>Typ</TableCell>
                <TableCell>Za okres</TableCell>
                <TableCell>Kwota</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.data.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>
                    {moment(item.createdTime).format('YYYY-MM-DD HH:mm:ss')}
                  </TableCell>
                  <TableCell>
                    {item.paymentItems
                      .map((paymentItem) =>
                        getFeeTypeLabel(paymentItem.paymentItemDetails.feeType)
                      )
                      .join(', ')}
                  </TableCell>
                  <TableCell>
                    {item.paymentDetails.startDate} -{' '}
                    {item.paymentDetails.endDate}
                  </TableCell>
                  <TableCell>{item.totalAmount} zł</TableCell>
                  <TableCell>
                    {getPaymentStatusLabel(item.gatewayType, item.gateway)}
                  </TableCell>
                  <TableCell>
                    <Link
                      tabIndex={-1}
                      to={RouteLinks.AccountPaymentOrderDetails.replace(
                        ':orderId',
                        item.id
                      )}
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        endIcon={<IoIosArrowRoundForward size={24} />}
                      >
                        potwierdzenie
                      </Button>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Box mt={3}>
            <TextRegular>Nie masz opłaconych żadnych składek.</TextRegular>
          </Box>
        )}
      </PagePaper>
    </>
  )
}
