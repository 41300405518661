import { ApiInterceptor } from '../Api/ApiInterceptor'
import { RangeDTO } from './types'
import { RangeLane } from './Lanes/types'

export function getRangesWithLanes() {
  return ApiInterceptor.get<RangeDTO[]>('/api/range', {
    headers: {
      Accept: 'application/vnd.ranges.listHavingLanes.v1+json'
    }
  })
}

export function getRangeLanes(rangeId: number) {
  return ApiInterceptor.get<RangeLane[]>(`/api/range/${rangeId}/lanes`)
}

export function getRange(rangeId: number | string) {
  return ApiInterceptor.get<RangeDTO>(`/api/range/${rangeId}`)
}
