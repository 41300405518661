import React, { useEffect } from 'react'
import { PagePaper } from '../Layout'
import { Table, TableBody, TableCell, TableRow } from '@mui/material'
import {
  NetworkRequestStatus,
  useNetworkRequest
} from '../../common/network-state'
import { ApiInterceptor } from '../Api/ApiInterceptor'
import { RangeDTO } from './types'
import { Link } from 'react-router-dom'
import { IoArrowForwardOutline } from 'react-icons/io5'
import { RouteLinks } from '../Routes/RouteNames'
import IconButton from '@mui/material/IconButton'
import styled from 'styled-components'
import { formatRoute } from '../../helpers/formatRoute'

export const RangesList = () => {
  const [fetch, ranges, status] = useNetworkRequest(
    () => ApiInterceptor.get<RangeDTO[]>('/api/range/all'),
    []
  )

  useEffect(() => {
    fetch()
  }, [fetch])

  return (
    <PagePaper
      title="Strzelnice"
      loading={status === NetworkRequestStatus.LOADING}
    >
      <Table>
        <TableBody>
          {ranges?.data?.map((range) => (
            <TableRow hover key={range.id}>
              <TableCell>{range.name}</TableCell>
              <TableCell>{range.address}</TableCell>
              <TableCell>
                <Link
                  to={formatRoute(RouteLinks.RangeDetails, {
                    rangeId: range.id
                  })}
                >
                  <ProceedIconButton color="primary">
                    <IoArrowForwardOutline />
                  </ProceedIconButton>
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </PagePaper>
  )
}

const ProceedIconButton = styled(IconButton)`
  visibility: hidden;
  tr:hover & {
    visibility: visible;
  }
`
