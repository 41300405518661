import { Link } from '../../../Link'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar
} from '@mui/material'
import Button from '@mui/material/Button'
import React, { useCallback } from 'react'
import { useToggle } from '../../../../hooks/useToggle'
import { ApiInterceptor } from '../../../Api/ApiInterceptor'

export interface CancelManualPaymentProps {
  orderId: string
  onChange: () => void
}

export function CancelManualPayment({
  orderId,
  onChange
}: CancelManualPaymentProps) {
  const [isCancelModalOpen, openCancelModal, closeCancelModal] = useToggle()
  const [isFeedbackOpen, openFeedback, closeFeedback] = useToggle()
  const cancelPayment = useCallback(async () => {
    await ApiInterceptor.delete(
      `/api/membership-admin/payment-order/${orderId}`
    )
    closeCancelModal()
    // TODO: the feedback is eaten because the onChange reloads the orders and this very component is gone
    openFeedback()
    onChange()
  }, [orderId, closeCancelModal, onChange, openFeedback])

  return (
    <>
      <Link onClick={openCancelModal}>Anuluj</Link>
      <Dialog open={isCancelModalOpen} onClose={closeCancelModal}>
        <DialogTitle>Usuń płatność</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Czy na pewno chcesz usunąć tę płatność? Data ważności konta
            klubowicza zostanie zaktualizowana.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={closeCancelModal}>
            Anuluj
          </Button>
          <Button variant="contained" color="primary" onClick={cancelPayment}>
            Usuń płatność
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={isFeedbackOpen}
        autoHideDuration={3000}
        message="Płatność została usunięta"
        onClose={closeFeedback}
      />
    </>
  )
}
