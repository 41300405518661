import {
  FormValues,
  FormTimeRange,
  TimeRange,
  RangeOpeningHoursPayload,
  RangeOpeningHours,
  TimeRangeMillis
} from './types'
import { DateTime, Duration } from 'luxon'

export const timeStringToDate = (timeString: string): DateTime => {
  return DateTime.fromFormat(timeString, 'HH:mm:ss')
}

export const dateTimeToString = (time: DateTime): string => {
  return time.toFormat('HH:mm:ss')
}

export const openingHoursToFormValues = (
  openingHours: RangeOpeningHours
): FormValues => ({
  monday: timeStringToTimeRange(openingHours.monday),
  tuesday: timeStringToTimeRange(openingHours.tuesday),
  wednesday: timeStringToTimeRange(openingHours.wednesday),
  thursday: timeStringToTimeRange(openingHours.thursday),
  friday: timeStringToTimeRange(openingHours.friday),
  saturday: timeStringToTimeRange(openingHours.saturday),
  sunday: timeStringToTimeRange(openingHours.sunday)
})

export const formValuesToApi = (
  formValues: FormValues
): RangeOpeningHoursPayload => ({
  monday: timeRangeToStringRange(formValues.monday),
  tuesday: timeRangeToStringRange(formValues.tuesday),
  wednesday: timeRangeToStringRange(formValues.wednesday),
  thursday: timeRangeToStringRange(formValues.thursday),
  friday: timeRangeToStringRange(formValues.friday),
  saturday: timeRangeToStringRange(formValues.saturday),
  sunday: timeRangeToStringRange(formValues.sunday)
})

export const openingHoursToMillis = (
  openingHours: TimeRange
): TimeRangeMillis => ({
  from: Duration.fromISOTime(openingHours.from).toMillis(),
  to: Duration.fromISOTime(openingHours.to).toMillis()
})

const timeStringToTimeRange = (timeString: TimeRange): FormTimeRange => ({
  from: timeStringToDate(timeString.from),
  to: timeStringToDate(timeString.to)
})

const timeRangeToStringRange = (time: FormTimeRange): TimeRange => ({
  from: dateTimeToString(time.from),
  to: dateTimeToString(time.to)
})
