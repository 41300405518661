import { AccountDTO } from '../Account/types'
import { CreateAccountPayload, CreateAccountSerializablePayload } from './types'
import { Box, IconButton, TableCell, TableRow, Tooltip } from '@mui/material'
import { InputField } from '../Form'
import React, { ReactNode } from 'react'
import { useField } from 'formik'
import { BsBoxArrowRight } from 'react-icons/bs'

export interface FieldRowProps {
  account: AccountDTO
  payload: CreateAccountSerializablePayload
  name: keyof CreateAccountPayload
  label: string
  inputComponent?: ReactNode
}

export function FieldRow({
  account,
  payload,
  name,
  label,
  inputComponent
}: FieldRowProps) {
  const [, , { setValue }] = useField(name)

  if (!account || !payload) {
    return null
  }

  if (
    !account[name] ||
    account[name].toLowerCase() === payload[name].toLowerCase()
  ) {
    return null
  }

  return (
    <TableRow>
      <TableCell>{label}</TableCell>
      <TableCell>
        <Box display="flex" alignItems="center" style={{ gap: '8px' }}>
          {account[name]}{' '}
          <Tooltip title="Wstaw wartość" disableInteractive>
            <IconButton onClick={() => setValue(account[name])}>
              <BsBoxArrowRight size={16} />
            </IconButton>
          </Tooltip>
        </Box>
      </TableCell>
      <TableCell>
        <Box display="flex" alignItems="center" style={{ gap: '8px' }}>
          {payload[name]}{' '}
          <Tooltip title="Wstaw wartość" disableInteractive>
            <IconButton onClick={() => setValue(payload[name])}>
              <BsBoxArrowRight size={16} />
            </IconButton>
          </Tooltip>
        </Box>
      </TableCell>
      <TableCell>
        {inputComponent || <InputField name={name} label={label} />}
      </TableCell>
    </TableRow>
  )
}
