import { HorizontalView, View } from '../../../Layout'
import React, { FC, ReactNode, useEffect, useMemo } from 'react'
import { MemberFeePeriodType } from '../../types'
import { TextRegular } from '../../../Text/TextRegular'
import { Box, InputAdornment } from '@mui/material'
import { elevation } from '../../../../styles/elevation'
import {
  ageRangeLabel,
  genderLabel,
  introductoryLabel,
  periodTypeLabel,
  purposeLabel,
  serviceLabel
} from '../../Account/PaymentCreator/criteriaDescriptionHelper'
import {
  EntryFeeWizardUnits,
  FeeConfigWizard,
  FeeUnitWizard,
  MemberFeeUnitWizard,
  MemberFeeWizardConfig
} from './duck/types'
import { InputField } from '../../../Form'
import styled from 'styled-components'
import { IoMdCheckmarkCircleOutline } from 'react-icons/io'
import { validateAmount } from './helpers'
import { FirstPaymentUnitField } from './FirstPaymentUnitField'
import {
  calculateFirstPaymentUnitPrice,
  getEffectiveFirstPaymentDensity,
  periodWeight
} from './firstPayment.helpers'
import { FormikHelpers } from 'formik'

export interface UnitCardProps {
  index: number
  config: FeeConfigWizard
  unit: FeeUnitWizard
  isEntry: boolean
  values: EntryFeeWizardUnits
  setFieldValue: FormikHelpers<MemberFeeWizardConfig>['setFieldValue']
}

const firstPaymentUnitFieldName = (i1, i2) =>
  `${i1}.firstPaymentUnits.${i2}.amount`

export const UnitCard: FC<UnitCardProps> = ({
  index,
  config,
  unit,
  isEntry,
  values,
  setFieldValue
}) => {
  const effectivePeriodType = isEntry
    ? MemberFeePeriodType.YEAR
    : (unit as MemberFeeUnitWizard).periodType
  const effectiveDensity = getEffectiveFirstPaymentDensity(
    config.firstPaymentPeriodDensity,
    effectivePeriodType
  )
  const currentAmount = values[index].amount
  const showFirstPayment =
    periodWeight[effectiveDensity] < periodWeight[effectivePeriodType]
  const title = useMemo(() => {
    return isEntry
      ? 'Wpisowe'
      : `Składka ${periodTypeLabel((unit as MemberFeeUnitWizard).periodType)}`
  }, [isEntry, unit])

  // Populate proportional or not editable first payments
  useEffect(() => {
    if (config.enableFirstPaymentPeriod) {
      unit.firstPaymentUnits.forEach((firstUnit, i) => {
        const newPrice = calculateFirstPaymentUnitPrice(
          config,
          firstUnit,
          currentAmount,
          i,
          effectiveDensity,
          effectivePeriodType
        )

        if (newPrice) {
          setFieldValue(firstPaymentUnitFieldName(index, i), newPrice)
        }
      })
    }
  }, [
    config,
    unit,
    setFieldValue,
    index,
    currentAmount,
    effectiveDensity,
    effectivePeriodType
  ])

  return (
    <CardWrapper>
      <HorizontalView alignItems="flex-end">
        <Box flexGrow={1}>
          <Box mb={2}>
            <TextRegular variant="large">{title}</TextRegular>
          </Box>
          <HorizontalView>
            <Box>
              {config.ageApplies && (
                <CriteriaItem>{ageRangeLabel(unit.ageRange)}</CriteriaItem>
              )}
              {config.purposeApplies && (
                <CriteriaItem>{purposeLabel(unit.purpose)}</CriteriaItem>
              )}
              {config.serviceApplies && (
                <CriteriaItem>{serviceLabel(unit.isService)}</CriteriaItem>
              )}
              {config.genderApplies && (
                <CriteriaItem>{genderLabel(unit.gender)}</CriteriaItem>
              )}
              {config.introductoryApplies && (
                <CriteriaItem>
                  {introductoryLabel(unit.hasIntroductory)}
                </CriteriaItem>
              )}
            </Box>
          </HorizontalView>
        </Box>
        <Box>
          <InputField
            autoFocus={index === 0}
            name={`${index}.amount`}
            label="Kwota"
            placeholder="np. 100"
            noMargin
            validate={validateAmount}
            InputProps={{
              endAdornment: <InputAdornment position="end">zł</InputAdornment>
            }}
          />{' '}
        </Box>
      </HorizontalView>
      {config.enableFirstPaymentPeriod && (
        <Box mt={3}>
          <View alignItems="flex-end">
            <TextRegular variant="medium">Pomniejszone płatności</TextRegular>
            {showFirstPayment ? (
              <>
                {!values[index].amount && (
                  <TextRegular light>Uzupełnij główną kwotę...</TextRegular>
                )}
                {values[index].amount &&
                  unit.firstPaymentUnits.map((firstPaymentUnit, i) => (
                    <FirstPaymentUnitField
                      key={i}
                      config={config}
                      unit={firstPaymentUnit}
                      value={values[index].firstPaymentUnits[i].amount}
                      periodType={effectivePeriodType}
                      name={firstPaymentUnitFieldName(index, i)}
                    />
                  ))}
              </>
            ) : (
              <TextRegular light>Takie same stawki, jak główna</TextRegular>
            )}
          </View>
        </Box>
      )}
    </CardWrapper>
  )
}

const CardWrapper = styled(View)`
  border: 1px solid #ccc;
  box-shadow: ${elevation(2)};
  margin: 8px 0;
  border-radius: 4px;
  padding: 16px;
`

const CriteriaItem: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <Box>
      <CriteriaIcon />
      {children}
    </Box>
  )
}
const CriteriaIcon = styled(IoMdCheckmarkCircleOutline)`
  margin-right: 8px;
`
