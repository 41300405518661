import React, { useState } from 'react'
import { RangeBlockedLane, RangeCombinedEvent } from './types'
import { styled, Tooltip } from '@mui/material'
import { colors, fontSize } from '../../styles/variables'
import { useToggle } from '../../hooks/useToggle'
import { NewEventDialog } from './NewEventDialog'
import { CalendarEventBadge } from './CalendarEventBadge'
import { CalendarEventDetails } from './CalendarEventDetails'
import { DeleteBlockadeDialog } from './DeleteBlockadeDialog'

export interface CalendarEventProps {
  event: RangeCombinedEvent
  onSuccess: () => void
}

export function CalendarEvent({ event, onSuccess }: CalendarEventProps) {
  const [detailsVisible, showDetails, hideDetails] = useToggle()
  const [newBlockadeVisible, showNewBlockade, hideNewBlockade] = useToggle()
  const [deleteBlockedLaneEvent, setDeleteBlockedLaneEvent] =
    useState<RangeCombinedEvent>(null)
  const [deleteBlockedLane, setDeleteBlockedLane] =
    useState<RangeBlockedLane>(null)

  const handleShowNewBlockade = () => {
    hideDetails()
    showNewBlockade()
  }

  const handleShowDeleteBlockade = (
    event: RangeCombinedEvent,
    blockedLane: RangeBlockedLane
  ) => {
    hideDetails()
    setDeleteBlockedLaneEvent(event)
    setDeleteBlockedLane(blockedLane)
  }

  const handleNewBlockadeSuccess = () => {
    hideNewBlockade()
    onSuccess()
  }

  const handleDeleteBlockadeSuccess = () => {
    setDeleteBlockedLaneEvent(null)
    setDeleteBlockedLane(null)
    onSuccess()
  }

  return (
    <>
      <EventDetailsTooltip
        title={
          <CalendarEventDetails
            event={event}
            onClickAway={hideDetails}
            onNewBlockade={handleShowNewBlockade}
            onDeleteBlockade={handleShowDeleteBlockade}
          />
        }
        arrow
        onClose={hideDetails}
        open={detailsVisible}
        disableFocusListener
        disableHoverListener
        disableTouchListener
      >
        <CalendarEventBadge event={event} onClick={showDetails} />
      </EventDetailsTooltip>
      <NewEventDialog
        open={newBlockadeVisible}
        onClose={hideNewBlockade}
        event={event}
        onSuccess={handleNewBlockadeSuccess}
      />
      <DeleteBlockadeDialog
        open={!!deleteBlockedLane}
        onClose={() => setDeleteBlockedLane(null)}
        blockedLaneEvent={deleteBlockedLaneEvent}
        blockedLane={deleteBlockedLane}
        onSuccess={handleDeleteBlockadeSuccess}
      />
    </>
  )
}

const EventDetailsTooltip = styled(Tooltip)(({ theme }) => ({
  '&.MuiTooltip-arrow': {
    color: '#fff',
    '&::before': {
      borderWidth: '1px 0 0 1px',
      borderColor: '#d6d6d6',
      borderStyle: 'solid'
    }
  },
  '&.MuiTooltip-tooltip': {
    backgroundColor: '#fff',
    border: '1px solid #d6d6d6',
    borderRadius: '4px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    color: colors.tar,
    fontSize: fontSize.regular,
    maxWidth: '600px',
    padding: `${theme.spacing(3)}`
  }
}))
