import { Action } from 'redux'
import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { createSelector } from 'reselect'
import { AxiosResponse } from 'axios'
import { NetworkRequestStatus } from '../common/network-state'

export interface NetworkDataState<DataType, ErrorType> {
  status: NetworkRequestStatus
  data: DataType
  error: ErrorType
}

interface NetworkDataAction<DataType, ErrorType> extends Action {
  type: string
  payload: DataType | ErrorType
}

const getInitialState = <DataType, ErrorType>(): NetworkDataState<
  DataType,
  ErrorType
> => ({
  status: NetworkRequestStatus.IDLE,
  data: null,
  error: null
})

export const networkDataDuckFactory = <DataType, ErrorType>(
  duckName: string,
  fetchFn: () => Promise<AxiosResponse<DataType>>,
  storePrefix?: string
) => {
  const actionNames = {
    REQUEST: `${duckName}/REQUEST`,
    RECEIVE: `${duckName}/RECEIVE`,
    FAILED: `${duckName}/FAILED`,
    RESET: `${duckName}/RESET`
  }

  const reducer = (
    state: NetworkDataState<DataType, ErrorType> = getInitialState(),
    action: NetworkDataAction<DataType, ErrorType>
  ): NetworkDataState<DataType, ErrorType> => {
    switch (action.type) {
      case actionNames.REQUEST:
        return { ...state, status: NetworkRequestStatus.LOADING }
      case actionNames.RECEIVE:
        return {
          ...state,
          status: NetworkRequestStatus.LOADED,
          data: action.payload as DataType
        }
      case actionNames.FAILED:
        return {
          ...state,
          status: NetworkRequestStatus.ERROR,
          error: action.payload as ErrorType
        }
      case actionNames.RESET:
        return getInitialState()
      default:
        return state
    }
  }

  const actions = {
    useFetch: () => {
      const dispatch = useDispatch()
      return useCallback(async () => {
        dispatch({ type: actionNames.REQUEST })

        try {
          const response = await fetchFn()
          dispatch({ type: actionNames.RECEIVE, payload: response.data })
        } catch (e) {
          dispatch({ type: actionNames.FAILED, payload: e })
        }
      }, [dispatch])
    },
    useReset: () => {
      const dispatch = useDispatch()
      return useCallback(() => {
        dispatch({ type: actionNames.RESET })
      }, [dispatch])
    }
  }

  const stateSelector = storePrefix
    ? (state): NetworkDataState<DataType, ErrorType> =>
        state[storePrefix][duckName]
    : (state): NetworkDataState<DataType, ErrorType> => state[duckName]

  const selectors = {
    status: createSelector(stateSelector, (state) => state.status),
    data: createSelector(stateSelector, (state) => state.data),
    error: createSelector(stateSelector, (state) => state.error)
  }

  return {
    actionNames,
    actions,
    reducer,
    selectors
  }
}
