import { Box } from '@mui/material'
import { TextRegular } from '../../../Text/TextRegular'
import { PagePaper } from '../../../Layout'
import React from 'react'
import { Helmet } from 'react-helmet'

export const NoPaymentConfigs = () => (
  <PagePaper>
    <Helmet>
      <title>Brak opcji płatności</title>
    </Helmet>
    <Box mt={2}>
      <TextRegular variant="large">Brak płatności</TextRegular>
    </Box>
    <Box mt={3}>
      <TextRegular>
        Nie mamy dla Ciebie żadnej opcji płatności. Skontaktuj się z obsługą
        klubu.
      </TextRegular>
    </Box>
  </PagePaper>
)
