import { TextRegular } from '../../../Text/TextRegular'
import { Box } from '@mui/material'
import moment from 'moment'
import { plural } from '../../../../helpers/plural'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Paper } from '../../../Paper/Paper'
import { muiTheme } from '../../../../common/muiTheme'
import { View } from '../../../Layout'
import { IoMdCheckmarkCircleOutline } from 'react-icons/io'
import { useSelector } from 'react-redux'
import {
  latestFeeConfigSelector,
  entryFeeUnitsCountSelector,
  formValuesSelector,
  memberFeeUnitsCountSelector
} from './duck'
import { Alert } from '../../../Alert'

export const Sidebar = () => {
  const formValues = useSelector(formValuesSelector)
  const entryFeeConfig = formValues.entryFeeConfig
  const memberFeeConfig = formValues.memberFeeConfig
  const entryFeeAgeRanges = formValues.entryFeeAgeRanges
  const memberFeeAgeRanges = formValues.memberFeeAgeRanges
  const entryFeesCount = useSelector(entryFeeUnitsCountSelector)
  const memberFeesCount = useSelector(memberFeeUnitsCountSelector)
  const latestConfig = useSelector(latestFeeConfigSelector)
  const entryFeesCountDetermined = useMemo<boolean>(() => {
    return !entryFeeConfig.ageApplies || entryFeeAgeRanges.length > 0
  }, [entryFeeConfig, entryFeeAgeRanges])
  const memberFeesCountDetermined = useMemo<boolean>(
    () => !memberFeeConfig.ageApplies || memberFeeAgeRanges.length > 0,
    [memberFeeConfig, memberFeeAgeRanges]
  )

  return (
    <Wrapper>
      <TextRegular variant="large">Podsumowanie</TextRegular>
      {latestConfig && (
        <Alert vertical>
          Obecna konfiguracja obowiązuje od{' '}
          {moment(latestConfig.startTime).format('D. MMMM YYYY')}
          {latestConfig.endTime
            ? ` do ${moment(latestConfig.endTime).format('D. MMMM YYYY')}`
            : ''}
          .
          <br />
          Po utworzeniu nowej konfiguracji, poprzednia zostanie zakończona z
          dniem poprzedzającym nową.
        </Alert>
      )}
      <Box mt={3}>
        <SidebarLabel>Ilość dostępnych wariantów:</SidebarLabel>
        <TextRegular>
          Wpisowe:
          {entryFeesCountDetermined ? '' : ' od'} {entryFeesCount}
          <br />
          Składki:
          {memberFeesCountDetermined ? '' : ' od'} {memberFeesCount}
        </TextRegular>
      </Box>
      <Box mt={3}>
        <SidebarLabel>Kryteria wpisowego:</SidebarLabel>
        {!entryFeeConfig.ageApplies &&
          !entryFeeConfig.purposeApplies &&
          !entryFeeConfig.serviceApplies &&
          !entryFeeConfig.genderApplies &&
          !entryFeeConfig.introductoryApplies && (
            <TextRegular light>brak</TextRegular>
          )}
        {entryFeeConfig.ageApplies && (
          <ConfigLabel>
            <ConfigLabelIcon />
            wiek
            {entryFeeAgeRanges.length
              ? ` (${entryFeeAgeRanges.length + 1} ${plural(
                  entryFeeAgeRanges.length + 1,
                  'przedział',
                  'przedziały',
                  'przedziałów'
                )})`
              : ''}
          </ConfigLabel>
        )}
        {entryFeeConfig.purposeApplies && (
          <ConfigLabel>
            <ConfigLabelIcon />
            cel
          </ConfigLabel>
        )}
        {entryFeeConfig.serviceApplies && (
          <ConfigLabel>
            <ConfigLabelIcon />
            służby
          </ConfigLabel>
        )}
        {entryFeeConfig.genderApplies && (
          <ConfigLabel>
            <ConfigLabelIcon />
            płeć
          </ConfigLabel>
        )}
        {entryFeeConfig.introductoryApplies && (
          <ConfigLabel>
            <ConfigLabelIcon />
            wprowadzający
          </ConfigLabel>
        )}
      </Box>
      <Box mt={3}>
        <SidebarLabel>Kryteria składki:</SidebarLabel>
        {!memberFeeConfig.ageApplies &&
          !memberFeeConfig.purposeApplies &&
          !memberFeeConfig.serviceApplies &&
          !memberFeeConfig.genderApplies &&
          !memberFeeConfig.introductoryApplies && (
            <TextRegular light>brak</TextRegular>
          )}
        {memberFeeConfig.ageApplies && (
          <ConfigLabel>
            <ConfigLabelIcon />
            wiek
            {entryFeeAgeRanges.length
              ? ` (${entryFeeAgeRanges.length + 1} ${plural(
                  entryFeeAgeRanges.length + 1,
                  'przedział',
                  'przedziały',
                  'przedziałów'
                )})`
              : ''}
          </ConfigLabel>
        )}
        {memberFeeConfig.purposeApplies && (
          <ConfigLabel>
            <ConfigLabelIcon />
            cel
          </ConfigLabel>
        )}
        {memberFeeConfig.serviceApplies && (
          <ConfigLabel>
            <ConfigLabelIcon />
            służby
          </ConfigLabel>
        )}
        {memberFeeConfig.genderApplies && (
          <ConfigLabel>
            <ConfigLabelIcon />
            płeć
          </ConfigLabel>
        )}
        {memberFeeConfig.introductoryApplies && (
          <ConfigLabel>
            <ConfigLabelIcon />
            wprowadzający
          </ConfigLabel>
        )}
      </Box>
    </Wrapper>
  )
}

const Wrapper = styled(Paper)`
  ${muiTheme.breakpoints.up('md')} {
    margin-left: 24px;
    width: 300px;
    flex-shrink: 0;
  }
`

const SidebarLabel = styled(TextRegular)`
  display: block;
  font-weight: bold;
  margin-bottom: 4px;
`

const ConfigLabel = styled(View)`
  align-items: center;
  flex-direction: row;
  margin-top: 4px;
`

const ConfigLabelIcon = styled(IoMdCheckmarkCircleOutline)`
  margin-right: 4px;
`
