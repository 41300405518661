import { plural } from '../../helpers/plural'
import {
  AnyGateway,
  CreateMemberFeeAgeRange,
  GatewayManual,
  GatewayP24,
  GatewayPayU
} from './types'
import {
  GatewayType,
  ManualPaymentReason,
  P24OrderStatus,
  PaymentFeeType,
  PayUOrderStatus
} from './enums'

const yearsPlural = (value: number) => plural(value, 'rok', 'lata', 'lat')
export function getAgeRangeText(ageRange: CreateMemberFeeAgeRange): string {
  if (ageRange.from && ageRange.to) {
    return `${ageRange.from}-${ageRange.to} ${yearsPlural(ageRange.to)}`
  }

  if (!ageRange.from) {
    return `poniżej ${ageRange.to} ${yearsPlural(ageRange.to)}`
  }

  return `od ${ageRange.from} ${yearsPlural(ageRange.from)}+`
}

export function getPaymentStatusLabel(
  gatewayType: GatewayType,
  gateway: AnyGateway
): string {
  switch (gatewayType) {
    case GatewayType.MANUAL:
      switch ((gateway as GatewayManual).reason) {
        case ManualPaymentReason.CORRECTION:
          return 'Zakończona (korekta)'
        case ManualPaymentReason.SYNC:
          return 'Zakończona (wyrównanie)'
        case ManualPaymentReason.EXEMPT:
          return 'Zakończona (zwolniony)'
        case ManualPaymentReason.OTHER:
        default:
          return 'Zakończona (manualna)'
      }
    case GatewayType.PAY_U:
      switch ((gateway as GatewayPayU).status) {
        case PayUOrderStatus.NEW:
          return 'Nowa'
        case PayUOrderStatus.WAITING_FOR_CONFIRMATION:
          return 'Czeka na potwierdzenie'
        case PayUOrderStatus.PENDING:
          return 'W trakcie'
        case PayUOrderStatus.COMPLETED:
          return 'Zakończona'
        case PayUOrderStatus.CANCELED:
          return 'Anulowana'
        case PayUOrderStatus.REJECTED:
          return 'Odrzucona'
        case PayUOrderStatus.REFUNDED:
          return 'Zwrot'
        default:
          return ''
      }
    case GatewayType.P24:
      switch ((gateway as GatewayP24).status) {
        case P24OrderStatus.NEW:
          return 'Nowa'
        case P24OrderStatus.VERIFIED:
          return 'Zakończona'
        default:
          return ''
      }
    default:
      return ''
  }
}

export function getGatewayTypeLabel(
  gatewayType: GatewayType,
  gateway: AnyGateway
): string {
  switch (gatewayType) {
    case GatewayType.PAY_U:
      return `PayU (${getPaymentStatusLabel(gatewayType, gateway)})`
    case GatewayType.P24:
      return `Przelewy24 (${getPaymentStatusLabel(gatewayType, gateway)})`
    case GatewayType.MANUAL:
      return 'Manualna'
    default:
      return ''
  }
}

export function getFeeTypeLabel(feeType: PaymentFeeType): string {
  switch (feeType) {
    case PaymentFeeType.ENTRY_FEE:
      return 'wpisowe'
    case PaymentFeeType.MEMBER_FEE:
      return 'składka'
    case PaymentFeeType.LICENSE_FEE:
      return 'licencja'
    default:
      return ''
  }
}
