import React from 'react'
import { SsoAuth } from './SsoAuth'
import { SsoVendor } from './enums'

export function SsoForum() {
  return (
    <SsoAuth
      vendor={SsoVendor.FORUM}
      vendorUrl={process.env.REACT_APP_FORUM_URL}
      title="Zaloguj się do Forum"
      proceedText="Przejdź do forum"
      redirectSoonText="Za chwilę nastąpi przekierowanie do forum..."
    />
  )
}
