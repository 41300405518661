import {
  Button,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListItemTextProps
} from '@mui/material'
import { IoChevronForward } from 'react-icons/io5'
import React, { FC } from 'react'

export interface AdminNewPaymentListItemProps {
  primary: ListItemTextProps['primary']
  secondary?: ListItemTextProps['secondary']
  onClick: () => void
}

export const AdminNewPaymentListItem: FC<AdminNewPaymentListItemProps> = ({
  primary,
  secondary,
  onClick
}) => {
  return (
    <ListItem>
      <ListItemText primary={primary} secondary={secondary} />
      <ListItemSecondaryAction>
        <Button
          variant="contained"
          color="primary"
          size="small"
          endIcon={<IoChevronForward />}
          onClick={onClick}
        >
          Wybierz
        </Button>
      </ListItemSecondaryAction>
    </ListItem>
  )
}
