import React, { FC, ReactNode } from 'react'
import { View, ViewProps } from './View'
import styled from 'styled-components'
import { Loading } from '../Loading'
import { muiTheme } from '../../common/muiTheme'
import { Helmet } from 'react-helmet'
import Box from '@mui/material/Box'
import { TextRegular } from '../Text/TextRegular'
import { HorizontalView } from './HorizontalView'

export interface PagePaperProps extends ViewProps {
  loading?: boolean
  variant?: 'large' | 'normal' | 'small'
  title?: string
  sideContent?: ReactNode
}

export const PagePaper: FC<PagePaperProps> = ({
  children,
  loading,
  variant = 'normal',
  title,
  sideContent,
  ...props
}) => {
  return (
    <PageContent data-test="PagePaper" variant={variant} {...props}>
      {!!title && (
        <>
          <Helmet>
            <title>{title}</title>
          </Helmet>
          <Box mb={5}>
            <HorizontalView justify="space-between">
              <TextRegular data-test="PagePaper-title" size="large">
                {title}
              </TextRegular>
              {sideContent}
            </HorizontalView>
          </Box>
        </>
      )}
      {loading ? (
        <Box mt={3} display="flex" justifyContent="center">
          <Loading />
        </Box>
      ) : (
        children
      )}
    </PageContent>
  )
}

interface PageContentProps {
  variant?: PagePaperProps['variant']
}

export const PageContent = styled(View)<PageContentProps>`
  margin: 30px 20px;
  padding: 20px;
  min-height: 200px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: ${(p) => {
    switch (p.variant) {
      case 'large':
        return '900px'
      case 'normal':
        return '700px'
      case 'small':
        return '400px'
    }
  }};

  @media (min-width: ${(p) => {
      switch (p.variant) {
        case 'large':
          return '980px'
        case 'normal':
          return '780px'
        case 'small':
          return '480px'
      }
    }}) {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  ${muiTheme.breakpoints.up('md')} {
    min-height: 360px;
    margin: 40px auto;
    padding: 40px;
  }
`
