import { useField } from 'formik'
import React, { FC } from 'react'
import { Select, SelectProps } from '@mui/material'
import { FormError } from '../FormError'
import Box from '@mui/material/Box'

export const SelectField: FC<SelectProps> = ({ name, onChange, ...props }) => {
  const [{ onChange: onChangeField, value, ...field }, { touched, error }] =
    useField(name)

  const handleChange = (e, child) => {
    if (onChange) {
      onChange(e, child)
    }
    onChangeField(e)
  }

  return (
    <>
      <Select
        error={touched && !!error}
        {...field}
        value={value === null ? '' : value}
        onChange={handleChange}
        {...props}
      />
      {touched && !!error && (
        <Box mt={1}>
          <FormError>{error}</FormError>
        </Box>
      )}
    </>
  )
}
