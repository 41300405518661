import React, { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { View } from '../../../Layout'
import { CurrentStepLabel } from './CurrentStepLabel'
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel
} from '@mui/material'
import { formValuesSelector, useGoToNextStep, useSetFormValues } from './duck'
import { useSelector } from 'react-redux'
import { FormError } from '../../../Form'
import { MemberFeePeriodType } from '../../types'
import { PeriodLabel } from '../../enums'
import { WizardButtons } from './WizardButtons'
import {
  firstPaymentPeriodDensityLabel,
  getLowestWeightPeriodType,
  periodWeight
} from './firstPayment.helpers'
import { Alert } from '../../../Alert'

export const periodsOrdered = [
  MemberFeePeriodType.YEAR,
  MemberFeePeriodType.HALF_YEAR,
  MemberFeePeriodType.QUARTER,
  MemberFeePeriodType.MONTH
]

export const MemberFeePeriodsStep = () => {
  const setFormValues = useSetFormValues('memberFeePeriods')
  const goToNextStep = useGoToNextStep()
  const [touched, setTouched] = useState(false)
  const formValues = useSelector(formValuesSelector)
  const [periodsSet] = useState(new Set(formValues.memberFeePeriods))

  const lowestWeightPeriod = useMemo(
    () => getLowestWeightPeriodType(formValues.memberFeePeriods),
    [formValues.memberFeePeriods]
  )
  const isDensityWeightTooBig = useMemo(
    () =>
      formValues.memberFeeConfig.enableFirstPaymentPeriod &&
      periodWeight[formValues.memberFeeConfig.firstPaymentPeriodDensity] >
        periodWeight[lowestWeightPeriod],
    [formValues.memberFeeConfig, lowestWeightPeriod]
  )

  const submit = useCallback(() => {
    setTouched(true)

    if (periodsSet.size > 0) {
      goToNextStep()
    }
  }, [setTouched, periodsSet, goToNextStep])

  const handleTogglePeriod = (e: ChangeEvent<HTMLInputElement>) => {
    const period: MemberFeePeriodType = e.target.value as MemberFeePeriodType

    if (periodsSet.has(period)) {
      periodsSet.delete(period)
    } else {
      periodsSet.add(period)
    }

    setFormValues(Array.from(periodsSet))
  }

  return (
    <View>
      <CurrentStepLabel />

      <Box mt={3}>
        <FormGroup>
          <FormLabel>Wybierz dostępne cykle składek.</FormLabel>
          {periodsOrdered.map((period) => (
            <FormControlLabel
              key={period}
              control={
                <Checkbox
                  onChange={handleTogglePeriod}
                  checked={periodsSet.has(period)}
                  color="primary"
                  value={period}
                />
              }
              label={PeriodLabel[period]}
            />
          ))}
        </FormGroup>
      </Box>
      {touched && periodsSet.size === 0 && (
        <Box mt={3}>
          <FormError>Zaznacz przynajmniej jedną opcję.</FormError>
        </Box>
      )}

      {isDensityWeightTooBig && (
        <Box mt={2}>
          <Alert warning>
            Niektóre z wybranych cykli (np. {PeriodLabel[lowestWeightPeriod]})
            mają większą częstotliwość niż cykl pomniejszonych płatności (
            {firstPaymentPeriodDensityLabel(
              formValues.memberFeeConfig.firstPaymentPeriodDensity
            )}
            ). Pomniejszone płatności dla tych cykli zostaną zredukowane.
          </Alert>
        </Box>
      )}

      <WizardButtons handleSubmit={submit} />
    </View>
  )
}
