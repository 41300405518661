import { ModifyBookingOperation } from '../types'

export interface RequestReducerState {
  isLoading: boolean
  operation: ModifyBookingOperation
  isSuccess: boolean
  isError: boolean
}

export type RequestReducerActionType =
  | 'startLoading'
  | 'stopLoading'
  | 'success'
  | 'error'
  | 'cleanResponse'
  | 'reset'

export const requestReducerInitialState: RequestReducerState = {
  isLoading: false,
  operation: null,
  isSuccess: false,
  isError: false
}

export function requestReducer(
  state: RequestReducerState,
  action: { type: RequestReducerActionType }
): RequestReducerState {
  switch (action.type) {
    case 'startLoading':
      return {
        ...state,
        isLoading: true
      }
    case 'stopLoading':
      return {
        ...state,
        isLoading: false,
        operation: null
      }
    case 'success':
      return {
        ...state,
        isLoading: false,
        operation: null,
        isSuccess: true,
        isError: false
      }
    case 'error':
      return {
        ...state,
        isLoading: false,
        operation: null,
        isSuccess: false,
        isError: true
      }
    case 'cleanResponse':
      return {
        ...state,
        isSuccess: false,
        isError: false
      }
    case 'reset':
      return { ...requestReducerInitialState }
    default:
      return state
  }
}
