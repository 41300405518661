import { NetworkRequestStatus } from '../../../../common/network-state'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import React, { Fragment, ReactNode, useState } from 'react'
import { AxiosError } from 'axios'
import { TextRegular } from '../../../Text/TextRegular'
import { MembershipFeeConfigDTO } from '../../types'

export interface PaymentFeesListProps {
  columnNames: string[]
  noResultsText: string
  renderRow: (payload: {
    onToggleExpandedRow: () => void
    isExpanded: boolean
    data: MembershipFeeConfigDTO
  }) => ReactNode
  renderExpandedRow: (payload: {
    data: MembershipFeeConfigDTO
    isExpanded: boolean
  }) => ReactNode
  getRowKey: (rowData: MembershipFeeConfigDTO) => string | number
  data: MembershipFeeConfigDTO[]
  dataStatus: NetworkRequestStatus
  dataError: AxiosError
}

export const PaymentFeesList = ({
  columnNames,
  noResultsText,
  renderRow,
  renderExpandedRow,
  getRowKey,
  data,
  dataStatus,
  dataError
}: PaymentFeesListProps) => {
  const [expandedRow, setExpandedRow] = useState(null)

  const toggleExpandedRow = (i) => {
    if (expandedRow === i) {
      setExpandedRow(null)
    } else {
      setExpandedRow(i)
    }
  }

  return (
    <div>
      {dataStatus === NetworkRequestStatus.LOADING && (
        <Box ml={2}>Wczytywanie...</Box>
      )}
      {dataStatus === NetworkRequestStatus.LOADED && (
        <>
          {data.length > 0 ? (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {columnNames.map((columnName, i) => (
                      <TableCell key={columnName + i}>{columnName}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((memberFeeConfig, i) => (
                    <Fragment key={getRowKey(memberFeeConfig)}>
                      <TableRow
                        sx={{
                          '& > *': {
                            borderBottom: 'unset'
                          }
                        }}
                      >
                        {renderRow({
                          onToggleExpandedRow: () => toggleExpandedRow(i),
                          isExpanded: expandedRow === i,
                          data: memberFeeConfig
                        })}
                      </TableRow>
                      <TableRow>
                        {renderExpandedRow({
                          data: memberFeeConfig,
                          isExpanded: expandedRow === i
                        })}
                      </TableRow>
                    </Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box mt={1}>
              <TextRegular light>{noResultsText}</TextRegular>
            </Box>
          )}
        </>
      )}

      {dataStatus === NetworkRequestStatus.ERROR &&
        dataError &&
        dataError.response.status !== 404 && (
          <Box>Wystąpił błąd podczas pobierania składek</Box>
        )}
    </div>
  )
}
