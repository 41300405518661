import React from 'react'
import { RouteLinks } from '../Routes/RouteNames'
import { Logo } from '../Logo'
import { Link } from 'react-router-dom'
import { LogoMark } from '../Logo/LogoMark'
import { Theme, useMediaQuery } from '@mui/material'
import { useSelector } from 'react-redux'
import { currentClubDuck } from '../../ducks/currentClub.duck'
import styled from 'styled-components'

export const HeaderLogo = () => {
  const clubDTO = useSelector(currentClubDuck.selectors.data)
  const upSm = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'))

  return (
    <Link to={RouteLinks.Home}>
      {clubDTO?.avatarUrl ? (
        <ClubAvatarImg src={clubDTO.avatarUrl} />
      ) : (
        <>{upSm ? <Logo size={120} /> : <LogoMark size={30} />}</>
      )}
    </Link>
  )
}

const ClubAvatarImg = styled.img`
  display: block;
  max-height: 50px;
  max-width: 120px;
`
