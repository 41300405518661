import React, { useState } from 'react'
import Box from '@mui/material/Box'
import { InputField } from '../Form'
import Button from '@mui/material/Button'
import styled from 'styled-components'
import { BsQuestionCircle } from 'react-icons/bs'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import peselHintImage from './pesel_hint.png'
import pinHintImage from './pin_hint.png'

export interface VerificationFormProps {
  onSubmit: () => void
  isLoading: boolean
}

export function VerificationForm({onSubmit, isLoading}: VerificationFormProps) {
  const [peselHintVisible, setPeselHintVisible] = useState(false)
  const [pinHintVisible, setPinHintVisible] = useState(false)

  return (
    <>
      <strong>Podaj dane dotyczące zaświadczenia:</strong>
      <Box maxWidth={270}>
        <InputField
          name="pesel"
          label="PESEL"
          InputProps={{
            endAdornment: (
              <HintIcon size={20} onClick={() => setPeselHintVisible(true)} />
            )
          }}
        />
        <InputField
          name="pin"
          label="PIN"
          InputProps={{
            endAdornment: (
              <HintIcon size={20} onClick={() => setPinHintVisible(true)} />
            )
          }}
        />
        <Box mt={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={onSubmit}
            disabled={isLoading}
          >
            {isLoading ? 'Sprawdzam...' : 'Sprawdź zaświadczenie'}
          </Button>

        </Box>
      </Box>

      <Dialog open={peselHintVisible}>
        <DialogTitle>Gdzie znaleźć numer PESEL?</DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" alignItems="center">
            Numer PESEL członka klubu znajdziesz w treści zaświadczenia.
            <Img src={peselHintImage} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setPeselHintVisible(false)}
          >
            Zamknij
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={pinHintVisible}>
        <DialogTitle>Gdzie znaleźć numer PIN?</DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box>
              Unikalny numer PIN znajdziesz w stopce zaświadczenia.
              <br />
              <br />
              <strong>Uwaga!</strong> Każde zaświadczenie posiada automatycznie
              wygenerowany, unikalny numer PIN. Nie używaj numeru PIN do
              weryfikacji wielu różnych zaświadczeń.
            </Box>
            <Img src={pinHintImage} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setPinHintVisible(false)}
          >
            Zamknij
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const HintIcon = styled(BsQuestionCircle)`
  cursor: pointer;
`

const Img = styled.img`
  width: 180px;
  margin-top: 40px;
  border: 4px solid;
`
