import React, { FC } from 'react'
import styled from 'styled-components'

export interface LogoProps {
  light?: boolean
  size?: number
}

export const mainColor = '#373539'
export const accentColor = '#E60004'

const LogoSvg: FC<LogoProps> = ({ light = false, ...props }) => {
  const finalMainColor = light ? '#fff' : mainColor
  const finalAccentColor = light ? '#ccc' : accentColor

  return (
    <svg
      xmlSpace="preserve"
      version="1.1"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
      viewBox="0 0 125 24"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g>
        <path
          fill={finalMainColor}
          d="
            M 8.0453 4.8168
            l 1.8677 0
            c -0.0023,0.0981 -0.0034,0.1987 -0.0034,0.3021
            l 0 3.056 -1.8643 0
            c -0.4627,0 -0.8617,0.1651 -1.1919,0.4953 -0.3302,0.3301 -0.4953,0.7248 -0.4953,1.1797
            l 0 0.0041 -3.3581 0
            c 0.001,-1.3834 0.4933,-2.5689 1.4778,-3.5555 0.9834,-0.9853 2.1743,-1.4817 3.5675,-1.4817
            z
            m 4.2188 0
            l 1.88 0
            c 1.3851,0 2.5721,0.4963 3.5554,1.4817 0.9845,0.9865 1.4768,2.1722 1.4777,3.5555
            l -3.3581 0 0 -0.0041
            c 0,-0.4549 -0.1691,-0.8496 -0.5033,-1.1797 -0.3342,-0.3302 -0.725,-0.4953 -1.1717,-0.4953
            l -1.8764 0 0 -3.056
            c 0,-0.1034 -0.0012,-0.204 -0.0036,-0.3021
            z
            m 6.9131 8.3953
            c -0.0009,1.3912 -0.4921,2.5779 -1.4777,3.5634 -0.9825,0.9825 -2.1705,1.4737 -3.5554,1.4737
            l -1.8764 0 0 -3.3581 1.8764 0
            c 0.4469,0 0.8375,-0.165 1.1717,-0.4952 0.3323,-0.3282 0.5013,-0.7242 0.5033,-1.1838
            l 3.3581 0
            z
            m -9.2676 5.0371
            l -1.8643 0
            c -1.393,0 -2.585,-0.4913 -3.5675,-1.4737 -0.9855,-0.9855 -1.4768,-2.1721 -1.4778,-3.5634
            l 3.3581 0
            c 0.002,0.4596 0.1671,0.8556 0.4953,1.1838 0.3302,0.3302 0.7288,0.4952 1.1919,0.4952
            l 1.8643 0 0 3.3581
            z "
        />
        <path
          fill={finalAccentColor}
          d="M22.86 9.858c0,-1.3851 0.4912,-2.5729 1.4777,-3.5595 0.9865,-0.9865 2.1743,-1.4817 3.5675,-1.4817l2.891 0 0 0 0.9543 0 6.5995 0 0.4268 0c0.8335,0 1.2482,0.4189 1.2482,1.2603l0 0.8375c0,0.8414 -0.4149,1.2603 -1.2482,1.2603l-0.4147 0 -0.0121 0 -8.0249 0 0 0 -2.4199 0c-0.4631,0 -0.8617,0.1651 -1.1919,0.4953 -0.3302,0.3301 -0.4952,0.7248 -0.4952,1.1797l0 3.3541c0,0.4631 0.165,0.8617 0.4952,1.1919 0.3302,0.3302 0.7288,0.4952 1.1919,0.4952l2.4199 0 0 0 8.0249 0 0.0121 0 0.4147 0c0.8335,0 1.2482,0.419 1.2482,1.2523l0 0.8415c0,0.8414 -0.4149,1.2643 -1.2482,1.2643l-0.4268 0 -6.5995 0 -0.9543 0 0 0 -2.891 0c-1.3932,0 -2.581,-0.4912 -3.5675,-1.4737 -0.9865,-0.9865 -1.4777,-2.1743 -1.4777,-3.5674l0 -3.3501z"
        />
        <path
          fill={finalAccentColor}
          d="M54.7913 13.2242c0.9785,0 1.957,0 2.9434,0 0.8335,0 1.2482,-0.4188 1.2482,-1.2523 0,-0.3543 0,-0.7087 0,-1.055 0,-0.3462 0,-0.6965 0,-1.0509 0,-1.3891 -0.4922,-2.5761 -1.4736,-3.5635 -0.9834,-0.9894 -2.1664,-1.4857 -3.5514,-1.4857l-6.1587 0c-1.3931,0 -2.5809,0.4952 -3.5674,1.4817 -0.9865,0.9866 -1.4778,2.1744 -1.4778,3.5595l0 3.3501c0,1.3931 0.4904,2.582 1.4778,3.5674 0.9854,0.9834 2.1745,1.4737 3.5674,1.4737l5.6996 0 0 0 0.9181 0c0,0 0.004,0 0.012,0 0.0081,0 0.0163,0 0.0242,0l3.2816 0c0.8333,0 1.2482,-0.4229 1.2482,-1.2643l0 -0.8415c0,-0.8333 -0.4147,-1.2523 -1.2482,-1.2523l-4.9104 0 2.8086 0 -5.4137 0 2.8086 0 -5.2286 0c-0.463,0 -0.8616,-0.165 -1.1918,-0.4952 -0.3254,-0.3254 -0.4904,-0.7172 -0.4952,-1.1717 2.893,0 5.7861,0 8.6791,0zm-8.6792 -3.3582l0 -0.0161c0,-0.4549 0.1651,-0.8496 0.4953,-1.1797 0.3302,-0.3302 0.729,-0.4953 1.1918,-0.4953l6.1587 0c0.4467,0 0.8375,0.1651 1.1717,0.4993 0.3342,0.3342 0.5033,0.7328 0.5033,1.1918l0 0c-0.6362,0 -1.2684,0 -1.8924,0 -2.5428,0 -5.0856,0 -7.6284,0z"
        />
        <path
          fill={finalAccentColor}
          d="M65.07 13.2161c0,0.6845 0.2094,1.1355 0.6322,1.3569 0.0632,0.0334 0.5323,0.3181 1.0549,0.3181l4.1595 0c0.8375,0 1.2603,0.4232 1.2603,1.2644l0 0.8415c0,0.8331 -0.4232,1.2522 -1.2603,1.2522l-4.0065 0c-0.0399,0 -0.0886,0 -0.153,0 -1.3931,0 -2.581,-0.4912 -3.5674,-1.4737 -0.9866,-0.9824 -1.4778,-2.1703 -1.4778,-3.5594 0,-2.6492 0,-1.3246 0,-3.9739 0,-0.8418 0,-2.3234 0,-3.1651 0,-0.8415 0.4228,-1.2603 1.2643,-1.2603l0.4148 0 0.4268 0c0.8335,0 1.2522,0.4189 1.2522,1.2603l0 0.3906 0 0.0241c0,3.3526 0,3.3715 0,6.7243z"
        />
        <path
          fill={finalMainColor}
          d="M115.3932 13.2161c0,0.6845 0.2094,1.1355 0.6322,1.3569 0.0632,0.0334 0.5323,0.3181 1.0549,0.3181l4.1595 0c0.8375,0 1.2602,0.4232 1.2602,1.2644l0 0.8415c0,0.8331 -0.4231,1.2522 -1.2602,1.2522l-4.0066 0c-0.0398,0 -0.0885,0 -0.1529,0 -1.3932,0 -2.581,-0.4912 -3.5675,-1.4737 -0.9865,-0.9824 -1.4777,-2.1703 -1.4777,-3.5594 0,-2.6492 0,-1.3246 0,-3.9739 0,-0.8418 0,-2.3234 0,-3.1651 0,-0.8415 0.4227,-1.2603 1.2643,-1.2603l0.4147 0 0.4268 0c0.8335,0 1.2523,0.4189 1.2523,1.2603l0 0.3906 0 0.0241c0,3.3526 0,3.3715 0,6.7243z"
        />
        <path
          fill={finalMainColor}
          d="M77.4774 17.9553l0 1.9529c0,0 0,0.004 0,0.012 0,0.0081 0,0.0161 0,0.0242l0 0.3906c0,0.8335 -0.4188,1.2482 -1.2522,1.2482l-0.4268 0 0 0 -0.4148 0c-0.8415,0 -1.2643,-0.4188 -1.2643,-1.2522 0,-1.7435 0,-3.491 0,-5.2386 0,-1.7435 0,-3.4909 0,-5.2384 0,-1.3851 0.4923,-2.568 1.4778,-3.5555 0.9833,-0.9853 2.1743,-1.4817 3.5674,-1.4817l6.1626 0c1.3851,0 2.5721,0.4963 3.5555,1.4817 0.9854,0.9874 1.4777,2.1744 1.4777,3.5595 0,0.5637 0,1.1274 0,1.6871 0,0.5597 0,1.1113 0,1.663 0,1.3931 -0.4912,2.5809 -1.4777,3.5674 -0.9825,0.9825 -2.1665,1.4737 -3.5514,1.4737l-6.1667 0c-0.5715,0 -1.1355,-0.0966 -1.6871,-0.2939zm1.6871 -3.0642l6.1626 0c0.447,0 0.8376,-0.165 1.1718,-0.4952 0.3342,-0.3302 0.5033,-0.7288 0.5033,-1.1919 0,-0.5516 0,-1.1073 0,-1.667 0,-0.5556 0,-1.1194 0,-1.6871 0,-0.4549 -0.1691,-0.8496 -0.5033,-1.1797 -0.3342,-0.3302 -0.725,-0.4953 -1.1718,-0.4953l-6.1626 0c-0.4628,0 -0.8617,0.1651 -1.1919,0.4953 -0.3301,0.3301 -0.4952,0.7248 -0.4952,1.1797l0 2.4562 0 0 0 0.9462c0.0081,0.447 0.1771,0.8335 0.5073,1.1557 0.3302,0.3221 0.7248,0.4831 1.1798,0.4831z"
        />
        <path
          fill={finalMainColor}
          d="M98.1224 8.1749c-0.4627,0 -0.8617,0.1651 -1.1919,0.4953 -0.3302,0.3301 -0.4952,0.7248 -0.4952,1.1797l0 3.3541c0,0.4631 0.165,0.8617 0.4952,1.1919 0.3302,0.3302 0.7289,0.4952 1.1919,0.4952 0.3463,0 0.6926,0 1.0389,0 0.3462,0 3.5051,0 3.8594,0 0.8415,0 1.2603,0.419 1.2603,1.2523l0 0.4268 0 0 0 0.4147c0,0.8414 -0.4188,1.2643 -1.2603,1.2643 -0.3543,0 -3.5132,0 -3.8594,0 -0.3463,0 -0.6926,0 -1.0389,0 -1.3932,0 -2.581,-0.4912 -3.5674,-1.4737 -0.9866,-0.9865 -1.4778,-2.1743 -1.4778,-3.5674l0 -3.3501c0,-1.3851 0.4923,-2.572 1.4778,-3.5595 0.9833,-0.9853 2.1742,-1.4817 3.5674,-1.4817l6.1626 0c1.3851,0 2.5721,0.4963 3.5555,1.4817 0.9854,0.9874 1.4777,2.1746 1.4777,3.5595l0 2.9031 0 0 0 0.9422 0 2.8709 0 0.4107c0,0.8414 -0.419,1.2643 -1.2523,1.2643l-0.8415 0c-0.8414,0 -1.2643,-0.4227 -1.2643,-1.2643l0 -0.4147 0 0 0 -4.2963 0 0 0 -2.4199c0,-0.459 -0.1691,-0.8537 -0.5033,-1.1838 -0.3343,-0.3302 -0.725,-0.4953 -1.1718,-0.4953l-6.1626 0z"
        />
        <path
          fill={finalAccentColor}
          d="M10.6739 9.854l0.8415 0c0.8335,0 1.2523,0.4227 1.2523,1.2643l0 0.8415c0,0.8335 -0.4188,1.2523 -1.2523,1.2523l-0.8415 0c-0.8415,0 -1.2643,-0.4188 -1.2643,-1.2523l0 -0.8415c0,-0.8416 0.4228,-1.2643 1.2643,-1.2643z"
        />
      </g>
    </svg>
  )
}

export const Logo = styled(LogoSvg)<LogoProps>`
  display: block;
  width: ${(p) => (p.size ? p.size : 70)}px;
`
