import { HiOutlineUpload } from 'react-icons/hi'
import {
  Alert,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar
} from '@mui/material'
import React, { ChangeEvent, useMemo, useState } from 'react'
import { useToggle } from '../../hooks/useToggle'
import { uploadDocuments } from './documents.service'
import { IoImageOutline } from 'react-icons/io5'
import { muiTheme } from '../../common/muiTheme'

const acceptedFileTypes = ['application/pdf']

export function AdminDocumentsUpload() {
  const [uploadDialogVisible, showUploadDialog, hideUploadDialog] = useToggle()
  const [isLoading, startLoading, stopLoading] = useToggle()
  const [isSuccess, showSuccess, hideSuccess] = useToggle()
  const [files, setFiles] = useState<File[]>([])
  const hasWrongFileNames = useMemo(
    () => files.some((file) => !file.name.match(/_ID[\d]+_/)),
    [files]
  )

  const handleClose = () => {
    setFiles([])
    hideUploadDialog()
  }

  const handleFilesChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFiles(Array.from(e.target.files))
  }

  const submitUpload = async () => {
    startLoading()

    try {
      // TODO: handle errors
      await uploadDocuments(files)

      handleClose()
      showSuccess()
    } finally {
      stopLoading()
    }
  }

  return (
    <>
      <Button
        startIcon={<HiOutlineUpload />}
        variant="contained"
        color="primary"
        onClick={showUploadDialog}
      >
        Upload dokumentów
      </Button>
      <Dialog
        open={uploadDialogVisible}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Uploaduj dokumenty</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 1 }}>
            Uploaduj dokumenty dla wielu osób na raz.
          </DialogContentText>
          <Alert severity="warning" sx={{ mb: 2 }}>
            Uwaga! Żeby automatycznie dopasować każdy dokument do konta
            klubowicza, nazwy plików powinny zawierać ciąg znaków:{' '}
            <Box component="code" sx={{ display: 'block', my: 1 }}>
              _ID&#123;id klubowicza&#125;_
            </Box>
            Np. "<code>Zaświadczenie_o_członkostwie__ID4523_.pdf</code>"
          </Alert>

          <Button
            variant="contained"
            color="primary"
            size="large"
            component="label"
            startIcon={<IoImageOutline />}
          >
            Wybierz pliki
            <input
              accept={acceptedFileTypes.join(',')}
              hidden
              type="file"
              onChange={handleFilesChange}
              multiple
            />
          </Button>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              mt: 2,
              gap: 1
            }}
          >
            {files.map((file) => (
              <Chip key={file.name} label={file.name} />
            ))}
          </Box>
          {hasWrongFileNames && (
            <Alert severity="error" sx={{ my: 2 }}>
              Niektóre pliki mają nieprawidłową nazwę.
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            startIcon={<HiOutlineUpload />}
            disabled={isLoading || !files?.length || hasWrongFileNames}
            onClick={submitUpload}
          >
            {isLoading ? (
              <CircularProgress size={muiTheme.typography.body1.fontSize} />
            ) : (
              'Uploaduj'
            )}
          </Button>
          <Button variant="text" onClick={handleClose}>
            Anuluj
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={isSuccess} onClose={hideSuccess} autoHideDuration={2000}>
        <Alert severity="success">Dokumenty zostały zuploadowane.</Alert>
      </Snackbar>
    </>
  )
}
