import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText
} from '@mui/material'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useToggle } from '../../../../hooks/useToggle'
import { AccountDTO } from '../../../Account/types'
import { UserSelect } from '../../../UserSelect'
import Box from '@mui/material/Box'
import {
  createLicenseManualPayment,
  createMembershipExemptManualPayment,
  createMembershipManualPayment,
  fetchAccountApplicableConfig
} from '../../payment-service'
import {
  MemberFeePeriodType,
  MemberFeeUnitDTO,
  MembershipApplicableConfigDTO
} from '../../types'
import { Loading } from '../../../Loading'
import { TextRegular } from '../../../Text/TextRegular'
import { licenseFeeApplicable } from '../../Account/PaymentCreator/helpers'
import moment from 'moment'
import { periodWeight } from '../Wizard/firstPayment.helpers'
import { AdminNewPaymentListItem } from './AdminNewPaymentListItem'
import { IoChevronForward } from 'react-icons/io5'

interface SelectedFee {
  type: 'license' | 'member' | 'exempt'
  year?: number
  unit?: MemberFeeUnitDTO
}

export interface AdminNewPaymentProps {
  onRefresh: () => void
}

export const AdminNewPayment: FC<AdminNewPaymentProps> = ({ onRefresh }) => {
  const [isOpen, open, close] = useToggle(false)
  const [isConfirmationOpen, openConfirmation, closeConfirmation] =
    useToggle(false)
  const [selectedAccount, setSelectedAccount] = useState<AccountDTO>(null)
  const [loading, startLoading, stopLoading] = useToggle()
  const [config, setConfig] = useState<MembershipApplicableConfigDTO>(null)
  const [selectedFee, setSelectedFee] = useState<SelectedFee>(null)
  const fetchApplicableConfig = useCallback(async () => {
    startLoading()

    try {
      const response = await fetchAccountApplicableConfig(selectedAccount.id)
      setConfig(response.data)
    } finally {
      stopLoading()
    }
  }, [selectedAccount, startLoading, stopLoading])

  const getFeeAmount = useCallback(
    (amount: number) => `${amount.toFixed(2)} zł`,
    []
  )
  const getFeePartials = useCallback(
    (feeUnit: MemberFeeUnitDTO) => {
      const licenseApplicable = licenseFeeApplicable(
        config.licenseFeeYears,
        feeUnit
      )

      if (config.entryFeeApplicable && licenseApplicable) {
        return '(w tym wpisowe i licencja)'
      } else if (config.entryFeeApplicable) {
        return '(w tym wpisowe)'
      } else if (licenseApplicable) {
        return '(w tym licencja)'
      }

      return ''
    },
    [config]
  )
  const periodLabel = useCallback((unit: MemberFeeUnitDTO) => {
    const startDate = moment(unit.startDate)
    const periodWeightAmount = periodWeight[unit.periodType]

    switch (unit.periodType) {
      case MemberFeePeriodType.YEAR:
        return `rok ${startDate.format('YYYY')}`
      case MemberFeePeriodType.MONTH:
        return startDate.format('MMMM YYYY')
      case MemberFeePeriodType.HALF_YEAR:
      case MemberFeePeriodType.QUARTER:
        return `${startDate.format('MMMM')} - ${moment(startDate)
          .add(periodWeightAmount - 1, 'months')
          .format('MMMM')} ${startDate.format('YYYY')}`
      default:
        return ''
    }
  }, [])
  const selectMemberFeeUnit = useCallback((unit: MemberFeeUnitDTO) => {
    setSelectedFee({
      type: 'member',
      unit
    })
  }, [])
  const selectLicenseYear = useCallback((year: number) => {
    setSelectedFee({
      type: 'license',
      year
    })
  }, [])
  const handleClose = useCallback(() => {
    close()
    setSelectedAccount(null)
  }, [close])
  const closeConfirm = useCallback(() => {
    setSelectedFee(null)
  }, [])
  const confirmPayment = useCallback(async () => {
    switch (selectedFee.type) {
      case 'member':
        await createMembershipManualPayment({
          accountId: selectedAccount.id,
          memberFeeUnitId: selectedFee.unit.id
        })
        break
      case 'license':
        await createLicenseManualPayment({
          accountId: selectedAccount.id,
          year: selectedFee.year
        })
        break
      case 'exempt':
        await createMembershipExemptManualPayment({
          accountId: selectedAccount.id,
          year: moment(config.memberFeeUnits[0].startDate).year()
        })
    }

    setSelectedFee(null)
    setConfig(null)
    setSelectedAccount(null)
    close()
    openConfirmation()
    onRefresh()
  }, [
    selectedFee,
    selectedAccount,
    setSelectedFee,
    close,
    openConfirmation,
    onRefresh,
    config
  ])

  const selectExemptPayment = () => {
    setSelectedFee({
      type: 'exempt',
      year: moment(config.memberFeeUnits[0].startDate).year()
    })
  }

  useEffect(() => {
    if (selectedAccount) {
      fetchApplicableConfig()
    }
  }, [selectedAccount, fetchApplicableConfig])

  return (
    <div>
      <Button variant="contained" color="primary" size="small" onClick={open}>
        Nowa płatność
      </Button>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>Nowa płatność</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Wybierz klubowicza, dla którego chcesz utworzyć płatność.
          </DialogContentText>
          <UserSelect onChange={setSelectedAccount} />

          {selectedAccount && (
            <Box mt={2}>
              {loading ? (
                <Loading size="small" />
              ) : (
                config && (
                  <>
                    {config.licenseFeeYears.length > 0 && (
                      <>
                        <TextRegular strong>Licencja</TextRegular>
                        <List>
                          {config.licenseFeeYears.map((year) => (
                            <AdminNewPaymentListItem
                              key={year}
                              primary={year}
                              onClick={() => selectLicenseYear(year)}
                            />
                          ))}
                        </List>
                      </>
                    )}

                    <TextRegular strong>Składka</TextRegular>
                    <List>
                      <ListItem>
                        <ListItemText
                          primary={`rok ${moment(
                            config.memberFeeUnits[0].startDate
                          ).format('YYYY')}`}
                          secondary="ZWOLNIONY"
                        />
                        <ListItemSecondaryAction>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            endIcon={<IoChevronForward />}
                            onClick={selectExemptPayment}
                          >
                            Wybierz
                          </Button>
                        </ListItemSecondaryAction>
                      </ListItem>

                      {config.memberFeeUnits.map((unit) => (
                        <AdminNewPaymentListItem
                          key={unit.id}
                          primary={periodLabel(unit)}
                          secondary={`${getFeeAmount(
                            unit.amount
                          )} ${getFeePartials(unit)}`}
                          onClick={() => selectMemberFeeUnit(unit)}
                        />
                      ))}
                    </List>
                  </>
                )
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Zamknij</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={!!selectedFee}>
        <DialogContent>
          <DialogContentText>
            {selectedFee?.type === 'exempt' ? (
              <>
                Zatwierdzenie tej akcji spowoduje zwolnienie tego klubowicza ze
                składki na wybrany rok.
              </>
            ) : (
              <>
                Zatwierdzenie tej akcji spowoduje utworzenie płatności dla tego
                klubowicza
                {selectedFee?.type === 'member' &&
                  ' i przedłużenie daty ważności konta'}
                .
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirm}>Anuluj</Button>
          <Button variant="contained" color="primary" onClick={confirmPayment}>
            Zatwierdź
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isConfirmationOpen}>
        <DialogContent>
          <DialogContentText>Składka została utworzona.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmation}>Zamknij</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
