import React, { useEffect, useMemo } from 'react'
import { PagePaper } from '../Layout'
import {
  NetworkRequestStatus,
  useNetworkRequest
} from '../../common/network-state'
import { ApiInterceptor } from '../Api/ApiInterceptor'
import { Navigate } from 'react-router-dom'
import { FormError } from '../Form'
import { ContactHelp } from '../ContactHelp/ContactHelp'
import Box from '@mui/material/Box'

export function CalendarAuth() {
  const searchParams = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  )
  const [fetch, response, requestState, error] = useNetworkRequest(
    () =>
      ApiInterceptor.post('/api/calendar/auth', {
        code: searchParams.get('code'),
        scope: searchParams.get('scope'),
        redirectUri: `${window.location.origin}${window.location.pathname}`
      }),
    [searchParams]
  )
  const loading = requestState === NetworkRequestStatus.LOADING

  useEffect(() => {
    fetch()
  }, [fetch])

  if (!error && !loading && response?.status && response?.status < 299) {
    return <Navigate to={searchParams.get('state')} replace />
  }

  return (
    <PagePaper loading={loading}>
      {!error ? (
        'Za chwilę nastąpi przekierowanie...'
      ) : (
        <>
          <FormError>Wystąpił błąd przy próbie uwierzytelniania.</FormError>
          <Box mt={1}>
            <ContactHelp />
          </Box>
        </>
      )}
    </PagePaper>
  )
}
