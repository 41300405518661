import React, { useCallback } from 'react'
import { login as loginApi } from './login.service'
import { useSetJwt } from '../Auth/auth.duck'
import { GenericLoginPage } from './GenericLoginPage'
import { ApplicationType } from '../Account/enums'

export function PortalLogin() {
  const setJwt = useSetJwt()
  const handleLogin = useCallback(
    async (username, password) => {
      const response = await loginApi(username, password, ApplicationType.REACT)
      await setJwt(response.data.token)
    },
    [setJwt]
  )

  return <GenericLoginPage handleLogin={handleLogin} />
}
