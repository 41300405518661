import React from 'react'
import { Loading } from '../../../Loading'
import Box from '@mui/material/Box'
import { TextRegular } from '../../../Text/TextRegular'
import { IoBan } from 'react-icons/io5'
import { HorizontalView } from '../../../Layout'
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup
} from '@mui/material'
import { periodTypeLabel } from '../../Account/PaymentCreator/criteriaDescriptionHelper'
import { FieldArray, Field } from 'formik'
import { MembershipBatchApplicableConfigResponse } from '../../types'
import { CheckboxField } from '../../../Form/Mui/CheckboxField'

export interface GroupPaymentOptionsProps {
  batchConfig: MembershipBatchApplicableConfigResponse | null
  isLoading: boolean
  onFetch: () => void
}

export function GroupPaymentOptions({
  batchConfig,
  isLoading,
  onFetch
}: GroupPaymentOptionsProps) {
  return (
    <>
      <Box mt={1}>
        <Button variant="contained" color="primary" onClick={onFetch}>
          Oblicz
        </Button>
      </Box>
      {isLoading ? (
        <Box mt={2}>
          <Loading size="xsmall" />
        </Box>
      ) : batchConfig ? (
        <Box mt={2}>
          {batchConfig.map((accountInfo, index) => (
            <Box
              key={accountInfo.account.id}
              mb={1}
              py={2}
              px={1}
              display="flex"
              justifyContent="space-between"
              borderBottom="1px solid #ccc"
            >
              <Box>
                <Box mb={1}>
                  #{accountInfo.account.id} {accountInfo.account.firstName}{' '}
                  {accountInfo.account.lastName}
                </Box>
                <Box>
                  <TextRegular light>Konto ważne do: </TextRegular>
                  <TextRegular>
                    {accountInfo.expirationDate || '[brak daty]'}
                  </TextRegular>
                </Box>
              </Box>
              <Box>
                <Box>
                  {!accountInfo.entryFeeApplicable &&
                  !accountInfo.memberFeeApplicable &&
                  !accountInfo.licenseFeeApplicable ? (
                    <HorizontalView>
                      <IoBan />
                      <Box ml={1}>składka nie jest wymagana</Box>
                    </HorizontalView>
                  ) : (
                    <Box>
                      <Field
                        type="hidden"
                        name={`accounts.${index}.accountId`}
                        value={accountInfo.account.id}
                      />
                      <FieldArray
                        name="accounts"
                        render={({ form }) => (
                          <FormControl component="fieldset">
                            <FormLabel component="legend">Opłaty</FormLabel>
                            <FormGroup>
                              {accountInfo.entryFeeApplicable && (
                                <FormControlLabel
                                  control={
                                    <CheckboxField
                                      name={`accounts.${index}.useEntryFee`}
                                    />
                                  }
                                  label={`Wpisowe - ${accountInfo.entryFeeUnit.amount} zł`}
                                />
                              )}
                              {accountInfo.memberFeeApplicable && (
                                <>
                                  <FormControlLabel
                                    control={
                                      <CheckboxField
                                        name={`accounts.${index}.useMemberFee`}
                                      />
                                    }
                                    label="Składka"
                                  />

                                  <Box ml={1}>
                                    <RadioGroup
                                      name={`accounts.${index}.memberFeeUnitId`}
                                      value={
                                        form.values.accounts[index]
                                          .memberFeeUnitId
                                      }
                                      onChange={(e) =>
                                        form.setFieldValue(
                                          `accounts.${index}.memberFeeUnitId`,
                                          e.target.value
                                        )
                                      }
                                    >
                                      {accountInfo.memberFeeUnits.map(
                                        (memberFeeUnit) => (
                                          <FormControlLabel
                                            key={memberFeeUnit.id}
                                            value={memberFeeUnit.id}
                                            disabled={
                                              !form.values.accounts[index]
                                                .useMemberFee
                                            }
                                            control={<Radio />}
                                            label={`${periodTypeLabel(
                                              memberFeeUnit.periodType
                                            )} - ${memberFeeUnit.amount} zł`}
                                          />
                                        )
                                      )}
                                    </RadioGroup>
                                  </Box>
                                </>
                              )}
                              {accountInfo.licenseFeeApplicable && (
                                <FormControlLabel
                                  control={
                                    <CheckboxField
                                      name={`accounts.${index}.useLicenseFee`}
                                    />
                                  }
                                  label="Licencja PZSS"
                                />
                              )}
                            </FormGroup>
                          </FormControl>
                        )}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      ) : null}
    </>
  )
}
