import { RouteLinks } from '../components/Routes/RouteNames'

export function formatRoute(
  route: RouteLinks,
  params: Record<string, string | number>
): string {
  return Object.entries(params).reduce(
    (str, [key, value]) =>
      str.replace(new RegExp(`:${key}`, 'g'), value.toString()),
    route
  )
}
