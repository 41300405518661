import { ApiInterceptor } from '../Api/ApiInterceptor'
import { Document } from './types'

export function getCurrentAccountDocuments() {
  return ApiInterceptor.get<Document[]>('/api/documents')
}

export function getAccountDocuments(accountId: number) {
  return ApiInterceptor.get<Document[]>(`/api/documents/${accountId}`)
}

export function downloadDocument(accountId: number, documentId: string) {
  return ApiInterceptor.get<Blob>(`/api/documents/${accountId}/${documentId}`, {
    responseType: 'blob'
  })
}

export function generateMembershipCertificates(
  accountIds: number[],
  printStamp: boolean
) {
  return ApiInterceptor.post<Blob>(
    '/api/documents/membership-certificate',
    {
      accountIds,
      printStamp
    },
    {
      responseType: 'blob'
    }
  )
}

export function uploadDocuments(files: File[]) {
  const formData = new FormData()
  files.forEach((file) => formData.append('documents', file))

  return ApiInterceptor.post('/api/documents/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function deleteDocument(accountId: number, documentId: string) {
  return ApiInterceptor.delete(`/api/documents/${accountId}/${documentId}`)
}
