import React, { useEffect } from 'react'
import { PagePaper } from '../../Layout'
import { TextRegular } from '../../Text/TextRegular'
import {
  NetworkRequestStatus,
  useNetworkRequest
} from '../../../common/network-state'
import { ApiInterceptor } from '../../Api/ApiInterceptor'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { BookingPaymentOrderDTO } from '../types'
import { FormError } from '../../Form'
import { BookingsRangePicker } from '../BookingsRangePicker'
import { BookingTile } from '../BookingTile'

export function BookingsList() {
  const [fetch, bookingsResponse, fetchStatus] = useNetworkRequest(
    () => ApiInterceptor.get<BookingPaymentOrderDTO[]>('/api/bookings'),
    []
  )

  useEffect(() => {
    fetch()
  }, [fetch])

  return (
    <PagePaper
      loading={fetchStatus === NetworkRequestStatus.LOADING}
      title="Moje rezerwacje"
    >
      {fetchStatus === NetworkRequestStatus.LOADED && (
        <>
          {bookingsResponse?.data?.length === 0 ? (
            <Box
              my={2}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <TextRegular size="medium">Brak rezerwacji</TextRegular>
              <Box mt={2}>
                Nie posiadasz żadnych rezerwacji. Przeglądaj kalendarz, żeby
                znaleźć wolne terminy.
              </Box>
              <Box mt={2}>
                <BookingsRangePicker>Zobacz wolne terminy</BookingsRangePicker>
              </Box>
            </Box>
          ) : (
            bookingsResponse.data.map((booking) => (
              <BookingTile key={booking.id} booking={booking} hidePerson />
            ))
          )}
        </>
      )}

      {fetchStatus === NetworkRequestStatus.ERROR && (
        <Box>
          <TextRegular size="medium">
            <FormError>Błąd</FormError>
          </TextRegular>
          <Box my={2}>Wystąpił błąd podczas ładowania rezerwacji!</Box>
          <Button variant="contained" onClick={fetch}>
            Spróbuj ponownie
          </Button>
        </Box>
      )}
    </PagePaper>
  )
}
