import React, { FC, ReactNode } from 'react'
import { Box } from '@mui/material'
import { HorizontalView } from '../../../Layout'
import { IoMdCheckmarkCircleOutline } from 'react-icons/io'

export const CriteriaDescriptionItem: FC<{ children?: ReactNode }> = ({
  children
}) => {
  return (
    <Box mb={1}>
      <HorizontalView align="flex-start">
        <Box ml={1} mr={2}>
          <IoMdCheckmarkCircleOutline />
        </Box>
        {children}
      </HorizontalView>
    </Box>
  )
}
