import styled, { keyframes } from 'styled-components'
import { BsArrowRepeat } from 'react-icons/bs'

const rotate = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
`
export const PendingIcon = styled(BsArrowRepeat)`
  animation: ${rotate} 1s linear infinite;
`