import React, { useCallback, useEffect, useRef, useState } from 'react'
import { AccountDTO } from '../Account/types'
import {
  Autocomplete,
  TextField,
  TextFieldProps,
  Typography,
  Box
} from '@mui/material'
import { useToggle } from '../../hooks/useToggle'
import { ApiInterceptor } from '../Api/ApiInterceptor'
import { PageResponse } from '../Api/types'
import axios, { CancelTokenSource } from 'axios'

export interface UserSelectProps {
  onChange: (account: AccountDTO) => void
  value?: AccountDTO | null
  allClubs?: boolean
  inputProps?: TextFieldProps
}

export function UserSelect({
  onChange,
  allClubs = false,
  inputProps,
  value
}: UserSelectProps) {
  const [isInitial, setInitial] = useState(true)
  const [inputValue, setInputValue] = useState<string>('')
  const [userOptions, setUserOptions] = useState([])
  const [loading, startLoading, stopLoading] = useToggle()
  const abortRef = useRef<CancelTokenSource>()
  const fetchUsers = useCallback(
    async (value: string) => {
      startLoading()

      if (abortRef.current?.token) {
        abortRef.current.cancel()
      }

      abortRef.current = axios.CancelToken.source()

      try {
        const response = await ApiInterceptor.get<PageResponse<AccountDTO>>(
          '/api/accounts/text-search',
          {
            params: {
              text: value,
              pageSize: 10,
              pageNumber: 0,
              allClubs
            },
            cancelToken: abortRef.current.token
          }
        )

        setUserOptions(response.data.content)
      } catch (error) {
      } finally {
        stopLoading()
      }
    },
    [startLoading, stopLoading, allClubs]
  )

  const handleChange = useCallback(
    (event, value: AccountDTO) => {
      onChange(value)
      setInputValue('')
    },
    [onChange]
  )

  const handleInputChange = useCallback((event, newValue) => {
    setInputValue(newValue)
  }, [])

  const handleOpen = useCallback(() => {
    if (isInitial) {
      setInitial(false)
      fetchUsers('')
    }
  }, [isInitial, fetchUsers])

  useEffect(() => {
    fetchUsers(inputValue)
  }, [inputValue, fetchUsers])

  return (
    <Autocomplete<AccountDTO>
      noOptionsText="Brak wyników"
      loadingText="Ładowanie wyników..."
      onInputChange={handleInputChange}
      inputValue={inputValue}
      onOpen={handleOpen}
      options={userOptions}
      onChange={handleChange}
      filterOptions={(options) => options}
      value={value}
      loading={loading}
      getOptionLabel={(option) =>
        `#${option.id} ${option.firstName} ${option.lastName}`
      }
      renderOption={(props, option) => (
        <Box
          component="li"
          {...props}
          key={option.id}
          sx={{
            '&&': {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start'
            }
          }}
        >
          <Typography>
            #{option.id} {option.firstName} {option.lastName}
          </Typography>
          <Typography variant="caption">{option.email}</Typography>
        </Box>
      )}
      renderInput={(params) => <TextField {...params} {...inputProps} />}
      isOptionEqualToValue={(option, value) => option.id === value.id}
    />
  )
}
