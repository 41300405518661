import React, { useEffect } from 'react'
import { PagePaper } from '../../Layout'
import {
  NetworkRequestStatus,
  useNetworkRequest
} from '../../../common/network-state'
import { ApiInterceptor } from '../../Api/ApiInterceptor'
import { useParams } from 'react-router'
import { BookingPaymentOrderDTO } from '../types'
import { AxiosError, AxiosResponse } from 'axios'
import { Alert, Box } from '@mui/material'

export function BookingsOrder() {
  const { orderId } = useParams()
  const [fetchOrder, orderResponse, status, error] = useNetworkRequest<
    AxiosResponse<BookingPaymentOrderDTO>,
    void,
    AxiosError
  >(
    () =>
      ApiInterceptor.get<BookingPaymentOrderDTO>(`/api/bookings/${orderId}`),
    [orderId]
  )

  useEffect(() => {
    fetchOrder()
  }, [fetchOrder])

  return (
    <PagePaper title="Twoja rezerwacja">
      {status === NetworkRequestStatus.ERROR ? (
        error?.response?.status === 404 ? (
          <Alert variant="filled" color="error">
            {/* TODO: a human readable ID would be nice here */}
            Nie znaleziono rezerwacji o numerze {orderId}.
          </Alert>
        ) : (
          <Alert variant="filled" color="error">
            Wystąpił nieokreślony błąd.
          </Alert>
        )
      ) : (
        <Box>{orderResponse?.data?.paymentDetails.range.name}</Box>
      )}
    </PagePaper>
  )
}
