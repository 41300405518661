import styled from 'styled-components'
import React, { useCallback, useRef } from 'react'
import { HeaderLogo } from './HeaderLogo'
import { colors, zIndexes } from '../../styles/variables'
import { HorizontalView, View } from '../Layout'
import { elevation } from '../../styles/elevation'
import { RouteLinks } from '../Routes/RouteNames'
import {
  Box,
  Button,
  Menu,
  MenuItem,
  styled as styledEmotion
} from '@mui/material'
import { MenuLink } from './MenuLink'
import { IoPersonCircleOutline } from 'react-icons/io5'
import {
  hasRoleSelector,
  isImpersonatedSelector,
  useLogout
} from '../Auth/auth.duck'
import { useToggle } from '../../hooks/useToggle'
import { accountSelector } from '../Account/account.duck'
import { useSelector } from 'react-redux'
import { TextRegular } from '../Text/TextRegular'
import { currentClubDuck } from '../../ducks/currentClub.duck'
import { Role } from '../Account/enums'
import { ApiInterceptor } from '../Api/ApiInterceptor'
import { LoginResponse } from '../Login/login.service'
import { RiChat1Line } from 'react-icons/ri'
import { Link } from '../Link'

export const Header = () => {
  const menuRef = useRef()
  const logoutAction = useLogout()
  const [menuOpen, openMenu, closeMenu] = useToggle()
  const account = useSelector(accountSelector)
  const currentClub = useSelector(currentClubDuck.selectors.data)
  const isClubAdmin = useSelector(hasRoleSelector(Role.CLUB_ADMIN))
  const isSuperAdmin = useSelector(hasRoleSelector(Role.SUPER_ADMIN))
  const isImpersonated = useSelector(isImpersonatedSelector)

  const loginToAdminApp = async () => {
    const response = await ApiInterceptor.post<LoginResponse>(
      '/api/login/admin-app'
    )
    window.location.href = `${process.env.REACT_APP_ADMIN_URL}/token-auth/${response.data.token}`
  }

  const logout = useCallback(() => {
    closeMenu()
    logoutAction()
  }, [closeMenu, logoutAction])

  return (
    <>
      <View>
        <FloatingWrapper>
          <Box mr={2}>
            <HorizontalView justify="flex-start">
              <HeaderLogo />
              <Box sx={{ ml: 2, display: { xs: 'none', sm: 'block' } }}>
                <TextRegular>
                  {currentClub?.shortName || currentClub?.name || 'Moje konto'}
                </TextRegular>
              </Box>
            </HorizontalView>
          </Box>
          <MainArea>
            <OuterLink href="https://forum.celpal.net">
              <HorizontalView>
                <Box mr={0.5}>
                  <RiChat1Line size={20} />
                </Box>
                forum
              </HorizontalView>
            </OuterLink>
            <Separator />
            {!!currentClub && (
              <>
                {/*<MenuLink to={RouteLinks.Bookings}>rezerwacje</MenuLink>*/}
                <MenuLink to={RouteLinks.Payments}>płatności</MenuLink>
                <MenuLink to={RouteLinks.Documents}>dokumenty</MenuLink>
              </>
            )}
            {isClubAdmin && (
              <>
                <MenuLink to={RouteLinks.Calendar}>wydarzenia</MenuLink>
                <MenuLink to={RouteLinks.Ranges}>strzelnice</MenuLink>
                <MenuLink to={RouteLinks.ClubSettings}>klub</MenuLink>
              </>
            )}
            {isSuperAdmin && (
              <MenuLink to={RouteLinks.SuperAdmin}>super-admin</MenuLink>
            )}
            <Separator />
            <AccountButton
              ref={menuRef}
              onClick={openMenu}
              endIcon={<IoPersonCircleOutline size={32} />}
              title={isImpersonated ? 'Impersonacja' : undefined}
              variant="text"
            >
              <AccountText isImpersonated={isImpersonated}>
                {isImpersonated && <strong>Imp! </strong>}{' '}
                {account?.firstName || account?.login || ''}
              </AccountText>
            </AccountButton>
            <Menu
              anchorEl={menuRef.current}
              open={menuOpen}
              onClose={closeMenu}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <MenuItem onClick={loginToAdminApp}>Admin panel (2020)</MenuItem>
              <MenuItem onClick={logout}>Wyloguj</MenuItem>
            </Menu>
          </MainArea>
        </FloatingWrapper>
        <Spacer />
      </View>
    </>
  )
}

const FloatingWrapper = styled(HorizontalView)`
  align-items: center;
  height: 56px;
  background: #fff;
  box-shadow: ${elevation(2)};
  position: fixed;
  padding: 0 16px;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${zIndexes.HEADER};
`

const Spacer = styled(View)`
  height: 56px;
`

const Separator = styled(View)`
  width: 1px;
  height: 2em;
  margin: 0 16px;
  background: #dedede;
`

const AccountButton = styledEmotion(Button)({
  '&.MuiButton-root': {
    textTransform: 'none'
  },
  '&.MuiButton-label': {
    color: colors.tar
  }
})

const MainArea = styled(HorizontalView)`
  flex-grow: 1;
  justify-content: flex-end;
  overflow: hidden;
  text-overflow: ellipsis;
`

const AccountText = styled(TextRegular)<{ isImpersonated: boolean }>`
  color: ${(p) => (p.isImpersonated ? '#e3930b' : '#333')};
`

const OuterLink = styled(Link)`
  color: ${colors.mainLink};
  margin: 0 8px;

  svg {
    display: block;
  }
`
