import { Link, Navigate, Routes, useParams } from 'react-router-dom'
import { RouteLinks, RouteNames } from '../../Routes/RouteNames'
import React, { useEffect } from 'react'
import { Route } from 'react-router'
import { RangeInfo } from './RangeInfo'
import { LinkTab, SubMenu } from '../../Header/SubMenu'
import IconButton from '@mui/material/IconButton'
import { IoArrowBackOutline } from 'react-icons/io5'
import { HorizontalView, View } from '../../Layout'
import styled from 'styled-components'
import { RangeOpeningHours } from '../OpeningHours'
import { useNetworkRequest } from '../../../common/network-state'
import { TextRegular } from '../../Text/TextRegular'
import Box from '@mui/material/Box'
import { RangeLanes } from '../Lanes'
import { formatRoute } from '../../../helpers/formatRoute'
import { getRange } from '../ranges.service'

export const RangeDetailsRoutes = () => {
  const { rangeId } = useParams<{ rangeId: string }>()
  const [fetch, rangeResponse] = useNetworkRequest(
    () => getRange(rangeId),
    [rangeId]
  )
  const range = rangeResponse?.data

  useEffect(() => {
    fetch()
  }, [fetch])

  return (
    <>
      <MenuView>
        <IconButton component={Link} to={RouteLinks.Ranges} color="primary">
          <IoArrowBackOutline />
        </IconButton>
        <View>
          <Box ml={3} my={2}>
            <TextRegular size="medium" strong>
              {range?.name || <>&nbsp;</>}
            </TextRegular>
          </Box>
          <SubMenu>
            <LinkTab
              to={formatRoute(RouteLinks.RangeInfo, {
                rangeId
              })}
              label="Informacje"
            />
            <LinkTab
              to={formatRoute(RouteLinks.RangeOpeningHours, {
                rangeId
              })}
              label="Godziny otwarcia"
            />
            <LinkTab
              to={formatRoute(RouteLinks.RangeLanes, {
                rangeId
              })}
              label="Tory strzeleckie"
            />
          </SubMenu>
        </View>
      </MenuView>
      <Routes>
        <Route
          path={RouteNames.RangeInfo}
          element={<RangeInfo range={range} />}
        />
        <Route
          path={RouteNames.RangeOpeningHours}
          element={<RangeOpeningHours range={range} />}
        />
        <Route
          path={RouteNames.RangeLanes}
          element={<RangeLanes range={range} />}
        />
        <Route
          path="*"
          element={
            <Navigate
              to={formatRoute(RouteLinks.RangeInfo, {
                rangeId
              })}
              replace
            />
          }
        />
      </Routes>
    </>
  )
}

const MenuView = styled(HorizontalView)`
  background-color: #fff;
`
