import React, { useReducer } from 'react'
import { Formik, FormikProps } from 'formik'
import { HorizontalView } from '../Layout'
import { Logo } from '../Logo'
import Box from '@mui/material/Box'
import { LoginTextField } from '../Login'
import { InputField } from '../Form'
import { Button } from '@mui/material'
import { FormTitle } from '../Form/FormLayout'
import * as Yup from 'yup'
import { ApiInterceptor } from '../Api/ApiInterceptor'
import { ErrorType } from '../Api/enums'
import { LoginResponse } from '../Login/login.service'
import { useSetJwt } from '../Auth/auth.duck'
import { AuthPage } from '../Auth/AuthPage'

interface FormValues {
  email: string
  password: string
  username: string
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Podaj adres email')
    .email('Nieprawidłowy adres email'),
  username: Yup.string().required('Podaj nazwę użytkownika'),
  password: Yup.string().required('Podaj hasło')
})

export function SsoSignUpForm() {
  const setJwt = useSetJwt()
  const [{ success, userExistsError }, dispatchAction] = useReducer(
    (state, actionName) => {
      switch (actionName) {
        case 'success':
          return { ...state, success: true }
        case 'userExists':
          return { ...state, userExistsError: true }
      }
    },
    { success: false, userExistsError: false }
  )

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikProps<FormValues>
  ) => {
    try {
      const response = await ApiInterceptor.post<LoginResponse>(
        '/api/account/app/create',
        values
      )

      if (response.data?.token) {
        setJwt(response.data.token)
      }
      dispatchAction('success')
      setSubmitting(false)
    } catch (e) {
      if (
        e?.response?.status === 400 &&
        e.response.data.errorType === ErrorType.USER_ALREADY_EXISTS
      ) {
        dispatchAction('userExists')
      }
    }
  }

  if (success) {
    return (
      <AuthPage title="Rejestracja">
        Konto zostało utworzone. Na podany adres email wysłaliśmy link niezbędny
        do aktywacji Twojego nowego konta.
      </AuthPage>
    )
  }

  return (
    <AuthPage title="Rejestracja">
      <Formik<FormValues>
        onSubmit={handleSubmit}
        initialValues={{ email: '', username: '', password: '' }}
        validationSchema={validationSchema}
      >
        {({ submitForm, isSubmitting }) => (
          <>
            <HorizontalView justify="center">
              <Logo light size={220} />
            </HorizontalView>
            <Box mt={5}>
              <FormTitle>Zarejestruj się</FormTitle>
            </Box>
            <Box my={2}>Załóż konto CelPal.</Box>
            <InputField
              autoFocus
              name="email"
              placeholder="Adres e-mail"
              fullWidth
              required
              InputComponent={LoginTextField}
            />
            <InputField
              name="username"
              placeholder="Nazwa użytkownika"
              fullWidth
              required
              InputComponent={LoginTextField}
              error={userExistsError}
              helperText={
                userExistsError && 'Podana nazwa użytkownika jest już zajęta.'
              }
            />
            <InputField
              name="password"
              type="password"
              placeholder="Hasło"
              fullWidth
              required
              InputComponent={LoginTextField}
            />
            <Button
              sx={{ mt: 2, mb: 1 }}
              onClick={submitForm}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Tworzę konto...' : 'Utwórz konto'}
            </Button>
          </>
        )}
      </Formik>
    </AuthPage>
  )
}
