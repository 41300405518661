import { PagePaper } from '../Layout'
import React, { useState } from 'react'
import { Logo } from '../Logo'
import Box from '@mui/material/Box'
import { Formik, FormikErrors } from 'formik'
import { FormError } from '../Form'
import { ApiInterceptor } from '../Api/ApiInterceptor'
import { AxiosError } from 'axios'
import { MembershipCertificateVerification } from './types'
import { TextRegular } from '../Text/TextRegular'
import { VerificationDocument } from './VerificationDocument'
import { VerificationForm } from './VerificationForm'

interface FormValues {
  pesel: string
  pin: string
}

export function CertificateVerification() {
  const [networkError, setNetworkError] = useState<AxiosError>(null)
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState<MembershipCertificateVerification>(null)

  const handleSubmit = async ({ pesel, pin }: FormValues) => {
    setNetworkError(null)
    setLoading(true)

    try {
      const response =
        await ApiInterceptor.post<MembershipCertificateVerification>(
          '/api/documents/verify-membership-certificate',
          {
            pesel,
            pin: pin.trim()
          }
        )
      setData(response.data)
    } catch (e) {
      setNetworkError(e)
    } finally {
      setLoading(false)
    }
  }

  const validate = ({ pesel, pin }: FormValues): FormikErrors<FormValues> => {
    const errors: FormikErrors<FormValues> = {}

    if (!pesel) {
      errors.pesel = 'Podaj numer PESEL'
    }

    if (!pin) {
      errors.pin = 'Podaj PIN'
    } else if (pin.trim().length !== 6) {
      errors.pin = 'Numer PIN musi zawierać dokładnie 6 znaków'
    } else if (!pin.trim().match(/^\d+$/)) {
      errors.pin = 'Numer PIN powienien zawierać tylko cyfry'
    }

    return errors
  }

  return (
    <>
      <Box mt={5} display="flex" justifyContent="center">
        <a href="https://celpal.net">
          <Logo size={210} />
        </a>
      </Box>
      <PagePaper title="Cyfrowy System Weryfikacji Dokumentów">
        <Box py={2} mb={4}>
          <TextRegular light>
            Na tej stronie możesz zweryfikować poprawność zaświadczeń
            członkowskich wygenerowanych w systemie CelPal.
          </TextRegular>
        </Box>
        <Formik
          initialValues={{ pesel: '', pin: '' }}
          onSubmit={handleSubmit}
          validate={validate}
        >
          {({ submitForm }) => (
            <Box
              display="flex"
              flexGrow={1}
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Box>
                <VerificationForm onSubmit={submitForm} isLoading={isLoading} />
                {!!networkError && (
                  <Box mt={2}>
                    <FormError>
                      {networkError?.response.status === 404
                        ? 'Nie znaleziono dokumentu pasującego do podanych kryteriów.'
                        : 'Wystąpił błąd połączenia z API CelPal.'}
                    </FormError>
                  </Box>
                )}
              </Box>

              <VerificationDocument data={data} onClear={() => setData(null)} />
            </Box>
          )}
        </Formik>
      </PagePaper>
    </>
  )
}
