import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import { App } from './App'
// import * as serviceWorker from './serviceWorker'
import { applyMiddleware, createStore } from 'redux'
import { rootReducer } from './ducks/root.duck'
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction'
import { authMiddleware } from './components/Auth/auth.middleware'
import { ApiInterceptor } from './components/Api/ApiInterceptor'
import { logout, setForceLogout } from './components/Auth/auth.duck'
import { Settings } from 'luxon'
import moment from 'moment'
import 'moment/locale/pl'
import { init } from '@sentry/react'

// Locale
Settings.defaultLocale = 'pl'
moment.locale('pl')

const store = createStore(
  rootReducer,
  // TODO: think whether we want to use dev tools on production
  composeWithDevTools(applyMiddleware(authMiddleware))
)

ApiInterceptor.intercept('response', null, async (error) => {
  if (error && error.response) {
    const {
      status,
      config: { url, method }
    } = error.response

    // TODO: also handle "no-internet" errors

    if (status === 401 && (method !== 'post' || url !== '/api/login')) {
      const hasJwt = !!store.getState().auth.jwt
      store.dispatch(setForceLogout(hasJwt))
      store.dispatch(logout())
    }
  }

  throw error
})

if (process.env.NODE_ENV === 'production') {
  init({
    dsn: 'https://16ecb0b7d0704cca8de0dcb4937fa9d2@o209370.ingest.sentry.io/5951129',
    ignoreErrors: [/Request failed with status code 401/]
  })
}

const root = createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <App store={store} />
  </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register()
