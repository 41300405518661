import React, { useState } from 'react'
import { Alert, Button, Dialog, Snackbar } from '@mui/material'
import { RiStackLine } from 'react-icons/ri'
import { useToggle } from '../../../../hooks/useToggle'
import { Formik } from 'formik'
import { DateTime } from 'luxon'
import { createBatchPayments } from '../../payment-service'
import * as Yup from 'yup'
import { AdminGroupPaymentForm, FormValues } from './AdminGroupPaymentForm'
import { ErrorElement } from '../../../Api/types'
import { AxiosError } from 'axios'

export interface AdminGroupPaymentProps {
  onRefresh: () => void
}

const validationSchema = Yup.object().shape({
  year: Yup.number().required('Podaj rok'),
  accountIds: Yup.string().required(
    'Podaj ID użytkowników oddzielone przecinkami'
  )
})

export function AdminGroupPayment({ onRefresh }: AdminGroupPaymentProps) {
  const [isOpen, open, close] = useToggle(false)
  const handleClose = () => {
    close()
  }
  const [error, setError] = useState<AxiosError<ErrorElement> | Error>(null)
  const [successMessageVisible, showSuccess, hideSuccess] = useToggle()

  const [isSubmitting, setSubmitting, setNotSubmitting] = useToggle()

  const confirmPayment = async (values: FormValues) => {
    if (isSubmitting) {
      return
    }

    setSubmitting()
    setError(null)
    try {
      await createBatchPayments(values.year, values.accounts)
      handleClose()
      showSuccess()
      onRefresh()
    } catch (e) {
      setError(e)
    } finally {
      setNotSubmitting()
    }
  }

  return (
    <>
      <Button
        startIcon={<RiStackLine />}
        variant="contained"
        size="small"
        onClick={open}
      >
        Płatności grupowe
      </Button>
      <Snackbar
        open={successMessageVisible}
        autoHideDuration={6000}
        onClose={hideSuccess}
      >
        <Alert onClose={hideSuccess} severity="success">
          Płatności zostały utworzone
        </Alert>
      </Snackbar>
      <Dialog open={isOpen} onClose={handleClose} fullWidth>
        <Formik
          initialValues={{
            year: DateTime.now().toFormat('yyyy'),
            accountIds: '',
            accounts: []
          }}
          validationSchema={validationSchema}
          onSubmit={confirmPayment}
        >
          {({ submitForm, values, setFieldValue }) => (
            <AdminGroupPaymentForm
              values={values}
              error={error}
              submitForm={submitForm}
              setFieldValue={setFieldValue}
              onCancel={handleClose}
              isSubmitting={isSubmitting}
            />
          )}
        </Formik>
      </Dialog>
    </>
  )
}
