import { HorizontalView } from '../../../Layout'
import { Box, InputAdornment } from '@mui/material'
import { TextRegular } from '../../../Text/TextRegular'
import { validateAmount } from './helpers'
import {
  FeeConfigWizard,
  FirstPaymentResolverType,
  FirstPaymentUnitWizard
} from './duck/types'
import { InputField } from '../../../Form'
import React, { FC, useMemo } from 'react'
import { MemberFeePeriodType } from '../../types'
import {
  firstPaymentPeriodDensityMonthsLabel,
  getEffectiveFirstPaymentDensity
} from './firstPayment.helpers'

export interface FirstPaymentUnitFieldProps {
  config: FeeConfigWizard
  unit: FirstPaymentUnitWizard
  value: string
  name: string
  periodType: MemberFeePeriodType
}

export const FirstPaymentUnitField: FC<FirstPaymentUnitFieldProps> = ({
  config,
  unit,
  value,
  name,
  periodType
}) => {
  const validateFirstPayment = useMemo(() => {
    if (
      config.enableFirstPaymentPeriod &&
      config.firstPaymentPeriodResolver === FirstPaymentResolverType.CUSTOM
    ) {
      return validateAmount
    }

    return () => null
  }, [config])

  const effectiveDensity = useMemo(
    () =>
      getEffectiveFirstPaymentDensity(
        config.firstPaymentPeriodDensity,
        periodType
      ),
    [config, periodType]
  )

  const densityLabel = useMemo(
    () =>
      firstPaymentPeriodDensityMonthsLabel(
        effectiveDensity,
        unit.periodIndex,
        periodType
      ),
    [effectiveDensity, unit, periodType]
  )

  return (
    <Box mt={1}>
      <HorizontalView>
        <Box mr={1}>
          <TextRegular>{densityLabel}</TextRegular>
        </Box>
        {config.firstPaymentPeriodResolver ===
          FirstPaymentResolverType.PROPORTIONAL && (
          <TextRegular>{value} zł</TextRegular>
        )}
        {config.firstPaymentPeriodResolver ===
          FirstPaymentResolverType.CUSTOM && (
          <InputField
            disabled={!unit.editable}
            name={name}
            label="Kwota"
            placeholder="np. 100"
            size="small"
            noMargin
            validate={validateFirstPayment}
            InputProps={{
              endAdornment: <InputAdornment position="end">zł</InputAdornment>
            }}
          />
        )}
      </HorizontalView>
    </Box>
  )
}
