import React from 'react'
import { Store } from 'redux'
import { Provider } from 'react-redux'
import { Main } from './components/Main'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { muiTheme } from './common/muiTheme'
import { Helmet } from 'react-helmet'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

export interface AppProps {
  store: Store
}

export function App({ store }: AppProps) {
  return (
    <Provider store={store}>
      <Helmet titleTemplate="%s | CelPal" defaultTitle="CelPal" />
      <StyledEngineProvider>
        <ThemeProvider theme={muiTheme}>
          <LocalizationProvider
            adapterLocale="pl"
            dateAdapter={AdapterLuxon}
            locale="pl"
          >
            <Main />
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  )
}
