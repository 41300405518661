import React from 'react'
import { useSelector } from 'react-redux'
import { hasRoleSelector } from '../Auth/auth.duck'
import { Role } from '../Account/enums'
import { AdminDocuments } from './AdminDocuments'
import { MyDocuments } from './MyDocuments'

export function Documents() {
  const isAdmin = useSelector(hasRoleSelector(Role.DOCUMENTS_ADMIN))

  return isAdmin ? <AdminDocuments /> : <MyDocuments />
}
