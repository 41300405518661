import { colors } from '../../../../../styles/variables'
import { Box } from '@mui/material'
import { TextRegular } from '../../../../Text/TextRegular'
import { View } from '../../../../Layout'
import React, { FC } from 'react'
import { StatusBadge } from './StatusBadge'
import { PendingIcon } from './PendingIcon'

export const StatusPending: FC = () => {
  return (
    <View alignItems="flex-start">
      <StatusBadge background={colors.mainDark} color="#fff">
        <Box display="flex" mr={1}>
          <PendingIcon size={24} />
        </Box>
        <TextRegular variant="medium">Płatność jest przetwarzana</TextRegular>
      </StatusBadge>
    </View>
  )
}
