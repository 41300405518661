import { AccountDTO } from './types'
import { Action } from 'redux'
import { useDispatch } from 'react-redux'
import { fetchAccount } from './account.service'
import { useCallback } from 'react'
import { createSelector } from 'reselect'

export interface AccountState {
  isFetching: boolean
  account: AccountDTO
}

const initialState: AccountState = {
  isFetching: false,
  account: null
}

export interface AccountStateForRoot {
  account: AccountState
}

export enum AccountActionType {
  REQUEST = 'account/request',
  RECEIVE = 'account/receive'
}

interface RequestAccount extends Action {
  type: AccountActionType.REQUEST
}

interface ReceiveAccount {
  type: AccountActionType.RECEIVE
  account: AccountDTO
}

type AccountAction = RequestAccount | ReceiveAccount

const requestAccount = () => ({
  type: AccountActionType.REQUEST
})

const receiveAccount = (account: AccountDTO) => ({
  type: AccountActionType.RECEIVE,
  account
})

export const useFetchAccount = () => {
  const dispatch = useDispatch()

  return useCallback(async () => {
    dispatch(requestAccount())

    // TODO: handle errors
    const response = await fetchAccount()

    if (response) {
      dispatch(receiveAccount(response.data))
    }
  }, [dispatch])
}

export const useClearAccount = () => {
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch(receiveAccount(null))
  }, [dispatch])
}

export const accountStateSelector = (state: AccountStateForRoot) =>
  state.account
export const accountFetchingSelector = createSelector(
  accountStateSelector,
  (state) => state.isFetching
)
export const accountSelector = createSelector(
  accountStateSelector,
  (state) => state.account
)
export const hasAccountSelector = createSelector(
  accountStateSelector,
  (state) => !state.isFetching && state.account
)

export const accountReducer = (
  state: AccountState = initialState,
  action: AccountAction
): AccountState => {
  switch (action.type) {
    case AccountActionType.REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case AccountActionType.RECEIVE:
      return {
        ...state,
        isFetching: false,
        account: action.account
      }
    default:
      return state
  }
}
