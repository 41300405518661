import Box from '@mui/material/Box'
import { TextRegular } from '../Text/TextRegular'
import { Link } from '../Link'
import { HorizontalView } from '../Layout'
import { AiFillGoogleCircle } from 'react-icons/ai'
import { BlockedLanesInfo } from './BlockedLanesInfo'
import { ClickAwayListener } from '@mui/material'
import React from 'react'
import { RangeBlockedLane, RangeCombinedEvent } from './types'
import { DateTime } from 'luxon'
import { IoWarning } from 'react-icons/io5'
import { colors } from '../../styles/variables'

export interface CalendarEventDetailsProps {
  event: RangeCombinedEvent
  onClickAway: () => void
  onNewBlockade: () => void
  onDeleteBlockade: (
    event: RangeCombinedEvent,
    blockedLane: RangeBlockedLane
  ) => void
}

export function CalendarEventDetails({
  event,
  onClickAway,
  onNewBlockade,
  onDeleteBlockade
}: CalendarEventDetailsProps) {
  const startTime = DateTime.fromISO(event.startTime)
  const endTime = DateTime.fromISO(event.endTime)
  const isWholeDay = !!event.googleEvent?.start.date

  const handleDeleteBlockade = (blockedLane: RangeBlockedLane) => {
    onDeleteBlockade(event, blockedLane)
  }

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Box>
        <TextRegular size="medium">{event.summary}</TextRegular>
        <Box my={1}>
          {!isWholeDay ? (
            <>
              {startTime.toFormat('HH:mm')} - {endTime.toFormat('HH:mm')}
            </>
          ) : (
            <>{startTime.toFormat('d. MMMM yyyy')}</>
          )}
        </Box>
        {!!event.googleEvent && (
          <Link target="_blank" href={event.googleEvent.htmlLink}>
            <HorizontalView>
              <AiFillGoogleCircle size={24} />
              <Box ml={1}>Wydarzenie w kalendarzu Google</Box>
            </HorizontalView>
          </Link>
        )}
        {!event.googleEvent && event.hasGoogleEvent && (
          <HorizontalView>
            <IoWarning color={colors.warn} size={24} />
            <Box ml={1}>
              Wydarzenie istnieje w kalendarzu Google,
              <br />
              jednak nie znaleziono powiązanego z nim konta.
            </Box>
          </HorizontalView>
        )}
        <Box mt={5}>
          <BlockedLanesInfo
            blockedLanes={event.blockedLanes}
            onNewBlockade={onNewBlockade}
            onDeleteBlockade={handleDeleteBlockade}
          />
        </Box>
      </Box>
    </ClickAwayListener>
  )
}
