import { View } from '../Layout'
import { IoIosImages } from 'react-icons/io'
import { TextRegular } from '../Text/TextRegular'
import React, { FC, useState } from 'react'
import { useToggle } from '../../hooks/useToggle'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material'
import Box from '@mui/material/Box'
import styled from 'styled-components'
import { ApiInterceptor } from '../Api/ApiInterceptor'
import { Link } from '../Link'
import { Field, Formik } from 'formik'
import { FileInput } from '../Form/FileInput'
import { networkError } from '../../helpers/networkError'
import { ErrorType } from '../Api/enums'

export interface AvatarProps {
  onSave: () => void
  saveUri: string
  title: string
  dialogTitle: string
  fileResource: string
}

interface FormValues {
  file: File
}

const acceptedFileTypes = ['image/jpeg', 'image/png', 'image/svg+xml']
const oneMBFileSize = 1024 * 1024

export const ClubResourceImage: FC<AvatarProps> = ({
  onSave,
  saveUri,
  title,
  dialogTitle,
  fileResource
}) => {
  const [dialogVisible, showDialog, hideDialog] = useToggle()
  const [error, setError] = useState(null)

  const save = async (values: FormValues) => {
    setError(null)
    const formData = new FormData()
    formData.append('image', values.file)

    try {
      await ApiInterceptor.post(saveUri, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })

      hideDialog()
      onSave()
    } catch (e) {
      networkError(e)
        .onErrorResponse(ErrorType.FILE_SIZE_EXCEEDED, () =>
          setError('Zbyt duży rozmiar pliku')
        )
        .elseReportToSentry()
    }
  }

  const validate = (values: FormValues) => {
    if (!values.file) {
      return { file: 'Wybierz plik' }
    }

    if (!acceptedFileTypes.includes(values.file.type)) {
      return { file: `Dozwolony format: ${acceptedFileTypes.join(',')}` }
    }

    if (values.file.size > oneMBFileSize) {
      return { file: `Wybierz plik mniejszy niż 1 MB` }
    }

    return null
  }

  return (
    <View>
      <Dialog open={dialogVisible} maxWidth="xs" fullWidth>
        <Formik<FormValues>
          onSubmit={save}
          initialValues={{ file: null }}
          validate={validate}
        >
          {({ submitForm, isSubmitting }) => (
            <>
              <DialogTitle>{dialogTitle}</DialogTitle>
              <DialogContent>
                <Box>
                  <Field
                    name="file"
                    component={FileInput}
                    accept={acceptedFileTypes.join(',')}
                  />
                  <TextRegular light>
                    <Box mt={2}>Format: jpg, png, svg</Box>
                    <Box mt={1}>Maksymalny rozmiar: 1 MB</Box>
                  </TextRegular>
                </Box>
                {error && <Box mt={2}>{error}</Box>}
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  onClick={hideDialog}
                  disabled={isSubmitting}
                >
                  Anuluj
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={submitForm}
                  disabled={isSubmitting}
                >
                  Zapisz
                </Button>
              </DialogActions>
            </>
          )}
        </Formik>
      </Dialog>
      <View alignItems="flex-end">
        <Box mb={1}>
          <TextRegular size="medium" strong>
            {title}
          </TextRegular>
        </Box>
        {fileResource ? (
          <AvatarImg alt={title} src={fileResource} />
        ) : (
          <IoIosImages size={50} />
        )}
        <Box mt={1}>
          <Link onClick={showDialog}>{fileResource ? 'Zmień' : 'Dodaj'}</Link>
        </Box>
      </View>
    </View>
  )
}

const AvatarImg = styled.img`
  max-width: 130px;
  max-height: 130px;
`
