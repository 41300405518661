import { Box } from '@mui/material'
import { TextRegular } from '../../../../Text/TextRegular'
import { BiErrorAlt } from 'react-icons/bi'
import { View } from '../../../../Layout'
import React, { FC } from 'react'
import { StatusBadge } from './StatusBadge'
import { colors } from '../../../../../styles/variables'

export const StatusUnknown: FC = () => {
  return (
    <View alignItems="flex-start">
      <StatusBadge color={colors.mainDark}>
        <Box display="flex" mr={1}>
          <BiErrorAlt size={24} />
        </Box>
        <TextRegular variant="medium">Status nieokreślony</TextRegular>
      </StatusBadge>
    </View>
  )
}
