import React from 'react'
import { Box, Paper } from '@mui/material'
import { Link } from '../Link'
import { FaFacebookSquare } from 'react-icons/fa'
import { GoMail } from 'react-icons/go'
import { HorizontalView } from '../Layout'
import styled from 'styled-components'
import { TextRegular } from '../Text/TextRegular'
import { useSelector } from 'react-redux'
import { currentClubDuck } from '../../ducks/currentClub.duck'

export const ContactHelp = () => {
  const club = useSelector(currentClubDuck.selectors.data)

  return (
    <Paper elevation={3}>
      <Box p={2}>
        <TextRegular variant="large">Potrzebujesz pomocy?</TextRegular>
        {!!club?.email && (
          <>
            <Box mt={2} mb={1}>
              <TextRegular variant="medium">
                Kontakt z klubem {club.shortName}
              </TextRegular>
            </Box>
            <LinkStyled href={`mailto:${club.email}`}>
              <HorizontalView>
                <GoMail size={24} />
                &nbsp;{club.email}
              </HorizontalView>
            </LinkStyled>
          </>
        )}
        <Box mt={2} mb={1}>
          <TextRegular variant="medium">Obsługa techniczna:</TextRegular>
        </Box>
        <Box>
          <LinkStyled href="mailto:kontakt@celpal.net">
            <HorizontalView>
              <GoMail size={24} />
              &nbsp;kontakt@celpal.net
            </HorizontalView>
          </LinkStyled>
        </Box>
        <Box mt={1}>
          <LinkStyled
            href="https://www.facebook.com/CelPalApp"
            target="_blank"
            rel="noreferrer noopener"
          >
            <HorizontalView>
              <FaFacebookSquare size={20} />
              &nbsp;fb.com/CelPalApp
            </HorizontalView>
          </LinkStyled>
        </Box>
      </Box>
    </Paper>
  )
}

const LinkStyled = styled(Link)`
  display: inline-block;
`
