import { Box } from '@mui/material'
import {
  ageRangeLabel,
  genderLabel,
  introductoryLabel,
  purposeLabel,
  serviceLabel
} from './criteriaDescriptionHelper'
import React, { FC } from 'react'
import { FeeConfig, FeeUnit } from '../../types'
import { CriteriaDescriptionItem } from './CriteriaDescriptionItem'

interface CriteriaDescriptionProps {
  feeConfig: FeeConfig
  feeUnit: FeeUnit
  licenseApplicable?: boolean
}

export const CriteriaDescription: FC<CriteriaDescriptionProps> = ({
  feeConfig,
  feeUnit,
  licenseApplicable = false
}) => {
  if (
    !feeConfig.ageApplies &&
    !feeConfig.genderApplies &&
    !feeConfig.introductoryApplies &&
    !feeConfig.purposeApplies &&
    !feeConfig.serviceApplies &&
    !licenseApplicable
  ) {
    return null
  }

  return (
    <Box mt={2} mb={1}>
      {feeConfig.ageApplies && (
        <CriteriaDescriptionItem>
          {ageRangeLabel(feeUnit.ageRange)}
        </CriteriaDescriptionItem>
      )}
      {feeConfig.genderApplies && (
        <CriteriaDescriptionItem>
          {genderLabel(feeUnit.gender)}
        </CriteriaDescriptionItem>
      )}
      {feeConfig.introductoryApplies && (
        <CriteriaDescriptionItem>
          {introductoryLabel(feeUnit.hasIntroductory)}
        </CriteriaDescriptionItem>
      )}
      {feeConfig.purposeApplies && (
        <CriteriaDescriptionItem>
          {purposeLabel(feeUnit.purpose)}
        </CriteriaDescriptionItem>
      )}
      {feeConfig.serviceApplies && (
        <CriteriaDescriptionItem>
          {serviceLabel(feeUnit.isService)}
        </CriteriaDescriptionItem>
      )}
      {licenseApplicable && (
        <CriteriaDescriptionItem>
          W tym licencja sportowa
        </CriteriaDescriptionItem>
      )}
    </Box>
  )
}
