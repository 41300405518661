import { Button } from '@mui/material'
import { IoImageOutline } from 'react-icons/io5'
import { FormError } from './FormError'
import React, {
  ChangeEvent,
  FC,
  FocusEventHandler,
  HTMLProps,
  useEffect,
  useState
} from 'react'
import { IoMdClose } from 'react-icons/io'
import { HorizontalView, View } from '../Layout'
import { useToggle } from '../../hooks/useToggle'
import styled from 'styled-components'
import { colors } from '../../styles/variables'
import { Loading } from '../Loading'
import Box from '@mui/material/Box'
import { FieldProps } from 'formik'

export const FileInput: FC<
  Omit<FieldProps<File>, 'meta'> & HTMLProps<HTMLInputElement>
> = ({
  field: { name, onBlur: onBlurField, value },
  form,
  onChange,
  onBlur,
  ...props
}) => {
  const meta = form.getFieldMeta(name)
  const [isLoading, startLoading, stopLoading] = useToggle()
  const [image, setImage] = useState(null)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    form.setFieldValue(name, e.target.files[0])
  }
  const handleBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    if (onBlur) {
      onBlur(e)
    }
    onBlurField(e)
  }

  useEffect(() => {
    if (value) {
      startLoading()
      const fileReader = new FileReader()
      fileReader.addEventListener('load', (frEvent) => {
        setImage(frEvent.target.result)
        stopLoading()
      })
      fileReader.readAsDataURL(value)
    } else {
      setImage(null)
    }
  }, [value, startLoading, stopLoading])

  if (value && value.type.match(/^image\//)) {
    if (isLoading && !image) {
      return <Loading />
    }

    return (
      <View>
        <RemoveLink onClick={() => form.setFieldValue(name, null)}>
          <IoMdClose />
          &nbsp; usuń
        </RemoveLink>
        <img alt="awatar" src={image} />
        {meta.touched && !!meta.error && (
          <Box mt={1}>
            <FormError>{meta.error}</FormError>
          </Box>
        )}
      </View>
    )
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        size="large"
        component="label"
        startIcon={<IoImageOutline />}
      >
        Wybierz plik
        <input
          {...props}
          hidden
          type="file"
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </Button>
      {meta.touched && !!meta.error && (
        <Box mt={1}>
          <FormError>{meta.error}</FormError>
        </Box>
      )}
    </>
  )
}

const RemoveLink = styled(HorizontalView)`
  color: ${colors.error};
  cursor: pointer;
`
