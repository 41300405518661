import React, { FC, useEffect } from 'react'
import { PagePaper } from '../../Layout'
import { RangeDTO } from '../types'
import { Table, TableBody, TableCell, TableRow } from '@mui/material'
import { Formik } from 'formik'
import Button from '@mui/material/Button'
import {
  formValuesToApi,
  openingHoursToFormValues,
  timeStringToDate
} from './helpers'
import { HoursRow } from './HoursRow'
import {
  NetworkRequestStatus,
  useNetworkRequest
} from '../../../common/network-state'
import { ApiInterceptor } from '../../Api/ApiInterceptor'
import {
  FormTimeRange,
  FormValues,
  RangeOpeningHours as RangeOpeningHoursType
} from './types'
import styled from 'styled-components'

export interface RangeOpeningHoursProps {
  range: RangeDTO
}

const getInitialValue = (): FormTimeRange => ({
  from: timeStringToDate('00:00:00'),
  to: timeStringToDate('00:00:00')
})

const initialValues: FormValues = {
  monday: getInitialValue(),
  tuesday: getInitialValue(),
  wednesday: getInitialValue(),
  thursday: getInitialValue(),
  friday: getInitialValue(),
  saturday: getInitialValue(),
  sunday: getInitialValue()
}

export const RangeOpeningHours: FC<RangeOpeningHoursProps> = ({ range }) => {
  const [fetch, openingHoursResponse, status] = useNetworkRequest(
    () =>
      ApiInterceptor.get<RangeOpeningHoursType>(
        `/api/range/${range.id}/opening-hours`
      ),
    [range?.id]
  )
  const saveOpeningHours = async (values: FormValues) => {
    await ApiInterceptor.post(
      `/api/range/${range.id}/opening-hours`,
      formValuesToApi(values)
    )
  }

  useEffect(() => {
    if (range) {
      fetch()
    }
  }, [fetch, range])

  return (
    <PagePaper
      title={`Godziny otwarcia - ${range ? range.name : '...'}`}
      loading={!range}
      alignItems="flex-start"
    >
      {status !== NetworkRequestStatus.IDLE &&
        status !== NetworkRequestStatus.LOADING && (
          <Formik
            initialValues={
              openingHoursResponse?.data
                ? openingHoursToFormValues(openingHoursResponse.data)
                : initialValues
            }
            onSubmit={saveOpeningHours}
          >
            {({ submitForm }) => (
              <TableStyled>
                <TableBody>
                  <HoursRow label="Poniedziałek" name="monday" />
                  <HoursRow label="Wtorek" name="tuesday" />
                  <HoursRow label="Środa" name="wednesday" />
                  <HoursRow label="Czwartek" name="thursday" />
                  <HoursRow label="Piątek" name="friday" />
                  <HoursRow label="Sobota" name="saturday" />
                  <HoursRow label="Niedziela" name="sunday" />
                  <TableRow>
                    <TableCell colSpan={2}>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={submitForm}
                      >
                        Zapisz
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </TableStyled>
            )}
          </Formik>
        )}
    </PagePaper>
  )
}

const TableStyled = styled(Table)`
  && {
    width: auto;
  }

  td {
    border: none;
  }
`
