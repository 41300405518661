import { TextRegular } from '../Text/TextRegular'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import { FaCheckCircle } from 'react-icons/fa'
import { IoIosCloseCircle } from 'react-icons/io'
import { FormError } from '../Form'
import { Link } from '../Link'
import React from 'react'
import { MembershipCertificateVerification } from './types'
import styled from 'styled-components'

export interface DocumentProps {
  data: MembershipCertificateVerification | null
  onClear: () => void
}

export function VerificationDocument({ data, onClear }: DocumentProps) {
  if (!data) {
    return null
  }

  const memberSince = DateTime.fromISO(data.memberSince)
  const firstPaymentDate = DateTime.fromISO(data.firstPaymentDate)
  const dateTime = DateTime.fromISO(data.createdDate)

  return (
    <CertificateWrapperBox py={3} px={4}>
      <TextRegular size="medium" strong>
        Zaświadczenie z dnia {dateTime.toFormat('yyyy-MM-dd')}
      </TextRegular>
      <Box mt={3}>
        <TextRegular light size="small">
          Ważność zaświadczenia
        </TextRegular>
        <Box mt={1}>
          {data.isValid ? (
            <Box display="flex" alignItems="center">
              <Box mr={1}>
                <FaCheckCircle display="block" size={24} color="#00c322" />{' '}
              </Box>
              <Box>
                zaświadczenie jest autentyczne
                <br />
                <TextRegular light size="small">
                  PESEL i PIN zgodny
                </TextRegular>
              </Box>
            </Box>
          ) : (
            <Box display="flex" alignItems="center">
              <Box mr={0.5}>
                <IoIosCloseCircle display="block" size={24} color="#f93b00" />
              </Box>
              nie potwierdzono autentyczności
            </Box>
          )}
        </Box>
      </Box>
      <Box mt={3}>
        <TextRegular light size="small">
          Nazwa klubu
        </TextRegular>
        <Box mt={1}>{data.clubName}</Box>
      </Box>
      <Box mt={3}>
        <TextRegular light size="small">
          Imię i nazwisko członka klubu
        </TextRegular>
        <Box mt={1}>
          {data.account.firstName} {data.account.lastName}
        </Box>
      </Box>
      <Box mt={2}>
        {data.isAccountActive && data.isAccountExpirationValid ? (
          <Box display="flex" alignItems="center">
            <Box mr={1}>
              <FaCheckCircle display="block" size={24} color="#00c322" />{' '}
            </Box>
            <Box>konto jest aktywne</Box>
          </Box>
        ) : (
          <Box display="flex" alignItems="center">
            <Box mr={0.5}>
              <IoIosCloseCircle display="block" size={24} color="#f93b00" />
            </Box>
            konto nieaktywne
          </Box>
        )}
      </Box>
      <Box mt={3}>
        <TextRegular light size="small">
          Data wstąpienia do klubu
        </TextRegular>
        <Box mt={1}>
          {data.memberSince ? (
            memberSince.toFormat('d. MMMM yyyy r.')
          ) : (
            <FormError>brak danych</FormError>
          )}
        </Box>
      </Box>
      <Box mt={3}>
        <TextRegular light size="small">
          Członkostwo w klubie od
        </TextRegular>
        <Box mt={1}>
          {data.firstPaymentDate ? (
            (firstPaymentDate.toMillis() < memberSince.toMillis()
              ? memberSince
              : firstPaymentDate
            ).toFormat('d. MMMM yyyy r.')
          ) : (
            <FormError>brak danych</FormError>
          )}
        </Box>
      </Box>
      <Box mt={3}>
        <TextRegular light size="small">
          Data utworzenia zaświadczenia
        </TextRegular>
        <Box mt={1}>{dateTime.toFormat('yyyy-MM-dd HH:mm')}</Box>
      </Box>
      <Box mt={3}>
        <Link onClick={onClear}>Zamknij</Link>
      </Box>
    </CertificateWrapperBox>
  )
}

const CertificateWrapperBox = styled(Box)`
  background: #fafafa;
  border: 1px solid #ccc;
  align-self: flex-start;
  border-radius: 4px;
  box-shadow: 3px -3px 10px -3px rgb(0 0 0, 0.1);
`
