import styled from 'styled-components'
import React, { forwardRef } from 'react'
import {
  OverridableComponent,
  OverridableComponentGeneric,
  OverridableComponentProps
} from '../../common/overridableComponents'

type ViewOwnProps = {
  alignItems?: HTMLElement['style']['alignItems']
}

export type ViewProps = OverridableComponentProps<ViewOwnProps, 'div'> & {
  alignItems?: HTMLElement['style']['alignItems']
}

const ViewComponent: OverridableComponent<ViewOwnProps, 'div'> = (
  { component, ...props },
  ref
) => {
  // @ts-ignore
  return <ViewStyled ref={ref} as={component || 'div'} {...props} />
}

export const View = forwardRef(ViewComponent) as OverridableComponentGeneric<
  ViewOwnProps,
  'div'
>

const ViewStyled = styled.div<ViewOwnProps>`
  display: flex;
  flex-direction: column;
  ${(p) => p.alignItems && `align-items: ${p.alignItems};`};
`
