import { jwtSelector, useFetchJwtFromStore } from '../Auth/auth.duck'
import React, { useEffect } from 'react'
import { RootRoutes } from '../Routes'
import { useSelector } from 'react-redux'
import { useClearAccount, useFetchAccount } from '../Account/account.duck'
import { currentClubDuck } from '../../ducks/currentClub.duck'

export const Main = () => {
  const jwt = useSelector(jwtSelector)
  const fetchAccount = useFetchAccount()
  const fetchCurrentClub = currentClubDuck.actions.useFetch()
  const clearAccount = useClearAccount()
  const clearCurrentClub = currentClubDuck.actions.useReset()
  const fetchJwt = useFetchJwtFromStore()

  useEffect(() => {
    fetchJwt()
  }, [fetchJwt])

  useEffect(() => {
    if (jwt) {
      fetchAccount()
      fetchCurrentClub()
    } else {
      clearAccount()
      clearCurrentClub()
    }
  }, [jwt, clearAccount, fetchAccount, fetchCurrentClub, clearCurrentClub])

  useEffect(() => {
    const splashScreen = document.getElementById('splash-screen')
    const transitionEnd = () => {
      splashScreen.removeEventListener('transitionend', transitionEnd)
      splashScreen.remove()
    }

    splashScreen.addEventListener('transitionend', transitionEnd)
    splashScreen.style.opacity = '0'
  }, [])

  return <RootRoutes />
}
