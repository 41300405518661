import {
  GatewayP24,
  GatewayPayU,
  MembershipP24PaymentOrder,
  MembershipPaymentOrder,
  MembershipPayUPaymentOrder
} from '../../../types'
import React, { FC } from 'react'
import { GatewayType, P24OrderStatus, PayUOrderStatus } from '../../../enums'
import { StatusNew } from './StatusNew'
import { StatusCompleted } from './StatusCompleted'
import { StatusPending } from './StatusPending'
import { StatusErrored } from './StatusErrored'
import { StatusWaitingForConfirmation } from './StatusWaitingForConfirmation'
import { StatusManual } from './StatusManual'
import { StatusUnknown } from './StatusUnknown'

export interface OrderStatusProps {
  order: MembershipPaymentOrder
}

export const OrderStatus: FC<OrderStatusProps> = ({ order }) => {
  switch (order.gatewayType) {
    case GatewayType.MANUAL:
      return <StatusManual order={order} />
    case GatewayType.PAY_U:
      switch ((order.gateway as GatewayPayU).status) {
        case PayUOrderStatus.NEW:
          return <StatusNew />
        case PayUOrderStatus.PENDING:
          return <StatusPending />
        case PayUOrderStatus.WAITING_FOR_CONFIRMATION:
          return <StatusWaitingForConfirmation />
        case PayUOrderStatus.REJECTED:
          return <StatusErrored>Płatność odrzucona</StatusErrored>
        case PayUOrderStatus.CANCELED:
          return <StatusErrored>Płatność anulowana</StatusErrored>
        case PayUOrderStatus.COMPLETED:
          return <StatusCompleted order={order as MembershipPayUPaymentOrder} />
        case PayUOrderStatus.REFUNDED:
          return <StatusErrored>Zwrot środków</StatusErrored>
      }
      break
    case GatewayType.P24:
      switch ((order.gateway as GatewayP24).status) {
        case P24OrderStatus.NEW:
          return <StatusNew />
        case P24OrderStatus.VERIFIED:
          return <StatusCompleted order={order as MembershipP24PaymentOrder} />
      }
      break
  }

  return <StatusUnknown />
}
