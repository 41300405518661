import React, { FC } from 'react'
import { Box, Button } from '@mui/material'
import { useSelector } from 'react-redux'
import { firstStepSelector, useGoToPreviousStep } from './duck'

export interface WizardButtonsProps {
  nextButtonLabel?: string
  handleSubmit: () => void
  isLoading?: boolean
}

export const WizardButtons: FC<WizardButtonsProps> = ({
  handleSubmit,
  nextButtonLabel = 'Dalej',
  isLoading
}) => {
  const isFirstStep = useSelector(firstStepSelector)
  const goToPreviousStep = useGoToPreviousStep()

  return (
    <Box display="flex" justifyContent="space-between" mt={4}>
      {!isFirstStep && (
        <Button onClick={goToPreviousStep} disabled={isLoading}>
          Wstecz
        </Button>
      )}
      <Button
        sx={{ ml: 'auto' }}
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={isLoading}
      >
        {nextButtonLabel}
      </Button>
    </Box>
  )
}
