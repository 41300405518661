import React, { useCallback, useEffect, useState } from 'react'
import { authSso } from './sso.service'
import { useSsoConnectPayload } from './useSsoConnectPayload'
import { SsoVendor } from './enums'
import { networkError } from '../../helpers/networkError'
import { ErrorType } from '../Api/enums'
import { captureException } from '@sentry/react'
import { FormError } from '../Form'
import { InvalidSsoLink } from './InvalidSsoLink'

export interface RedirectToSsoVendorProps {
  vendor: SsoVendor
  vendorUrl: string
  redirectSoonText: string
  jwt: string
}

export function RedirectToSsoVendor({
  vendor,
  vendorUrl,
  redirectSoonText,
  jwt
}: RedirectToSsoVendorProps) {
  const { sso, sig, returnSsoUrl } = useSsoConnectPayload()
  const [isInvalid, setInvalid] = useState(false)
  const [error, setError] = useState<Error>(null)
  const [unauthenticatedError, setUnathenticated] = useState(false)
  const redirect = useCallback(async () => {
    try {
      const response = await authSso(vendor, sso, sig, jwt)
      window.location.href = `${decodeURIComponent(returnSsoUrl)}?sso=${
        response.data.sso
      }&sig=${response.data.sig}`
    } catch (e) {
      networkError(e)
        .on400((axiosError) => {
          if (axiosError.errorType === ErrorType.INVALID_PAYLOAD) {
            setInvalid(true)
          } else {
            captureException(e)
            setError(e)
          }
        })
        .on401(() => setUnathenticated(true))
        .else(() => setError(e))
    }
  }, [jwt, vendor, sso, sig, returnSsoUrl])

  useEffect(() => {
    redirect()
  }, [redirect])

  if (isInvalid) {
    return <InvalidSsoLink vendorUrl={vendorUrl} />
  }

  if (unauthenticatedError) {
    return <FormError>Błąd logowania. Skontaktuj się z obsługą.</FormError>
  }

  if (error) {
    return (
      <FormError>
        Wystąpił nieokreślony błąd. Spróbuj ponownie lub skontaktuj się z
        obsługą.
      </FormError>
    )
  }

  return <>{redirectSoonText}</>
}
