import React, { FC } from 'react'
import { PagePaper } from '../../Layout'
import { RangeDTO } from '../types'
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { AiFillClockCircle } from 'react-icons/ai'
import { RouteLinks } from '../../Routes/RouteNames'

export interface RangeInfoProps {
  range: RangeDTO
}

export const RangeInfo: FC<RangeInfoProps> = ({ range }) => {
  return (
    <PagePaper loading={!range} title="Strzelnica - informacje">
      {!!range && (
        <>
          {range.name}
          <Box mt={1}>{range.address}</Box>
          <Box mt={1}>
            {range.lat} {range.lng}
          </Box>
          <Box mt={2}>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<AiFillClockCircle />}
              component={Link}
              to={RouteLinks.RangeOpeningHours.replace(
                ':rangeId',
                range.id.toString()
              )}
            >
              Godziny otwarcia
            </Button>
          </Box>
        </>
      )}
    </PagePaper>
  )
}
