import React from 'react'
import { View } from '../../Layout'
import styled from 'styled-components'
import { AdminPaymentConfigList } from './ConfigList'
import { Navigate, Route, Routes } from 'react-router-dom'
import { RouteLinks, RouteNames } from '../../Routes/RouteNames'
import { AdminPaymentSettings } from './Settings'
import { AdminPaymentsWizard } from './Wizard'
import { AdminPaymentOrdersList } from './OrdersList'
import { Helmet } from 'react-helmet'
import { GatewayType } from '../enums'

export const AdminPaymentRoutes = () => {
  return (
    <Wrapper>
      <Helmet>
        <title>Składki członkowskie - administracja</title>
      </Helmet>
      <Routes>
        <Route
          path={RouteNames.AdminPaymentOrdersList}
          element={<AdminPaymentOrdersList />}
        />
        <Route
          path={RouteNames.AdminPaymentConfigList}
          element={<AdminPaymentConfigList />}
        />
        <Route
          path={RouteNames.AdminPaymentPayUSettings}
          element={<AdminPaymentSettings gatewayType={GatewayType.PAY_U} title="PayU"
                                         posIdLabel="Id punktu płatności (pos_id)"
                                         secondKeyLabel="Drugi klucz (MD5)"
                                         clientIdLabel="Protokół OAuth - client_id"
                                         clientSecretLabel="Protokół OAuth - client_secret"
          />}
        />
        <Route
          path={RouteNames.AdminPaymentP24USettings}
          element={<AdminPaymentSettings gatewayType={GatewayType.P24} title="Przelewy24"
                                         posIdLabel="Numer klienta (merchantId)"
                                         secondKeyLabel="Klucz do CRC"
                                         clientIdLabel=""
                                         clientSecretLabel="Klucz do raportów (klucz do API)"
          />}
        />
        <Route
          path={RouteNames.AdminPaymentWizard}
          element={<AdminPaymentsWizard />}
        />
        <Route
          path={RouteNames.AdminPaymentConfig}
          element={<Navigate to={RouteLinks.AdminPaymentConfigList} replace />}
        />
        <Route
          path="*"
          element={<Navigate to={RouteLinks.AdminPaymentOrdersList} replace />}
        />
      </Routes>
    </Wrapper>
  )
}

const Wrapper = styled(View)`
  min-height: 100%;
`
