import { Route, Routes } from 'react-router-dom'
import { RouteNames } from './RouteNames'
import { RangesRoutes } from '../Ranges'
import { ClubSettings } from '../ClubSettings'
import { CalendarAuth } from '../CalendarAuth'
import { Calendar } from '../Calendar'
import React from 'react'

export function ClubAdminRoutes() {
  return (
    <Routes>
      <Route path={RouteNames.Ranges} element={<RangesRoutes />} />
      <Route path={RouteNames.ClubSettings} element={<ClubSettings />} />
      <Route
        path={RouteNames.CalendarAuthRedirect}
        element={<CalendarAuth />}
      />
      <Route path={RouteNames.Calendar} element={<Calendar />} />
    </Routes>
  )
}
