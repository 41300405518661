import { useSelector } from 'react-redux'
import { formValuesSelector } from './duck'
import { useMemo } from 'react'
import { Gender, MemberFeePeriodType, MembershipPurpose } from '../../types'
import { EntryFeeWizardUnits, MemberFeeWizardUnits } from './duck/types'
import { periodsOrdered } from './MemberFeePeriodsStep'
import { generateFirstPaymentPeriods } from './firstPayment.helpers'

export function useGenerateEntryFeeUnits() {
  const formValues = useSelector(formValuesSelector)

  return useMemo<EntryFeeWizardUnits>(() => {
    const config = formValues.entryFeeConfig
    const ageRanges = formValues.entryFeeAgeRanges
    const purposes = config.purposeApplies
      ? [
          MembershipPurpose.SPORT,
          MembershipPurpose.COLLECTION,
          MembershipPurpose.BOTH
        ]
      : [null]
    const services = config.serviceApplies ? [true, false] : [null]
    const genders = config.genderApplies ? [Gender.MALE, Gender.FEMALE] : [null]
    const introductories = config.introductoryApplies ? [true, false] : [null]
    const units: EntryFeeWizardUnits = []
    const effectiveAgeRanges = config.ageApplies
      ? ageRanges
          .map((ageRange, i) => ({
            from: ageRanges[i - 1] || null,
            to: ageRange
          }))
          .concat([{ from: ageRanges[ageRanges.length - 1], to: null }])
      : [null]
    const firstPaymentUnits = config.enableFirstPaymentPeriod
      ? generateFirstPaymentPeriods(
          config.firstPaymentPeriodDensity,
          MemberFeePeriodType.YEAR
        )
      : null

    effectiveAgeRanges.forEach((ageRange) => {
      services.forEach((isService) => {
        genders.forEach((gender) => {
          introductories.forEach((hasIntroductory) => {
            purposes.forEach((purpose) => {
              units.push({
                amount: '',
                firstPaymentUnits,
                isService,
                gender,
                hasIntroductory,
                purpose,
                ageRange
              })
            })
          })
        })
      })
    })

    return units
  }, [formValues])
}

export function useGenerateMemberFeeUnits() {
  const formValues = useSelector(formValuesSelector)

  return useMemo<MemberFeeWizardUnits>(() => {
    const config = formValues.memberFeeConfig
    const periods = formValues.memberFeePeriods
    const ageRanges = formValues.memberFeeAgeRanges
    const purposes = config.purposeApplies
      ? [
          MembershipPurpose.SPORT,
          MembershipPurpose.COLLECTION,
          MembershipPurpose.BOTH
        ]
      : [null]
    const services = config.serviceApplies ? [true, false] : [null]
    const genders = config.genderApplies ? [Gender.MALE, Gender.FEMALE] : [null]
    const introductories = config.introductoryApplies ? [true, false] : [null]
    const units: MemberFeeWizardUnits = []
    const effectiveAgeRanges = config.ageApplies
      ? ageRanges
          .map((ageRange, i) => ({
            from: ageRanges[i - 1] || null,
            to: ageRange
          }))
          .concat([{ from: ageRanges[ageRanges.length - 1], to: null }])
      : [null]

    effectiveAgeRanges.forEach((ageRange) => {
      services.forEach((isService) => {
        genders.forEach((gender) => {
          introductories.forEach((hasIntroductory) => {
            periodsOrdered.forEach((periodType) => {
              if (periods.includes(periodType)) {
                purposes.forEach((purpose) => {
                  units.push({
                    amount: '',
                    firstPaymentUnits: config.enableFirstPaymentPeriod
                      ? generateFirstPaymentPeriods(
                          config.firstPaymentPeriodDensity,
                          periodType
                        )
                      : null,
                    isService,
                    gender,
                    hasIntroductory,
                    purpose,
                    periodType,
                    ageRange
                  })
                })
              }
            })
          })
        })
      })
    })

    return units
  }, [formValues])
}
