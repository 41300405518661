import { MembershipFeeWizardStateForRoot } from './types'
import { createSelector } from 'reselect'
import { feesDuck } from '../../../fee-configs.duck'
import { NetworkRequestStatus } from '../../../../../common/network-state'
import { initialState } from './reducer'

export const membershipFeeWizardStateSelector = (
  state: MembershipFeeWizardStateForRoot
) => state.membershipFeeWizard
export const currentStepSelector = createSelector(
  membershipFeeWizardStateSelector,
  (state) => state.currentStep
)
export const firstStepSelector = createSelector(
  currentStepSelector,
  (step) => step === initialState.currentStep
)
export const formValuesSelector = createSelector(
  membershipFeeWizardStateSelector,
  (state) => state.formValues
)
export const latestFeeConfigSelector = createSelector(
  feesDuck.selectors.data,
  feesDuck.selectors.status,
  (configs, status) => {
    if (status !== NetworkRequestStatus.LOADED) {
      return null
    }

    return configs.find((config) => !config.endTime)
  }
)
export const entryFeeUnitsCountSelector = createSelector(
  formValuesSelector,
  (formValues) => {
    let count = 1

    if (formValues.entryFeeConfig.purposeApplies) {
      count = count * 3
    }

    if (formValues.entryFeeConfig.serviceApplies) {
      count = count * 2
    }

    if (formValues.entryFeeConfig.genderApplies) {
      count = count * 2
    }

    if (formValues.entryFeeConfig.introductoryApplies) {
      count = count * 2
    }

    if (
      formValues.entryFeeConfig.ageApplies &&
      formValues.entryFeeAgeRanges.length > 0
    ) {
      count = count * (formValues.entryFeeAgeRanges.length + 1)
    }

    return count
  }
)

export const memberFeeUnitsCountSelector = createSelector(
  formValuesSelector,
  (formValues) => {
    const config = formValues.memberFeeConfig
    const ageRanges = formValues.memberFeeAgeRanges
    const periodTypes = formValues.memberFeePeriods

    let count = 1

    if (config.purposeApplies) {
      count = count * 3
    }

    if (config.serviceApplies) {
      count = count * 2
    }

    if (config.genderApplies) {
      count = count * 2
    }

    if (config.introductoryApplies) {
      count = count * 2
    }

    if (config.ageApplies && ageRanges.length > 0) {
      count = count * (ageRanges.length + 1)
    }

    if (periodTypes.length > 0) {
      count = count * periodTypes.length
    }

    return count
  }
)
