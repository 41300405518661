import React, { useEffect } from 'react'
import Button from '@mui/material/Button'
import { useToggle } from '../../../hooks/useToggle'
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'
import {
  NetworkRequestStatus,
  useNetworkRequest
} from '../../../common/network-state'
import Box from '@mui/material/Box'
import { FormError } from '../../Form'
import { TextRegular } from '../../Text/TextRegular'
import styled from 'styled-components'
import { colors } from '../../../styles/variables'
import { RouteLinks } from '../../Routes/RouteNames'
import { formatRoute } from '../../../helpers/formatRoute'
import { Link } from 'react-router-dom'
import { getRangesWithLanes } from '../../Ranges/ranges.service'

export function BookingsRangePicker({ children }) {
  const [rangeDialogOpen, showRangeDialog, hideRangeDialog] = useToggle()
  const [fetch, rangesResponse, fetchStatus] = useNetworkRequest(() =>
    getRangesWithLanes()
  )

  useEffect(() => {
    if (rangeDialogOpen) {
      fetch()
    }
  }, [rangeDialogOpen, fetch])

  return (
    <>
      <Button onClick={showRangeDialog} color="primary" variant="contained">
        {children}
      </Button>
      <Dialog open={rangeDialogOpen} onClose={hideRangeDialog}>
        <DialogTitle>Wybierz strzelnicę</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Wybierz strzelnicę, dla której chcesz zobaczyć kalendarz.
          </DialogContentText>
          {fetchStatus === NetworkRequestStatus.LOADING && <CircularProgress />}
          {fetchStatus === NetworkRequestStatus.LOADED && (
            <Box mt={2}>
              {rangesResponse.data?.map((range) => (
                <RangeTileLink
                  key={range.id}
                  to={formatRoute(RouteLinks.BookingsRange, {
                    rangeId: range.id
                  })}
                >
                  <Box>
                    <TextRegular>{range.name}</TextRegular>
                  </Box>
                  <TextRegular light>{range.address}</TextRegular>
                </RangeTileLink>
              ))}
              {rangesResponse.data?.length === 0 && (
                <>
                  Brak dostępnych strzelnic z możliwością rezerwacji torów.
                  Jeśli nie ma tu strzelnicy, której szukasz, skontaktuj się z
                  obsługą klubu.
                </>
              )}
            </Box>
          )}
          {fetchStatus === NetworkRequestStatus.ERROR && (
            <Box mt={3}>
              <FormError>
                Wystąpił błąd podczas pobierania listy strzelnic.
              </FormError>
              <Box mt={1}>
                <Button variant="contained" onClick={fetch}>
                  Spróbuj ponownie
                </Button>
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={hideRangeDialog}>
            Zamknij
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const RangeTileLink = styled(Link)`
  display: block;
  margin: 8px 0;
  border: 1px solid ${colors.fog};
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: ${colors.offWhite};
  }
`
