import { Box, Button } from '@mui/material'
import { TextRegular } from '../../../Text/TextRegular'
import { plural } from '../../../../helpers/plural'
import React, { useCallback, useState } from 'react'
import { RouteLinks } from '../../../Routes/RouteNames'
import { saveMembershipConfig } from './helpers'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { formValuesSelector } from './duck'
import { WizardButtons } from './WizardButtons'

export const SummaryStep = () => {
  const [isSaving, setIsSaving] = useState(false)
  const formValues = useSelector(formValuesSelector)
  const entryFeeUnits = formValues.entryFeeUnits
  const memberFeeUnits = formValues.memberFeeUnits
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(null)
  const navigate = useNavigate()

  const closeWizard = useCallback(() => {
    navigate(RouteLinks.AdminPaymentConfigList)
  }, [navigate])

  const saveFeeConfig = async () => {
    setIsSaving(true)

    try {
      await saveMembershipConfig(formValues)
      setSuccess(true)
    } catch (e) {
      setError(e)
    } finally {
      setIsSaving(false)
    }
  }

  const retry = () => {
    setError(null)
    saveFeeConfig()
  }

  if (error) {
    return (
      <Box>
        Wystąpił błąd podczas tworzenia składek. Spróbuj ponownie lub skontaktuj
        się z administratorem.
        <Box display="flex" justifyContent="space-between" mt={4}>
          <Button onClick={retry}>Spróbuj ponownie</Button>
        </Box>
      </Box>
    )
  }

  if (success) {
    return (
      <>
        <TextRegular>
          <strong>Gratulacje!</strong>
        </TextRegular>
        <Box mt={2}>System składek członkowskich został utworzony.</Box>
        <Box display="flex" justifyContent="space-between" mt={4}>
          <Button onClick={closeWizard}>Zamknij</Button>
        </Box>
      </>
    )
  }

  return (
    <>
      <Box mt={3}>
        <TextRegular>
          Zapisanie systemu płatności spowoduje utworzenie{' '}
          {memberFeeUnits.length}{' '}
          {plural(memberFeeUnits.length, 'składki', 'składek', 'składek')}{' '}
          {plural(
            memberFeeUnits.length,
            'członkowskiej',
            'członkowskich',
            'członkowskich'
          )}{' '}
          oraz{' '}
          {plural(entryFeeUnits.length, 'wpisowego', 'wpisowych', 'wpisowych')}.
        </TextRegular>
      </Box>
      <Box mt={2}>
        <TextRegular>
          <strong>Uwaga</strong>
        </TextRegular>
        <br />
        <TextRegular variant="small">
          Po dokonaniu pierwszej płatności, dokonywanie zmian w systemie
          płatności nie będzie możliwe.
          <br />W celu zmiany warunków składek będzie konieczne utworzenie
          nowego systemu płatności (poprzedni system zostanie zamknięty z
          odpowiednią datą).
        </TextRegular>
      </Box>
      <WizardButtons
        handleSubmit={saveFeeConfig}
        nextButtonLabel="Zapisz"
        isLoading={isSaving}
      />
    </>
  )
}
