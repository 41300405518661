import { GatewayType } from '../Payments/enums'
import { GatewayPayU } from '../Payments/types'
import { RangeDTO } from '../Ranges/types'
import { RangeLane } from '../Ranges/Lanes/types'
import { AccountBasicDTO } from '../Account/types'

export interface BookingPaymentOrderDTO {
  id: string
  createdTime: string
  createdById: number
  clubId: number
  account: AccountBasicDTO
  totalAmount: number
  gatewayType: GatewayType
  gateway: GatewayPayU
  paymentDetails: {
    range: RangeDTO
    lane: RangeLane
    laneNumber: number
    startDate: string
    durationMinutes: number
  }
}

export interface RangeAvailableHoursDTO {
  rangeId: number
  availableHours: AvailableHoursRangeDTO[]
}

export interface AvailableHoursRangeDTO {
  from: string
  to: string
  availableLanes: AvailableLanesDTO[]
}

export interface AvailableLanesDTO {
  laneId: string
  availableLaneNumbers: number[]
}

export enum ModifyBookingOperation {
  CANCEL_PAYMENT = 'CANCEL_PAYMENT',
  CONFIRM_PAYMENT = 'CONFIRM_PAYMENT'
}
