import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { DateTime } from 'luxon'
import { RangeCombinedEvent } from './types'
import { CalendarEvent } from './CalendarEvent'
import Box from '@mui/material/Box'

export interface CalendarDayProps {
  day: DateTime
  selectedDate: DateTime
  events: RangeCombinedEvent[] | null
  onEventChange: () => void
}

export function CalendarDay({
  day,
  selectedDate,
  events,
  onEventChange
}: CalendarDayProps) {
  const now = useMemo(() => DateTime.now(), [])
  const isCurrentMonth = selectedDate.hasSame(day, 'month')
  const isToday = useMemo(() => day.hasSame(now, 'day'), [day, now])
  const startOfDay = useMemo(() => day.startOf('day').valueOf(), [day])
  const endOfDay = useMemo(() => day.endOf('day').valueOf(), [day])
  const dayGoogleEvents = useMemo(
    () =>
      events
        ? events.filter(
            (event) =>
              DateTime.fromISO(event.startTime).valueOf() >= startOfDay &&
              DateTime.fromISO(event.startTime).valueOf() < endOfDay
          )
        : [],
    [events, startOfDay, endOfDay]
  )

  return (
    <Day>
      <DayLabel isOtherMonth={!isCurrentMonth} isToday={isToday}>
        {day.toFormat('d')}. {day.day === 1 && day.monthShort}
      </DayLabel>
      <Box flexGrow={1}>
        {dayGoogleEvents.map((event, i) => (
          <CalendarEvent
            event={event}
            key={event.id || i}
            onSuccess={onEventChange}
          />
        ))}
      </Box>
    </Day>
  )
}

const Day = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`

const DayLabel = styled.div<{ isOtherMonth?: boolean; isToday?: boolean }>`
  display: flex;
  justify-content: flex-end;

  ${(p) =>
    p.isToday &&
    css`
      font-weight: bold;
    `}

  ${(p) =>
    p.isOtherMonth &&
    css`
      color: #ccc;
    `}
`
