import {
  MembershipFeeWizardActionType,
  MembershipFeeWizardSteps
} from './enums'
import {
  FirstPaymentResolverType,
  MembershipFeeWizardAction,
  MembershipFeeWizardState
} from './types'
import { FirstPaymentPeriodDensity } from '../../../types'
import { DateTime } from 'luxon'

export const initialState: MembershipFeeWizardState = {
  currentStep: MembershipFeeWizardSteps.COMMON,
  formValues: {
    common: {
      startTime: DateTime.now(),
      paymentDelayDays: 0
    },
    entryFeeConfig: {
      enableFirstPaymentPeriod: false,
      firstPaymentPeriodResolver: FirstPaymentResolverType.PROPORTIONAL,
      firstPaymentPeriodDensity: FirstPaymentPeriodDensity.HALF_YEAR,
      ageApplies: false,
      purposeApplies: false,
      serviceApplies: false,
      genderApplies: false,
      introductoryApplies: false
    },
    entryFeeAgeRanges: [],
    entryFeeUnits: [],
    memberFeeConfig: {
      enableFirstPaymentPeriod: false,
      firstPaymentPeriodResolver: FirstPaymentResolverType.PROPORTIONAL,
      firstPaymentPeriodDensity: FirstPaymentPeriodDensity.HALF_YEAR,
      ageApplies: false,
      purposeApplies: false,
      serviceApplies: false,
      genderApplies: false,
      introductoryApplies: false
    },
    memberFeePeriods: [],
    memberFeeAgeRanges: [],
    memberFeeUnits: []
  }
}

export const membershipFeeWizardReducer = (
  state: MembershipFeeWizardState = initialState,
  action: MembershipFeeWizardAction
): MembershipFeeWizardState => {
  switch (action.type) {
    case MembershipFeeWizardActionType.SET_CURRENT_STEP:
      return { ...state, currentStep: action.step }
    case MembershipFeeWizardActionType.SET_FORM_VALUES:
      return {
        ...state,
        formValues: {
          ...state.formValues,
          [action.key]: action.payload
        }
      }
    case MembershipFeeWizardActionType.RESET:
      return { ...initialState }
    default:
      return state
  }
}
