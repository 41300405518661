import {
  MembershipFeeWizardActionType,
  MembershipFeeWizardSteps
} from './enums'
import { Action } from 'redux'
import {
  CreateEntryFeeAgeRange,
  FirstPaymentPeriodDensity,
  Gender,
  MemberFeePeriodType,
  MembershipPurpose
} from '../../../types'
import { DateTime } from 'luxon'

export interface FirstPaymentUnitWizard {
  amount: string
  periodIndex: number
  editable: boolean
}

export enum FirstPaymentResolverType {
  PROPORTIONAL = 'PROPORTIONAL',
  CUSTOM = 'CUSTOM'
}

export interface FeeConfigWizard {
  enableFirstPaymentPeriod: boolean
  firstPaymentPeriodResolver: FirstPaymentResolverType
  firstPaymentPeriodDensity: FirstPaymentPeriodDensity
  ageApplies: boolean
  purposeApplies: boolean
  serviceApplies: boolean
  genderApplies: boolean
  introductoryApplies: boolean
}

export interface FeeUnitWizard {
  amount: string
  firstPaymentUnits: FirstPaymentUnitWizard[]
  ageRange: CreateEntryFeeAgeRange
  purpose: MembershipPurpose
  isService: boolean
  gender: Gender
  hasIntroductory: boolean
}

export interface EntryFeeUnitWizard extends FeeUnitWizard {}

export interface MemberFeeUnitWizard extends FeeUnitWizard {
  periodType: MemberFeePeriodType
}

// STATE
export interface MembershipFeeWizardState {
  currentStep: MembershipFeeWizardSteps
  formValues: {
    common: {
      startTime: DateTime
      paymentDelayDays: number
    }
    entryFeeConfig: FeeConfigWizard
    memberFeeConfig: FeeConfigWizard
    entryFeeAgeRanges: number[]
    memberFeeAgeRanges: number[]
    memberFeePeriods: MemberFeePeriodType[]
    entryFeeUnits: EntryFeeUnitWizard[]
    memberFeeUnits: MemberFeeUnitWizard[]
  }
}

export type FeeWizardForm = MembershipFeeWizardState['formValues']
export type CommonWizardConfig = FeeWizardForm['common']
export type EntryFeeWizardConfig = FeeWizardForm['entryFeeConfig']
export type MemberFeeWizardConfig = FeeWizardForm['memberFeeConfig']
export type EntryFeeWizardUnits = FeeWizardForm['entryFeeUnits']
export type MemberFeeWizardUnits = FeeWizardForm['memberFeeUnits']

export interface MembershipFeeWizardStateForRoot {
  membershipFeeWizard: MembershipFeeWizardState
}

// ACTIONS
export interface SetCurrentStep extends Action {
  type: MembershipFeeWizardActionType.SET_CURRENT_STEP
  step: MembershipFeeWizardSteps
}

export interface SetFormValues extends Action {
  type: MembershipFeeWizardActionType.SET_FORM_VALUES
  payload: FeeWizardForm[keyof FeeWizardForm]
  key: keyof FeeWizardForm
}

export interface Reset extends Action {
  type: MembershipFeeWizardActionType.RESET
}

export type MembershipFeeWizardAction = SetCurrentStep | SetFormValues | Reset
