import { Formik, FormikErrors } from 'formik'
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar
} from '@mui/material'
import { InputField } from '../../Form'
import Button from '@mui/material/Button'
import React from 'react'
import { RangeLane, RangeLaneFormValues } from './types'
import { ApiInterceptor } from '../../Api/ApiInterceptor'
import { RangeDTO } from '../types'
import { useToggle } from '../../../hooks/useToggle'

export interface AddLaneDialogProps {
  isOpen: boolean
  range: RangeDTO
  lane?: RangeLane
  onClose: () => void
  onCancel: () => void
}

const initialFormValues: RangeLaneFormValues = {
  name: '',
  length: 25,
  lanesAmount: 1
}

export function AddLaneDialog({
  isOpen,
  range,
  lane,
  onClose,
  onCancel
}: AddLaneDialogProps) {
  const [isSuccess, enableSuccess, disableSuccess] = useToggle()
  const [isFail, enableFail, disableFail] = useToggle()

  const validateLane = (
    values: RangeLaneFormValues
  ): FormikErrors<RangeLaneFormValues> => {
    const errors: FormikErrors<RangeLaneFormValues> = {}

    if (!values.name?.trim()) {
      errors.name = 'Podaj nazwę toru'
    }

    if (!values.length) {
      errors.length = 'Podaj długość toru'
    } else if (values.length <= 0) {
      errors.length = 'Podaj długość toru większą od 0'
    }

    if (!values.lanesAmount) {
      errors.lanesAmount = 'Podaj ilość stanowisk'
    } else if (values.lanesAmount <= 0) {
      errors.lanesAmount = 'Podaj ilość stanowisk większą od zera'
    }

    return errors
  }

  const createRangeLane = async (values: RangeLaneFormValues) => {
    try {
      if (lane) {
        await ApiInterceptor.put(
          `/api/range/${range.id}/lanes/${lane.id}`,
          values
        )
      } else {
        await ApiInterceptor.post(`/api/range/${range.id}/lanes`, values)
      }

      enableSuccess()
      onClose()
    } catch (e) {
      enableFail()
    }
  }

  return (
    <>
      <Dialog open={isOpen}>
        <Formik
          initialValues={lane ? lane : initialFormValues}
          onSubmit={createRangeLane}
          validate={validateLane}
        >
          {({ submitForm, isSubmitting }) => (
            <>
              <DialogTitle>
                {lane ? 'Edytuj' : 'Dodaj'} tor strzelecki
              </DialogTitle>
              <DialogContent>
                <InputField name="name" label="Nazwa" />
                <InputField
                  name="length"
                  label="Długość (m)"
                  type="number"
                  InputProps={{ inputProps: { min: 1, step: 1 } }}
                />
                <InputField
                  name="lanesAmount"
                  label="Ilość stanowisk"
                  type="number"
                  InputProps={{ inputProps: { min: 1, step: 1 } }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  onClick={onCancel}
                  disabled={isSubmitting}
                >
                  Anuluj
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={submitForm}
                  disabled={isSubmitting}
                >
                  Zapisz
                </Button>
              </DialogActions>
            </>
          )}
        </Formik>
      </Dialog>
      <Snackbar
        open={isSuccess}
        onClose={disableSuccess}
        autoHideDuration={5000}
      >
        <Alert severity="success" onClose={disableSuccess}>
          Tor został zapisany!
        </Alert>
      </Snackbar>
      <Snackbar open={isFail} onClose={disableFail} autoHideDuration={5000}>
        <Alert severity="error" onClose={disableFail}>
          Wystąpił błąd. Tor nie został zapisany.
        </Alert>
      </Snackbar>
    </>
  )
}
