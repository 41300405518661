import { PagePaper } from '../Layout'
import React, { useEffect } from 'react'
import { Alert, Box, Link } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { RouteLinks } from '../Routes/RouteNames'
import { currentClubDuck } from '../../ducks/currentClub.duck'

export function MergeAccountSuccess() {
  const fetchCurrentClub = currentClubDuck.actions.useFetch()

  useEffect(() => {
    fetchCurrentClub()
  }, [fetchCurrentClub])

  return (
    <PagePaper title="Rejestracja zakończona">
      <Alert severity="success">
        Gratulacje!
        <br />
        Twoje konto jest teraz aktywne.
      </Alert>

      <Box mt={2}>
        <Link component={RouterLink} to={RouteLinks.Home}>
          Przejdź do strony głównej
        </Link>
      </Box>
    </PagePaper>
  )
}
