import TextField from '@mui/material/TextField'
import { colors } from '../../styles/variables'
import { styled } from '@mui/material'

export const LoginTextField = styled(TextField)({
  '&.MuiTextField-root': {
    '& input + fieldset': {
      transition: 'box-shadow 0.2s ease-in, border-color 0.2s ease-in'
    },
    '& input:placeholder': {
      color: colors.offWhite
    },
    '&&:hover input + fieldset': {
      borderColor: '#fff'
    },
    '&& input:focus + fieldset': {
      borderColor: '#dbefff'
    },
    '&& input + fieldset': {
      borderColor: colors.offWhite
    },
    '&&.invalid input:invalid + fieldset': {
      borderColor: '#ff1300',
      boxShadow: '0 0 5px #ff1200'
    },
    '&&.invalid:hover input:invalid + fieldset': {
      borderColor: '#c41200'
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: '#ff1200',
      textShadow: '0 0 5px #ff1200'
    }
  }
})
