import { Grid, TableCell, TableRow } from '@mui/material'
import { Field } from 'formik'
import React, { FC } from 'react'
import styled from 'styled-components'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'

export interface HoursRowProps {
  label: string
  name: string
}

export const HoursRow: FC<HoursRowProps> = ({ label, name }) => {
  return (
    <TableRow>
      <TableCell>{label}</TableCell>
      <TableCell>
        <Grid container spacing={2}>
          <Grid item>
            <Field
              name={`${name}.from`}
              children={({ field, form }) => (
                <TimePickerStyled
                  {...field}
                  ampm={false}
                  label="Otwarcie"
                  inputVariant="outlined"
                  onChange={(value) => form.setFieldValue(field.name, value)}
                  views={['hours', 'minutes']}
                />
              )}
            />
          </Grid>
          <Grid item>
            <Field
              name={`${name}.to`}
              children={({ field, form }) => (
                <TimePickerStyled
                  {...field}
                  ampm={false}
                  label="Zamknięcie"
                  inputVariant="outlined"
                  onChange={(value) => form.setFieldValue(field.name, value)}
                  views={['hours', 'minutes']}
                />
              )}
            />
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  )
}

const TimePickerStyled = styled(TimePicker)`
  width: 100px;
`
