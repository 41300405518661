import React from 'react'
import { SsoAuth } from './SsoAuth'
import { SsoVendor } from './enums'

export function SsoCompetition() {
  return (
    <SsoAuth
      vendor={SsoVendor.COMPETITION}
      vendorUrl={process.env.REACT_APP_COMPETITION_URL}
      title="Zaloguj się"
      proceedText="Przejdź do zawodów"
      redirectSoonText="Za chwilę nastąpi przekierowanie do zawodów..."
    />
  )
}
