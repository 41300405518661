import React, { FC, useCallback, useMemo, useState } from 'react'
import { MemberFeeUnit, MembershipApplicableConfigDTO } from '../../types'
import { HorizontalView, PagePaper } from '../../../Layout'
import { TextRegular } from '../../../Text/TextRegular'
import { Box } from '@mui/material'
import {
  createLicenseOnlinePayment,
  createMembershipOnlinePayment,
  saveLicenseSince
} from '../../payment-service'
import { RouteLinks } from '../../../Routes/RouteNames'
import { colors } from '../../../../styles/variables'
import { IoWarning } from 'react-icons/io5'
import moment from 'moment'
import { MemberFeeOfferCard } from './MemberFeeOfferCard'
import { LicenseFeeOfferCard } from './LicenseFeeOfferCard'
import { useSelector } from 'react-redux'
import { accountSelector } from '../../../Account/account.duck'
import { VariantListContainer } from './PaymentOfferCard'
import { LicenseFeeCreator } from './LicenseFeeCreator'
import { LicenseFeeCancel } from './LicenseFeeCancel'
import { Helmet } from 'react-helmet'
import { ContactHelp } from '../../../ContactHelp/ContactHelp'
import { currentClubDuck } from '../../../../ducks/currentClub.duck'
import styled from 'styled-components'
import { captureException } from '@sentry/react'

export interface PaymentOffersListProps {
  config: MembershipApplicableConfigDTO
  onRefresh: () => void
}

export const PaymentOffersList: FC<PaymentOffersListProps> = ({
  config,
  onRefresh
}) => {
  const accountMeDTO = useSelector(accountSelector)
  const currentClub = useSelector(currentClubDuck.selectors.data)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const licenseFees = useMemo(
    () =>
      config.licenseFeeYears.filter((licenseFeeYear) =>
        config.memberFeeUnits.every(
          (memberFeeUnit) =>
            moment(memberFeeUnit.startDate).get('year') !== licenseFeeYear
        )
      ),
    [config]
  )

  const initFeePayment = useCallback(async (memberFeeUnit: MemberFeeUnit) => {
    setLoading(true)
    setError(null)

    try {
      const response = await createMembershipOnlinePayment({
        memberFeeUnitId: memberFeeUnit.id,
        continueUrl: `${window.location.origin}${RouteLinks.AccountPaymentOrderDetails}`
      })

      window.location.href = response.data.redirectUri
    } catch (e) {
      setError(e)
      captureException(e)
    }

    setLoading(false)
  }, [])

  const initLicensePayment = useCallback(async (year: number) => {
    setLoading(true)
    setError(null)

    try {
      const response = await createLicenseOnlinePayment({
        year,
        continueUrl: `${window.location.origin}${RouteLinks.AccountPaymentOrderDetails}`
      })

      window.location.href = response.data.redirectUri
    } catch (e) {
      setError(e)
      captureException(e)
    }

    setLoading(false)
  }, [])

  const handleSaveLicenseSince = useCallback(
    async (year: string) => {
      await saveLicenseSince(year)
      onRefresh()
    },
    [onRefresh]
  )

  const handleCancelLicenseSince = useCallback(async () => {
    await saveLicenseSince(null)
    onRefresh()
  }, [onRefresh])

  return (
    <PagePaper>
      <Helmet>
        <title>Opłać składkę</title>
      </Helmet>

      <Box mb={3}>
        <HorizontalView>
          {currentClub?.avatarUrl && (
            <Box mr={2}>
              <AvatarImg alt="logo" src={currentClub.avatarUrl} />
            </Box>
          )}
          <TextRegular size="large">
            <strong>{currentClub?.name}</strong> - płatności
          </TextRegular>
        </HorizontalView>
      </Box>

      {error && (
        <TextRegular color={colors.error}>
          <HorizontalView>
            <Box mr={1}>
              <IoWarning size={40} />
            </Box>
            <TextRegular variant="medium">
              Wystąpił błąd. Spróbuj ponownie lub skontaktuj się z obsługą
              klubu.
            </TextRegular>
          </HorizontalView>
        </TextRegular>
      )}

      {accountMeDTO.pzssLicenseSince ? (
        <>
          {!accountMeDTO.pzssLicenseNumber && (
            <LicenseFeeCancel onSave={handleCancelLicenseSince} />
          )}
        </>
      ) : (
        <LicenseFeeCreator onSaveDate={handleSaveLicenseSince} />
      )}

      <Box my={3}>
        <VariantListContainer>
          {licenseFees.map((licenseFeeYear) => (
            <LicenseFeeOfferCard
              key={licenseFeeYear}
              config={config}
              year={licenseFeeYear}
              loading={loading}
              onPay={initLicensePayment}
            />
          ))}

          {config.memberFeeUnits.map((memberFeeUnit, i) => (
            <MemberFeeOfferCard
              key={i}
              unit={memberFeeUnit}
              config={config}
              onPay={initFeePayment}
              loading={loading}
            />
          ))}
        </VariantListContainer>
      </Box>

      <Box mt={1}>
        <ContactHelp />
      </Box>
    </PagePaper>
  )
}

const AvatarImg = styled.img`
  max-height: 64px;
  max-width: 120px;
`
