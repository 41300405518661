export const fontSize = {
  xlarge: 30,
  large: 24,
  medium: 20,
  regular: 16,
  small: 12
}

export const colors = {
  main: '#4e9fff',
  mainLink: '#00a0ff',
  mainDark: '#2267bc',
  error: '#ff1f00',
  warn: '#f5a335',
  success: '#67b700',
  tar: '#212323',
  smoke: '#999',
  fog: '#ccc',
  cloud: '#efefef',
  backgroundShade: '#eff2f5',
  offWhite: '#f0f0f0',
  facebookBlue: '#4267B2',
  white: '#ffffff'
}

export enum zIndexes {
  APP_LOADER = 1000000,
  FULL_SCREEN = 100000,
  MODAL = 10000,
  MAIN_MENU = 1000,
  HEADER = 100
}
