import React, { ElementType, forwardRef, RefAttributes } from 'react'
import { View, ViewProps } from './View'
import styled from 'styled-components'
import { ForwarderRef } from '../../common/types'
import { muiTheme } from '../../common/muiTheme'

export type HorizontalViewProps = ViewProps & StyledViewProps

interface StyledViewProps {
  align?: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline'
  justify?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
  gap?: number
}

function HorizontalViewComponent<T extends ElementType>(
  props,
  ref: ForwarderRef<T>
) {
  return <ViewStyled ref={ref} {...props} />
}

export const HorizontalView = forwardRef(HorizontalViewComponent) as <
  T extends ElementType = 'div'
>(
  props: HorizontalViewProps & RefAttributes<T>
) => JSX.Element

const ViewStyled = styled(View)<StyledViewProps & RefAttributes<unknown>>`
  flex-direction: row;
  align-items: ${(p) => p.align || 'center'};
  justify-content: ${(p) => p.justify || 'flex-start'};
  ${(p) => (p.gap ? `gap: ${muiTheme.spacing(p.gap)}` : '')};
`
