import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material'
import React, { useEffect } from 'react'
import IconButton from '@mui/material/IconButton'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { GenderLabel, PeriodLabel, PurposeLabel } from '../../enums'
import { getAgeRangeText } from '../../helpers'
import Collapse from '@mui/material/Collapse'
import { TextRegular } from '../../../Text/TextRegular'
import { fetchMembershipFeeConfigs } from '../../payment-service'
import { Criteria } from './Criteria'
import { PaymentFeesList } from './PaymentFeesList'
import Button from '@mui/material/Button'
import { RouteLinks } from '../../../Routes/RouteNames'
import { useNavigate } from 'react-router-dom'
import { plural } from '../../../../helpers/plural'
import { FeeStartingDate } from './FeeStartingDate'
import { AgeRangesList } from './AgeRangesList'
import styled from 'styled-components'
import { PagePaper, View } from '../../../Layout'
import { Helmet } from 'react-helmet'
import { useToggle } from '../../../../hooks/useToggle'
import { IoIosAdd } from 'react-icons/io'
import { IoTrashOutline } from 'react-icons/io5'
import {
  NetworkRequestStatus,
  useNetworkRequest
} from '../../../../common/network-state'
import { ApiInterceptor } from '../../../Api/ApiInterceptor'
import { ErrorElement } from '../../../Api/types'
import { ErrorType } from '../../../Api/enums'
import { FormError } from '../../../Form'

export const AdminPaymentConfigList = () => {
  const navigate = useNavigate()
  const goToMemberFeeWizard = () => {
    navigate(RouteLinks.AdminPaymentWizard)
  }
  const [
    fetchConfigList,
    feeConfigListResponse,
    requestStatus,
    memberFeesError
  ] = useNetworkRequest(fetchMembershipFeeConfigs)
  const [isDeleteConfigDialogOpen, openDeleteDialog, closeDeleteDialog] =
    useToggle()
  const [deleteConfig, , deletingStatus, error] = useNetworkRequest(
    async (configId: string) => {
      await ApiInterceptor.delete(`/api/membership-fee-config/${configId}`)

      closeDeleteDialog()
      fetchConfigList()
    }
  )

  useEffect(() => {
    fetchConfigList()
  }, [fetchConfigList])

  return (
    <PagePaper>
      <Helmet>
        <title>Składki - lista konfiguracji</title>
      </Helmet>
      <TextRegular variant="xlarge">Konfiguracje składek</TextRegular>
      <Box mt={5}>
        <PaymentFeesList
          data={feeConfigListResponse?.data}
          dataStatus={requestStatus}
          dataError={memberFeesError}
          noResultsText="Brak składek."
          columnNames={[
            'Okres obowiązywania',
            'Kryteria',
            'Cykle składki',
            'Grupy wiekowe',
            '',
            ''
          ]}
          getRowKey={(data) => data.id}
          renderRow={({ onToggleExpandedRow, isExpanded, data }) => (
            <>
              <TableCell>
                <FeeStartingDate data={data} />
              </TableCell>
              <TableCell>
                <TextRegular strong>składka</TextRegular>
                {data.memberFeeConfig.ageApplies ? (
                  <Criteria>wiek</Criteria>
                ) : null}
                {data.memberFeeConfig.purposeApplies ? (
                  <Criteria>cel</Criteria>
                ) : null}
                {data.memberFeeConfig.serviceApplies ? (
                  <Criteria>służby</Criteria>
                ) : null}
                {data.memberFeeConfig.genderApplies ? (
                  <Criteria>płeć</Criteria>
                ) : null}
                {data.memberFeeConfig.introductoryApplies ? (
                  <Criteria>osoba wprowadzająca</Criteria>
                ) : null}
                <Box mt={1}>
                  <TextRegular strong>wpisowe</TextRegular>
                </Box>
                {data.entryFeeConfig.ageApplies ? (
                  <Criteria>wiek</Criteria>
                ) : null}
                {data.entryFeeConfig.purposeApplies ? (
                  <Criteria>cel</Criteria>
                ) : null}
                {data.entryFeeConfig.serviceApplies ? (
                  <Criteria>służby</Criteria>
                ) : null}
                {data.entryFeeConfig.genderApplies ? (
                  <Criteria>płeć</Criteria>
                ) : null}
                {data.entryFeeConfig.introductoryApplies ? (
                  <Criteria>osoba wprowadzająca</Criteria>
                ) : null}
              </TableCell>
              <TableCell>
                {data.memberFeeConfig.periodTypes
                  .map((periodType) => PeriodLabel[periodType])
                  .join(', ')}
              </TableCell>
              <TableCell>
                <Box>
                  <TextRegular strong>składka</TextRegular>
                </Box>
                {data.memberFeeConfig.ageApplies ? (
                  <AgeRangesList units={data.memberFeeUnits} />
                ) : (
                  'n/d'
                )}
                <Box mt={1}>
                  <TextRegular strong>wpisowe</TextRegular>
                </Box>
                {data.entryFeeConfig.ageApplies ? (
                  <AgeRangesList units={data.entryFeeUnits} />
                ) : (
                  'n/d'
                )}
              </TableCell>
              <TableCell>
                <Box>
                  <TextRegular strong>
                    {data.memberFeeUnits.length}&nbsp;
                    {plural(
                      data.memberFeeUnits.length,
                      'składka',
                      'składki',
                      'składek'
                    )}
                  </TextRegular>
                </Box>
                <Box mt={1}>
                  <TextRegular strong>
                    {data.entryFeeUnits.length}&nbsp;
                    {plural(
                      data.entryFeeUnits.length,
                      'wpisowe',
                      'wpisowe',
                      'wpisowych'
                    )}
                  </TextRegular>
                </Box>
              </TableCell>
              <TableCell>
                <IconButton size="small" onClick={onToggleExpandedRow}>
                  {isExpanded ? <FiChevronUp /> : <FiChevronDown />}
                </IconButton>
              </TableCell>
            </>
          )}
          renderExpandedRow={({ data, isExpanded }) => (
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={isExpanded} unmountOnExit>
                <Box m={1}>
                  <TextRegular variant="large">Szczegóły składek</TextRegular>
                  <Table size="small" style={{ width: 'auto' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>cykl</TableCell>
                        {data.memberFeeConfig.ageApplies && (
                          <TableCell>wiek</TableCell>
                        )}
                        {data.memberFeeConfig.purposeApplies && (
                          <TableCell>cel</TableCell>
                        )}
                        {data.memberFeeConfig.serviceApplies && (
                          <TableCell>służby</TableCell>
                        )}
                        {data.memberFeeConfig.genderApplies && (
                          <TableCell>płeć</TableCell>
                        )}
                        {data.memberFeeConfig.introductoryApplies && (
                          <TableCell>osoba wprowadzająca</TableCell>
                        )}
                        <TableCell>składka</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.memberFeeUnits.map((unit) => (
                        <TableRow key={unit.id}>
                          <TableCell>{PeriodLabel[unit.periodType]}</TableCell>
                          {data.memberFeeConfig.ageApplies && (
                            <TableCell>
                              {getAgeRangeText(unit.ageRange)}
                            </TableCell>
                          )}
                          {data.memberFeeConfig.purposeApplies && (
                            <TableCell>{PurposeLabel[unit.purpose]}</TableCell>
                          )}
                          {data.memberFeeConfig.serviceApplies && (
                            <TableCell>
                              {unit.isService ? 'tak' : 'nie'}
                            </TableCell>
                          )}
                          {data.memberFeeConfig.genderApplies && (
                            <TableCell>{GenderLabel[unit.gender]}</TableCell>
                          )}
                          {data.memberFeeConfig.introductoryApplies && (
                            <TableCell>
                              {unit.hasIntroductory ? 'tak' : 'nie'}
                            </TableCell>
                          )}
                          <TableCell>
                            <strong>{unit.amount} zł</strong>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <Box mt={3}>
                    <TextRegular variant="large">
                      Szczegóły wpisowego
                    </TextRegular>
                    <Table size="small" style={{ width: 'auto' }}>
                      <TableHead>
                        <TableRow>
                          {data.entryFeeConfig.ageApplies && (
                            <TableCell>wiek</TableCell>
                          )}
                          {data.entryFeeConfig.purposeApplies && (
                            <TableCell>cel</TableCell>
                          )}
                          {data.entryFeeConfig.serviceApplies && (
                            <TableCell>służby</TableCell>
                          )}
                          {data.entryFeeConfig.genderApplies && (
                            <TableCell>płeć</TableCell>
                          )}
                          {data.entryFeeConfig.introductoryApplies && (
                            <TableCell>osoba wprowadzająca</TableCell>
                          )}
                          <TableCell>składka</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.entryFeeUnits.map((unit) => (
                          <TableRow key={unit.id}>
                            {data.entryFeeConfig.ageApplies && (
                              <TableCell>
                                {getAgeRangeText(unit.ageRange)}
                              </TableCell>
                            )}
                            {data.entryFeeConfig.purposeApplies && (
                              <TableCell>
                                {PurposeLabel[unit.purpose]}
                              </TableCell>
                            )}
                            {data.entryFeeConfig.serviceApplies && (
                              <TableCell>
                                {unit.isService ? 'tak' : 'nie'}
                              </TableCell>
                            )}
                            {data.entryFeeConfig.genderApplies && (
                              <TableCell>{GenderLabel[unit.gender]}</TableCell>
                            )}
                            {data.entryFeeConfig.introductoryApplies && (
                              <TableCell>
                                {unit.hasIntroductory ? 'tak' : 'nie'}
                              </TableCell>
                            )}
                            <TableCell>
                              <strong>{unit.amount} zł</strong>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                  <Box mt={2}>
                    <View alignItems="flex-start">
                      <TextRegular size="large">Akcja</TextRegular>
                      <Box mt={1}>
                        <Button
                          startIcon={<IoTrashOutline />}
                          onClick={openDeleteDialog}
                          variant="outlined"
                          color="secondary"
                        >
                          Usuń
                        </Button>
                      </Box>
                    </View>
                    <Dialog open={isDeleteConfigDialogOpen}>
                      <DialogTitle>Usuń konfigurację</DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          Czy napewno chcesz usunąć wybraną konfigurację?
                          <br />
                          <TextRegular strong>
                            Ta operacja jest nieodwracalna.
                          </TextRegular>
                          {(error?.response?.data as ErrorElement)
                            ?.errorType ===
                            ErrorType.MEMBERSHIP_CONFIG_HAS_ORDERS && (
                            <Box mt={2}>
                              <FormError>
                                Nie można usunąć konfiguracji, dla której
                                istnieją opłacone składki.
                              </FormError>
                            </Box>
                          )}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          variant="contained"
                          onClick={closeDeleteDialog}
                          disabled={
                            deletingStatus === NetworkRequestStatus.LOADING
                          }
                        >
                          Anuluj
                        </Button>
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={() => deleteConfig(data.id)}
                          disabled={
                            deletingStatus === NetworkRequestStatus.LOADING
                          }
                        >
                          Usuń
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Box>
                </Box>
              </Collapse>
            </TableCell>
          )}
        />
        <Box mt={2}>
          <Button
            startIcon={<IoIosAdd />}
            color="primary"
            variant="contained"
            onClick={goToMemberFeeWizard}
          >
            Nowa konfiguracja
          </Button>
        </Box>
        <Box mt={5}>
          <SectionTitle variant="large">Licencja sportowa</SectionTitle>
          <Box mt={2}>
            Składka za licencję sportową wynosi 75 zł i jest naliczana
            automatycznie klubowiczom, którzy posiadają licencję zawodniczą
            (numer licencji lub data obowiązku posiadania licencji).
          </Box>
        </Box>
      </Box>
    </PagePaper>
  )
}

const SectionTitle = styled(TextRegular)`
  margin-top: 32px;
  font-weight: bold;
`
