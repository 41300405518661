import { HorizontalView, View } from '../../../Layout'
import { TextRegular } from '../../../Text/TextRegular'
import {
  Box,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Radio,
  RadioGroup
} from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { CurrentStepLabel } from './CurrentStepLabel'
import { formValuesSelector, useGoToNextStep, useSetFormValues } from './duck'
import { WizardButtons } from './WizardButtons'
import { Field, Formik } from 'formik'
import { FirstPaymentResolverType, MemberFeeWizardConfig } from './duck/types'
import { periodDensityOptions } from './firstPayment.helpers'
import { HelpTooltip } from '../../../HelpTooltip/HelpTooltip'
import { SelectField } from '../../../Form/Mui/SelectField'
import { CheckboxField } from '../../../Form/Mui/CheckboxField'

export const MemberFeeConfigStep = () => {
  const goToNextStep = useGoToNextStep()
  const formValues = useSelector(formValuesSelector)
  const setFormValues = useSetFormValues('memberFeeConfig')

  const handleNextStep = (values: MemberFeeWizardConfig) => {
    setFormValues(values)
    goToNextStep()
  }

  return (
    <Formik<MemberFeeWizardConfig>
      initialValues={formValues.memberFeeConfig}
      onSubmit={handleNextStep}
    >
      {({ handleSubmit, values }) => (
        <View>
          <CurrentStepLabel />

          <Box mt={3}>
            <TextRegular strong>Składka członkowska</TextRegular>
          </Box>

          <Box mt={4}>
            <TextRegular variant="medium">Kryteria</TextRegular>
            <Box>
              <TextRegular variant="small">
                Określ kryteria, jakie będą uzwględniane przy obliczaniu
                składek.
              </TextRegular>
            </Box>

            <Box mt={1}>
              <FormGroup>
                <FormControlLabel
                  control={<CheckboxField name="ageApplies" />}
                  label="Wiek"
                />
                <HorizontalView>
                  <FormControlLabel
                    control={<CheckboxField name="purposeApplies" />}
                    label="Cel (sportowe / kolekcjonerskie)"
                  />
                  <HelpTooltip>
                    Zaznacz tę opcję, jeśli przewidujesz inne wysokości składek
                    dla sportowców i kolekcjonerów (bez względu na posiadaną
                    licencję).
                  </HelpTooltip>
                </HorizontalView>
                <FormControlLabel
                  control={<CheckboxField name="serviceApplies" />}
                  label="Przynależność do służb"
                />
                <FormControlLabel
                  control={<CheckboxField name="genderApplies" />}
                  label="Płeć"
                />
                <FormControlLabel
                  control={<CheckboxField name="introductoryApplies" />}
                  label="Osoba wprowadzająca"
                />
              </FormGroup>
            </Box>
          </Box>

          <Box mt={4}>
            <TextRegular variant="medium">
              Pomniejszona pierwsza płatność
            </TextRegular>
            <Box>
              <TextRegular variant="small">
                Możesz określić różne stawki za składkę w zależności od okresu w
                roku, w jakim jest dokonywana pierwsza płatność. Dzięki temu
                nowi członkowie mogą płacić mniej, jeśli zapisują się później
                niż na początku roku (np. połowę pełnej stawki w okresie
                czerwiec - grudzień).
              </TextRegular>
            </Box>

            <Box my={3}>
              <FormControlLabel
                control={<CheckboxField name="enableFirstPaymentPeriod" />}
                label="Użyj pomniejszonych płatności"
              />
            </Box>
            {values.enableFirstPaymentPeriod && (
              <>
                <Box mt={2} mb={0.5}>
                  <TextRegular>
                    Określ wielkość cykli, dla jakich ustalisz pomniejszone
                    płatności:
                  </TextRegular>
                </Box>
                <SelectField name="firstPaymentPeriodDensity">
                  {periodDensityOptions.map((option, i) => (
                    <MenuItem key={i} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </SelectField>
                <Box mt={2}>
                  <Box>Sposób obliczania pomniejszonych składek:</Box>
                  <Field
                    name="firstPaymentPeriodResolver"
                    children={({ field }) => (
                      <RadioGroup {...field}>
                        <FormControlLabel
                          value={FirstPaymentResolverType.PROPORTIONAL}
                          control={<Radio />}
                          label={
                            <HorizontalView>
                              <Box mr={1}>Proporcjonalny</Box>
                              <HelpTooltip>
                                Pomniejszone składki zostaną obliczone
                                automatycznie, jako proporcjonalna część pełnej
                                składki dla danych kryteriów.
                              </HelpTooltip>
                            </HorizontalView>
                          }
                        />
                        <FormControlLabel
                          value={FirstPaymentResolverType.CUSTOM}
                          control={<Radio />}
                          label={
                            <HorizontalView>
                              <Box mr={1}>Ręczny</Box>
                              <HelpTooltip>
                                Każda z pomniejszonych składek będzie ustalana
                                przez Ciebie indywidualnie. Uważaj! Przy dużej
                                ilości kryteriów może to oznaczać bardzo dużo
                                kwot do uzupełnienia.
                              </HelpTooltip>
                            </HorizontalView>
                          }
                        />
                      </RadioGroup>
                    )}
                  />
                </Box>
              </>
            )}
          </Box>

          <WizardButtons handleSubmit={handleSubmit} />
        </View>
      )}
    </Formik>
  )
}
