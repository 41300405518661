import React from 'react'
import { TextRegular } from '../Text/TextRegular'
import styled from 'styled-components'
import { View } from '../Layout'

export const FormTitle = ({ children }) => (
  <TitleStyled variant="large">{children}</TitleStyled>
)

const TitleStyled = styled(TextRegular)`
  margin: 16px 0;
`

export const FormContent = styled(View)`
  flex-grow: 1;
  justify-content: center;
`

export const FormActions = styled(View)`
  flex-shrink: 0;
  justify-content: flex-end;
  padding-bottom: 24px;
`

export const HiddenInputSubmit = styled.input.attrs({
  type: 'submit'
})`
  position: absolute;
  visibility: hidden;
  pointer-events: none;
`
