import React, {
  FC,
  FunctionComponent,
  HTMLProps,
  useCallback,
  useRef,
  useState
} from 'react'
import { Label } from './Label'
import { colors, fontSize } from '../../styles/variables'
import { View } from '../Layout'
import styled from 'styled-components'

export interface TextareaProps extends HTMLProps<HTMLTextAreaElement> {
  label?: string
  hasError?: boolean
}

export const Textarea: FC<TextareaProps> = ({
  label,
  hasError = false,
  ...props
}) => {
  const [focused, setFocused] = useState(false)
  const focus = useCallback(() => {
    setFocused(true)
  }, [setFocused])
  const blur = useCallback(() => {
    setFocused(false)
  }, [setFocused])
  const inputRef = useRef()
  const focusInput = useCallback(() => {
    const input = inputRef.current as HTMLInputElement
    input.focus()
  }, [inputRef])

  return (
    <View>
      {!!label && (
        <Label onClick={focusInput} focused={focused} hasError={hasError}>
          {label}
        </Label>
      )}
      <Wrapper>
        <TextareaStyled
          ref={inputRef}
          onFocus={focus}
          onBlur={blur}
          {...props}
        />
        <BottomBorder hasError={hasError} focused={focused} />
      </Wrapper>
    </View>
  )
}

const Wrapper = styled(View)`
  flex-direction: row;
  position: relative;
  margin: 14px 0;
  height: 120px;
  padding: 8px;
  outline: none;
  border: none;
`

const TextareaStyled: FunctionComponent = styled.textarea`
  font-size: ${fontSize.regular}px;
  flex-grow: 1;
`

const BottomBorder = styled(View)<{ hasError?: boolean; focused?: boolean }>`
  position: absolute;
  height: ${(p) => (p.focused ? 2 : 1)}px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${(p) => (p.hasError ? '#ff1f00' : colors.smoke)};
`
