import { Formik, FormikErrors, Field } from 'formik'
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem
} from '@mui/material'
import { View } from '../Layout'
import { SelectField } from '../Form/Mui/SelectField'
import Button from '@mui/material/Button'
import React, { ReactNode, useCallback, useEffect } from 'react'
import { ApiInterceptor } from '../Api/ApiInterceptor'
import { RangeCombinedEvent } from './types'
import Box from '@mui/material/Box'
import { useNetworkRequest } from '../../common/network-state'
import { RangeDTO } from '../Ranges/types'
import { TextRegular } from '../Text/TextRegular'
import { getRangeLanes } from '../Ranges/ranges.service'

interface FormValues {
  rangeId: number
  blockedLaneId: string
  blockedLaneNumbers: { [laneNumber: number]: boolean }
}

export interface NewBlockadeDialogProps {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  event: RangeCombinedEvent
}

export function NewEventDialog({
  open,
  onClose,
  event,
  onSuccess
}: NewBlockadeDialogProps) {
  // TODO: do not fetch this for every single event. Keep it in redux
  const [fetchRanges, ranges] = useNetworkRequest(
    () => ApiInterceptor.get<RangeDTO[]>('/api/range/all'),
    []
  )
  const [fetchLanes, rangeLanes] = useNetworkRequest((rangeId: number) =>
    getRangeLanes(rangeId)
  )
  const getSelectedRangeName = useCallback(
    (rangeId: number) => {
      const range = ranges.data.find((range) => range.id === rangeId)
      if (range) {
        return range.name
      }

      return ''
    },
    [ranges?.data]
  )
  const getSelectedLanesAmount = useCallback(
    (laneId: string) => {
      if (laneId) {
        const lane = rangeLanes.data.find((lane) => lane.id === laneId)
        if (lane) {
          return lane.lanesAmount
        }
      }

      return 0
    },
    [rangeLanes?.data]
  )
  const getBlockedLaneNumbersArray = useCallback(
    (laneNumbersObject: FormValues['blockedLaneNumbers']) =>
      Object.entries(laneNumbersObject)
        .filter(([, value]) => !!value)
        .map(([num]) => num),
    []
  )

  const handleRangeSelect = (e) => {
    fetchLanes(e.target.value)
  }

  const saveNewBlockade = async (values: FormValues) => {
    if (event.id) {
      await ApiInterceptor.post(`/api/range-events/${event.id}/blocked-lane`, {
        blockedLaneId: values.blockedLaneId,
        blockedLaneNumbers: getBlockedLaneNumbersArray(
          values.blockedLaneNumbers
        )
      })
    } else {
      await ApiInterceptor.post('/api/range-events', {
        summary: event.googleEvent.summary,
        blockedLaneId: values.blockedLaneId,
        blockedLaneNumbers: getBlockedLaneNumbersArray(
          values.blockedLaneNumbers
        ),
        startTime: event.startTime,
        endTime: event.endTime,
        googleEventId: event.googleEvent.id
      })
    }

    onSuccess()
  }

  const validateBlockade = (values: FormValues): FormikErrors<FormValues> => {
    const errors: FormikErrors<FormValues> = {}

    if (!values.blockedLaneId) {
      errors.blockedLaneId = 'Wybierz tor strzelecki'
    } else if (
      getBlockedLaneNumbersArray(values.blockedLaneNumbers).length === 0
    ) {
      errors.blockedLaneNumbers = 'Wybierz stanowisko'
    }

    return errors
  }

  useEffect(() => {
    fetchRanges()
  }, [fetchRanges])

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <Formik<FormValues>
        onSubmit={saveNewBlockade}
        validate={validateBlockade}
        initialValues={{
          rangeId: null,
          blockedLaneId: '',
          blockedLaneNumbers: {}
        }}
      >
        {({ values, touched, errors, submitForm }) => (
          <>
            <DialogTitle>Dodaj blokadę toru</DialogTitle>
            <DialogContent>
              <>
                <Grid
                  container
                  direction="column"
                  alignItems="stretch"
                  spacing={3}
                >
                  {ranges?.data && (
                    <Grid item>
                      <FormControl
                        fullWidth
                        variant="filled"
                        error={!!errors.rangeId}
                      >
                        <InputLabel>Strzelnica</InputLabel>
                        <SelectField
                          name="rangeId"
                          onChange={handleRangeSelect}
                          renderValue={getSelectedRangeName}
                        >
                          {ranges.data.map((range) => (
                            <MenuItem
                              key={range.id}
                              value={range.id}
                              disabled={event.blockedLanes.some(
                                (blockedLane) =>
                                  blockedLane.lane.rangeId === range.id
                              )}
                            >
                              <View>
                                {range.name}
                                <Box>
                                  <TextRegular size="small">
                                    {range.address}
                                  </TextRegular>
                                </Box>
                              </View>
                            </MenuItem>
                          ))}
                        </SelectField>
                      </FormControl>
                    </Grid>
                  )}

                  {!!values.rangeId && rangeLanes && (
                    <Grid item>
                      {rangeLanes.data.length > 0 ? (
                        <FormControl
                          fullWidth
                          variant="filled"
                          error={
                            touched.blockedLaneId && !!errors.blockedLaneId
                          }
                        >
                          <InputLabel>Tor strzelecki</InputLabel>
                          <SelectField name="blockedLaneId">
                            {rangeLanes?.data.map((lane) => (
                              <MenuItem key={lane.id} value={lane.id}>
                                {lane.name}
                              </MenuItem>
                            ))}
                          </SelectField>
                          {touched.blockedLaneId && errors.blockedLaneId && (
                            <FormHelperText>
                              {errors.blockedLaneId}
                            </FormHelperText>
                          )}
                        </FormControl>
                      ) : (
                        <Box mt={2}>
                          <TextRegular light>
                            Brak torów strzeleckich na wybranej strzelnicy.
                          </TextRegular>
                        </Box>
                      )}
                    </Grid>
                  )}

                  {!!values.blockedLaneId && !!rangeLanes.data.length && (
                    <Grid item>
                      <FormControl
                        fullWidth
                        error={
                          touched.blockedLaneNumbers &&
                          !!errors.blockedLaneNumbers
                        }
                      >
                        <FormLabel>Stanowiska</FormLabel>
                        {Array(getSelectedLanesAmount(values.blockedLaneId))
                          .fill(0)
                          .map((_, i) => (
                            <FormControlLabel
                              key={i}
                              control={
                                <Field
                                  name={`blockedLaneNumbers.${i + 1}`}
                                  as={Checkbox}
                                />
                              }
                              label={`Stanowisko nr ${i + 1}`}
                            />
                          ))}
                        {touched.blockedLaneNumbers &&
                          errors.blockedLaneNumbers && (
                            <FormHelperText>
                              {errors.blockedLaneNumbers as ReactNode}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              </>
            </DialogContent>
            <DialogActions>
              <Button color="primary" variant="contained" onClick={submitForm}>
                Zapisz
              </Button>
              <Button variant="contained" onClick={onClose}>
                Anuluj
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  )
}
