import React, { useCallback, useEffect, useState } from 'react'
import { ErrorType } from '../../../Api/enums'
import { MissingDateOfBirth } from './MissingDateOfBirth'
import { PaymentLoading } from './PaymentLoading'
import { NoPaymentConfigs } from './NoPaymentConfigs'
import { PaymentOffersList } from './PaymentOffersList'
import { fetchApplicableConfig } from '../../payment-service'
import { networkError } from '../../../../helpers/networkError'
import { PagePaper } from '../../../Layout'
import { Alert } from '../../../Alert'
import { TextRegular } from '../../../Text/TextRegular'
import { useFetchAccount } from '../../../Account/account.duck'
import { Helmet } from 'react-helmet'
import { useToggle } from '../../../../hooks/useToggle'
import { Box } from '@mui/material'
import { ContactHelp } from '../../../ContactHelp/ContactHelp'
import { ErrorElement } from '../../../Api/types'

export const AccountPaymentCreator = () => {
  const [loading, setLoading] = useState(false)
  const [config, setConfig] = useState(null)
  const [missingDateOfBirth, setMissingDateOfBirth] = useState(false)
  const [noResults, setNoResults] = useToggle(false)
  const [error, setError] = useState(null)
  const fetchAccount = useFetchAccount()

  const fetchConfig = useCallback(async () => {
    setLoading(true)

    try {
      const response = await fetchApplicableConfig()
      setConfig(response.data)

      if (!response.data) {
        setNoResults()
      }
    } catch (e) {
      networkError<ErrorElement>(e)
        .on404(setNoResults)
        .on4xx5xx((data) => {
          if (data.errorType === ErrorType.MISSING_DATE_OF_BIRTH) {
            setMissingDateOfBirth(true)
          }
        })
        .onNoApi((e) => setError(e))
        .else((e) => setError(e))
    } finally {
      setLoading(false)
    }
  }, [setNoResults])

  useEffect(() => {
    fetchConfig()
  }, [fetchConfig])

  if (loading) {
    return <PaymentLoading />
  }

  if (missingDateOfBirth) {
    return <MissingDateOfBirth onSuccess={() => setMissingDateOfBirth(false)} />
  }

  if (noResults) {
    return <NoPaymentConfigs />
  }

  if (error) {
    return (
      <PagePaper>
        <Helmet>
          <title>Składki - błąd</title>
        </Helmet>
        <TextRegular variant="large">Składka członkowska</TextRegular>
        <Alert warning>Wystąpił nieokreślony błąd.</Alert>
        <Box mt={1}>
          <ContactHelp />
        </Box>
      </PagePaper>
    )
  }

  if (config) {
    return <PaymentOffersList config={config} onRefresh={fetchAccount} />
  }

  return null
}
