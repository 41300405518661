import { HorizontalView } from '../Layout'
import { Logo } from '../Logo'
import { InputField } from '../Form'
import { Formik, FormikProps } from 'formik'
import React from 'react'
import { Box, Button } from '@mui/material'
import { LoginTextField } from '../Login'
import { useToggle } from '../../hooks/useToggle'
import { updateUsername } from '../Account/account.service'
import { ErrorType } from '../Api/enums'
import * as Yup from 'yup'

interface FormValues {
  username: string
}

const validationSchema = Yup.object().shape({
  username: Yup.string().required('Podaj nazwę użytkownika')
})

export interface SsoUsernameFormProps {
  jwt: string
  onSuccess: () => void
}

export function SsoUsernameForm({ jwt, onSuccess }: SsoUsernameFormProps) {
  const [userExistsError, setUserExistsError, clearUserExistsError] =
    useToggle()

  const handleSubmit = async (
    { username }: FormValues,
    { setSubmitting }: FormikProps<FormValues>
  ) => {
    try {
      clearUserExistsError()
      await updateUsername(username, jwt)
      onSuccess()
    } catch (e) {
      // TODO: handle other errors too
      if (
        e?.response?.status === 400 &&
        e.response.data.errorType === ErrorType.USER_ALREADY_EXISTS
      ) {
        setUserExistsError()
      }
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Formik<FormValues>
      initialValues={{ username: '' }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ submitForm, isSubmitting }) => (
        <>
          <HorizontalView justify="center">
            <Logo light size={220} />
          </HorizontalView>
          <Box my={2}>
            Podaj nazwę użytkownika, którą będziesz się posługiwać.
          </Box>
          <InputField
            autoFocus
            name="username"
            placeholder="Nazwa użytkownika"
            fullWidth
            required
            InputComponent={LoginTextField}
            error={userExistsError}
            helperText={userExistsError && 'Wybrana nazwa jest już zajęta'}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={submitForm}
            disabled={isSubmitting}
            sx={{ mt: 2 }}
          >
            {isSubmitting ? 'Zapisuję...' : 'Zapisz'}
          </Button>
        </>
      )}
    </Formik>
  )
}
