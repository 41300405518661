import { BookingPaymentOrderDTO, ModifyBookingOperation } from '../types'
import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar
} from '@mui/material'
import { BookingTile } from '../BookingTile'
import { TextRegular } from '../../Text/TextRegular'
import Button from '@mui/material/Button'
import React, { useReducer } from 'react'
import { modifyBooking } from '../bookings.service'
import { requestReducer, requestReducerInitialState } from './reducer'

export interface ChangeBookingDialogProps {
  booking: BookingPaymentOrderDTO
  onClose: () => void
  onChange: () => void
}

export function ChangeBookingDialog({
  booking,
  onClose,
  onChange
}: ChangeBookingDialogProps) {
  const [{ isLoading, isSuccess, isError }, dispatch] = useReducer(
    requestReducer,
    requestReducerInitialState
  )
  const modify = async (operation: ModifyBookingOperation) => {
    dispatch({
      type: 'startLoading'
    })

    try {
      await modifyBooking(booking.id, operation)
      dispatch({ type: 'success' })
      onChange()
      onClose()
    } catch (e) {
      dispatch({ type: 'error' })
    }
  }

  const handleClose = () => {
    dispatch({ type: 'reset' })
    onClose()
  }

  return (
    <>
      <Dialog open={!!booking} onClose={handleClose} fullWidth maxWidth="lg">
        {booking && (
          <>
            <DialogTitle>Zmień rezerwację</DialogTitle>
            <DialogContent>
              <Box mt={3} mb={5} display="flex" justifyContent="center">
                <BookingTile booking={booking} />
              </Box>
              Wybierz jedną z opcji:
              <ul>
                <li>
                  <TextRegular strong>Odbierz rezerwację</TextRegular> - Wybierz
                  tę opcję, jeśli osoba przyszła na umówioną godzinę. Blokada
                  środków na koncie zostanie cofnięta.
                </li>
                <li>
                  <TextRegular strong>Zaksięguj rezerwację</TextRegular> -
                  Wybierz tę opcję, jeśli osoba nie pojawiła się na umówioną
                  godzinę. Zablokowane środki zostaną pobrane z konta klienta.{' '}
                  <TextRegular strong>
                    Księgowanie zaległych rezerwacji odbywa się automatycznie w
                    regularnych odstępach czasu.
                  </TextRegular>
                </li>
                <li>
                  <TextRegular strong>Anuluj</TextRegular> - Powrót do listy
                  rezerwacji
                </li>
              </ul>
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                onClick={handleClose}
                disabled={isLoading}
              >
                Anuluj
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => modify(ModifyBookingOperation.CANCEL_PAYMENT)}
                disabled={isLoading}
              >
                Odbierz rezerwację
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => modify(ModifyBookingOperation.CONFIRM_PAYMENT)}
                disabled={isLoading}
              >
                Zaksięguj płatność
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <Snackbar
        open={isSuccess || isError}
        onClose={() => dispatch({ type: 'cleanResponse' })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={3000}
      >
        <Alert severity={isSuccess ? 'success' : 'error'}>
          {isSuccess && <>Rezerwacja została zmodyfikowana.</>}
          {isError && <>Wystąpił błąd podczas zapytania.</>}
        </Alert>
      </Snackbar>
    </>
  )
}
