import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material'
import Box from '@mui/material/Box'
import { FormikBag } from 'formik'
import { FormError, InputField } from '../../../Form'
import { GroupPaymentOptions } from './GroupPaymentOptions'
import {
  NetworkRequestStatus,
  useNetworkRequest
} from '../../../../common/network-state'
import { TextRegular } from '../../../Text/TextRegular'
import React, { useEffect, useMemo } from 'react'
import { fetchBatchApplicableConfig } from '../../payment-service'
import { ErrorElement } from '../../../Api/types'
import { ErrorType } from '../../../Api/enums'
import { AxiosError } from 'axios'

export interface FormValues {
  year: string
  accountIds: string
  accounts: {
    accountId: string
    useEntryFee: boolean
    useMemberFee: boolean
    useLicenseFee: boolean
    memberFeeUnitId: string | null
  }[]
}

export interface AdminGroupPaymentFormProps {
  values: FormValues
  submitForm: FormikBag<unknown, FormValues>['submitForm']
  setFieldValue: FormikBag<unknown, FormValues>['setFieldValue']
  error: AxiosError<ErrorElement> | Error
  onCancel: () => void
  isSubmitting: boolean
}

export function AdminGroupPaymentForm({
  values,
  submitForm,
  setFieldValue,
  error,
  onCancel,
  isSubmitting
}: AdminGroupPaymentFormProps) {
  const [fetchBatchConfig, batchConfig, status, , resetBatchConfig] =
    useNetworkRequest(
      ({ year, accountIds }: FormValues) =>
        fetchBatchApplicableConfig(year, accountIds.split(',')),
      []
    )

  const hasApplicableAccounts = useMemo(
    () =>
      batchConfig?.data.some(
        (account) =>
          account.entryFeeApplicable ||
          account.memberFeeApplicable ||
          account.licenseFeeApplicable
      ),
    [batchConfig?.data]
  )

  const handleCancel = () => {
    resetBatchConfig()
    onCancel()
  }

  useEffect(() => {
    if (batchConfig?.data) {
      setFieldValue(
        'accounts',
        batchConfig?.data.map((accountInfo) => ({
          accountId: accountInfo.account.id,
          // TODO: set those (and memberFeeUnitId) according to guessed values from bank export sheet
          useEntryFee: accountInfo.entryFeeApplicable,
          useMemberFee: accountInfo.memberFeeApplicable,
          useLicenseFee: accountInfo.licenseFeeApplicable,
          memberFeeUnitId: ''
        }))
      )
    }
  }, [batchConfig, setFieldValue])

  return (
    <>
      <DialogTitle>Utwórz wiele płatności</DialogTitle>
      <DialogContent>
        <Box width="260px">
          <InputField name="year" label="Rok płatności" type="number" />
        </Box>
        <InputField
          name="accountIds"
          label="ID użytkowników (oddzielone przecinkami)"
        />
        <GroupPaymentOptions
          batchConfig={batchConfig?.data}
          isLoading={status === NetworkRequestStatus.LOADING}
          onFetch={() => fetchBatchConfig(values)}
        />
      </DialogContent>
      {batchConfig?.data ? (
        <>
          <DialogActions>
            <Button onClick={handleCancel}>Anuluj</Button>
            <Button
              color="primary"
              variant="contained"
              onClick={submitForm}
              disabled={!hasApplicableAccounts}
            >
              {isSubmitting ? 'Zatwierdzam...' : 'Zatwierdź'}
            </Button>
          </DialogActions>
          {error && (
            <Box m={2}>
              <FormError>
                {(error as AxiosError<ErrorElement>)?.response?.data
                  ?.errorType === ErrorType.BATCH_PAYMENT_FEE_UNIT_MISMATCH
                  ? 'Wybierz rodzaj składki dla każdego konta.'
                  : 'Wystąpił błąd podczas tworzenia składek.'}
              </FormError>
            </Box>
          )}
          {!hasApplicableAccounts && (
            <Box m={2} display="flex" justifyContent="flex-end">
              <TextRegular light>
                Wszyscy wybrani użytkownicy mają opłaconą składkę
              </TextRegular>
            </Box>
          )}
        </>
      ) : (
        <DialogActions>
          <Button onClick={handleCancel}>Zamknij</Button>
        </DialogActions>
      )}
    </>
  )
}
