import { PayUOrderStatus } from '../../Payments/enums'
import { BookingPaymentOrderDTO } from '../types'
import { DateTime } from 'luxon'

export function getBookingStatusText(status: PayUOrderStatus): string {
  switch (status) {
    case PayUOrderStatus.WAITING_FOR_CONFIRMATION:
      return 'aktywna'
    case PayUOrderStatus.CANCELED:
    case PayUOrderStatus.REFUNDED:
      return 'zrealizowana'
    case PayUOrderStatus.REJECTED:
      return 'odrzucona'
    case PayUOrderStatus.NEW:
    case PayUOrderStatus.PENDING:
      return 'nowa'
    case PayUOrderStatus.COMPLETED:
      return 'opłacona'
    default:
      return 'nieokreślony'
  }
}

export function getBookingStatusColor(
  status: PayUOrderStatus,
  isDelayed: boolean
): 'primary' | 'secondary' | undefined {
  if (isDelayed) {
    return 'secondary'
  }

  switch (status) {
    case PayUOrderStatus.WAITING_FOR_CONFIRMATION:
    case PayUOrderStatus.NEW:
    case PayUOrderStatus.PENDING:
      return 'primary'
    case PayUOrderStatus.REJECTED:
      return 'secondary'
    case PayUOrderStatus.CANCELED:
    case PayUOrderStatus.REFUNDED:
    case PayUOrderStatus.COMPLETED:
    default:
      return undefined
  }
}

const bookingDelayedStatuses: PayUOrderStatus[] = [
  PayUOrderStatus.WAITING_FOR_CONFIRMATION,
  PayUOrderStatus.NEW,
  PayUOrderStatus.PENDING
]
export function isBookingDelayed(booking: BookingPaymentOrderDTO): boolean {
  return (
    bookingDelayedStatuses.includes(booking.gateway.status) &&
    DateTime.fromISO(booking.paymentDetails.startDate).toMillis() <
      DateTime.now().toMillis()
  )
}
