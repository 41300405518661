import { AxiosRequestConfig } from 'axios'

export function getAuthorizationHeader(
  token: string
): AxiosRequestConfig['headers'] {
  if (token) {
    return {
      'X-Authorization': `Bearer ${token}`
    }
  }

  return {}
}
