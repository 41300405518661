import axios, { AxiosRequestConfig } from 'axios'
import { AuthStore } from '../Auth/AuthStore'
import { getAuthorizationHeader } from './helpers'

export type InterceptorFunction = (response: any) => any

export class ApiInterceptor {
  public static api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
  })

  static async get<ResponseType>(path: string, options?: AxiosRequestConfig) {
    return this.api.get<ResponseType>(path, this.getOptions(options))
  }

  static async post<ResponseType, Payload extends {} = {}>(
    path: string,
    payload?: Payload,
    options?: AxiosRequestConfig
  ) {
    return this.api.post<ResponseType>(path, payload, this.getOptions(options))
  }

  static async put<ResponseType, Payload extends {} = {}>(
    path: string,
    payload?: Payload,
    options?: AxiosRequestConfig
  ) {
    return this.api.put<ResponseType>(path, payload, this.getOptions(options))
  }

  static async patch<ResponseType, Payload extends {} = {}>(
    path: string,
    payload?: Payload,
    options?: AxiosRequestConfig
  ) {
    return this.api.patch<ResponseType>(path, payload, this.getOptions(options))
  }

  static async delete<ResponseType>(
    path: string,
    options?: AxiosRequestConfig
  ) {
    return this.api.delete<ResponseType>(path, this.getOptions(options))
  }

  static intercept(
    direction: 'request' | 'response',
    successInterceptor: InterceptorFunction,
    errorInterceptor: InterceptorFunction
  ) {
    this.api.interceptors[direction].use(successInterceptor, errorInterceptor)
  }

  private static getOptions(options?: AxiosRequestConfig): AxiosRequestConfig {
    const jwt = AuthStore.getJwt()

    return {
      ...options,
      headers: {
        ...getAuthorizationHeader(jwt),
        ...(options && options.headers)
      }
    }
  }
}
