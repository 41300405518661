import React from 'react'
import { Navigate, Routes } from 'react-router-dom'
import { Route } from 'react-router'
import { RangesList } from './RangesList'
import { RouteLinks, RouteNames } from '../Routes/RouteNames'
import { RangeDetailsRoutes } from './Details/RangeDetailsRoutes'

export const RangesRoutes = () => {
  return (
    <Routes>
      <Route path={RouteNames.RangesList} element={<RangesList />} />
      <Route path={RouteNames.RangeDetails} element={<RangeDetailsRoutes />} />
      <Route path="*" element={<Navigate to={RouteLinks.RangesList} />} />
    </Routes>
  )
}
