import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { RouteLinks, RouteNames } from '../Routes/RouteNames'
import { BookingsList } from './BookingsList'
import { Helmet } from 'react-helmet'
import { BookingsRangeView } from './BookingsRangeView'
import { BookingsRangesList } from './BookingsRangesList'
import { LinkTab, SubMenu } from '../Header/SubMenu'
import { BookingsOrder } from './BookingsOrder'
import { useSelector } from 'react-redux'
import { hasRoleSelector } from '../Auth/auth.duck'
import { Role } from '../Account/enums'
import { BookingsManager, BookingsManagerList } from './BookingsManager'
import { BookingsSettings } from './BookingsSettings'

export function BookingsRoutes() {
  const isBookingsManager = useSelector(hasRoleSelector(Role.BOOKINGS_MANAGER))
  const isBookingsAdmin = useSelector(hasRoleSelector(Role.BOOKINGS_ADMIN))

  return (
    <>
      <Helmet>
        <title>Rezerwacje</title>
      </Helmet>
      <SubMenu>
        {isBookingsManager && (
          <LinkTab label="Lista rezerwacji" to={RouteLinks.BookingsManager} />
        )}
        <LinkTab label="Nowa rezerwacja" to={RouteLinks.BookingsRanges} />
        <LinkTab label="Moje rezerwacje" to={RouteLinks.BookingsList} />
        {isBookingsAdmin && (
          <LinkTab label="Ustawienia" to={RouteLinks.BookingsSettings} />
        )}
      </SubMenu>

      <Routes>
        <Route path={RouteNames.BookingsList} element={<BookingsList />} />
        <Route
          path={RouteNames.BookingsManager}
          element={<BookingsManager />}
        />
        <Route
          path={RouteNames.BookingsManagerList}
          element={<BookingsManagerList />}
        />
        <Route
          path={RouteNames.BookingsSettings}
          element={<BookingsSettings />}
        />
        <Route
          path={RouteNames.BookingsRanges}
          element={<BookingsRangesList />}
        />
        <Route
          path={RouteNames.BookingsRange}
          element={<BookingsRangeView />}
        />
        <Route
          path={RouteNames.BookingsRangeLane}
          element={<BookingsRangeView />}
        />
        <Route path={RouteNames.BookingsOrder} element={<BookingsOrder />} />

        <Route
          path="*"
          element={
            <Navigate
              to={
                isBookingsAdmin || isBookingsManager
                  ? RouteLinks.BookingsManager
                  : RouteLinks.BookingsRanges
              }
              replace
            />
          }
        />
      </Routes>
    </>
  )
}
