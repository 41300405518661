import React, { FC } from 'react'
import { TextRegular, TextRegularProps } from '../Text/TextRegular'
import styled from 'styled-components'

export type LabelProps = TextStyledProps & TextRegularProps

interface TextStyledProps {
  focused?: boolean
  hasError?: boolean
}

export const Label: FC<LabelProps> = ({ focused, hasError, ...props }) => (
  // @ts-ignore
  <TextStyled
    variant="small"
    light
    focused={focused}
    hasError={hasError}
    {...props}
  />
)

const TextStyled = styled(TextRegular)<TextStyledProps>`
  margin-top: 24px;
  color: ${(p) => (p.hasError ? '#ff1f00' : p.focused ? '#333' : 'inherit')};
`
