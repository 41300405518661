import React, { useEffect, useMemo } from 'react'
import { PagePaper } from '../../Layout'
import {
  NetworkRequestStatus,
  useNetworkRequest
} from '../../../common/network-state'
import { getRangeLanes, getRangesWithLanes } from '../../Ranges/ranges.service'
import Box from '@mui/material/Box'
import { TextRegular } from '../../Text/TextRegular'
import { plural } from '../../../helpers/plural'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'
import { formatRoute } from '../../../helpers/formatRoute'
import { RouteLinks } from '../../Routes/RouteNames'

export function BookingsRangesList() {
  const [fetchRanges, rangesResponse, rangesStatus] = useNetworkRequest(() =>
    getRangesWithLanes()
  )
  const [fetchLanes, lanesResponse, lanesStatus] = useNetworkRequest(
    () =>
      Promise.all(rangesResponse.data.map((range) => getRangeLanes(range.id))),
    [rangesResponse]
  )
  const rangesCombo = useMemo(
    () =>
      rangesResponse && lanesResponse
        ? rangesResponse.data.map((range) => ({
            range,
            lanes: lanesResponse
              .map((lr) => lr.data)
              .find((lanes) => lanes[0].rangeId === range.id)
          }))
        : [],
    [rangesResponse, lanesResponse]
  )

  const isLoading = useMemo(
    () =>
      rangesStatus === NetworkRequestStatus.LOADING ||
      lanesStatus === NetworkRequestStatus.LOADING,
    [rangesStatus, lanesStatus]
  )

  useEffect(() => {
    fetchRanges()
  }, [fetchRanges])

  useEffect(() => {
    if (rangesStatus === NetworkRequestStatus.LOADED) {
      fetchLanes()
    }
  }, [rangesStatus, fetchLanes])

  return (
    <PagePaper loading={isLoading} title="Zarezerwuj stanowisko">
      {rangesCombo?.length ? (
        rangesCombo.map((combo) => (
          <Box key={combo.range.id}>
            <Link
              to={formatRoute(RouteLinks.BookingsRange, {
                rangeId: combo.range.id
              })}
            >
              <TextRegular size="large">{combo.range.name}</TextRegular>
            </Link>
            <Box mt={2} mb={3}>
              {combo.lanes.map((lane, i) => (
                <Box
                  key={lane.id}
                  display="flex"
                  alignItems="center"
                  width="50%"
                  my={1}
                  p={1}
                  borderTop={i === 0 ? '' : '1px solid #ccc'}
                >
                  <Box flexGrow={1} mr={3}>
                    {lane.name}
                    {lane.lanesAmount > 0 ? (
                      <TextRegular size="small">
                        &nbsp;({lane.lanesAmount}&nbsp;
                        {plural(
                          lane.lanesAmount,
                          'stanowisko',
                          'stanowiska',
                          'stanowisk'
                        )}
                        )
                      </TextRegular>
                    ) : (
                      ''
                    )}
                  </Box>
                  <Button
                    component={Link}
                    to={formatRoute(RouteLinks.BookingsRangeLane, {
                      rangeId: combo.range.id,
                      laneId: lane.id
                    })}
                    color="primary"
                    variant="contained"
                    size="small"
                  >
                    Rezerwuj
                  </Button>
                </Box>
              ))}
            </Box>
          </Box>
        ))
      ) : (
        <Box>Brak dostępnych stanowisk</Box>
      )}
    </PagePaper>
  )
}
