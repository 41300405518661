import React from 'react'
import styled from 'styled-components'
import { View } from '../../../Layout'
import { colors } from '../../../../styles/variables'
import { TextRegular } from '../../../Text/TextRegular'
import Box from '@mui/material/Box'
import { muiTheme } from '../../../../common/muiTheme'

export const VariantListContainer = styled(View)`
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 32px;
  
  ${muiTheme.breakpoints.up('md')} {
    flex-direction: row;
    align-items: stretch;
  }
`

export const VariantContainer = styled(View)`
  border: 1px solid #ccc;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  overflow: hidden;
  margin-top: 16px;
  width: 300px;

  ${muiTheme.breakpoints.up('md')} {
    &:nth-child(even) {
      margin-left: 32px;
    }
  }
`

export const VariantInner = styled(View)`
  padding: 16px;
`

export const VariantTitle = (props) => (
  <Box mb={2}>
    <TextRegular variant="large" strong {...props} />
  </Box>
)

export const VariantFooter = styled(View)`
  align-items: flex-end;
  flex-grow: 1;
  justify-content: flex-end;
  background: #3e5b63;
  padding: 16px 16px;
  color: #fff;
`

export const PeriodRangeLabel = styled(View)`
  align-self: flex-start;
  margin-top: 16px;
  padding: 8px 16px;
  border-radius: 24px;
  background: ${colors.cloud};
`

export const TotalAmount = styled(View)`
  flex-direction: row;
  align-items: center;
`

export const TotalAmountText = styled(TextRegular)`
  margin-left: 8px;
`
