import { ApiInterceptor } from '../Api/ApiInterceptor'
import { AccountDTO } from './types'
import { AxiosRequestConfig } from 'axios'
import { getAuthorizationHeader } from '../Api/helpers'

export const fetchAccount = (options?: AxiosRequestConfig) => {
  return ApiInterceptor.get<AccountDTO>('/api/member/me', options)
}

export const updateUsername = (username: string, jwt: string) => {
  return ApiInterceptor.post(
    '/api/my-account',
    {
      username
    },
    {
      headers: {
        ...getAuthorizationHeader(jwt),
        'Content-Type': 'application/vnd.account.updateUsername.v1+json'
      }
    }
  )
}
