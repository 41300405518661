import React, { FC, useMemo } from 'react'
import styled, { keyframes } from 'styled-components'
import { colors } from '../../styles/variables'
import { View } from '../Layout'

export interface LoadingProps {
  animating?: boolean
  size?: 'small' | 'xsmall' | 'normal'
}

interface LoadingStyledProps {
  animating: boolean
  size: number
}

export const Loading: FC<LoadingProps> = ({
  animating = true,
  size = 'normal'
}) => {
  const sizePx = useMemo(() => {
    switch (size) {
      case 'xsmall':
        return 30
      case 'small':
        return 45
      case 'normal':
      default:
        return 90
    }
  }, [size])

  return (
    <LoadingWrapper>
      <LoadingStyled animating={animating} size={sizePx} />
    </LoadingWrapper>
  )
}

const rotate = keyframes`
  0% {transform: rotate(0);}
  100% {transform: rotate(360deg);}
`

const LoadingWrapper = styled(View)`
  position: relative;
`

const LoadingStyled = styled(View).attrs<LoadingProps>(({ animating }) => ({
  style: {
    animationPlayState: animating ? 'running' : 'paused'
  }
}))<LoadingStyledProps>`
  width: ${(p) => p.size}px;
  height: ${(p) => p.size}px;
  border: 2px solid ${colors.main};
  border-right-color: transparent;
  border-radius: 50%;
  animation: ${rotate} 0.8s linear infinite;
`
