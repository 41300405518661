import { colors } from '../../../../../styles/variables'
import { Box } from '@mui/material'
import { TextRegular } from '../../../../Text/TextRegular'
import { IoMdDoneAll } from 'react-icons/io'
import moment from 'moment'
import { View } from '../../../../Layout'
import React, { FC } from 'react'
import { StatusBadge } from './StatusBadge'
import { MembershipPaymentOrder } from '../../../types'
import { PaymentFeeType } from '../../../enums'

export interface StatusManualProps {
  order: MembershipPaymentOrder
}

export const StatusManual: FC<StatusManualProps> = ({ order }) => {
  const hasMemberFee = order.paymentItems.some(
    (item) => item.paymentItemDetails.feeType === PaymentFeeType.MEMBER_FEE
  )

  return (
    <View alignItems="flex-start">
      <StatusBadge background={colors.success} color="#fff">
        <Box display="flex" mr={1}>
          <IoMdDoneAll size={24} />
        </Box>
        <TextRegular variant="medium">Płatność zakończona</TextRegular>
      </StatusBadge>
      {hasMemberFee && (
        <Box mt={3}>
          <TextRegular variant="medium">
            Twoje konto jest ważne do{' '}
            <TextRegular strong>
              {moment(order.paymentDetails.endDate).format('DD.MM.YYYY')}
            </TextRegular>
          </TextRegular>
        </Box>
      )}
    </View>
  )
}
