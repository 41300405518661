import { View } from '../../../Layout'
import { CurrentStepLabel } from './CurrentStepLabel'
import React, { useCallback } from 'react'
import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import { Formik } from 'formik'
import {
  formValuesSelector,
  memberFeeUnitsCountSelector,
  useGoToNextStep,
  useSetFormValues
} from './duck'
import { WizardButtons } from './WizardButtons'
import { MemberFeeWizardUnits } from './duck/types'
import { useGenerateMemberFeeUnits } from './hooks'
import { UnitCard } from './UnitCard'

export const MemberFeeUnitsStep = () => {
  const formValues = useSelector(formValuesSelector)
  const config = formValues.memberFeeConfig
  const setFormValues = useSetFormValues('memberFeeUnits')
  const goToNextStep = useGoToNextStep()
  const units = useGenerateMemberFeeUnits()
  const feesCount = useSelector(memberFeeUnitsCountSelector)
  const submit = useCallback(
    (values: MemberFeeWizardUnits) => {
      setFormValues(values)
      goToNextStep()
    },
    [setFormValues, goToNextStep]
  )

  return (
    <Formik<MemberFeeWizardUnits>
      initialValues={units}
      onSubmit={submit}
      validateOnChange
    >
      {({ handleSubmit, values, setFieldValue }) => (
        <View>
          <CurrentStepLabel />

          <Box mt={3} mb={3}>
            Określ wysokość{' '}
            {feesCount > 1 ? `${feesCount} składek.` : 'składki.'}
          </Box>

          {units.map((unit, i) => (
            <UnitCard
              key={i}
              index={i}
              isEntry={false}
              config={config}
              unit={unit}
              values={values}
              setFieldValue={setFieldValue}
            />
          ))}

          <WizardButtons handleSubmit={handleSubmit} />
        </View>
      )}
    </Formik>
  )
}
