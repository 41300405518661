import Tooltip, { TooltipProps } from '@mui/material/Tooltip'
import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'
import { IoMdInformationCircleOutline } from 'react-icons/io'

export interface HelpTooltipProps
  extends Omit<TooltipProps, 'children' | 'title'> {
  children: ReactNode
  title?: TooltipProps['title']
}

export const HelpTooltip: FC<HelpTooltipProps> = ({ children, ...props }) => {
  return (
    <Tooltip title={children} arrow disableInteractive {...props}>
      <div>
        <TooltipIcon size={16} />
      </div>
    </Tooltip>
  )
}

const TooltipIcon = styled(IoMdInformationCircleOutline)`
  display: block;
  cursor: pointer;
`
