import { createTheme } from '@mui/material/styles'
import { colors } from '../styles/variables'
import { lighten } from 'polished'

const theme = createTheme({
  spacing: 8,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1200,
      xl: 1600
    }
  }
})

export const muiTheme = createTheme(theme, {
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'outlined'
      }
    },
    MuiSelect: {
      defaultProps: {
        variant: 'outlined'
      }
    },
    MuiButton: {
      defaultProps: {
        variant: 'outlined',
        color: 'inherit'
      },
      styleOverrides: {
        containedPrimary: {
          background: `linear-gradient(116deg, #4e9fff, #4ea8ff 75%)`,
          color: '#fff'
        },
        outlined: {
          boxShadow: theme.shadows[2],
          borderColor: 'transparent'
        },
        root: {
          paddingLeft: '16px',
          paddingRight: '16px',
          paddingTop: '8px',
          paddingBottom: '8px',
          borderRadius: '32px',
          textTransform: 'none',
          fontSize: '16px',
          '&.Mui-disabled': {
            background: '#a9b4bf'
          }
        },
        sizeSmall: {
          paddingTop: '4px',
          paddingBottom: '4px',
          paddingLeft: '12px',
          paddingRight: '12px'
        }
      }
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover'
      }
    }
  },
  palette: {
    primary: {
      light: lighten(0.05, colors.main),
      main: colors.main,
      dark: colors.mainDark,
      contrastText: colors.white
    },
    secondary: {
      main: colors.error
    },
    action: {
      disabled: '#fff'
    }
  }
})
