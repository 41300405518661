import {
  NetworkRequestStatus,
  useNetworkRequest
} from '../../common/network-state'
import {
  downloadDocument,
  getCurrentAccountDocuments
} from './documents.service'
import React, { useEffect, useState } from 'react'
import { HorizontalView, PagePaper } from '../Layout'
import {
  Alert,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material'
import { IoMdCloudDownload } from 'react-icons/io'
import { useSelector } from 'react-redux'
import { accountSelector } from '../Account/account.duck'
import FileSaver from 'file-saver'
import { Document } from './types'
import IconButton from '@mui/material/IconButton'
import { DateTime } from 'luxon'
import { DeleteDocument } from './DeleteDocument'

export function MyDocuments() {
  const account = useSelector(accountSelector)
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')

  const [fetch, documentsResponse, status] = useNetworkRequest(
    () => getCurrentAccountDocuments(),
    []
  )

  const handleDownload = async (document: Document) => {
    const response = await downloadDocument(account.id, document.id)
    FileSaver.saveAs(response.data, document.name)
  }

  useEffect(() => {
    fetch()
  }, [fetch])

  return (
    <PagePaper
      title="Moje Dokumenty"
      loading={status === NetworkRequestStatus.LOADING}
    >
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={3000}
        onClose={() => setErrorMessage('')}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <Snackbar
        open={!!successMessage}
        autoHideDuration={3000}
        onClose={() => setSuccessMessage('')}
      >
        <Alert severity="success">{successMessage}</Alert>
      </Snackbar>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nazwa</TableCell>
            <TableCell>Data dodania</TableCell>
            <TableCell>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {documentsResponse?.data?.length ? (
            documentsResponse?.data.map((document) => (
              <TableRow key={document.id}>
                <TableCell>{document.name}</TableCell>
                <TableCell>
                  {DateTime.fromISO(document.createdTime).toFormat(
                    'yyyy-MM-dd HH:mm'
                  )}
                </TableCell>
                <TableCell>
                  <HorizontalView gap={2}>
                    <DeleteDocument
                      account={account}
                      document={document}
                      onRefresh={fetch}
                      onSuccess={setSuccessMessage}
                      onError={setErrorMessage}
                    />
                    <IconButton
                      onClick={() => handleDownload(document)}
                      title="Pobierz"
                    >
                      <IoMdCloudDownload />
                    </IconButton>
                  </HorizontalView>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={3}>
                Brak dokumentów na Twoim koncie.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </PagePaper>
  )
}
