import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material'
import React, { FC, useCallback } from 'react'
import { useToggle } from '../../../../hooks/useToggle'
import { Link } from '../../../Link'

export interface LicenseFeeCancelProps {
  onSave: () => void
}

export const LicenseFeeCancel: FC<LicenseFeeCancelProps> = ({ onSave }) => {
  const [modalOpen, open, close] = useToggle()
  const handleSave = useCallback(() => {
    close()
    onSave()
  }, [onSave, close])

  return (
    <Box>
      Nie posiadasz licencji sportowej?{' '}
      <Link onClick={open}>Kliknij tutaj</Link>
      <Dialog open={modalOpen} onClick={close}>
        <DialogTitle>Wycofaj licencję sportową</DialogTitle>
        <DialogContent>
          Jeśli wprowadziłeś rok uzyskania licencji przez pomyłkę i nie chcesz
          widzieć opłat za licencję, nadal możesz wycofać tę zmianę.
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>Anuluj</Button>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Wycofaj licencję sportową
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
