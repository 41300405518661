import { Link } from '@mui/material'
import React, { useMemo } from 'react'

export interface InvalidSsoLinkProps {
  vendorUrl: string
}

export function InvalidSsoLink({ vendorUrl }: InvalidSsoLinkProps) {
  const shortVendorUrl = useMemo(
    () => vendorUrl.replace(/https?:\/\//, ''),
    [vendorUrl]
  )

  return (
    <>
      Niepoprawny link. Upewnij się, że logujesz się przez stronę{' '}
      <Link href={vendorUrl}>{shortVendorUrl}</Link>
    </>
  )
}
