import { Tooltip } from '@mui/material'
import { MdLock } from 'react-icons/md'
import { colors } from '../../styles/variables'
import { AiFillGoogleCircle } from 'react-icons/ai'
import React, { forwardRef, MutableRefObject } from 'react'
import styled from 'styled-components'
import { RangeCombinedEvent } from './types'
import { IoWarning } from 'react-icons/io5'

export interface CalendarEventBadgeProps {
  event: RangeCombinedEvent
  onClick: () => void
}

function CalendarEventBadgeComponent(
  { event, onClick }: CalendarEventBadgeProps,
  ref:
    | ((instance: HTMLDivElement | null) => void)
    | MutableRefObject<HTMLDivElement | null>
    | null
) {
  return (
    <Event onClick={onClick} ref={ref}>
      <EventSummary>{event.summary}</EventSummary>
      <EventIcon>
        {event.blockedLanes.length > 0 && (
          <Tooltip title="Wydarzenie posiada blokadę toru" disableInteractive>
            <div>
              <MdLock color={colors.success} />
            </div>
          </Tooltip>
        )}
        {!!event.googleEvent && (
          <Tooltip title="Wydarzenie w kalendarzu Google" disableInteractive>
            <div>
              <AiFillGoogleCircle />
            </div>
          </Tooltip>
        )}
        {!event.googleEvent && event.hasGoogleEvent && (
          <Tooltip
            title="Wydarzenie istnieje w kalendarzu Google, jednak nie znaleziono powiązanego z nim konta"
            disableInteractive
          >
            <div>
              <IoWarning color={colors.warn} />
            </div>
          </Tooltip>
        )}
      </EventIcon>
    </Event>
  )
}

const Event = styled.div`
  display: flex;
  align-items: center;
  padding: 1px;
  background: #fff;
  border: 1px solid ${colors.smoke};
  border-radius: 2px;
  cursor: pointer;

  &:hover {
    background: #f0f0f0;
  }
`

const EventSummary = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  flex-grow: 1;
`

const EventIcon = styled.div`
  display: flex;
  margin: 1px;

  svg {
    display: block;
  }
`

export const CalendarEventBadge = forwardRef<
  HTMLDivElement,
  CalendarEventBadgeProps
>(CalendarEventBadgeComponent)
CalendarEventBadge.displayName = 'CalendarEventBadge'
