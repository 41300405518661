import React, { FC, useMemo } from 'react'
import { FeeAgeRange, FeeUnit } from '../../types'

export interface AgeRangesListProps {
  units: FeeUnit[]
}

export const AgeRangesList: FC<AgeRangesListProps> = ({ units }) => {
  const uniqueRanges = useMemo<FeeAgeRange[]>(
    () =>
      units.reduce((uniques, unit) => {
        if (uniques.includes(unit)) {
          return uniques
        }

        return [...uniques, unit.ageRange]
      }, []),
    [units]
  )

  return (
    <>
      0 -{' '}
      {uniqueRanges
        .filter((range) => range.from)
        .map((range) => range.from)
        .join(' - ')}{' '}
      - &infin;
    </>
  )
}
