import moment from 'moment'
import Tooltip from '@mui/material/Tooltip'
import { Box } from '@mui/material'
import React, { forwardRef, ReactElement } from 'react'
import styled from 'styled-components'
import { IoIosCheckmarkCircle } from 'react-icons/io'
import { colors } from '../../../../styles/variables'
import { IconType } from 'react-icons'

export interface FeeStartingDateProps<T> {
  data: T
}

interface DataType {
  startTime: string
  endTime: string
}

export const FeeStartingDate = <T extends DataType>({
  data
}: FeeStartingDateProps<T>): ReactElement => {
  const now = moment()
  const startTimeMoment = moment(data.startTime)
  const endTimeMoment = moment(data.endTime)
  const isCurrentConfig =
    startTimeMoment.startOf('day').isSameOrBefore(now) &&
    (!data.endTime || endTimeMoment.endOf('day').isSameOrAfter(now))

  return (
    <Box display="flex" alignItems="center">
      {isCurrentConfig && (
        <Tooltip title="Aktywna składka" arrow disableInteractive>
          <CheckIcon size={20} />
        </Tooltip>
      )}
      {data.endTime
        ? `${startTimeMoment.format('YYYY-MM-DD')} - ${endTimeMoment.format(
            'YYYY-MM-DD'
          )}`
        : `od ${startTimeMoment.format('YYYY-MM-DD')}`}
    </Box>
  )
}

const CheckIcon: IconType = styled(
  forwardRef((props, ref) => <IoIosCheckmarkCircle {...props} />)
)`
  display: block;
  margin-right: 8px;
  color: ${colors.success};
`
