import { ApiInterceptor } from '../Api/ApiInterceptor'
import { SsoAuthLoginResponse, SsoAuthVerificationResponse } from './types'
import { SsoVendor } from './enums'
import { getAuthorizationHeader } from '../Api/helpers'

export const authSso = (
  vendor: SsoVendor,
  sso: string,
  sig: string,
  jwt?: string
) => {
  return ApiInterceptor.post<SsoAuthLoginResponse>(
    '/api/auth-sso',
    {
      vendor,
      sso,
      sig
    },
    {
      headers: getAuthorizationHeader(jwt)
    }
  )
}

export const verifySsoConnectPayload = (
  vendor: SsoVendor,
  sso: string,
  sig: string
) => {
  return ApiInterceptor.post<SsoAuthVerificationResponse>(
    '/api/auth-sso/verify',
    {
      vendor,
      sso,
      sig
    },
    {
      headers: {
        'Content-Type': 'application/vnd.sso.verifyPayload.v1+json'
      }
    }
  )
}
