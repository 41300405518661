import React, { useCallback, useMemo, useState } from 'react'
import { Formik } from 'formik'
import { HorizontalView } from '../Layout'
import { FormActions, FormTitle, HiddenInputSubmit } from '../Form/FormLayout'
import { Logo } from '../Logo'
import Box from '@mui/material/Box'
import { FormError, InputField } from '../Form'
import { Button } from '@mui/material'
import { Link } from '@mui/material'
import styled from 'styled-components'
import { LoginTextField } from './LoginTextField'
import { AuthPage } from '../Auth/AuthPage'

export interface LoginProps {
  handleLogin: (username, password) => void
  title?: string
  subTitle?: JSX.Element | string
  customContent?: JSX.Element | string
  customError?: JSX.Element | string
}

export function GenericLoginPage({
  handleLogin,
  title = 'Zaloguj się',
  subTitle,
  customContent,
  customError
}: LoginProps) {
  const [loading, setLoading] = useState(false)
  const [networkError, setNetworkError] = useState<string>(null)
  const [wasValidated, setWasValidated] = useState(false)
  const error = useMemo(
    () => networkError || customError,
    [networkError, customError]
  )

  const submit = useCallback(
    async ({ username, password }) => {
      setNetworkError(null)
      setLoading(true)

      try {
        await handleLogin(username, password)
        setLoading(false)
      } catch (e) {
        if (e.response?.status === 401) {
          setNetworkError('Nie udało sie zalogować - błędny login lub hasło')
        } else {
          setNetworkError('Nie udało sie zalogować - nieokreślony błąd')
        }
      } finally {
        setLoading(false)
      }
    },
    [handleLogin]
  )

  const validate = useCallback(
    ({ username, password }) => {
      if (!wasValidated) {
        setWasValidated(true)
      }

      const errors: Record<string, string> = {}

      if (!username) {
        errors.username = 'Podaj adres email'
      }

      if (!password) {
        errors.password = 'Podaj hasło'
      }

      return errors
    },
    [wasValidated]
  )

  return (
    <Formik
      initialValues={{
        username: '',
        password: ''
      }}
      onSubmit={submit}
      validate={validate}
      validateOnChange={wasValidated}
      validateOnBlur={wasValidated}
    >
      {({ handleSubmit, submitForm }) => (
        <AuthPage title={title}>
          {customContent || (
            <Form onSubmit={handleSubmit}>
              <HiddenInputSubmit />
              <HorizontalView justify="center">
                <Logo light size={220} />
              </HorizontalView>
              <Box mt={5}>
                <FormTitle>{title}</FormTitle>
              </Box>
              {subTitle}
              <InputField
                autoFocus
                name="username"
                placeholder="Email / Nazwa użytkownika"
                fullWidth
                required
                InputComponent={LoginTextField}
              />
              <InputField
                name="password"
                type="password"
                placeholder="Hasło"
                fullWidth
                required
                InputComponent={LoginTextField}
              />
              <FormActions>
                {!!error && (
                  <Box mt={2}>
                    <FormError>{error}</FormError>
                  </Box>
                )}
                <Button
                  sx={{ mt: 2, mb: 1 }}
                  disabled={loading}
                  onClick={submitForm}
                  color="primary"
                >
                  {loading ? 'Logowanie...' : 'Zaloguj się'}
                </Button>
                <Box mt={1}>
                  <Link
                    href={`${process.env.REACT_APP_ADMIN_URL}/reset-password`}
                  >
                    Nie pamiętasz hasła?
                  </Link>
                </Box>
              </FormActions>
            </Form>
          )}
        </AuthPage>
      )}
    </Formik>
  )
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;
`
