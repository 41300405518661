export enum RouteNames {
  // Login / Auth
  Login = '/login',
  TokenAuth = '/token-auth/:token',

  // Home page
  Home = '/home',

  // Payments
  Payments = '/payments/*',
  PaymentsCreatorPolish = '/platnosci',
  AdminPayments = '/admin/*',
  AdminPaymentOrdersList = '/orders',
  AdminPaymentConfig = '/config',
  AdminPaymentConfigList = '/config/list',
  AdminPaymentWizard = '/config/wizard',
  AdminPaymentPayUSettings = '/settings-payu',
  AdminPaymentP24USettings = '/settings-p24',
  AccountPayments = '/account/*',
  AccountPaymentCreator = '/create',
  AccountPaymentOrdersList = '/orders',
  AccountPaymentOrderDetails = '/orders/:orderId',

  // Super Admin
  SuperAdmin = '/super-admin',

  // Club
  Club = '/club/*',
  ClubSettings = '/settings',

  // Ranges
  Ranges = '/ranges/*',
  RangesList = '/list',
  RangeDetails = '/:rangeId/*',
  RangeInfo = '/info',
  RangeOpeningHours = '/opening-hours',
  RangeLanes = '/lanes',

  // Calendar
  Calendar = '/calendar',
  CalendarAuthRedirect = '/calendar-auth',

  // SSO Auth
  Sso = '/sso/*',
  SsoForum = '/forum',
  SsoCompetition = '/competition',
  SsoSignup = '/signup',

  // Bookings
  Bookings = '/bookings/*',
  BookingsList = '/my-bookings',
  BookingsRanges = '/range',
  BookingsRange = '/range/:rangeId',
  BookingsRangeLane = '/range/:rangeId/:laneId',
  BookingsManager = '/manager',
  BookingsManagerList = '/manager/:rangeId',
  BookingsSettings = '/settings',
  BookingsOrder = '/:orderId',

  // CSWD
  CertificateVerification = '/cswd',

  // Documents
  Documents = '/documents',

  // Merge Accounts
  MergeAccount = '/merge-account/*',
  MergeAccountSuccess = '/success',
  MergeAccountToken = '/:token'
}

export enum RouteLinks {
  // Login / Auth
  Login = '/login',
  TokenAuth = '/token-auth/:token',

  // Home page
  Home = '/home',

  // Payments
  Payments = '/payments',
  PaymentsCreatorPolish = '/platnosci',
  AdminPayments = '/payments/admin',
  AdminPaymentOrdersList = '/payments/admin/orders',
  AdminPaymentConfig = '/payments/admin/config',
  AdminPaymentConfigList = '/payments/admin/config/list',
  AdminPaymentWizard = '/payments/admin/config/wizard',
  AdminPaymentPayUSettings = '/payments/admin/settings-payu',
  AdminPaymentP24Settings = '/payments/admin/settings-p24',
  AccountPayments = '/payments/account',
  AccountPaymentCreator = '/payments/account/create',
  AccountPaymentOrdersList = '/payments/account/orders',
  AccountPaymentOrderDetails = '/payments/account/orders/:orderId',

  // Super Admin
  SuperAdmin = '/super-admin',

  // Club
  Club = '/club',
  ClubSettings = '/club/settings',

  // Ranges
  Ranges = '/club/ranges',
  RangesList = '/club/ranges/list',
  RangeDetails = '/club/ranges/:rangeId',
  RangeInfo = '/club/ranges/:rangeId/info',
  RangeOpeningHours = '/club/ranges/:rangeId/opening-hours',
  RangeLanes = '/club/ranges/:rangeId/lanes',

  // Calendar
  Calendar = '/club/calendar',
  CalendarAuthRedirect = '/club/calendar-auth',

  // SSO Auth
  Sso = '/sso',
  SsoForum = '/sso/forum',
  SsoCompetition = '/sso/competition',
  SsoSignup = '/sso/signup',

  // Bookings
  Bookings = '/bookings',
  BookingsList = '/bookings/my-bookings',
  BookingsRanges = '/bookings/range',
  BookingsRange = '/bookings/range/:rangeId',
  BookingsRangeLane = '/bookings/range/:rangeId/:laneId',
  BookingsManager = '/bookings/manager',
  BookingsManagerList = '/bookings/manager/:rangeId',
  BookingsSettings = '/bookings/settings',
  BookingsOrder = '/bookings/:orderId',

  // CSWD
  CertificateVerification = '/cswd',

  // Documents
  Documents = '/documents',

  // Merge Accounts
  MergeAccount = '/merge-account',
  MergeAccountSuccess = '/merge-account/success',
  MergeAccountToken = '/merge-account/:token'
}
