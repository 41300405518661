import { View } from '../../../Layout'
import { TextRegular } from '../../../Text/TextRegular'
import { Box, InputAdornment } from '@mui/material'
import React, { useEffect, useMemo } from 'react'
import {
  latestFeeConfigSelector,
  formValuesSelector,
  useGoToNextStep,
  useSetFormValues
} from './duck'
import { useSelector } from 'react-redux'
import { CurrentStepLabel } from './CurrentStepLabel'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { FormError } from '../../../Form'
import { feesDuck } from '../../fee-configs.duck'
import { NetworkRequestStatus } from '../../../../common/network-state'
import { TextField } from '@mui/material'
import { Field, Formik } from 'formik'
import { Loading } from '../../../Loading'
import { CommonWizardConfig } from './duck/types'
import { WizardButtons } from './WizardButtons'
import { HelpTooltip } from '../../../HelpTooltip/HelpTooltip'
import styled from 'styled-components'
import { DateTime } from 'luxon'

export const CommonConfigStep = () => {
  const goToNextStep = useGoToNextStep()
  const formValues = useSelector(formValuesSelector)
  const setCommonFormValues = useSetFormValues('common')
  const configsLoaded =
    useSelector(feesDuck.selectors.status) === NetworkRequestStatus.LOADED
  const latestConfig = useSelector(latestFeeConfigSelector)
  const minStartDate = useMemo<DateTime>(
    () =>
      latestConfig
        ? DateTime.fromFormat(latestConfig.startTime, 'yyyy-MM-dd').plus({
            day: 1
          })
        : DateTime.now().set({ hour: 0, minute: 0, second: 0 }),
    [latestConfig]
  )

  const handleNextStep = (values) => {
    setCommonFormValues(values)
    goToNextStep()
  }

  const validate = ({
    startTime,
    paymentDelayDays
  }: CommonWizardConfig): { [P in keyof CommonWizardConfig]?: string } => {
    const errors: { [P in keyof CommonWizardConfig]?: string } = {}

    if (!startTime) {
      errors.startTime = 'Podaj datę'
    } else if (startTime.toMillis() < minStartDate.toMillis()) {
      errors.startTime = 'Data zbyt wczesna'
    }

    if (!paymentDelayDays && paymentDelayDays !== 0) {
      errors.paymentDelayDays = 'Podaj ilość dni'
    }

    return errors
  }

  useEffect(() => {
    if (configsLoaded && !formValues.common.startTime) {
      setCommonFormValues({
        ...formValues.common,
        startTime: latestConfig
          ? DateTime.fromFormat(latestConfig.startTime, 'yyyy-MM-dd').plus({
              day: 1
            })
          : DateTime.now()
      })
    }
  }, [configsLoaded, formValues, setCommonFormValues, latestConfig])

  if (!configsLoaded) {
    return (
      <Box mt={3}>
        <Loading size="small" />
      </Box>
    )
  }

  return (
    <Formik<CommonWizardConfig>
      initialValues={formValues.common}
      onSubmit={handleNextStep}
      validate={validate}
    >
      {({ handleSubmit }) => (
        <View>
          <CurrentStepLabel />

          <Box mt={3}>
            <Box mb={2}>
              <TextRegular strong>Ustawienia ogólne</TextRegular>
            </Box>
          </Box>

          <Box mt={3}>
            <Field
              name="startTime"
              children={({ field, form }) => (
                <DatePickerStyled
                  {...field}
                  onChange={(value) => form.setFieldValue(field.name, value)}
                  format="dd/MM/yyyy"
                  minDate={minStartDate}
                  variant="inline"
                  inputVariant="outlined"
                  label="Data rozpoczęcia"
                  invalidDateMessage={<FormError>Uzupełnij datę</FormError>}
                  InputAdornmentProps={{ position: 'start' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <HelpTooltip>
                          Od kiedy ma obowiązywać ta konfiguracja?
                        </HelpTooltip>
                      </InputAdornment>
                    )
                  }}
                  minDateMessage={
                    <FormError>
                      Data nie może być wcześniejsza niż{' '}
                      {minStartDate.toFormat('yyyy-MM-dd')}
                    </FormError>
                  }
                />
              )}
            />
          </Box>
          <Box mt={3}>
            <Field
              name="paymentDelayDays"
              children={({ field, meta: { touched, error } }) => (
                <TextFieldStyled
                  {...field}
                  error={!!touched && !!error}
                  helperText={touched && error ? error : null}
                  type="number"
                  label="Płatność przeterminowana (dni)"
                  inputProps={{ min: '0' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <HelpTooltip>
                          Podaj ilość dni, jaka może upłynąć po terminie opłaty
                          składki, zanim ponownie zostane naliczone wpisowe.
                        </HelpTooltip>
                      </InputAdornment>
                    )
                  }}
                />
              )}
            />
          </Box>
          <WizardButtons handleSubmit={handleSubmit} />
        </View>
      )}
    </Formik>
  )
}

const DatePickerStyled = styled(DatePicker)`
  width: 300px;
`

const TextFieldStyled = styled(TextField)`
  width: 300px;
`
