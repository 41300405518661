import { useDispatch, useSelector, useStore } from 'react-redux'
import { useCallback } from 'react'
import {
  MembershipFeeWizardActionType,
  MembershipFeeWizardSteps
} from './enums'
import { currentStepSelector, formValuesSelector } from './selectors'
import { FeeWizardForm, MembershipFeeWizardStateForRoot } from './types'

export const useGoToNextStep = () => {
  const dispatch = useDispatch()
  const currentStep = useSelector(currentStepSelector)
  const store = useStore<MembershipFeeWizardStateForRoot>()

  return useCallback(() => {
    const getStep = () => {
      const state = store.getState()
      const formValues = formValuesSelector(state)

      switch (currentStep) {
        case MembershipFeeWizardSteps.COMMON:
          return MembershipFeeWizardSteps.ENTRY_FEE_CONFIG
        case MembershipFeeWizardSteps.ENTRY_FEE_CONFIG:
          if (formValues.entryFeeConfig.ageApplies) {
            return MembershipFeeWizardSteps.ENTRY_FEE_AGE_RANGES
          }
          return MembershipFeeWizardSteps.ENTRY_FEE_UNITS
        case MembershipFeeWizardSteps.ENTRY_FEE_AGE_RANGES:
          return MembershipFeeWizardSteps.ENTRY_FEE_UNITS
        case MembershipFeeWizardSteps.ENTRY_FEE_UNITS:
          return MembershipFeeWizardSteps.MEMBER_FEE_CONFIG
        case MembershipFeeWizardSteps.MEMBER_FEE_CONFIG:
          if (formValues.memberFeeConfig.ageApplies) {
            return MembershipFeeWizardSteps.MEMBER_FEE_AGE_RANGES
          }
          return MembershipFeeWizardSteps.MEMBER_FEE_PERIODS
        case MembershipFeeWizardSteps.MEMBER_FEE_AGE_RANGES:
          return MembershipFeeWizardSteps.MEMBER_FEE_PERIODS
        case MembershipFeeWizardSteps.MEMBER_FEE_PERIODS:
          return MembershipFeeWizardSteps.MEMBER_FEE_UNITS
        case MembershipFeeWizardSteps.MEMBER_FEE_UNITS:
          return MembershipFeeWizardSteps.SUMMARY
        default:
          return MembershipFeeWizardSteps.ENTRY_FEE_CONFIG
      }
    }

    dispatch({
      type: MembershipFeeWizardActionType.SET_CURRENT_STEP,
      step: getStep()
    })
  }, [dispatch, currentStep, store])
}

export const useGoToPreviousStep = () => {
  const dispatch = useDispatch()
  const currentStep = useSelector(currentStepSelector)
  const store = useStore<MembershipFeeWizardStateForRoot>()

  return useCallback(() => {
    const getStep = () => {
      const state = store.getState()
      const formValues = formValuesSelector(state)

      switch (currentStep) {
        case MembershipFeeWizardSteps.SUMMARY:
          return MembershipFeeWizardSteps.MEMBER_FEE_UNITS
        case MembershipFeeWizardSteps.MEMBER_FEE_UNITS:
          return MembershipFeeWizardSteps.MEMBER_FEE_PERIODS
        case MembershipFeeWizardSteps.MEMBER_FEE_PERIODS:
          if (formValues.memberFeeConfig.ageApplies) {
            return MembershipFeeWizardSteps.MEMBER_FEE_AGE_RANGES
          }
          return MembershipFeeWizardSteps.MEMBER_FEE_CONFIG
        case MembershipFeeWizardSteps.MEMBER_FEE_AGE_RANGES:
          return MembershipFeeWizardSteps.MEMBER_FEE_CONFIG
        case MembershipFeeWizardSteps.MEMBER_FEE_CONFIG:
          return MembershipFeeWizardSteps.ENTRY_FEE_UNITS
        case MembershipFeeWizardSteps.ENTRY_FEE_UNITS:
          if (formValues.entryFeeConfig.ageApplies) {
            return MembershipFeeWizardSteps.ENTRY_FEE_AGE_RANGES
          }
          return MembershipFeeWizardSteps.ENTRY_FEE_CONFIG
        case MembershipFeeWizardSteps.ENTRY_FEE_AGE_RANGES:
          return MembershipFeeWizardSteps.ENTRY_FEE_CONFIG
        case MembershipFeeWizardSteps.ENTRY_FEE_CONFIG:
          return MembershipFeeWizardSteps.COMMON
        case MembershipFeeWizardSteps.COMMON:
          throw new Error('Cannot move back from first step')
        default:
          return MembershipFeeWizardSteps.ENTRY_FEE_CONFIG
      }
    }

    dispatch({
      type: MembershipFeeWizardActionType.SET_CURRENT_STEP,
      step: getStep()
    })
  }, [dispatch, currentStep, store])
}

export const useSetFormValues = <Key extends keyof FeeWizardForm>(key: Key) => {
  const dispatch = useDispatch()

  return useCallback(
    (payload: FeeWizardForm[Key]) => {
      dispatch({
        type: MembershipFeeWizardActionType.SET_FORM_VALUES,
        payload,
        key
      })
    },
    [dispatch, key]
  )
}

export const useResetEntryFeeWizard = () => {
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch({
      type: MembershipFeeWizardActionType.RESET
    })
  }, [dispatch])
}
