import { Chip, InputLabel } from '@mui/material'
import Box from '@mui/material/Box'
import { FormError } from '../../Form'
import React from 'react'
import { useField } from 'formik'
import { AvailableLanesDTO } from '../types'

export interface LaneSelectorProps {
  name: string
  availableLane: AvailableLanesDTO
}

export function LaneSelector({ name, availableLane }: LaneSelectorProps) {
  const [field, meta, helpers] = useField(name)

  return (
    <Box mt={3}>
      <InputLabel>Numer stanowiska</InputLabel>
      <Box mt={1} display="flex">
        {availableLane?.availableLaneNumbers.map((laneNumber) => (
          <Box key={laneNumber} mr={1}>
            <Chip
              color={field.value === laneNumber ? 'primary' : 'default'}
              clickable
              label={laneNumber}
              onClick={() => helpers.setValue(laneNumber)}
            />
          </Box>
        ))}
      </Box>
      {meta.touched && !!meta.error && (
        <Box mt={1}>
          <FormError>{meta.error}</FormError>
        </Box>
      )}
    </Box>
  )
}
