import React, { ChangeEvent, FC, useCallback, useMemo, useState } from 'react'
import { View } from '../../../Layout'
import { CurrentStepLabel } from './CurrentStepLabel'
import { Box, Button, TextField } from '@mui/material'
import { useSelector } from 'react-redux'
import { formValuesSelector, useGoToNextStep, useSetFormValues } from './duck'
import { TextRegular } from '../../../Text/TextRegular'
import { FormError } from '../../../Form'
import styled from 'styled-components'
import { colors } from '../../../../styles/variables'
import { IoMdClose } from 'react-icons/io'
import { WizardButtons } from './WizardButtons'
import { BiPlus } from 'react-icons/bi'

export const EntryFeeAgeRangesStep = () => {
  const formValues = useSelector(formValuesSelector)
  const ages = formValues.entryFeeAgeRanges
  const sortedAges = useMemo(
    () => [...ages].sort((a, b) => (a > b ? 1 : a < b ? -1 : 0)),
    [ages]
  )
  const [touched, setTouched] = useState(false)
  const goToNextStep = useGoToNextStep()
  const setAgeRanges = useSetFormValues('entryFeeAgeRanges')
  const [inputValue, setInputValue] = useState('')

  const submit = useCallback(() => {
    setTouched(true)
    if (ages.length > 0) {
      goToNextStep()
    }
  }, [ages, goToNextStep])
  const addAge = useCallback(() => {
    const numberValue = +inputValue

    if (numberValue > 0) {
      if (!ages.includes(numberValue)) {
        setAgeRanges([...ages, numberValue])
      }

      setInputValue('')
    }
  }, [ages, inputValue, setAgeRanges])

  const deleteAge = useCallback(
    (ageToDelete: number) => {
      setAgeRanges([...ages.filter((age) => age !== ageToDelete)])
    },
    [ages, setAgeRanges]
  )

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.currentTarget.value)
  }

  return (
    <View>
      <CurrentStepLabel />

      <Box mt={3} mb={3}>
        Określ przedziały wiekowe.
      </Box>

      <TextRegular>
        Wpisz wiek, który będzie wyznaczał każdy przedział. Możesz utworzyć
        dowolną ilość przedziałów.
      </TextRegular>
      <Box mt={0.5}>
        <TextRegular variant="small">
          Np. jeśli przewidujesz składki dla osób do 18 roku życia oraz powyżej,
          wpisz "18" i kliknij "Dodaj".
        </TextRegular>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <TextField
          placeholder="np. 18"
          type="number"
          inputProps={{ min: 1 }}
          onChange={handleInputChange}
          value={inputValue}
        />
        <Button size="small" onClick={addAge} startIcon={<BiPlus />}>
          Dodaj
        </Button>
      </Box>

      {ages.length > 0 && (
        <Box mt={3} display="flex">
          {sortedAges.map((age) => (
            <AgeBadge key={age} age={age} onDelete={deleteAge} />
          ))}
        </Box>
      )}

      {ages.length > 0 && (
        <Box mt={3}>
          <TextRegular>Zostaną utworzone przedziały wiekowe:</TextRegular>
          <Box display="flex">
            {sortedAges.map((age, i) => (
              <AgeRange key={i}>
                {ages[i - 1] ? ages[i - 1] : '0'} - {ages[i]}
              </AgeRange>
            ))}
            <AgeRange>{sortedAges[ages.length - 1]} - &infin;</AgeRange>
          </Box>
        </Box>
      )}

      {touched && !ages.length && (
        <Box mt={3}>
          <FormError>Dodaj przynajmniej jedną opcję.</FormError>
        </Box>
      )}

      <WizardButtons handleSubmit={submit} />
    </View>
  )
}

interface AgeBadgeProps {
  age: number
  onDelete: (age: number) => void
}

const AgeBadge: FC<AgeBadgeProps> = ({ age, onDelete }) => {
  const handleClick = useCallback(() => {
    onDelete(age)
  }, [onDelete, age])

  return (
    <Age onClick={handleClick}>
      {age} <DeleteAgeIcon />
    </Age>
  )
}

const Age = styled.div`
  display: flex;
  align-items: center;
  background: ${colors.mainLink};
  color: #fff;
  padding: 12px;
  border-radius: 4px;

  & + & {
    margin-left: 12px;
  }
`

const DeleteAgeIcon = styled(IoMdClose)`
  margin-left: 4px;
`

const AgeRange = styled(TextRegular)`
  display: block;
  background: ${colors.cloud};
  padding: 8px;
  margin-left: 8px;
  margin-top: 8px;
  border-radius: 4px;
`
