import {
  FeeConfigWizard,
  FirstPaymentResolverType,
  FirstPaymentUnitWizard
} from './duck/types'
import { FirstPaymentPeriodDensity, MemberFeePeriodType } from '../../types'
import moment from 'moment'

export const periodDensityOptions = [
  { value: FirstPaymentPeriodDensity.HALF_YEAR, label: 'Pół roku' },
  { value: FirstPaymentPeriodDensity.QUARTER, label: 'Kwartał' },
  { value: FirstPaymentPeriodDensity.MONTH, label: 'Miesiąc' }
]

export const periodWeight = {
  [MemberFeePeriodType.MONTH]: 1,
  [MemberFeePeriodType.QUARTER]: 3,
  [MemberFeePeriodType.HALF_YEAR]: 6,
  [MemberFeePeriodType.YEAR]: 12
}

export const getEffectiveFirstPaymentDensity = (
  density: FirstPaymentPeriodDensity,
  periodType: MemberFeePeriodType
): FirstPaymentPeriodDensity => {
  if (periodWeight[density] > periodWeight[periodType]) {
    return FirstPaymentPeriodDensity[periodType]
  }

  return density
}

export function firstPaymentPeriodDensityLabel(
  density: FirstPaymentPeriodDensity
) {
  switch (density) {
    case FirstPaymentPeriodDensity.QUARTER:
      return 'kwartalny'
    case FirstPaymentPeriodDensity.HALF_YEAR:
      return 'półroczny'
    case FirstPaymentPeriodDensity.MONTH:
      return 'miesięczny'
    default:
      return ''
  }
}

export function firstPaymentPeriodDensityMonthsLabel(
  density: FirstPaymentPeriodDensity,
  index: number,
  periodType: MemberFeePeriodType
) {
  const from = moment()
    .set('month', index * periodWeight[density])
    .format('MMMM')

  if (density === FirstPaymentPeriodDensity.MONTH) {
    return from
  }

  const relativeIndex = getFirstPaymentRelativeIndex(index, periodType, density)
  const maxMonth = periodWeight[periodType]
  const periodPart = (index - relativeIndex) / 2 + 1

  const to = moment()
    .set('month', maxMonth * periodPart - 1)
    .format('MMMM')

  return `${from} - ${to}`
}

export function getFirstPaymentRelativeIndex(
  index: number,
  periodType: MemberFeePeriodType,
  density: FirstPaymentPeriodDensity
) {
  return index % (periodWeight[periodType] / periodWeight[density])
}

export function firstPaymentProportionalAmount(
  amount: string,
  density: FirstPaymentPeriodDensity,
  index: number,
  periodType: MemberFeePeriodType
) {
  if (!amount || isNaN(parseFloat(amount))) {
    return '?'
  }

  const relativeIndex = getFirstPaymentRelativeIndex(index, periodType, density)

  if (relativeIndex === 0) {
    return amount
  }

  const densityDivider = periodWeight[periodType] / periodWeight[density]

  return (
    (parseFloat(amount) / densityDivider) *
    (densityDivider - relativeIndex)
  ).toFixed(0)
}

export function generateFirstPaymentPeriods(
  density: FirstPaymentPeriodDensity,
  periodType: MemberFeePeriodType
): FirstPaymentUnitWizard[] {
  return Array.from(Array(12 / periodWeight[density]).keys()).map((_, i) => {
    const editable =
      getFirstPaymentRelativeIndex(
        i,
        periodType,
        getEffectiveFirstPaymentDensity(density, periodType)
      ) !== 0

    return {
      amount: '',
      periodIndex: i,
      editable
    }
  })
}

export function calculateFirstPaymentUnitPrice(
  config: FeeConfigWizard,
  unit: FirstPaymentUnitWizard,
  parentPrice: string,
  index: number,
  density: FirstPaymentPeriodDensity,
  periodType: MemberFeePeriodType
) {
  if (
    config.firstPaymentPeriodResolver === FirstPaymentResolverType.CUSTOM &&
    !unit.editable
  ) {
    return parentPrice
  } else if (
    config.firstPaymentPeriodResolver === FirstPaymentResolverType.PROPORTIONAL
  ) {
    return firstPaymentProportionalAmount(
      parentPrice,
      density,
      index,
      periodType
    )
  }

  return null
}

export function getLowestWeightPeriodType(
  periods: MemberFeePeriodType[]
): MemberFeePeriodType {
  return periods.reduce(
    (lowest, period) =>
      periodWeight[period] < periodWeight[lowest] ? period : lowest,
    periods[0]
  )
}
