import { FeeWizardForm } from './duck/types'
import { createMembershipFeeConfig } from '../../payment-service'
import { v4 } from 'uuid'

export const validateAmount = (amount: string): string | null => {
  if (amount === '') {
    return 'Uzupełnij składkę'
  }

  const num = parseFloat(amount)

  if (num < 0) {
    return 'Składka nie może być ujemna'
  }

  if (isNaN(num)) {
    return 'Niepoprawna liczba'
  }

  return null
}

export function saveMembershipConfig(values: FeeWizardForm) {
  const {
    enableFirstPaymentPeriod: entryEnableFPP,
    firstPaymentPeriodResolver: entryResolver,
    ...entryFeeConfig
  } = values.entryFeeConfig
  const {
    enableFirstPaymentPeriod: memberEnableFPP,
    firstPaymentPeriodResolver: memberResolver,
    ...memberFeeConfig
  } = values.memberFeeConfig

  return createMembershipFeeConfig({
    startTime: values.common.startTime.toFormat('yyyy-MM-dd'),
    paymentDelayDays: values.common.paymentDelayDays,
    entryFeeConfig: {
      ...entryFeeConfig,
      firstPaymentPeriodDensity: entryEnableFPP
        ? entryFeeConfig.firstPaymentPeriodDensity
        : null
    },
    memberFeeConfig: {
      ...memberFeeConfig,
      firstPaymentPeriodDensity: memberEnableFPP
        ? memberFeeConfig.firstPaymentPeriodDensity
        : null,
      periodTypes: values.memberFeePeriods
    },
    entryFeeUnits: values.entryFeeUnits.map((unit) => ({
      ...unit,
      id: v4(),
      amount: parseFloat(unit.amount),
      firstPaymentUnits: entryEnableFPP
        ? unit.firstPaymentUnits.map((first) => ({
            periodIndex: first.periodIndex,
            amount: parseFloat(first.amount)
          }))
        : null
    })),
    memberFeeUnits: values.memberFeeUnits.map((unit) => ({
      ...unit,
      id: v4(),
      amount: parseFloat(unit.amount),
      firstPaymentUnits: memberEnableFPP
        ? unit.firstPaymentUnits.map((first) => ({
            periodIndex: first.periodIndex,
            amount: parseFloat(first.amount)
          }))
        : null
    }))
  })
}
