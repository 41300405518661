import React, { ComponentType, FC } from 'react'
import { FieldValidator, useField } from 'formik'
import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField'
import Box from '@mui/material/Box'
import styled from 'styled-components'

export interface InputFieldProps
  extends Omit<OutlinedTextFieldProps, 'variant'> {
  validate?: FieldValidator
  noMargin?: boolean
  variant?: OutlinedTextFieldProps['variant']
  InputComponent?: ComponentType
}

export const InputField: FC<InputFieldProps> = ({
  name,
  validate,
  variant = 'outlined',
  noMargin,
  InputComponent = TextFieldStyled,
  onChange,
  onBlur,
  ...props
}) => {
  const [
    { onChange: onChangeField, onBlur: onBlurField, ...field },
    { touched, error }
  ] = useField({ name, validate })
  const hasError = !!touched && !!error

  const handleChange = (e) => {
    onChangeField(e)
    if (onChange) {
      onChange(e)
    }
  }
  const handleBlur = (e) => {
    onBlurField(e)
    if (onBlur) {
      onBlur(e)
    }
  }

  return (
    <Box display="flex" justifyContent="stretch" mt={noMargin ? 0 : 2}>
      <InputComponent
        className={!!touched && !!error ? 'invalid' : ''}
        error={hasError}
        helperText={!!hasError && error}
        variant={variant}
        onChange={handleChange}
        onBlur={handleBlur}
        {...field}
        {...props}
      />
    </Box>
  )
}

const TextFieldStyled = styled(TextField)`
  flex-grow: 1;
`
