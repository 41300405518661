import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { AccountStatus } from '../Account/enums'
import { AccountNotActive } from './AccountNotActive'
import { HorizontalView } from '../Layout'
import { Logo } from '../Logo'
import Box from '@mui/material/Box'
import { Button } from '@mui/material'
import { Link } from '../Link'
import { jwtSelector, useLogout } from '../Auth/auth.duck'
import { useToggle } from '../../hooks/useToggle'
import { RedirectToSsoVendor } from './RedirectToSsoVendor'
import { SsoUsernameForm } from './SsoUsernameForm'
import { Loading } from '../Loading'
import { TextRegular } from '../Text/TextRegular'
import { SsoVendor } from './enums'
import {
  NetworkRequestStatus,
  useNetworkRequest
} from '../../common/network-state'
import { fetchAccount } from '../Account/account.service'
import { getAuthorizationHeader } from '../Api/helpers'

export interface AccountViewProps {
  vendor: SsoVendor
  vendorUrl: string
  proceedText: string
  redirectSoonText: string
  temporaryJwt: string | null
}

export function AccountView({
  vendor,
  vendorUrl,
  proceedText,
  redirectSoonText,
  temporaryJwt
}: AccountViewProps) {
  const storageJwt = useSelector(jwtSelector)
  const [fetch, accountResponse, status] = useNetworkRequest(
    () =>
      fetchAccount({
        headers: getAuthorizationHeader(temporaryJwt || storageJwt)
      }),
    [storageJwt, temporaryJwt]
  )
  const [shouldRedirectToVendor, redirectToVendor] = useToggle(!!temporaryJwt)
  const logOut = useLogout()

  useEffect(() => {
    if (storageJwt || temporaryJwt) {
      fetch()
    }
  }, [fetch, storageJwt, temporaryJwt])

  if (!storageJwt && !temporaryJwt) {
    return null
  }

  if (status === NetworkRequestStatus.LOADING || !accountResponse) {
    return (
      <Box display="flex" justifyContent="center">
        <Loading />
      </Box>
    )
  }

  if (accountResponse.data.status === AccountStatus.NEW) {
    return <AccountNotActive />
  }

  if (shouldRedirectToVendor) {
    if (!!accountResponse.data.login) {
      return (
        <Box mt={3}>
          <RedirectToSsoVendor
            vendorUrl={vendorUrl}
            vendor={vendor}
            redirectSoonText={redirectSoonText}
            jwt={temporaryJwt || storageJwt}
          />
        </Box>
      )
    }

    return (
      <SsoUsernameForm jwt={temporaryJwt || storageJwt} onSuccess={fetch} />
    )
  }

  return (
    <>
      <HorizontalView justify="center">
        <Logo light size={220} />
      </HorizontalView>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box mt={4} mb={2}>
          Jesteś zalogowany jako{' '}
          <TextRegular strong>
            {accountResponse.data.login || accountResponse.data.email}
          </TextRegular>
          .
        </Box>
        <Button color="primary" variant="contained" onClick={redirectToVendor}>
          {proceedText}
        </Button>
        <Box mt={2}>
          <Link onClick={logOut}>Wyloguj się</Link>
        </Box>
      </Box>
    </>
  )
}
