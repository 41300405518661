import { HorizontalView } from '../Layout'
import { Logo } from '../Logo'
import Box from '@mui/material/Box'
import React from 'react'
import { Link } from '../Link'
import { useLogout } from '../Auth/auth.duck'
import { AccountStatus } from '../Account/enums'

// import Button from '@mui/material/Button'

export function AccountNotActive() {
  const logOut = useLogout()
  //   TODO: implement
  // const sendActivationLink = () => {}

  return (
    <>
      <HorizontalView justify="center">
        <Logo light size={220} />
      </HorizontalView>
      <Box mt={2}>
        Twoje konto nie zostało aktywowane. Odbierz email z kodem aktywacyjnym.
      </Box>
      {/*<Box mt={4}>*/}
      {/*  Nie dotarł do Ciebie email aktywacyjny? Kliknij poniżej, żeby wysłać*/}
      {/*  nowy link na Twój adres email.*/}
      {/*  <Button onClick={sendActivationLink}>Wyślij link aktywacyjny</Button>*/}
      {/*</Box>*/}
      <Box mt={2}>
        <Link onClick={logOut}>Wyloguj się</Link>
      </Box>
    </>
  )
}
