import { TextRegular } from '../../../Text/TextRegular'
import { Box, Button } from '@mui/material'
import React, { FC, useCallback, useEffect, useState } from 'react'
import {
  PeriodRangeLabel,
  VariantContainer,
  VariantFooter,
  VariantInner,
  VariantTitle
} from './PaymentOfferCard'
import { MembershipApplicableConfigDTO } from '../../types'
import { PendingIcon } from '../PaymentOrderDetails/OrderStatus/PendingIcon'

export interface LicenseFeeOfferCardProps {
  config: MembershipApplicableConfigDTO
  year: number
  loading: boolean
  onPay: (year: number) => void
}

export const LicenseFeeOfferCard: FC<LicenseFeeOfferCardProps> = ({
  config,
  year,
  loading,
  onPay
}) => {
  const [selfLoading, setSelfLoading] = useState(false)
  const handlePayButton = useCallback(() => {
    setSelfLoading(true)
    onPay(year)
  }, [year, onPay])
  const licensePrice = 75
  const provisionAmount = (config.provisionPercent * licensePrice) / 100
  const totalAmount = licensePrice + provisionAmount

  useEffect(() => {
    if (!loading && selfLoading) {
      setSelfLoading(false)
    }
  }, [loading, selfLoading])

  return (
    <VariantContainer>
      <VariantInner>
        <VariantTitle>Licencja sportowa {year}</VariantTitle>
        <PeriodRangeLabel>cały rok</PeriodRangeLabel>
      </VariantInner>
      <VariantFooter>
        <TextRegular variant="xlarge" strong>
          {totalAmount.toFixed(0)} zł
        </TextRegular>
        {config.provisionPercent > 0 && (
          <Box mt={1} textAlign="right">
            <Box>
              <i>w tym:</i>
            </Box>
            prowizja: ({config.provisionPercent}%): {provisionAmount.toFixed(0)}{' '}
            zł
          </Box>
        )}
        <Button
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={handlePayButton}
          sx={{ mt: 2 }}
        >
          {selfLoading ? <PendingIcon size={16} /> : 'Zapłać'}
        </Button>
      </VariantFooter>
    </VariantContainer>
  )
}
