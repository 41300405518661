import { AxiosError as AxiosErrorOriginal } from 'axios'
import { AxiosCallback, AxiosError, Callback, NetworkErrorType } from './types'
import { ErrorElement } from '../../components/Api/types'
import { ErrorType } from '../../components/Api/enums'
import { captureException } from '@sentry/react'

export class NetworkError<Response> {
  private actionsInvoked = false

  constructor(private error: NetworkErrorType<Response>) {}

  public static fromError<ResponseT>(
    error: NetworkErrorType<ResponseT>
  ): NetworkError<ResponseT> {
    return new NetworkError(error)
  }

  public isError(): boolean {
    return this.error instanceof Error
  }

  public hasResponse(): boolean {
    return !!this.axiosError?.response
  }

  public hasRequest(): boolean {
    return !!this.axiosError?.request
  }

  public on400(callback?: AxiosCallback<Response>) {
    if (this.hasResponse() && this.axiosError.response.status === 400) {
      this.invokeAxiosAction(callback)
    }

    return this
  }

  public on401(callback?: AxiosCallback<Response>) {
    if (this.hasResponse() && this.axiosError.response.status === 401) {
      this.invokeAxiosAction(callback)
    }

    return this
  }

  public on4xx5xx(callback?: AxiosCallback<Response>) {
    if (this.hasResponse() && this.axiosError.response.status >= 400) {
      this.invokeAxiosAction(callback)
    }

    return this
  }

  public on404(callback?: AxiosCallback<Response>) {
    if (this.hasResponse() && this.axiosError.response.status === 404) {
      this.invokeAxiosAction(callback)
    }

    return this
  }

  public onErrorResponse(
    errorType: ErrorType,
    callback?: AxiosCallback<Response>
  ) {
    if (
      this.hasResponse() &&
      this.axiosError.response.data.hasOwnProperty('errorType')
    ) {
      if (
        (this.axiosError.response.data as unknown as ErrorElement).errorType ===
        errorType
      ) {
        this.invokeAxiosAction(callback)
      }
    }

    return this
  }

  public onNoApi(callback?: Callback<Response>) {
    if (
      !this.hasResponse() &&
      this.hasRequest() &&
      this.axiosError.request.status === 0
    ) {
      this.invokeAction(callback)
    }

    return this
  }

  public onNonNetworkError(callback?: Callback<Response>) {
    if (this.axiosError === null) {
      this.invokeAction(callback)
    }

    return this
  }

  public else(callback: Callback<Response>) {
    if (!this.actionsInvoked) {
      callback(this.error)
    }
  }

  public elseReportToSentry() {
    if (!this.actionsInvoked) {
      captureException(this.error)
    }

    return this
  }

  private invokeAxiosAction(callback?: AxiosCallback<Response>) {
    this.actionsInvoked = true
    if (callback) {
      callback(this.axiosError?.response?.data, this.axiosError)
    }
  }

  private invokeAction(callback?: Callback<Response>) {
    this.actionsInvoked = true
    if (callback) {
      callback(this.error)
    }
  }

  private get axiosError(): AxiosError<Response> | null {
    if ((this.error as AxiosErrorOriginal<Response>).isAxiosError) {
      return this.error as AxiosError<Response>
    }

    return null
  }
}
