import React, { useEffect, useState } from 'react'
import { HorizontalView, PagePaper } from '../../Layout'
import {
  NetworkRequestStatus,
  useNetworkRequest
} from '../../../common/network-state'
import { DateTime } from 'luxon'
import {
  Box,
  Link,
  Table,
  TableBody,
  TableCell,
  TableRow,
  useMediaQuery,
  useTheme
} from '@mui/material'
import Helmet from 'react-helmet'
import { BookingTile } from '../BookingTile'
// import { DayRenderer } from './DayRenderer'
import { useParams } from 'react-router'
import { getRange } from '../../Ranges/ranges.service'
import { TextRegular } from '../../Text/TextRegular'
import { Loading } from '../../Loading'
import { getBookingsInDateRange } from '../bookings.service'
import { Link as LinkRouter } from 'react-router-dom'
import { RouteLinks } from '../../Routes/RouteNames'
import { IoIosArrowRoundBack } from 'react-icons/io'
import { BookingPaymentOrderDTO } from '../types'
import { ChangeBookingDialog } from './ChangeBookingDialog'
import { DatePicker } from '@mui/x-date-pickers'

export function BookingsManagerList() {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

  const { rangeId } = useParams()

  const [date, setDate] = useState(DateTime.now())
  const [monthDate, setMonthDate] = useState(date)
  const [bookingToChange, setBookingToChange] =
    useState<BookingPaymentOrderDTO>(null)
  const cancelBookingToChange = () => setBookingToChange(null)

  const [fetchRange, range, rangeStatus] = useNetworkRequest(
    () => getRange(rangeId),
    [rangeId]
  )

  const [fetchBookings, bookings, bookingsStatus] = useNetworkRequest(
    () => range?.data && getBookingsInDateRange(range?.data, date, 'day'),
    [date, range?.data]
  )

  const [fetchMonthSummary, monthSummary] = useNetworkRequest(
    () => getBookingsInDateRange(range?.data, monthDate, 'month'),
    [monthDate.toFormat('yyyy-MM'), range?.data]
  )

  const isLoading = [rangeStatus, bookingsStatus].includes(
    NetworkRequestStatus.LOADING
  )

  const pageTitle = range?.data
    ? `Rezerwacje - ${range.data.name}`
    : 'Rezerwacje'

  useEffect(() => {
    fetchRange()
  }, [fetchRange])

  useEffect(() => {
    fetchBookings()
  }, [fetchBookings])

  useEffect(() => {
    fetchMonthSummary()
  }, [fetchMonthSummary])

  return (
    <PagePaper variant={isDesktop ? 'large' : 'normal'}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <HorizontalView align="flex-start" justify="space-between">
        <Box>
          <Link component={LinkRouter} to={RouteLinks.BookingsManager}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                mb: 2
              }}
            >
              <IoIosArrowRoundBack size={24} /> Do listy strzelnic
            </Box>
          </Link>
          <TextRegular size="large">{pageTitle}</TextRegular>
          {isLoading ? (
            <Box mt={4}>
              <Loading size="small" />
            </Box>
          ) : bookings?.data?.length === 0 ? (
            <Box mt={3}>Brak rezerwacji w wybranym dniu.</Box>
          ) : (
            <Table>
              <TableBody>
                {bookings?.data?.map((booking) => (
                  <TableRow key={booking.id}>
                    <TableCell>
                      <BookingTile
                        booking={booking}
                        hideDate
                        hideRangeName
                        onChange={setBookingToChange}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Box>
        <Box>
          <DatePicker
            // @ts-ignore
            label="Data"
            value={date}
            onChange={setDate}
            onMonthChange={setMonthDate}
            format="yyyy-MM-dd"
            sx={{ width: '180px' }}
            // TODO: find a way to fix this
            // renderDay={(day, selectedDate, dayInCurrentMonth, dayComponent) => (
            //   <DayRenderer
            //     day={day}
            //     selectedDate={selectedDate}
            //     dayComponent={dayComponent}
            //     monthSummary={monthSummary?.data}
            //   />
            // )}
          />
        </Box>
      </HorizontalView>
      <ChangeBookingDialog
        booking={bookingToChange}
        onClose={cancelBookingToChange}
        onChange={fetchBookings}
      />
    </PagePaper>
  )
}
