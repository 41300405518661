import React from 'react'
import { PagePaper } from '../Layout'
import { TextRegular } from '../Text/TextRegular'
import Box from '@mui/material/Box'
import { Button } from '@mui/material'
import { RouteLinks } from '../Routes/RouteNames'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

export const Home = () => {
  return (
    <PagePaper>
      <Helmet>
        <title>Moje konto</title>
      </Helmet>
      <TextRegular size="xlarge">Dzień dobry!</TextRegular>

      <Box mt={3}>
        <div>Chcesz opłacić składkę członkowską?</div>
        <Box mt={1}>
          <Button
            component={Link}
            to={RouteLinks.AccountPaymentCreator}
            variant="contained"
            color="primary"
          >
            Opłać składkę
          </Button>
        </Box>
      </Box>
    </PagePaper>
  )
}
