import styled from 'styled-components'
import { HorizontalView } from '../../../../Layout'

export const StatusBadge = styled(HorizontalView)<{
  background?: string
  color?: string
}>`
  margin-top: 24px;
  padding: 8px 16px;
  border-radius: 24px;
  color: ${(p) => (p.color ? p.color : 'inherit')};
  background: ${(p) => (p.background ? p.background : '#fff')};
`
