import { AuthActionType } from './auth.duck'
import { AuthStore } from './AuthStore'
import { configureScope } from '@sentry/react'
import decode from 'jwt-decode'
import { JwtDecoded } from '../Account/types'

const setJwtInStore = (jwt) => {
  if (jwt === null) {
    window.localStorage.removeItem('auth_jwt')
  } else {
    window.localStorage.setItem('auth_jwt', jwt)
  }
}

const setSentryUser = (jwt: string) => {
  if (jwt !== null) {
    const decodedToken: JwtDecoded = decode(jwt)
    configureScope((scope) => {
      scope.setUser({
        id: decodedToken.sub
      })
    })
  } else {
    configureScope((scope) => {
      scope.setUser(null)
    })
  }
}

export const authMiddleware = () => (next) => (action) => {
  if (action.type === AuthActionType.SET_JWT) {
    setJwtInStore(action.jwt)
    setSentryUser(action.jwt)
    AuthStore.setJwt(action.jwt)
  }

  return next(action)
}
