import React, { FC, useCallback, useState } from 'react'
import { PagePaper } from '../../../Layout'
import { Box, Button } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { FormError } from '../../../Form'
import { Field, Formik, FormikErrors } from 'formik'
import { ApiInterceptor } from '../../../Api/ApiInterceptor'
import { Helmet } from 'react-helmet'
import { networkError } from '../../../../helpers/networkError'
import { ErrorType } from '../../../Api/enums'
import { useToggle } from '../../../../hooks/useToggle'
import { useSelector } from 'react-redux'
import { accountSelector } from '../../../Account/account.duck'
import { TextRegular } from '../../../Text/TextRegular'
import { DateTime } from 'luxon'

export interface MissingDateOfBirthProps {
  onSuccess: () => void
}

interface FormValues {
  dateOfBirth: DateTime
}

export const MissingDateOfBirth: FC<MissingDateOfBirthProps> = ({
  onSuccess
}) => {
  const [isEditingAllowed, setEditingNotAllowed] = useToggle()
  const accountDTO = useSelector(accountSelector)
  const validate = useCallback((values: FormValues): FormikErrors<
    Record<keyof FormValues, string>
  > => {
    if (!values.dateOfBirth) {
      return { dateOfBirth: 'required' }
    }

    return null
  }, [])

  const submit = useCallback(
    async (values: FormValues) => {
      setIsLoading(true)

      try {
        await ApiInterceptor.post(
          '/api/my-account',
          {
            dateOfBirth: values.dateOfBirth.toFormat('yyyy-MM-dd')
          },
          {
            headers: {
              'Content-Type': 'application/vnd.account.dateOfBirth.v1+json'
            }
          }
        )
        onSuccess()
      } catch (e) {
        networkError(e).onErrorResponse(ErrorType.FIELD_NOT_EDITABLE, () =>
          setEditingNotAllowed()
        )
      } finally {
        setIsLoading(false)
      }
    },
    [onSuccess, setEditingNotAllowed]
  )
  const [isLoading, setIsLoading] = useState(false)

  return (
    <PagePaper>
      <Helmet>
        <title>Uzupełnij dane</title>
      </Helmet>
      <Formik
        initialValues={{ dateOfBirth: null }}
        validate={validate}
        onSubmit={submit}
      >
        {({ submitForm }) => (
          <>
            <Box>W Twoim profilu brakuje informacji o dacie urodzenia.</Box>
            <Box mt={2} width={300}>
              <Box mb={1}>
                <TextRegular light>Uzupełnij datę urodzenia.</TextRegular>
              </Box>
              <Field
                name="dateOfBirth"
                children={({ field, form }) => (
                  <DesktopDatePicker
                    {...field}
                    onChange={(value) => form.setFieldValue(field.name, value)}
                    format="d. MMMM yyyy"
                    variant="inline"
                    inputVariant="outlined"
                    label='np. "12. maja 1985"'
                    maxDate={DateTime.now()}
                    invalidDateMessage={<FormError>Uzupełnij datę</FormError>}
                    autoOk
                    maxDateMessage={
                      <FormError>Data nie może być z przyszłości</FormError>
                    }
                  />
                )}
              />
            </Box>
            {isEditingAllowed && (
              <Box mt={2}>
                <FormError>
                  Edycja nie jest możliwa.{' '}
                  {accountDTO?.declarationGiven && (
                    <>Deklaracja członkowska została dostarczona do klubu.</>
                  )}
                </FormError>
              </Box>
            )}
            <Box mt={2}>
              <Button
                color="primary"
                variant="contained"
                onClick={submitForm}
                disabled={isLoading}
              >
                Zapisz
              </Button>
            </Box>
          </>
        )}
      </Formik>
    </PagePaper>
  )
}
