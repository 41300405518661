import { FormikErrors } from 'formik'
import { PaymentSettings, PaymentSettingsFormValues } from './types'

export const validate = (values: PaymentSettingsFormValues) => {
  const errors: FormikErrors<PaymentSettings> = {}
  const provisionNumber = parseFloat(
    getProvisionWithCorrectComma(values.provisionPercent)
  )

  if (
    !values.provisionPercent ||
    (provisionNumber !== 0 && provisionNumber < 1)
  ) {
    errors.provisionPercent = 'Podaj wartość większą od 1%'
  } else if (isNaN(provisionNumber)) {
    errors.provisionPercent = 'Nieprawidłowa liczba'
  }

  return errors
}

export const getProvisionWithCorrectComma = (
  amount: string | number
): string => {
  if (typeof amount === 'number') {
    return amount.toString()
  }

  return amount.replace(',', '.')
}
