import {
  FeeAgeRange,
  Gender,
  MemberFeePeriodType,
  MembershipPurpose
} from '../../types'
import moment from 'moment'

export const yearLabel = (startDate: string) => {
  return moment(startDate).format('YYYY')
}

export const periodTypeLabel = (periodType: MemberFeePeriodType) => {
  switch (periodType) {
    case MemberFeePeriodType.YEAR:
      return 'roczna'
    case MemberFeePeriodType.HALF_YEAR:
      return 'półroczna'
    case MemberFeePeriodType.QUARTER:
      return 'kwartalna'
    case MemberFeePeriodType.MONTH:
      return 'miesięczna'
    default:
      return ''
  }
}

export const periodRangeLabel = (
  periodType: MemberFeePeriodType,
  startDate: string
): string => {
  switch (periodType) {
    case MemberFeePeriodType.YEAR:
      return `cały rok`
    case MemberFeePeriodType.MONTH:
      return moment(startDate).format('MMMM')
    case MemberFeePeriodType.QUARTER:
      return `${moment(startDate).format('MMMM')} - ${moment(startDate)
        .add(2, 'months')
        .format('MMMM')}`
    case MemberFeePeriodType.HALF_YEAR:
      return `${moment(startDate).format('MMMM')} - ${moment(startDate)
        .add(5, 'months')
        .format('MMMM')}`
    default:
      return ''
  }
}

export const ageRangeLabel = (ageRange: FeeAgeRange): string => {
  if (ageRange.from && ageRange.to) {
    return `Dla osób w wieku od ${ageRange.from} do ${ageRange.to} lat`
  }

  if (ageRange.from) {
    return `Dla osób w wieku od ${ageRange.from} lat`
  }

  return `Dla osób w wieku do ${ageRange.to} lat`
}

export const genderLabel = (gender: Gender): string => {
  switch (gender) {
    case Gender.MALE:
      return 'Dla mężczyzn'
    case Gender.FEMALE:
      return 'Dla kobiet'
    default:
      return ''
  }
}

export const introductoryLabel = (hasIntroductory: boolean): string => {
  if (hasIntroductory) {
    return 'Z osobą wprowadzającą'
  }

  return 'Bez osoby wprowadzającej'
}

export const purposeLabel = (purpose: MembershipPurpose): string => {
  switch (purpose) {
    case MembershipPurpose.BOTH:
      return 'Do celów kolekcjonerskich i sportowych'
    case MembershipPurpose.SPORT:
      return 'Do celów sportowych'
    case MembershipPurpose.COLLECTION:
      return 'Do celów kolekcjonerskich'
    default:
      return ''
  }
}

export const serviceLabel = (isService: boolean): string => {
  if (isService) {
    return 'Dla osób w służbach'
  }

  return 'Dla osób nie będących w służbach'
}
