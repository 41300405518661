import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { currentStepSelector, useResetEntryFeeWizard } from './duck'
import { EntryFeeConfigStep } from './EntryFeeConfigStep'
import { EntryFeeAgeRangesStep } from './EntryFeeAgeRangesStep'
import { EntryFeeUnitsStep } from './EntryFeeUnitsStep'
import { View } from '../../../Layout'
import styled from 'styled-components'
import { Paper } from '../../../Paper/Paper'
import { TextRegular } from '../../../Text/TextRegular'
import { muiTheme } from '../../../../common/muiTheme'
import { feesDuck } from '../../fee-configs.duck'
import { Sidebar } from './Sidebar'
import { MembershipFeeWizardSteps } from './duck/enums'
import { CommonConfigStep } from './CommonConfigStep'
import { MemberFeeConfigStep } from './MemberFeeConfigStep'
import { MemberFeePeriodsStep } from './MemberFeePeriodsStep'
import { MemberFeeAgeRangesStep } from './MemberFeeAgeRangesStep'
import { MemberFeeUnitsStep } from './MemberFeeUnitsStep'
import { SummaryStep } from './SummaryStep'
import { Helmet } from 'react-helmet'

export const AdminPaymentsWizard = () => {
  const currentStep = useSelector(currentStepSelector)
  const resetEntryFeeWizard = useResetEntryFeeWizard()
  const fetchConfigs = feesDuck.actions.useFetch()

  useEffect(() => {
    fetchConfigs()

    return () => resetEntryFeeWizard()
  }, [fetchConfigs, resetEntryFeeWizard])

  const CurrentStep = useMemo(() => {
    switch (currentStep) {
      case MembershipFeeWizardSteps.COMMON:
        return CommonConfigStep
      case MembershipFeeWizardSteps.ENTRY_FEE_CONFIG:
        return EntryFeeConfigStep
      case MembershipFeeWizardSteps.ENTRY_FEE_AGE_RANGES:
        return EntryFeeAgeRangesStep
      case MembershipFeeWizardSteps.ENTRY_FEE_UNITS:
        return EntryFeeUnitsStep
      case MembershipFeeWizardSteps.MEMBER_FEE_CONFIG:
        return MemberFeeConfigStep
      case MembershipFeeWizardSteps.MEMBER_FEE_PERIODS:
        return MemberFeePeriodsStep
      case MembershipFeeWizardSteps.MEMBER_FEE_AGE_RANGES:
        return MemberFeeAgeRangesStep
      case MembershipFeeWizardSteps.MEMBER_FEE_UNITS:
        return MemberFeeUnitsStep
      case MembershipFeeWizardSteps.SUMMARY:
        return SummaryStep
      default:
        return EntryFeeConfigStep
    }
  }, [currentStep])

  return (
    <Layout>
      <Helmet>
        <title>Składki - utwórz konfigurację</title>
      </Helmet>
      <MainContent>
        <TextRegular variant="large">Stwórz konfigurację składek</TextRegular>
        <CurrentStep />
      </MainContent>
      <Sidebar />
    </Layout>
  )
}

const Layout = styled(View)`
  align-items: stretch;
  margin: 32px;

  ${muiTheme.breakpoints.up('md')} {
    flex-direction: row;
    width: 960px;
    margin: 32px auto;
  }
`

const MainContent = styled(Paper)`
  flex-grow: 1;
`
