const pluralValues = [2, 3, 4]

export const plural = (
  value: number,
  singularLabel: string,
  pluralLabel: string,
  plural2Label: string = pluralLabel
): string => {
  if (value === 1) {
    return singularLabel
  }

  if (
    pluralValues.includes(value) ||
    (value >= 20 && value % 10 >= 2 && value % 10 <= 24)
  ) {
    return pluralLabel
  }

  return plural2Label
}
