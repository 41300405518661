import { ApiInterceptor } from '../Api/ApiInterceptor'
import {
  CreateLicenseOnlinePaymentPayload,
  CreateMembershipFeeConfigPayload,
  CreateMembershipOnlinePaymentPayload,
  CreateMembershipPaymentResponse,
  MembershipApplicableConfigDTO,
  MembershipFeeConfigDTO,
  CreateMembershipManualPaymentPayload,
  CreateLicenseManualPaymentPayload,
  MembershipPaymentOrderDTO,
  MembershipBatchApplicableConfigResponse,
  CreateMembershipExemptManualPaymentPayload
} from './types'
import { PageResponse } from '../Api/types'
import { FormValues } from './Admin/GroupPayment/AdminGroupPaymentForm'

const FEE_API_URL = '/api/membership-fee-config'
const MEMBERSHIP_API_URL = '/api/membership'
const MEMBERSHIP_ADMIN_API_URL = '/api/membership-admin'

export function createMembershipFeeConfig(
  payload: CreateMembershipFeeConfigPayload
) {
  return ApiInterceptor.post(FEE_API_URL, payload)
}

export function fetchMembershipFeeConfigs() {
  return ApiInterceptor.get<MembershipFeeConfigDTO[]>(FEE_API_URL)
}

export function fetchFeeConfigs() {
  return ApiInterceptor.get<MembershipFeeConfigDTO[]>(FEE_API_URL)
}

export function fetchApplicableConfig() {
  return ApiInterceptor.get<MembershipApplicableConfigDTO>(
    `${MEMBERSHIP_API_URL}/applicable-config`,
    {
      headers: {
        Accept: 'application/vnd.membership.v2.applicableConfig+json'
      }
    }
  )
}

export function fetchBatchApplicableConfig(year: string, accountIds: string[]) {
  return ApiInterceptor.get<MembershipBatchApplicableConfigResponse>(
    `/api/membership-admin/batch/applicable-config?year=${year}&${accountIds
      .map((accountId) => `accountIds=${accountId}`)
      .join('&')}`,
    {
      headers: {
        Accept: 'application/vnd.membership.v2.adminBatchApplicableConfig+json'
      }
    }
  )
}

export function createBatchPayments(
  year: string,
  accounts: FormValues['accounts']
) {
  return ApiInterceptor.post(
    '/api/membership-admin/payment-order',
    {
      year,
      accounts
    },
    {
      headers: {
        'Content-Type':
          'application/vnd.membership.v2.createMembershipManualBatchOrder+json'
      }
    }
  )
}

export function fetchAccountApplicableConfig(accountId: number) {
  return ApiInterceptor.get<MembershipApplicableConfigDTO>(
    `${MEMBERSHIP_ADMIN_API_URL}/applicable-config`,
    {
      params: {
        accountId
      },
      headers: {
        Accept: 'application/vnd.membership.v2.adminApplicableConfig+json'
      }
    }
  )
}

export function fetchOrdersList(page: number, accountId?: number) {
  return ApiInterceptor.get<PageResponse<MembershipPaymentOrderDTO>>(
    `${MEMBERSHIP_ADMIN_API_URL}/orders`,
    {
      params: {
        page,
        accountId
      }
    }
  )
}

export function saveLicenseSince(year: string) {
  return ApiInterceptor.post(
    `${MEMBERSHIP_API_URL}/license-since`,
    { year },
    {
      headers: {
        'Content-Type': 'application/vnd.membership.v2.setLicenseSince+json'
      }
    }
  )
}

export async function createMembershipOnlinePayment(
  payload: CreateMembershipOnlinePaymentPayload
) {
  return ApiInterceptor.post<
    CreateMembershipPaymentResponse,
    CreateMembershipOnlinePaymentPayload
  >(`${MEMBERSHIP_API_URL}/payment-order`, payload, {
    headers: {
      'Content-Type': 'application/vnd.membership.v2.createMembershipOrder+json'
    }
  })
}

export async function createLicenseOnlinePayment(
  payload: CreateLicenseOnlinePaymentPayload
) {
  return ApiInterceptor.post<
    CreateMembershipPaymentResponse,
    CreateLicenseOnlinePaymentPayload
  >(`${MEMBERSHIP_API_URL}/payment-order`, payload, {
    headers: {
      'Content-Type': 'application/vnd.membership.v2.createLicenseOrder+json'
    }
  })
}

export async function createMembershipManualPayment(
  payload: CreateMembershipManualPaymentPayload
) {
  return ApiInterceptor.post<void, CreateMembershipManualPaymentPayload>(
    `${MEMBERSHIP_ADMIN_API_URL}/payment-order`,
    payload,
    {
      headers: {
        'Content-Type':
          'application/vnd.membership.v2.createMembershipManualOrder+json'
      }
    }
  )
}

export async function createMembershipExemptManualPayment(
  payload: CreateMembershipExemptManualPaymentPayload
) {
  return ApiInterceptor.post<void, CreateMembershipExemptManualPaymentPayload>(
    `${MEMBERSHIP_ADMIN_API_URL}/payment-order`,
    payload,
    {
      headers: {
        'Content-Type':
          'application/vnd.membership.v2.createMembershipExemptManualOrder+json'
      }
    }
  )
}

export async function createLicenseManualPayment(
  payload: CreateLicenseManualPaymentPayload
) {
  return ApiInterceptor.post<
    CreateMembershipPaymentResponse,
    CreateLicenseManualPaymentPayload
  >(`${MEMBERSHIP_ADMIN_API_URL}/payment-order`, payload, {
    headers: {
      'Content-Type':
        'application/vnd.membership.v2.createLicenseManualOrder+json'
    }
  })
}
