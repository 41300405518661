import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar
} from '@mui/material'
import Button from '@mui/material/Button'
import React from 'react'
import { ApiInterceptor } from '../../Api/ApiInterceptor'
import { useToggle } from '../../../hooks/useToggle'

export interface DeleteLaneDialogProps {
  isOpen: boolean
  onClose: () => void
  onCancel: () => void
  rangeId: number
  laneId: string
}

export function DeleteLaneDialog({
  isOpen,
  onClose,
  onCancel,
  rangeId,
  laneId
}: DeleteLaneDialogProps) {
  const [isSuccess, enableSuccess, disableSuccess] = useToggle()
  const [isFail, enableFail, disableFail] = useToggle()
  const deleteLane = async () => {
    try {
      await ApiInterceptor.delete(`/api/range/${rangeId}/lanes/${laneId}`)
      enableSuccess()
      onClose()
    } catch (e) {
      enableFail()
    }
  }

  return (
    <>
      <Dialog open={isOpen}>
        <DialogTitle>Usuń tor</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Czy na pewno chcesz usunąć wybrany tor strzelecki?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onCancel}>
            Anuluj
          </Button>
          <Button variant="contained" color="secondary" onClick={deleteLane}>
            Usuń
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={isSuccess}
        onClose={disableSuccess}
        autoHideDuration={5000}
      >
        <Alert severity="success" onClose={disableSuccess}>
          Tor został usunięty
        </Alert>
      </Snackbar>
      <Snackbar open={isFail} onClose={disableFail} autoHideDuration={5000}>
        <Alert severity="error" onClose={disableFail}>
          Usunięcie toru nie powiodło się.
        </Alert>
      </Snackbar>
    </>
  )
}
