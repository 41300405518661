import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { RouteLinks, RouteNames } from './RouteNames'
import { Home } from '../Home'
import { PortalLogin } from '../Login'
import React from 'react'
import { View } from '../Layout'
import { Header } from '../Header/Header'
import styled from 'styled-components'
import { colors } from '../../styles/variables'
import { PaymentsRoutes } from '../Payments'
import { useSelector } from 'react-redux'
import { hasRoleSelector, jwtSelector } from '../Auth/auth.duck'
import { TokenAuth } from '../TokenAuth'
import { hasAccountSelector } from '../Account/account.duck'
import { Loading } from '../Loading'
import Box from '@mui/material/Box'
import { NotFound } from '../NotFound'
import { Footer } from 'components/Footer'
import { SuperAdmin } from '../SuperAdmin'
import { Role } from '../Account/enums'
import { ClubAdminRoutes } from './ClubAdminRoutes'
import { SsoRoutes } from '../Sso'
import { BookingsRoutes } from '../Bookings'
import { CertificateVerification } from '../CertificateVerification'
import { Documents } from '../Documents'
import { MergeAccountRoutes } from '../MergeAccount'

export const RootRoutes = () => {
  const jwt = useSelector(jwtSelector)
  const hasAccount = useSelector(hasAccountSelector)
  const isClubAdmin = useSelector(hasRoleSelector(Role.CLUB_ADMIN))
  const isSuperAdmin = useSelector(hasRoleSelector(Role.SUPER_ADMIN))

  return (
    <BrowserRouter basename={process.env.REACT_APP_PUBLIC_URL}>
      <AppRoot>
        <Routes>
          <Route path={RouteNames.TokenAuth} element={<TokenAuth />} />
          <Route path={RouteNames.Sso} element={<SsoRoutes />} />
          <Route
            path={RouteNames.CertificateVerification}
            element={<CertificateVerification />}
          />
          <Route
            path="*"
            element={
              !jwt ? (
                // This Switch is here for the sake of future routes that could be available for non logged users
                <Routes>
                  <Route path={RouteNames.Login} element={<PortalLogin />} />
                  <Route path="*" element={<PortalLogin />} />
                </Routes>
              ) : !hasAccount ? (
                <Box display="flex" justifyContent="center" mt={4}>
                  <Loading />
                </Box>
              ) : (
                <>
                  <Header />

                  <Box flexGrow={1}>
                    <Routes>
                      <Route
                        path="/"
                        element={<Navigate to={RouteLinks.Home} replace />}
                      />
                      <Route path={RouteNames.Home} element={<Home />} />
                      <Route
                        path={RouteNames.MergeAccount}
                        element={<MergeAccountRoutes />}
                      />
                      <Route
                        path={RouteNames.Bookings}
                        element={<BookingsRoutes />}
                      />
                      <Route
                        path={RouteNames.Payments}
                        element={<PaymentsRoutes />}
                      />
                      <Route
                        path={RouteNames.Documents}
                        element={<Documents />}
                      />
                      <Route
                        path={RouteNames.PaymentsCreatorPolish}
                        element={
                          <Navigate
                            to={RouteLinks.AccountPaymentCreator}
                            replace
                          />
                        }
                      />
                      {isClubAdmin && (
                        <Route
                          path={RouteNames.Club}
                          element={<ClubAdminRoutes />}
                        />
                      )}
                      {isSuperAdmin && (
                        <Route
                          path={RouteNames.SuperAdmin}
                          element={<SuperAdmin />}
                        />
                      )}
                      <Route path="*" element={<NotFound />} />
                    </Routes>
                  </Box>

                  <Footer />
                </>
              )
            }
          />
        </Routes>
      </AppRoot>
    </BrowserRouter>
  )
}

const AppRoot = styled(View)`
  background: ${colors.backgroundShade};
  width: 100%;
  min-height: 100%;
`
AppRoot.displayName = 'AppRoot'
