import { HorizontalView, PagePaper } from '../../../Layout'
import React, { useEffect, useRef, useState } from 'react'
import { TextRegular } from '../../../Text/TextRegular'
import { ApiInterceptor } from '../../../Api/ApiInterceptor'
import { useParams } from 'react-router'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material'
import { IoIosArrowRoundBack } from 'react-icons/io'
import { Link } from 'react-router-dom'
import { RouteLinks } from '../../../Routes/RouteNames'
import {
  GatewayManual,
  GatewayPayU,
  MembershipPaymentOrder,
  MembershipPayUPaymentOrder,
  GatewayP24,
  MembershipP24PaymentOrder
} from '../../types'
import { OrderStatus } from './OrderStatus'
import { colors } from '../../../../styles/variables'
import {
  GatewayType,
  ManualPaymentReason,
  P24OrderStatus,
  PayUOrderStatus
} from '../../enums'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import { getFeeTypeLabel } from '../../helpers'
import { captureException } from '@sentry/react'

const incompleteStatuses = [
  PayUOrderStatus.NEW,
  PayUOrderStatus.PENDING,
  PayUOrderStatus.WAITING_FOR_CONFIRMATION,
  P24OrderStatus.NEW
]

type OnlineOrder = MembershipPayUPaymentOrder | MembershipP24PaymentOrder

export const AccountPaymentOrderDetails = () => {
  const { orderId } = useParams<{ orderId: string }>()
  const [loading, setLoading] = useState(false)
  const [order, setOrder] = useState<MembershipPaymentOrder>()
  const timeoutRef = useRef<number>()

  useEffect(() => {
    const cancelToken = axios.CancelToken.source()
    let isMounted = true

    setLoading(true)
    fetch()

    return () => {
      isMounted = false
      cancelToken.cancel()

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }

    async function fetch() {
      try {
        const response = await ApiInterceptor.get<OnlineOrder>(
          `/api/membership/payment-order/${orderId}`,
          {
            headers: {
              Accept: 'application/vnd.membership.v2.getOrderDetails+json'
            }
          }
        )

        if (isMounted) {
          updateOrder(response.data)
        }
      } catch (e) {
        captureException(e)
      } finally {
        setLoading(false)
      }
    }

    function updateOrder(order: OnlineOrder) {
      setOrder(order)

      if (incompleteStatuses.includes(order.gateway.status)) {
        fetchPolled(order)
      }
    }

    async function fetchPolled(order: OnlineOrder) {
      let response
      try {
        response = await ApiInterceptor.get<OnlineOrder>(
          `/api/membership/payment-order/${orderId}?previousStatus=${order.gateway.status}`,
          {
            cancelToken: cancelToken.token,
            headers: {
              Accept: 'application/vnd.membership.v2.getPolledOrder+json'
            }
          }
        )
      } catch (e) {
        if (!(e instanceof axios.Cancel)) {
          timeoutRef.current = window.setTimeout(() => {
            fetchPolled(order)
          }, 4000)
        }
      }

      if (response) {
        if (!response.data) {
          fetchPolled(order)
        } else {
          updateOrder(response.data)
        }
      }
    }
  }, [orderId])

  return (
    <>
      <Helmet>
        <title>Składka - szczegóły</title>
      </Helmet>
      <PagePaper loading={loading}>
        <TextRegular variant="large">Potwierdzenie płatności</TextRegular>
        {order && (
          <>
            <Box mt={1}>
              <TextRegular>
                Okres: {order.paymentDetails.startDate} -{' '}
                {order.paymentDetails.endDate}
              </TextRegular>
            </Box>

            {order.gatewayType === GatewayType.MANUAL &&
            (order.gateway as GatewayManual).reason ===
              ManualPaymentReason.EXEMPT ? (
              <Typography sx={{ mt: 3 }} variant="h6">
                W tym okresie zostałeś zwolniony ze składki.
              </Typography>
            ) : (
              <Box sx={{ my: 4, alignSelf: 'flex-start', borderRadius: '4px' }}>
                <Box mb={2}>
                  <TextRegular size="medium">Rachunek:</TextRegular>
                </Box>
                <Table size="small">
                  <TableBody>
                    {order.paymentItems.map((paymentItem) => (
                      <TableRow key={paymentItem.id}>
                        <TableCell>
                          {getFeeTypeLabel(
                            paymentItem.paymentItemDetails.feeType
                          )}{' '}
                        </TableCell>
                        <TableCell align="right">
                          {paymentItem.amount} zł
                        </TableCell>
                      </TableRow>
                    ))}
                    {order.gatewayType === GatewayType.PAY_U &&
                    !!(order.gateway as GatewayPayU).provisionPercent ? (
                      <TableRow>
                        <TableCell>
                          <i>prowizja PayU:</i>
                        </TableCell>
                        <TableCell align="right">
                          <i>
                            {(order.gateway as GatewayPayU).provisionPercent} %
                          </i>
                        </TableCell>
                      </TableRow>
                    ) : (
                      order.gatewayType === GatewayType.P24 &&
                      !!(order.gateway as GatewayP24).provisionPercent && (
                        <TableRow>
                          <TableCell>
                            <i>prowizja Przelewy24:</i>
                          </TableCell>
                          <TableCell align="right">
                            <i>
                              {(order.gateway as GatewayP24).provisionPercent} %
                            </i>
                          </TableCell>
                        </TableRow>
                      )
                    )}
                    <TableRow>
                      <TableCell>
                        <TextRegular strong>Razem</TextRegular>
                      </TableCell>
                      <TableCell align="right">
                        <TextRegular strong>{order.totalAmount} zł</TextRegular>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            )}

            <OrderStatus order={order} />

            <Box mt={4}>
              <Link to={RouteLinks.AccountPaymentOrdersList}>
                <TextRegular variant="medium" color={colors.mainLink}>
                  <HorizontalView align="center">
                    <Box display="flex" mr={0.5}>
                      <IoIosArrowRoundBack size={32} />
                    </Box>
                    Do listy płatności
                  </HorizontalView>
                </TextRegular>
              </Link>
            </Box>
          </>
        )}
      </PagePaper>
    </>
  )
}
