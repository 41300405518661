import { HiOutlineDownload } from 'react-icons/hi'
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Snackbar,
  Typography
} from '@mui/material'
import React, { ChangeEvent, useState } from 'react'
import { useToggle } from '../../hooks/useToggle'
import { UserSelect } from '../UserSelect'
import { AccountDTO } from '../Account/types'
import { generateMembershipCertificates } from './documents.service'
import FileSaver from 'file-saver'
import { AxiosError } from 'axios'
import { ErrorElement } from '../Api/types'
import { ErrorType } from '../Api/enums'
import { muiTheme } from '../../common/muiTheme'

enum DocumentType {
  MEMBERSHIP_CERTIFICATE = 'MEMBERSHIP_CERTIFICATE'
}

export function AdminDocumentsDownload() {
  const [downloadDialogVisible, showDownloadDialog, hideDownloadDialog] =
    useToggle()
  const [accountsList, setAccountsList] = useState<AccountDTO[]>([])
  const [documentType, setDocumentType] = useState<DocumentType>()
  const [printStamp, togglePrintStamp] = useState(false)
  const [
    hasMissingInformation,
    setMissingInformation,
    unsetMissingInformation
  ] = useToggle()
  const [isLoading, startLoading, stopLoading] = useToggle()
  const [isSuccess, showSuccess, hideSuccess] = useToggle()

  const handleClose = () => {
    setAccountsList([])
    setDocumentType(undefined)
    togglePrintStamp(false)
    unsetMissingInformation()
    hideDownloadDialog()
  }

  const addAccount = (account: AccountDTO | null) => {
    if (
      account &&
      !accountsList.some((accountFromList) => accountFromList.id === account.id)
    ) {
      setAccountsList((oldAccounts) => [...oldAccounts, account])
    }
  }

  const removeAccount = (account: AccountDTO) => {
    setAccountsList((oldAccounts) =>
      oldAccounts.filter((accountFromList) => accountFromList.id !== account.id)
    )
  }

  const selectDocumentType = (event: ChangeEvent<HTMLInputElement>) => {
    setDocumentType(event.target.value as DocumentType)
  }

  const handleChangeStamp = (event: ChangeEvent<HTMLInputElement>) => {
    togglePrintStamp(event.target.checked)
  }

  const downloadDocuments = async () => {
    unsetMissingInformation()
    startLoading()

    try {
      // So far we only support membership certificates
      const response = await generateMembershipCertificates(
        accountsList.map((account) => account.id),
        printStamp
      )
      FileSaver.saveAs(
        response.data,
        response.headers['content-disposition'].replace(
          /^.+filename="([^"]+)"/,
          '$1'
        )
      )

      handleClose()
      showSuccess()
    } catch (error) {
      if ((error as AxiosError).isAxiosError) {
        const axiosError = error as AxiosError

        if (
          (JSON.parse(await axiosError.response.data.text()) as ErrorElement)
            .errorType === ErrorType.MISSING_INFORMATION
        ) {
          setMissingInformation()
        }
      }
    } finally {
      stopLoading()
    }
  }

  return (
    <>
      <Button
        startIcon={<HiOutlineDownload />}
        variant="contained"
        color="primary"
        onClick={showDownloadDialog}
      >
        Pobieranie dokumentów
      </Button>
      <Dialog
        open={downloadDialogVisible}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Generuj dokumenty</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 1 }}>
            Wygeneruj wiele dokumentów dla wybranych osób.
          </DialogContentText>
          <UserSelect
            onChange={addAccount}
            value={null}
            inputProps={{
              label: 'Znajdź klubowiczów'
            }}
          />
          <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 2, gap: 1 }}>
            {accountsList.map((account) => (
              <Chip
                key={account.id}
                label={`#${account.id} ${account.firstName} ${account.lastName}`}
                onDelete={() => removeAccount(account)}
                color="primary"
              />
            ))}
          </Box>
          <Divider sx={{ my: 2 }} />
          <FormControl>
            <FormLabel id="document-download-document-type-group-label">
              Rodzaj dokumentu
            </FormLabel>
            <RadioGroup
              aria-labelledby="document-download-document-type-group-label"
              onChange={selectDocumentType}
            >
              <FormControlLabel
                control={<Radio />}
                label="Zaświadczenie o członkostwie"
                value={DocumentType.MEMBERSHIP_CERTIFICATE}
              />
              <FormControlLabel
                control={<Radio disabled />}
                label="...więcej dokumentów wkrótce"
                value=""
              />
            </RadioGroup>
          </FormControl>
          {!!documentType && (
            <>
              <Divider sx={{ my: 2 }} />
              <FormControlLabel
                control={
                  <Checkbox onChange={handleChangeStamp} value={printStamp} />
                }
                label="Drukuj pieczątkę"
              />
            </>
          )}
          {hasMissingInformation && (
            <Typography sx={(theme) => ({ color: theme.palette.error.main })}>
              Wśród wybranych osób brakuje informacji o dacie dołączenia do
              klubu.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            startIcon={<HiOutlineDownload />}
            disabled={isLoading || !accountsList.length || !documentType}
            onClick={downloadDocuments}
          >
            {isLoading ? (
              <CircularProgress size={muiTheme.typography.body1.fontSize} />
            ) : (
              'Pobierz'
            )}
          </Button>
          <Button variant="text" onClick={handleClose}>
            Anuluj
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={isSuccess} onClose={hideSuccess} autoHideDuration={2000}>
        <Alert severity="success">Dokumenty zostaną pobrane.</Alert>
      </Snackbar>
    </>
  )
}
