import React from 'react'
import {
  GatewayManual,
  GatewayP24,
  MembershipPaymentOrderDTO
} from '../../types'
import { GatewayType, ManualPaymentStatus, P24OrderStatus } from '../../enums'
import { TextRegular } from '../../../Text/TextRegular'
import { CancelManualPayment } from './CancelManualPayment'
import { RefreshP24Payment } from './RefreshP24Payment'

export interface AdminPaymentActionsProps {
  order: MembershipPaymentOrderDTO
  onChange: () => void
}

export function AdminPaymentActions({
  order,
  onChange
}: AdminPaymentActionsProps) {
  switch (order.gatewayType) {
    case GatewayType.PAY_U:
      return null
    case GatewayType.P24:
      switch ((order.gateway as GatewayP24).status) {
        case P24OrderStatus.NEW:
          return <RefreshP24Payment orderId={order.id} onChange={onChange} />
        case P24OrderStatus.VERIFIED:
        default:
          return null
      }
    case GatewayType.MANUAL:
      switch ((order.gateway as GatewayManual).status) {
        case ManualPaymentStatus.ACTIVE:
          return <CancelManualPayment orderId={order.id} onChange={onChange} />
        case ManualPaymentStatus.OBSOLETE:
          return <TextRegular light>Usunięta</TextRegular>
        default:
          return null
      }
    default:
      return null
  }
}
