import { AvailableHoursRangeDTO } from '../types'
import { TableCell } from '@mui/material'
import Box from '@mui/material/Box'
import { DateTime } from 'luxon'
import React from 'react'
import styled, { css } from 'styled-components'
import { colors } from '../../../styles/variables'
import { rgba } from 'polished'

export interface DayColumnProps {
  now: DateTime
  availableHours: AvailableHoursRangeDTO[]
  onClick: (hour: AvailableHoursRangeDTO) => void
}

export function DayColumn({ now, availableHours, onClick }: DayColumnProps) {
  return (
    <TableCell width={`${100 / 7}%`}>
      <Box flexWrap="wrap" justifyContent="center" p={1}>
        {availableHours?.map((hour) => {
          const dateTime = DateTime.fromISO(hour.from, {
            zone: 'GMT',
            setZone: true
          })
          const hasLanes = hour.availableLanes.some(
            (lane) => lane.availableLaneNumbers.length > 0
          )
          const isActive = hasLanes && dateTime.valueOf() > now.valueOf()

          return (
            <HourBox
              $active={isActive}
              title={!hasLanes ? 'Wszystkie stanowiska zajęte' : undefined}
              key={dateTime.toMillis()}
              onClick={() => isActive && onClick(hour)}
              p={0.5}
              mt={1}
              mx={0.5}
            >
              {dateTime.toFormat('HH:mm')}
            </HourBox>
          )
        })}
      </Box>
    </TableCell>
  )
}

const HourBox = styled(Box)<{ $active: boolean }>`
  background: ${colors.fog};
  color: ${colors.white};
  border-radius: 4px;

  ${(p) =>
    p.$active &&
    css`
      background: ${colors.main};
      cursor: pointer;

      &:hover {
        background: ${rgba(colors.main, 0.8)};
      }
    `}
`
