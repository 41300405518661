import React, { FC, ReactNode } from 'react'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import bgImg from './celpal_bg.jpg'
import bgImgBlur from './celpal_bg_blur.jpg'

export const BackgroundModal: FC<{ children?: ReactNode }> = ({ children }) => {
  const [background, setBackground] = useState(null)

  useEffect(() => {
    const blurImage = new Image()
    const image = new Image()
    blurImage.onload = () => {
      setBackground(bgImgBlur)
      image.onload = () => {
        setBackground(bgImg)
      }
      image.src = bgImg
    }
    blurImage.src = bgImgBlur

    return () => {
      blurImage.onload = null
      image.onload = null
    }
  }, [])

  return <Wrapper background={background}>{children}</Wrapper>
}

const Wrapper = styled.div<{ background: string }>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 0 10px;
  background-size: cover;
  background-color: #2f2f2f;
  background-position: center;
  position: relative;
  ${(p) => !!p.background && `background-image: url(${p.background});`};
  transition: background-image 2s ease-out;

  @media (min-width: 600px) {
    padding: 0;
  }
`
