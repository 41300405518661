import React, {
  Children,
  FC,
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import Tabs from '@mui/material/Tabs'
import { LinkProps } from 'react-router-dom'

const getEffectiveValue = (pathname: string, paths: string[]): number => {
  const index = paths.findIndex((path) => pathname.includes(path))
  return index !== -1 ? index : 0
}

export const SubMenu: FC<{ children?: ReactNode }> = ({ children }) => {
  const location = useLocation()
  const pathsArray = useMemo<string[]>(
    () =>
      Children.map(children, (child) =>
        React.isValidElement(child)
          ? (((child as ReactElement).props as LinkProps).to as string)
          : null
      ),
    [children]
  )

  const [effectiveValue, setEffectiveValue] = useState(
    getEffectiveValue(location.pathname, pathsArray)
  )

  useEffect(() => {
    setEffectiveValue(getEffectiveValue(location.pathname, pathsArray))
  }, [location, pathsArray])

  return (
    <MenuTabs value={effectiveValue} indicatorColor="primary">
      {children}
    </MenuTabs>
  )
}

const MenuTabs = styled(Tabs)`
  background: #fff;
`
