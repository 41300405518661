import React from 'react'
import { HorizontalView, PagePaper, View } from '../Layout'
import { TextRegular } from '../Text/TextRegular'
import { useSelector } from 'react-redux'
import { currentClubDuck } from '../../ducks/currentClub.duck'
import { NetworkRequestStatus } from '../../common/network-state'
import Box from '@mui/material/Box'
import { ClubResourceImage } from './ClubResourceImage'

export const ClubSettings = () => {
  const fetchStatus = useSelector(currentClubDuck.selectors.status)
  const club = useSelector(currentClubDuck.selectors.data)
  const reFetchClub = currentClubDuck.actions.useFetch()

  return (
    <PagePaper
      loading={fetchStatus === NetworkRequestStatus.LOADING}
      title="Twój klub"
    >
      {club && (
        <HorizontalView justify="space-between" align="flex-start">
          <View>
            <TextRegular size="medium" strong>
              {club.name}
            </TextRegular>
            <TextRegular>{club.shortName}</TextRegular>

            <Box mt={2}>
              <HorizontalView>
                <Box mr={1}>
                  <TextRegular light>Kod</TextRegular>
                </Box>
                <TextRegular>{club.code}</TextRegular>
              </HorizontalView>
              <HorizontalView>
                <Box mr={1}>
                  <TextRegular light>Email</TextRegular>
                </Box>
                <TextRegular>{club.email}</TextRegular>
              </HorizontalView>
            </Box>
          </View>
          <View>
            <ClubResourceImage
              onSave={reFetchClub}
              saveUri="/api/current-club/avatar"
              fileResource={club.avatarUrl}
              title="Awatar"
              dialogTitle="Dodaj awatar"
            />
            <Box mt={3}>
              <ClubResourceImage
                onSave={reFetchClub}
                saveUri="/api/current-club/primary-stamp"
                title="Pieczątka podstawowa"
                dialogTitle="Dodaj pieczątkę"
                fileResource={club.primaryStampUrl}
              />
            </Box>
            <Box mt={3}>
              <ClubResourceImage
                onSave={reFetchClub}
                saveUri="/api/current-club/secondary-stamp"
                title="Pieczątka dodatkowa (podpis)"
                dialogTitle="Dodaj pieczątkę dodatkową"
                fileResource={club.secondaryStampUrl}
              />
            </Box>
          </View>
        </HorizontalView>
      )}
    </PagePaper>
  )
}
