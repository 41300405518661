import {
  MemberFeePeriodType,
  MemberFeeUnit,
  MembershipPaymentOrderDTO
} from '../../types'
import { PaymentFeeType } from '../../enums'
import { DateTime } from 'luxon'

const dateFormat = 'yyyy-MM-dd'

export function getFeeUnitPeriod(order: MembershipPaymentOrderDTO): string {
  const memberFee = order.paymentItems.find(
    (item) => item.paymentItemDetails.feeType === PaymentFeeType.MEMBER_FEE
  )

  const date = DateTime.fromFormat(order.paymentDetails.endDate, dateFormat)
  const year = date.year.toString()

  if (!memberFee) {
    return `cały rok ${year}`
  }

  let periodType = (memberFee.paymentItemDetails.feeUnit as MemberFeeUnit)
    ?.periodType
  // Due to an old bug, some MANUAL paymentOrders don't have periodType information in the MEMBER_FEE paymentItem
  if (!periodType) {
    // In such case try to retrieve payment period from date range
    periodType = getPeriodFromDateRange(
      order.paymentDetails.startDate,
      order.paymentDetails.endDate
    )

    // Still no luck?
    if (!periodType) {
      return '[brak informacji]'
    }
  }

  switch (periodType) {
    case MemberFeePeriodType.YEAR:
      return `cały rok ${year}`
    case MemberFeePeriodType.HALF_YEAR:
      return date.month <= 6
        ? `styczeń - czerwiec ${year}`
        : `lipiec - grudzień ${year}`
    case MemberFeePeriodType.QUARTER:
      if (date.month <= 3) {
        return `styczeń - marzec ${year}`
      }
      if (date.month <= 6) {
        return `kwiecień - czerwiec ${year}`
      }
      if (date.month <= 9) {
        return `lipiec - wrzesień ${year}`
      }
      return `październik - grudzień ${year}`
    case MemberFeePeriodType.MONTH:
      return `${date.monthLong} ${year}`
  }
}

function getPeriodFromDateRange(
  from?: string,
  to?: string
): MemberFeePeriodType {
  if (!from || !to) {
    return null
  }

  const fromDate = DateTime.fromFormat(from, dateFormat)
  const toDate = DateTime.fromFormat(to, dateFormat)
  const monthsDiff = Math.ceil(toDate.diff(fromDate, ['months']).months)

  if (monthsDiff >= 11) {
    return MemberFeePeriodType.YEAR
  }
  if (monthsDiff >= 5) {
    return MemberFeePeriodType.HALF_YEAR
  }
  if (monthsDiff >= 2) {
    return MemberFeePeriodType.QUARTER
  }

  return MemberFeePeriodType.MONTH
}
