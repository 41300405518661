import { Action } from 'redux'
import { useDispatch } from 'react-redux'
import { createSelector, Selector } from 'reselect'
import { useCallback } from 'react'
import decode from 'jwt-decode'
import { Role } from '../Account/enums'
import { JwtDecoded } from '../Account/types'

export interface AuthState {
  jwt: string
  jwtDecoded: JwtDecoded
  forceLogout: boolean
}

export interface AuthStateForRoot {
  auth: AuthState
}

const defaultState: AuthState = {
  jwt: null,
  jwtDecoded: null,
  forceLogout: false
}

export enum AuthActionType {
  SET_JWT = 'auth/SET_JWT',
  SET_FORCE_LOGOUT = 'auth/SET_FORCE_LOGOUT'
}

export interface SetJwt extends Action {
  type: AuthActionType.SET_JWT
  jwt: string
  jwtDecoded: JwtDecoded
}
export interface SetForceLogout extends Action {
  type: AuthActionType.SET_FORCE_LOGOUT
  force: boolean
}

export type AuthAction = SetJwt | SetForceLogout

export const setJwt = (jwt: string | null, jwtDecoded: JwtDecoded): SetJwt => ({
  type: AuthActionType.SET_JWT,
  jwt,
  jwtDecoded
})

export const setForceLogout = (force: boolean): SetForceLogout => ({
  type: AuthActionType.SET_FORCE_LOGOUT,
  force
})

export const useFetchJwtFromStore = () => {
  const dispatch = useDispatch()

  return useCallback(() => {
    const jwt = window.localStorage.getItem('auth_jwt')

    if (jwt) {
      dispatch(setJwt(jwt, decode(jwt)))
    }
  }, [dispatch])
}

export const useSetJwt = () => {
  const dispatch = useDispatch()

  return useCallback(
    (jwt: string | null) => {
      dispatch(setJwt(jwt, jwt ? decode(jwt) : null))
    },
    [dispatch]
  )
}

export const useLogout = () => {
  const setJwt = useSetJwt()

  return useCallback(() => {
    setJwt(null)
  }, [setJwt])
}

export const logout = () => {
  return setJwt(null, null)
}

export const authReducer = (
  state: AuthState = defaultState,
  action: AuthAction
): AuthState => {
  switch (action.type) {
    case AuthActionType.SET_JWT:
      return {
        ...state,
        jwt: action.jwt,
        jwtDecoded: action.jwtDecoded
      }
    case AuthActionType.SET_FORCE_LOGOUT:
      return {
        ...state,
        forceLogout: action.force
      }
    default:
      return state
  }
}

type JwtSelector<T> = Selector<AuthStateForRoot, T>

export const jwtSelector: JwtSelector<string> = (
  state: AuthStateForRoot
): string => state.auth.jwt
export const jwtDecodedSelector: JwtSelector<JwtDecoded> = (
  state: AuthStateForRoot
): JwtDecoded => state.auth.jwtDecoded
export const hasRoleSelector = (role: Role) =>
  createSelector(jwtDecodedSelector, (jwt) => {
    if (jwt) {
      return jwt.scopes.includes(role)
    }
    return false
  })
export const isImpersonatedSelector = createSelector(
  jwtDecodedSelector,
  (jwt) => !!jwt.isImpersonated
)
