import { TextRegular } from '../../../Text/TextRegular'
import {
  periodRangeLabel,
  periodTypeLabel,
  yearLabel
} from './criteriaDescriptionHelper'
import { CriteriaDescription } from './CriteriaDescription'
import {
  getDaysTillPeriodEnd,
  getFeeTotalAmount,
  getProvisionAmount,
  isPaymentDateRisky,
  licenseFeeApplicable
} from './helpers'
import {
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import {
  TotalAmount,
  TotalAmountText,
  VariantContainer,
  VariantFooter,
  VariantInner,
  VariantTitle
} from './PaymentOfferCard'
import { MemberFeeUnitDTO, MembershipApplicableConfigDTO } from '../../types'
import { PendingIcon } from '../PaymentOrderDetails/OrderStatus/PendingIcon'
import { BiCalendar } from 'react-icons/bi'
import { useToggle } from '../../../../hooks/useToggle'
import { Button } from '@mui/material'
import { plural } from '../../../../helpers/plural'

export interface MemberFeeOfferCardProps {
  unit: MemberFeeUnitDTO
  config: MembershipApplicableConfigDTO
  onPay: (unit: MemberFeeUnitDTO) => void
  loading: boolean
}

export const MemberFeeOfferCard: FC<MemberFeeOfferCardProps> = ({
  unit,
  config,
  onPay,
  loading
}) => {
  const [selfLoading, setSelfLoading] = useState(false)
  const [confirmVisible, showConfirm, hideConfirm] = useToggle()
  const isLicenseFeeApplicable = licenseFeeApplicable(
    config.licenseFeeYears,
    unit
  )
  const handlePayButton = () => {
    if (isPaymentDateRisky(unit)) {
      showConfirm()
    } else {
      confirmPay()
    }
  }
  const confirmPay = () => {
    if (confirmVisible) {
      hideConfirm()
    }
    setSelfLoading(true)
    onPay(unit)
  }

  useEffect(() => {
    if (!loading && selfLoading) {
      setSelfLoading(false)
    }
  }, [loading, selfLoading])

  return (
    <VariantContainer>
      <Dialog open={confirmVisible}>
        <DialogTitle>Płatność {periodTypeLabel(unit.periodType)}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Zamierzasz opłacić składkę za okres{' '}
            <strong>
              {periodRangeLabel(unit.periodType, unit.startDate)}{' '}
              {yearLabel(unit.startDate)}
            </strong>
            .
            <br />
            <br />
            Jeśli wybierzesz tę opcję, następna płatność będzie konieczna za{' '}
            {getDaysTillPeriodEnd(unit)}{' '}
            {plural(getDaysTillPeriodEnd(unit), 'dzień', 'dni', 'dni')}.
            <br />
            <br />
            Upewnij się, że opłacasz właściwą składkę.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={confirmPay}>
            Zapłać
          </Button>
          <Button variant="text" sx={{ py: 2 }} onClick={hideConfirm}>
            Anuluj
          </Button>
        </DialogActions>
      </Dialog>
      <VariantInner>
        <VariantTitle>
          Składka {periodTypeLabel(unit.periodType)} {yearLabel(unit.startDate)}
        </VariantTitle>
        <Chip
          icon={<BiCalendar size={20} />}
          label={periodRangeLabel(unit.periodType, unit.startDate)}
        />
        <CriteriaDescription
          feeConfig={config.memberFeeConfig}
          feeUnit={unit}
          licenseApplicable={licenseFeeApplicable(config.licenseFeeYears, unit)}
        />
        {config.entryFeeApplicable && (
          <Box mt={2}>
            <TextRegular strong>Wpisowe</TextRegular>
            <CriteriaDescription
              feeConfig={config.entryFeeConfig}
              feeUnit={config.entryFeeUnit}
            />
          </Box>
        )}
      </VariantInner>
      <VariantFooter>
        <TotalAmount>
          razem:
          <TotalAmountText variant="xlarge" strong>
            {getFeeTotalAmount(unit, config).toFixed(0)} zł
          </TotalAmountText>
        </TotalAmount>
        <Box mt={1} textAlign="right">
          {(config.entryFeeApplicable ||
            config.entryFeeApplicable ||
            config.provisionPercent > 0 ||
            isLicenseFeeApplicable) && (
            <Box>
              <i>w tym:</i>
            </Box>
          )}
          {config.entryFeeApplicable && (
            <Box>wpisowe: {config.entryFeeUnit.amount} zł</Box>
          )}
          {(config.entryFeeApplicable || config.provisionPercent > 0) && (
            <Box>składka: {unit.amount} zł</Box>
          )}
          {isLicenseFeeApplicable && <Box>licencja: 75 zł</Box>}
          {config.provisionPercent > 0 && (
            <Box>
              prowizja P24 ({config.provisionPercent}%):{' '}
              {getProvisionAmount(unit, config).toFixed()} zł
            </Box>
          )}
        </Box>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            borderRadius: '32px'
          }}
          disabled={loading}
          onClick={handlePayButton}
        >
          {selfLoading ? <PendingIcon size={16} /> : 'Zapłać'}
        </Button>
      </VariantFooter>
    </VariantContainer>
  )
}
