import Box from '@mui/material/Box'
import React from 'react'
import { BookingPaymentOrderDTO } from '../types'
import { DateTime } from 'luxon'
import { Chip, Tooltip } from '@mui/material'
import {
  getBookingStatusColor,
  getBookingStatusText,
  isBookingDelayed
} from './helpers'
import { TextRegular, TextRegularProps } from '../../Text/TextRegular'
import { RiErrorWarningLine } from 'react-icons/ri'
import { View } from 'components/Layout'
import Button from '@mui/material/Button'
import { PayUOrderStatus } from '../../Payments/enums'

export interface BookingTileProps {
  booking: BookingPaymentOrderDTO
  hidePerson?: boolean
  hideDate?: boolean
  hideRangeName?: boolean
  onChange?: (booking: BookingPaymentOrderDTO) => void
}

const Label = (props: TextRegularProps<'div'>) => (
  <Box mb={1}>
    <TextRegular size="small" light component="div" {...props} />
  </Box>
)
const Value = (props: TextRegularProps<'div'>) => (
  <TextRegular size="normal" component="div" strong {...props} />
)

const changeableStatuses = [
  PayUOrderStatus.WAITING_FOR_CONFIRMATION,
  PayUOrderStatus.NEW,
  PayUOrderStatus.PENDING
]

export function BookingTile({
  booking,
  hidePerson = false,
  hideDate = false,
  hideRangeName = false,
  onChange
}: BookingTileProps) {
  const startDate = DateTime.fromISO(booking.paymentDetails.startDate)
  const endDate = startDate.plus({
    minutes: booking.paymentDetails.durationMinutes
  })
  const isDelayed = isBookingDelayed(booking)
  const isChangeable = changeableStatuses.includes(booking.gateway.status)

  return (
    <Box display="flex" mt={1}>
      {!hidePerson && (
        <Box mr={5}>
          {/* TODO: How to verify people? By PESEL? */}
          <Label>Imię i nazwisko:</Label>
          <Value>
            {booking.account.firstName} {booking.account.lastName}
          </Value>
        </Box>
      )}
      <div>
        <Label>Czas:</Label>
        {!hideDate && (
          <Value strong={false}>{startDate.toFormat('dd. MMMM yyyy')}</Value>
        )}
        <Value>
          {startDate.toFormat('HH:mm')} - {endDate.toFormat('HH:mm')}
        </Value>
      </div>
      {!hideRangeName && (
        <Box ml={5}>
          <Label>Strzelnica:</Label>
          <Value>{booking.paymentDetails.range.name}</Value>
        </Box>
      )}
      <Box ml={5}>
        <Label>Tor:</Label>
        <Value>{booking.paymentDetails.lane.name}</Value>
      </Box>
      <Box ml={5}>
        <Label>Stanowisko:</Label>
        <Value>{booking.paymentDetails.laneNumber}</Value>
      </Box>
      <View component={Box} ml={5} alignItems="flex-end">
        <Chip
          label={getBookingStatusText(booking.gateway.status)}
          color={getBookingStatusColor(booking.gateway.status, isDelayed)}
          icon={
            isDelayed ? (
              <Tooltip
                arrow
                title={
                  <TextRegular size="small">
                    Ta rezerwacja jest przeterminowana, a nie została
                    zaksięgowana, ani anulowana.
                    <br />
                    <br />
                    Zostanie automatycznie zaksięgowana w najbliższym czasie.
                  </TextRegular>
                }
              >
                <View>
                  <RiErrorWarningLine size={24} />
                </View>
              </Tooltip>
            ) : undefined
          }
        />
        {onChange && (
          <Box mt={2}>
            <Tooltip
              disableHoverListener={isChangeable}
              disableInteractive
              title={
                !isChangeable && (
                  <>
                    Nie można zmienić rezerwacji, która została już
                    zaksięgowana.
                  </>
                )
              }
            >
              <span>
                <Button
                  disabled={!isChangeable}
                  color={isChangeable ? 'primary' : undefined}
                  variant="contained"
                  onClick={() => onChange(booking)}
                >
                  zmień
                </Button>
              </span>
            </Tooltip>
          </Box>
        )}
      </View>
    </Box>
  )
}
