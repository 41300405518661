import { useCallback, useState } from 'react'
import { AxiosError } from 'axios'

export enum NetworkRequestStatus {
  IDLE,
  LOADING,
  LOADED,
  ERROR
}

export type FetchHook<DataType, Params, ErrorType> = [
  (...p: Params[]) => void, // fetch function
  DataType, // data
  NetworkRequestStatus, // fetch state
  ErrorType, // error
  () => void // reset function
]

export const useNetworkRequest = <
  DataType,
  Params = void,
  ErrorType = AxiosError
>(
  fetchFn: (...p: Params[]) => Promise<DataType>,
  deps?: unknown[]
): FetchHook<DataType, Params, ErrorType> => {
  const [requestStatus, setRequestStatus] = useState<NetworkRequestStatus>(
    NetworkRequestStatus.IDLE
  )
  const [data, setData] = useState<DataType>(null)
  const [error, setError] = useState<ErrorType>(null)
  const reset = () => {
    setError(null)
    setData(null)
  }

  const fetch = useCallback(async (...p: Params[]) => {
    setRequestStatus(NetworkRequestStatus.LOADING)
    try {
      const response = await fetchFn(...p)
      setData(response)
      setRequestStatus(NetworkRequestStatus.LOADED)
    } catch (e) {
      setRequestStatus(NetworkRequestStatus.ERROR)
      setError(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps || [])

  return [fetch, data, requestStatus, error, reset]
}
