import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { PagePaper } from '../../Layout'
import { Link, useParams } from 'react-router-dom'
import { useNetworkRequest } from '../../../common/network-state'
import { ApiInterceptor } from '../../Api/ApiInterceptor'
import Box from '@mui/material/Box'
import { DateTime } from 'luxon'
import { TextRegular } from '../../Text/TextRegular'
import {
  Alert,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import styled, { css } from 'styled-components'
import { FormError } from '../../Form'
import { RouteLinks } from '../../Routes/RouteNames'
import { formatRoute } from '../../../helpers/formatRoute'
import { SelectField } from '../../Form/Mui/SelectField'
import { Formik, FormikErrors } from 'formik'
import { getRange, getRangeLanes } from '../../Ranges/ranges.service'
import { colors } from '../../../styles/variables'
import { plural } from '../../../helpers/plural'
import { createBookingOrder } from '../bookings.service'
import { AvailableHoursRangeDTO, RangeAvailableHoursDTO } from '../types'
import { LaneSelector } from './LaneSelector'
import { DayColumn } from './DayColumn'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

const visibleDays = 7
const availableDurations = [30, 60, 120]

const nominalMonth = {
  1: 'Styczeń',
  2: 'Luty',
  3: 'Marzec',
  4: 'Kwiecień',
  5: 'Maj',
  6: 'Czerwiec',
  7: 'Lipiec',
  8: 'Sierpień',
  9: 'Wrzesień',
  10: 'Październik',
  11: 'Listopad',
  12: 'Grudzień'
}

interface FormValues {
  laneId: string
  laneNumber: number
  duration: number
}

function validate(values: FormValues): FormikErrors<FormValues> {
  const errors: FormikErrors<FormValues> = {}

  if (!values.laneId) {
    errors.laneId = 'Wybierz tor strzelecki'
  }

  if (!values.laneNumber) {
    errors.laneNumber = 'Wybierz numer stanowiska'
  }

  if (!values.duration) {
    errors.duration = 'Wybierz długość rezerwacji'
  }

  return errors
}

export function BookingsRangeView() {
  const [currentDate, setCurrentDate] = useState(DateTime.now())
  const days = useMemo(
    () =>
      Array(visibleDays)
        .fill(0)
        .map((_, days) => currentDate.plus({ days })),
    [currentDate]
  )
  const { rangeId, laneId } = useParams()
  const [newEventAvailableRange, setNewEventAvailableRange] =
    useState<AvailableHoursRangeDTO>(null)
  const clearNewEventDate = useCallback(
    () => setNewEventAvailableRange(null),
    []
  )
  const [fetchRange, rangeResponse] = useNetworkRequest(
    () => getRange(rangeId),
    [rangeId]
  )
  const [fetchLanes, lanesResponse] = useNetworkRequest(
    () => getRangeLanes(rangeId),
    [rangeId]
  )
  const [fetchAvailableHours, availableHoursResponse, , availableHoursError] =
    useNetworkRequest(
      (laneId: string | null) =>
        ApiInterceptor.get<RangeAvailableHoursDTO>(
          `/api/bookings/available-hours/${rangeId}?dateTime=${encodeURIComponent(
            currentDate.toISO()
          )}${laneId ? `&laneId=${laneId}` : ''}`
        ),
      [rangeId, currentDate]
    )

  const goToPrevWeek = () => {
    setCurrentDate((date) => date.minus({ week: 1 }).set({ weekday: 1 }))
  }

  const goToNextWeek = () => {
    setCurrentDate((date) => date.plus({ week: 1 }).set({ weekday: 1 }))
  }

  const availableHoursDays = useMemo(
    () =>
      availableHoursResponse?.data?.availableHours.reduce(
        (weekdays, hoursRange) => {
          const fromDate = DateTime.fromISO(hoursRange.from, {
            zone: 'GMT',
            setZone: true
          })

          return {
            ...weekdays,
            [fromDate.weekday]: [
              ...(weekdays[fromDate.weekday] || []),
              hoursRange
            ]
          }
        },
        {}
      ),
    [availableHoursResponse?.data]
  )

  const newBookingAt = (availableRange: AvailableHoursRangeDTO) => {
    setNewEventAvailableRange(availableRange)
  }

  async function handleNewBooking(values: FormValues) {
    const response = await createBookingOrder({
      laneId: values.laneId,
      laneNumber: values.laneNumber,
      startDate: DateTime.fromISO(newEventAvailableRange.from).toMillis(),
      durationMinutes: values.duration,
      continueUrl: `${window.location.origin}${RouteLinks.BookingsOrder}`
    })

    window.location.href = response.data.redirectUrl
  }

  useEffect(() => {
    fetchRange()
    fetchLanes()
  }, [fetchRange, fetchLanes])

  useEffect(() => {
    if (currentDate) {
      fetchAvailableHours(laneId)
    }
  }, [fetchAvailableHours, laneId, currentDate])

  return (
    <>
      <PagePaper
        variant="large"
        title={
          rangeResponse?.data
            ? `Rezerwacje - ${rangeResponse.data.name}`
            : 'Rezerwacje'
        }
      >
        {availableHoursError && availableHoursError.response.status === 404 && (
          <Box my={2}>
            <FormError as="div">
              Brak dostępnych terminów. Skontaktuj się z administracją.
            </FormError>
            <Button
              variant="contained"
              component={Link}
              to={formatRoute(RouteLinks.RangeOpeningHours, {
                rangeId
              })}
            >
              Ustaw godziny otwarcia
            </Button>
          </Box>
        )}

        {rangeResponse?.data.timeZone &&
          rangeResponse.data.timeZone !== currentDate.toFormat('z') && (
            <Box mb={2}>
              <Alert variant="filled" color="warning">
                Wygląda na to, że wybrana strzelnica znajduje się w innej
                strefie czasowej (
                {DateTime.now()
                  .setZone(rangeResponse.data.timeZone)
                  .toFormat('ZZZZ ZZ')}
                ) niż Twoja strefa ({currentDate.toFormat('ZZZZ ZZ')}).
                <br />
                Dostępne godziny rezerwacji są podane w strefie czasowej{' '}
                <strong>strzelnicy</strong>. Upewnij się, że rezerwujesz tor na
                właściwą godzinę!
              </Alert>
            </Box>
          )}

        {lanesResponse?.data.length > 0 && (
          <Box mb={4}>
            <TextRegular size="medium">Tor:</TextRegular>
            <Box mt={2} display="flex">
              <Chip
                component={Link}
                to={formatRoute(RouteLinks.BookingsRange, {
                  rangeId
                })}
                clickable
                color={laneId ? 'default' : 'primary'}
                label="wszystkie"
              />
              {lanesResponse?.data.map((lane) => (
                <Box key={lane.id} ml={1}>
                  <Chip
                    component={Link}
                    to={formatRoute(RouteLinks.BookingsRangeLane, {
                      rangeId,
                      laneId: lane.id
                    })}
                    clickable
                    color={laneId === lane.id ? 'primary' : 'default'}
                    label={lane.name}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        )}

        <Box sx={{ display: 'flex', alignItems: 'center' }} mb={2}>
          <IconButton onClick={goToPrevWeek}>
            <ChevronLeftIcon />
          </IconButton>
          <IconButton onClick={goToNextWeek}>
            <ChevronRightIcon />
          </IconButton>
          <TextRegular size="medium">
            {nominalMonth[currentDate.month]}
          </TextRegular>
        </Box>

        <TableContainer>
          <TableStyled stickyHeader>
            <TableHead>
              <TableRow>
                {days.map((day) => (
                  <HeaderCell
                    key={day.toMillis()}
                    $isToday={
                      day.diff(currentDate, ['year', 'month', 'day']).days === 0
                    }
                  >
                    <TextRegular size="medium">
                      {day.toFormat('EEE')}
                    </TextRegular>
                    <Box mt={1}>
                      <TextRegular light>
                        {day.toFormat('d')}.&nbsp;{day.toFormat('MMMM')}
                      </TextRegular>
                    </Box>
                  </HeaderCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {availableHoursDays &&
                  days.map((day) => (
                    <DayColumn
                      key={day.weekday}
                      now={currentDate}
                      availableHours={availableHoursDays[day.weekday]}
                      onClick={newBookingAt}
                    />
                  ))}
              </TableRow>
            </TableBody>
          </TableStyled>
        </TableContainer>
      </PagePaper>
      <Dialog
        open={newEventAvailableRange !== null}
        onClose={clearNewEventDate}
        fullWidth
        maxWidth="xs"
      >
        <Formik<FormValues>
          initialValues={{
            laneId: laneId || null,
            laneNumber: null,
            duration: null
          }}
          validate={validate}
          onSubmit={handleNewBooking}
        >
          {({
            submitForm,
            values,
            touched,
            errors,
            setFieldValue,
            isSubmitting
          }) => (
            <>
              <DialogTitle>Nowa rezerwacja</DialogTitle>
              <DialogContent>
                {lanesResponse?.data?.length > 0 ? (
                  <FormControl fullWidth variant="filled">
                    <InputLabel>Tor strzelecki</InputLabel>
                    <SelectField name="laneId" disabled={!!laneId}>
                      {lanesResponse.data.map((lane) => (
                        <MenuItem key={lane.id} value={lane.id}>
                          {lane.name}
                        </MenuItem>
                      ))}
                    </SelectField>
                  </FormControl>
                ) : (
                  <Box mt={2}>
                    <TextRegular light>
                      Brak torów strzeleckich na wybranej strzelnicy.
                    </TextRegular>
                  </Box>
                )}

                {newEventAvailableRange && (
                  <Box mt={3}>
                    <InputLabel>Godzina</InputLabel>
                    <Box mt={1}>
                      {DateTime.fromISO(newEventAvailableRange.from).toFormat(
                        'HH:mm'
                      )}
                      {!!values.duration &&
                        ` - ${DateTime.fromISO(newEventAvailableRange.from)
                          .plus({ minutes: values.duration })
                          .toFormat('HH:mm')}`}
                    </Box>
                  </Box>
                )}

                {!!lanesResponse &&
                  !!values.laneId &&
                  newEventAvailableRange && (
                    <LaneSelector
                      name="laneNumber"
                      availableLane={newEventAvailableRange.availableLanes.find(
                        (lane) => lane.laneId === values.laneId
                      )}
                    />
                  )}

                <Box mt={3}>
                  <InputLabel>Długość rezerwacji</InputLabel>
                  <Box mt={1} display="flex">
                    {availableDurations.map((duration) => (
                      <Box key={duration} mr={1}>
                        <Chip
                          color={
                            values.duration === duration ? 'primary' : 'default'
                          }
                          clickable
                          label={`${duration} ${plural(
                            duration,
                            'minuta',
                            'minuty',
                            'minut'
                          )}`}
                          onClick={() => setFieldValue('duration', duration)}
                        />
                      </Box>
                    ))}
                  </Box>
                  {touched.duration && !!errors.duration && (
                    <Box mt={1}>
                      <FormError>{errors.duration}</FormError>
                    </Box>
                  )}
                </Box>

                <Box mt={3}>
                  <InputLabel>Kaucja</InputLabel>
                  <Box mt={1}>
                    <TextRegular strong size="medium">
                      20 zł
                    </TextRegular>
                  </Box>
                </Box>

                <PaymentInfo>
                  Po przejściu do płatności zablokujemy na Twoim koncie 20 zł.
                  Pieniądze z Twojego konta pobierzemy dopiero jeśli nie
                  skorzystasz z rezerwacji.
                </PaymentInfo>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={submitForm}
                  disabled={isSubmitting}
                >
                  Rezerwuj
                </Button>
                <Button variant="contained" onClick={clearNewEventDate}>
                  Anuluj
                </Button>
              </DialogActions>
            </>
          )}
        </Formik>
      </Dialog>
    </>
  )
}

const TableStyled = styled(Table)`
  th,
  td {
    text-align: center;
    border-right: 1px solid #e0e0e0;
  }

  td {
    padding: 0;
    vertical-align: top;
  }
`

const PaymentInfo = styled.div`
  background: ${colors.warn};
  color: ${colors.white};
  border-radius: 4px;
  margin: 32px 0 16px;
  padding: 8px;
`

const HeaderCell = styled(TableCell)<{ $isToday: boolean }>`
  ${(p) =>
    p.$isToday &&
    css`
      && {
        background-color: #ececec;
      }
    `};
`
