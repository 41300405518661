export enum ErrorType {
  PAYLOAD_NOT_COMPLETE = 'PAYLOAD_NOT_COMPLETE',
  PASSWORD_COMPLEXITY_NOT_ENOUGH = 'PASSWORD_COMPLEXITY_NOT_ENOUGH',
  WRONG_PASSWORD = 'WRONG_PASSWORD',
  USER_ALREADY_EXISTS = 'USER_ALREADY_EXISTS',
  ID_MISMATCH = 'ID_MISMATCH',
  MULTIPLE_RESULTS = 'MULTIPLE_RESULTS',
  OPERATION_NOT_AUTHORIZED = 'OPERATION_NOT_AUTHORIZED',
  ENTITY_EXISTS = 'ENTITY_EXISTS',
  OPERATION_FORBIDDEN = 'OPERATION_FORBIDDEN',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  RANGE_CONSTRAINT_VIOLATION = 'RANGE_CONSTRAINT_VIOLATION',
  MISSING_IDENTITY_CARD_ID = 'MISSING_IDENTITY_CARD_ID',
  MISSING_ADDRESS = 'MISSING_ADDRESS',

  /* general */
  FILE_SIZE_EXCEEDED = 'FILE_SIZE_EXCEEDED',
  MISSING_INFORMATION = 'MISSING_INFORMATION',

  /* account */
  ACCOUNT_NOT_EDITABLE = 'ACCOUNT_NOT_EDITABLE',
  FIELD_NOT_EDITABLE = 'FIELD_NOT_EDITABLE',
  ACCOUNT_NOT_VERIFIED = 'ACCOUNT_NOT_VERIFIED',
  ACCOUNT_NOT_IN_CLUB = 'ACCOUNT_NOT_IN_CLUB',

  /* club */
  MISSING_CLUB_STAMP = 'MISSING_CLUB_STAMP',

  /* Book errors */
  MISSING_SHIFT_INSTRUCTOR = 'MISSING_SHIFT_INSTRUCTOR',
  NOT_ALLOWED_TO_BOOK = 'NOT_ALLOWED_TO_BOOK',
  CANNOT_APPROVE_BOOK_ENTRY = 'CANNOT_APPROVE_BOOK_ENTRY',
  CANNOT_DELETE_BOOK_ENTRY = 'CANNOT_DELETE_BOOK_ENTRY',
  CANNOT_TRUST_GUEST_ENTRY = 'CANNOT_TRUST_GUEST_ENTRY',

  /* JWT errors */
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
  TOKEN_INVALID = 'TOKEN_INVALID',
  INVALID_PAYLOAD = 'INVALID_PAYLOAD',

  /* Membership payment */
  MISSING_DATE_OF_BIRTH = 'MISSING_DATE_OF_BIRTH',
  MISSING_PAYU_SETTINGS = 'MISSING_PAYU_SETTINGS',
  MISSING_P24_SETTINGS = 'MISSING_P24_SETTINGS',
  UNSUPPORTED_GATEWAY = 'UNSUPPORTED_GATEWAY',
  PAYU_ERROR = 'PAYU_ERROR',
  BATCH_PAYMENT_FEE_UNIT_MISMATCH = 'BATCH_PAYMENT_FEE_UNIT_MISMATCH',

  /* Membership config */
  MEMBERSHIP_CONFIG_HAS_ORDERS = 'MEMBERSHIP_CONFIG_HAS_ORDERS'
}
