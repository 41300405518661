import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { RouteNames } from '../Routes/RouteNames'
import { SsoForum } from './SsoForum'
import { NotFound } from '../NotFound'
import { SsoSignUpForm } from './SsoSignUpForm'
import { SsoCompetition } from './SsoCompetition'

export function SsoRoutes() {
  return (
    <Routes>
      <Route path={RouteNames.SsoCompetition} element={<SsoCompetition />} />
      <Route path={RouteNames.SsoForum} element={<SsoForum />} />
      <Route path={RouteNames.SsoSignup} element={<SsoSignUpForm />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
