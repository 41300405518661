import { useEffect } from 'react'
import { useSetJwt } from '../Auth/auth.duck'
import { useNavigate, useParams } from 'react-router-dom'

export const TokenAuth = () => {
  const q = new URLSearchParams(window.location.search)
  const params = useParams()
  const navigate = useNavigate()
  const token = params.token
  const redirectTo = q.get('redirectTo')
  const setJwt = useSetJwt()

  useEffect(() => {
    if (token) {
      setJwt(token)

      if (redirectTo) {
        navigate(redirectTo, { replace: true })
      }
    }
  }, [token, redirectTo, navigate, setJwt])

  return null
}
