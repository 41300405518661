import { IoMdClose } from 'react-icons/io'
import IconButton from '@mui/material/IconButton'
import React from 'react'
import { useToggle } from '../../hooks/useToggle'
import { Document } from './types'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'
import Box from '@mui/material/Box'
import { TextRegular } from '../Text/TextRegular'
import Button from '@mui/material/Button'
import { AccountDTO } from '../Account/types'
import { deleteDocument } from './documents.service'

export interface DeleteDocumentProps {
  account: AccountDTO
  document: Document
  onRefresh: () => void
  onSuccess: (successMessage: string) => void
  onError: (errorMessage: string) => void
}

export function DeleteDocument({
  account,
  document,
  onRefresh,
  onSuccess,
  onError
}: DeleteDocumentProps) {
  const [isOpen, open, close] = useToggle()
  const [loading, startLoading, stopLoading] = useToggle()

  const confirmDelete = async () => {
    try {
      startLoading()
      await deleteDocument(account.id, document.id)
      onSuccess('Dokument został usunięty.')
      close()
      onRefresh()
    } catch (e) {
      onError('Nie udało się usunąć dokumentu.')
    }

    stopLoading()
  }

  return (
    <>
      <IconButton onClick={open} title="Usuń">
        <IoMdClose />
      </IconButton>

      <Dialog open={isOpen} onClose={close} maxWidth="xs" fullWidth>
        <DialogTitle>Usuń dokument</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Ten dokument zostanie usunięty na zawsze, bez możliwości odzyskania!
          </DialogContentText>
          <Box my={2}>
            <TextRegular>
              #{account.id} {account.firstName} {account.lastName}
            </TextRegular>
            <Box mt={1}>
              <TextRegular strong>{document.name}</TextRegular>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="contained"
            disabled={loading}
            onClick={confirmDelete}
          >
            Usuń
          </Button>
          <Button onClick={close} disabled={loading}>
            Anuluj
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
