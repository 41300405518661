import React from 'react'
import { Paper } from '@mui/material'
import { HorizontalView } from '../Layout'
import { Logo } from '../Logo'
import Box from '@mui/material/Box'

export const Footer = () => {
  return (
    <Paper>
      <Box p={2}>
        <HorizontalView justify="flex-end">
          <a
            href="https://celpal.net"
            target="_blank"
            rel="noopener noreferrer"
          >
            <HorizontalView>
              Powered by&nbsp;<Logo size={100} />
            </HorizontalView>
          </a>
        </HorizontalView>
      </Box>
    </Paper>
  )
}
