import React from 'react'
import { Navigate, Routes, Route } from 'react-router-dom'
import { RouteLinks, RouteNames } from '../../Routes/RouteNames'
import { AccountPaymentCreator } from './PaymentCreator'
import { AccountPaymentOrdersList } from './PaymentOrdersList'
import { AccountPaymentOrderDetails } from './PaymentOrderDetails'
import { Helmet } from 'react-helmet'

export const AccountPaymentRoutes = () => {
  return (
    <>
      <Helmet>
        <title>Składki członkowskie</title>
      </Helmet>
      <Routes>
        <Route
          path={RouteNames.AccountPaymentCreator}
          element={<AccountPaymentCreator />}
        />
        <Route
          path={RouteNames.AccountPaymentOrderDetails}
          element={<AccountPaymentOrderDetails />}
        />
        <Route
          path={RouteNames.AccountPaymentOrdersList}
          element={<AccountPaymentOrdersList />}
        />
        <Route
          path="*"
          element={
            <Navigate to={RouteLinks.AccountPaymentOrdersList} replace />
          }
        />
      </Routes>
    </>
  )
}
