import React, { FC, HTMLProps, useCallback, useRef, useState } from 'react'
import { Label } from './Label'
import { colors, fontSize } from '../../styles/variables'
import { View } from '../Layout'
import styled, { css } from 'styled-components'

export interface InputProps extends HTMLProps<HTMLInputElement> {
  label?: string
  hasError?: boolean
}

export const Input: FC<InputProps> = ({
  label,
  hasError = false,
  className,
  ...props
}) => {
  const [focused, setFocused] = useState(false)
  const focus = useCallback(() => {
    setFocused(true)
  }, [setFocused])
  const blur = useCallback(() => {
    setFocused(false)
  }, [setFocused])
  const inputRef = useRef<HTMLInputElement>()
  const focusInput = useCallback(() => {
    inputRef.current.focus()
  }, [inputRef])

  return (
    <View>
      {!!label && (
        <Label onClick={focusInput} focused={focused} hasError={hasError}>
          {label}
        </Label>
      )}
      <Wrapper className={className}>
        <InputStyled
          ref={inputRef}
          onFocus={focus}
          hasError={hasError}
          onBlur={blur}
          {...props}
        />
        <BottomBorder hasError={hasError} focused={focused} />
      </Wrapper>
    </View>
  )
}

const Wrapper = styled(View)`
  flex-direction: row;
  position: relative;
  margin: 14px 0;
  height: 40px;
  padding: 8px;
`

const InputStyled: FC<{ hasError?: boolean }> = styled.input<{
  hasError?: boolean
}>`
  font-size: ${fontSize.regular}px;
  flex-grow: 1;
  border: none;
  outline: none;
  padding: 0;

  ${(p) =>
    p.hasError &&
    css`
      &::-webkit-input-placeholder {
        color: ${colors.error};
      }
    `}
`

const BottomBorder = styled(View)<{ hasError?: boolean; focused?: boolean }>`
  position: absolute;
  height: ${(p) => (p.focused ? 2 : 1)}px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${(p) => (p.hasError ? colors.error : colors.smoke)};
`
