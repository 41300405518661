import React, { useEffect, useState } from 'react'
import { HorizontalView, PagePaper } from '../../../Layout'
import { TextRegular } from '../../../Text/TextRegular'
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material'
import {
  NetworkRequestStatus,
  useNetworkRequest
} from '../../../../common/network-state'
import { fetchOrdersList } from '../../payment-service'
import { getFeeTypeLabel, getGatewayTypeLabel } from '../../helpers'
import Box from '@mui/material/Box'
import { AdminNewPayment } from '../NewPayment'
import { Loading } from '../../../Loading'
import { Helmet } from 'react-helmet'
import { DateTime } from 'luxon'
import { PageResponse } from '../../../Api/types'
import { GatewayManual, MembershipPaymentOrderDTO } from '../../types'
import { AxiosResponse } from 'axios'
import { AdminPaymentActions } from './AdminPaymentActions'
import {
  GatewayType,
  ManualPaymentReason,
  ManualPaymentStatus
} from '../../enums'
import styled, { css } from 'styled-components'
import { getFeeUnitPeriod } from './helpers'
import { AdminGroupPayment } from '../GroupPayment'
import { UserSelect } from '../../../UserSelect'
import { AccountDTO } from '../../../Account/types'

export const AdminPaymentOrdersList = () => {
  const [page, setPage] = useState(0)
  const [account, setAccount] = useState<AccountDTO>(null)
  const rowsPerPage = 10
  const [fetchOrders, data, status] = useNetworkRequest<
    AxiosResponse<PageResponse<MembershipPaymentOrderDTO>>
  >(
    () => fetchOrdersList(page, account ? account.id : undefined),
    [page, account]
  )
  const handlePageChange = (e, newPage: number) => {
    setPage(newPage)
  }

  useEffect(() => {
    fetchOrders()
  }, [fetchOrders, page])

  return (
    <PagePaper>
      <Helmet>
        <title>Składki - lista wpłat</title>
      </Helmet>
      <Box mb={2}>
        <HorizontalView justify="space-between">
          <TextRegular variant="large">Lista płatności</TextRegular>
          <HorizontalView>
            <Box mr={1}>
              <AdminNewPayment onRefresh={fetchOrders} />
            </Box>
            <AdminGroupPayment onRefresh={fetchOrders} />
          </HorizontalView>
        </HorizontalView>
      </Box>
      <Box mb={3} maxWidth={280}>
        <UserSelect
          inputProps={{ label: 'Znajdź klubowicza' }}
          onChange={setAccount}
        />
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Data</TableCell>
            <TableCell>Klubowicz</TableCell>
            <TableCell>Rodzaj transakcji</TableCell>
            <TableCell>Składka</TableCell>
            <TableCell>Okres</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {status === NetworkRequestStatus.LOADING && (
            <TableRow>
              <TableCell colSpan={4}>
                <HorizontalView justify="center">
                  <Loading size="xsmall" />
                </HorizontalView>
              </TableCell>
            </TableRow>
          )}
          {status === NetworkRequestStatus.LOADED &&
            data.data.content.map((item) => (
              <TableRowStyled
                key={item.id}
                $cancelled={
                  item.gatewayType === GatewayType.MANUAL &&
                  (item.gateway as GatewayManual).status ===
                    ManualPaymentStatus.OBSOLETE
                }
              >
                <TableCell>
                  {item.createdTime || item.lastModifiedTime ? (
                    <>
                      {DateTime.fromISO(
                        item.createdTime || item.lastModifiedTime
                      ).toFormat('dd.MM.yyyy HH:mm')}
                      {!item.createdTime &&
                        item.gatewayType === GatewayType.PAY_U &&
                        ' (zaksięgowano)'}
                    </>
                  ) : (
                    '(brak informacji)'
                  )}
                </TableCell>
                <TableCell>
                  #{item.account.id} {item.account.firstName}{' '}
                  {item.account.lastName}
                </TableCell>
                <TableCell>
                  {getGatewayTypeLabel(item.gatewayType, item.gateway)}
                  <Box mt={0.5}>
                    <AdminPaymentActions order={item} onChange={fetchOrders} />
                  </Box>
                </TableCell>
                <TableCell className="fee-cell">
                  {item.gatewayType === GatewayType.MANUAL &&
                    (item.gateway as GatewayManual).reason ===
                      ManualPaymentReason.EXEMPT &&
                    '(zwolniony)'}
                  {item.paymentItems.map((paymentItem, i) => (
                    <Box key={i} mt={1}>
                      {getFeeTypeLabel(paymentItem.paymentItemDetails.feeType)}{' '}
                      {paymentItem.amount}&nbsp;zł
                    </Box>
                  ))}
                </TableCell>
                <TableCell>{getFeeUnitPeriod(item)}</TableCell>
              </TableRowStyled>
            ))}
        </TableBody>
        {data?.data && (
          <TableFooter>
            <TableRow>
              <TablePagination
                count={data.data.totalElements}
                onPageChange={handlePageChange}
                colSpan={5}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[]}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} z ${count}`
                }
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </PagePaper>
  )
}

const TableRowStyled = styled(TableRow)<{ $cancelled: boolean }>`
  ${(p) =>
    p.$cancelled &&
    css`
      opacity: 0.6;
      .fee-cell {
        text-decoration: line-through;
      }
    `}
`
