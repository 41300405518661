import { HorizontalView } from '../Layout'
import Box from '@mui/material/Box'
import { MdLock } from 'react-icons/md'
import { colors } from '../../styles/variables'
import Button from '@mui/material/Button'
import React from 'react'
import { TextRegular } from '../Text/TextRegular'
import { TiDelete } from 'react-icons/ti'
import { plural } from '../../helpers/plural'
import { RangeBlockedLane } from './types'

export interface BlockedLaneInfoProps {
  blockedLanes: RangeBlockedLane[]
  onNewBlockade: () => void
  onDeleteBlockade: (blockedLane: RangeBlockedLane) => void
}

export function BlockedLanesInfo({
  blockedLanes,
  onNewBlockade,
  onDeleteBlockade
}: BlockedLaneInfoProps) {
  return (
    <>
      {blockedLanes.map((blockedLane) => (
        <HorizontalView key={blockedLane.id}>
          <Box mr={1}>
            <MdLock display="block" color={colors.success} size={24} />
          </Box>
          <Box flexGrow={1} mr={4}>
            <TextRegular strong>Blokada toru</TextRegular>
            <div>
              {blockedLane.lane.name} -{' '}
              {blockedLane.laneNumbers.length !== blockedLane.lane.lanesAmount
                ? `${plural(
                    blockedLane.laneNumbers.length,
                    'stanowisko',
                    'stanowiska'
                  )} nr ${blockedLane.laneNumbers.join(', ')}`
                : 'wszystkie stanowiska'}
            </div>
            <div>na strzelnicy {blockedLane.lane.rangeId}</div>
          </Box>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<TiDelete color={colors.error} size={24} />}
            onClick={() => onDeleteBlockade(blockedLane)}
          >
            Usuń
          </Button>
        </HorizontalView>
      ))}
      <Box mt={2}>
        <Button color="primary" variant="outlined" onClick={onNewBlockade}>
          Dodaj blokadę toru
        </Button>
      </Box>
    </>
  )
}
