import { PagePaper } from '../Layout'
import { TextRegular } from '../Text/TextRegular'
import Box from '@mui/material/Box'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'
import { RouteLinks } from '../Routes/RouteNames'
import React from 'react'
import { Helmet } from 'react-helmet'

export const NotFound = () => {
  return (
    <PagePaper>
      <Helmet>
        <title>404 - Strona nie istnieje</title>
      </Helmet>
      <TextRegular size="xlarge">404</TextRegular>
      <Box mt={3} mb={1}>
        Podana strona nie istnieje.
      </Box>

      <Link component={RouterLink} to={RouteLinks.Home}>
        Przejdź do strony głównej
      </Link>
    </PagePaper>
  )
}
