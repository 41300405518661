import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'
import Button from '@mui/material/Button'
import React from 'react'
import { RangeBlockedLane, RangeCombinedEvent } from './types'
import { ApiInterceptor } from '../Api/ApiInterceptor'

export interface DeleteBlockadeDialogProps {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  blockedLane: RangeBlockedLane
  blockedLaneEvent: RangeCombinedEvent
}

export function DeleteBlockadeDialog({
  open,
  onClose,
  blockedLane,
  blockedLaneEvent,
  onSuccess
}: DeleteBlockadeDialogProps) {
  const handleDelete = async () => {
    await ApiInterceptor.delete(
      `/api/range-events/${blockedLaneEvent.id}/blocked-lane/${blockedLane.id}`
    )
    onSuccess()
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle>Usuń blokadę toru</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Czy na pewno chcesz usunąć wybraną blokadę na torze:{' '}
          {blockedLane?.lane.name}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={handleDelete}>
          Usuń
        </Button>
        <Button variant="contained" onClick={onClose}>
          Anuluj
        </Button>
      </DialogActions>
    </Dialog>
  )
}
