import { useCallback, useState } from 'react'

export const useToggle = (
  defaultValue: boolean = false
): [boolean, () => void, () => void, () => void] => {
  const [state, setState] = useState(defaultValue)

  const toggle = useCallback((newValue?: boolean) => {
    setState((isTrue) => (newValue !== undefined ? newValue : !isTrue))
  }, [])

  const setTrue = useCallback(() => {
    setState(true)
  }, [])
  const setFalse = useCallback(() => {
    setState(false)
  }, [])

  return [state, setTrue, setFalse, toggle]
}
