import { combineReducers, Reducer } from 'redux'
import { authReducer, AuthStateForRoot } from '../components/Auth/auth.duck'
import {
  accountReducer,
  AccountStateForRoot
} from '../components/Account/account.duck'
import {
  FeeConfigsStateForRoot,
  feesDuck
} from '../components/Payments/fee-configs.duck'
import { MembershipFeeWizardStateForRoot } from '../components/Payments/Admin/Wizard/duck/types'
import { membershipFeeWizardReducer } from '../components/Payments/Admin/Wizard/duck'
import { currentClubDuck, CurrentClubStateForRoot } from './currentClub.duck'

export type RootState = AuthStateForRoot &
  AccountStateForRoot &
  MembershipFeeWizardStateForRoot &
  FeeConfigsStateForRoot &
  CurrentClubStateForRoot

export const rootReducer: Reducer<RootState> = combineReducers({
  auth: authReducer,
  account: accountReducer,
  membershipFeeWizard: membershipFeeWizardReducer,
  membershipFeeConfigs: feesDuck.reducer,
  currentClub: currentClubDuck.reducer
})
