import React, {
  ComponentPropsWithoutRef,
  FC,
  FunctionComponent,
  ReactElement,
  ReactNode
} from 'react'
import { colors } from '../../styles/variables'
import { TextRegular, TextRegularProps } from '../Text/TextRegular'
import styled, { css } from 'styled-components'

export interface LinkProps extends ComponentPropsWithoutRef<'a'> {
  icon?: ReactElement
  iconPlacement?: 'left' | 'right'
  textProps?: TextRegularProps
}

export const Link: FC<LinkProps> = ({
  children,
  icon,
  iconPlacement = 'right',
  textProps,
  ...props
}) => {
  return (
    <WrapperAnchor hasIcon={!!icon} iconPlacement={iconPlacement} {...props}>
      {!!icon && iconPlacement === 'left' && icon}
      <TextRegular {...textProps}>{children}</TextRegular>
      {!!icon && iconPlacement === 'right' && icon}
    </WrapperAnchor>
  )
}

interface WrapperStyledProps {
  children?: ReactNode
  hasIcon: boolean
  iconPlacement: LinkProps['iconPlacement']
}

const WrapperAnchor: FunctionComponent<WrapperStyledProps> = styled.a<WrapperStyledProps>`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${colors.mainLink};
  ${(p) => !!p.onClick && 'cursor: pointer;'}

  &:active {
    opacity: 0.8;
  }

  &:hover {
    text-decoration: underline;
  }

  ${(p) =>
    p.hasIcon &&
    p.iconPlacement === 'left' &&
    css`
      :first-child {
        margin-right: 10px;
      }
    `}

  ${(p) =>
    p.hasIcon &&
    p.iconPlacement === 'right' &&
    css`
      :last-child {
        margin-left: 10px;
      }
    `}
`
