import React, { ReactNode } from 'react'
import { View, ViewProps } from './View'
import styled, { css } from 'styled-components'

export type PageViewProps = StyledViewProps &
  ViewProps & {
    children?: ReactNode
    scrolled?: boolean
    onRefresh?: () => void
    refreshing?: boolean
  }

interface StyledViewProps {
  align?: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline'
  centered?: boolean
  padded?: boolean
}

export const PageView = ({
  centered = false,
  align,
  padded = false,
  scrolled = false,
  onRefresh,
  refreshing = false,
  children,
  ...props
}: PageViewProps) => {
  if (!scrolled && onRefresh) {
    console.warn(
      'Attempt to use `onRefresh` without `scrolled` prop in PageView component'
    )
  }

  // TODO: implement onRefresh, refreshing
  // TODO: make sure it works properly with scrolled prop
  return (
    <ViewStyled align={align} centered={centered} padded={padded} {...props}>
      {children}
    </ViewStyled>
  )
}

const ViewStyled = styled(View)<StyledViewProps>`
  background-color: #fff;
  justify-content: ${(p) => (p.centered ? 'center' : 'inherit')};
  align-items: ${(p) => (p.centered ? 'center' : p.align || 'inherit')};
  flex-grow: 1;
  box-sizing: border-box;
  width: 1136px;
  max-width: 100%;
  margin: 0 auto;

  ${(p) =>
    p.padded &&
    css`
      padding: 0 36px;
    `}
`
