import { Box } from '@mui/material'
import { TextRegular } from '../../../../Text/TextRegular'
import { View } from '../../../../Layout'
import React, { FC } from 'react'
import { StatusBadge } from './StatusBadge'
import { colors } from '../../../../../styles/variables'
import { PendingIcon } from './PendingIcon'

export const StatusNew: FC = () => {
  return (
    <View alignItems="flex-start">
      <StatusBadge color={colors.mainDark}>
        <Box display="flex" mr={1}>
          <PendingIcon size={24} />
        </Box>
        <TextRegular variant="medium">Nowa płatność - czekaj na aktualizację</TextRegular>
      </StatusBadge>
    </View>
  )
}
