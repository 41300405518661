import React, { ChangeEvent, FC, useCallback, useState } from 'react'
import { TextRegular } from '../../../Text/TextRegular'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@mui/material'
import Box from '@mui/material/Box'
import moment from 'moment'
import { useToggle } from '../../../../hooks/useToggle'

export interface LicenseFeeCreatorProps {
  onSaveDate: (date: string) => void
}

export const LicenseFeeCreator: FC<LicenseFeeCreatorProps> = ({
  onSaveDate
}) => {
  const [editMode, open, close] = useToggle()
  const [date, setDate] = useState<string>(moment().format('YYYY'))
  const changeInput = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setDate(e.target.value)
  }, [])
  const handleSave = useCallback(() => {
    onSaveDate(date)
    close()
  }, [onSaveDate, date, close])

  return (
    <Box>
      <TextRegular>Posiadasz licencję sportową?</TextRegular>
      <Box mt={2}>
        <Button variant="contained" color="primary" onClick={open}>
          Opłać licencję
        </Button>
        <Dialog open={editMode} onClose={close}>
          <DialogTitle>Licencja sportowa</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Podaj rok, w którym uzyskałeś licencję sportową.
            </DialogContentText>
            <TextField onChange={changeInput} value={date} />
          </DialogContent>
          <DialogActions>
            <Button onClick={close}>Anuluj</Button>
            <Button variant="contained" color="primary" onClick={handleSave}>
              Zapisz
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  )
}
