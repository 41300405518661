import { View } from '../../../Layout'
import { CurrentStepLabel } from './CurrentStepLabel'
import React, { useCallback } from 'react'
import { Box } from '@mui/material'
import {
  formValuesSelector,
  entryFeeUnitsCountSelector,
  useSetFormValues,
  useGoToNextStep
} from './duck'
import { useSelector } from 'react-redux'
import { Formik } from 'formik'
import { useGenerateEntryFeeUnits } from './hooks'
import { EntryFeeWizardUnits } from './duck/types'
import { WizardButtons } from './WizardButtons'
import { UnitCard } from './UnitCard'

export const EntryFeeUnitsStep = () => {
  const formValues = useSelector(formValuesSelector)
  const config = formValues.entryFeeConfig
  const setFormValues = useSetFormValues('entryFeeUnits')
  const goToNextStep = useGoToNextStep()
  const units = useGenerateEntryFeeUnits()
  const feesCount = useSelector(entryFeeUnitsCountSelector)
  const submit = useCallback(
    (values: EntryFeeWizardUnits) => {
      setFormValues(values)
      goToNextStep()
    },
    [setFormValues, goToNextStep]
  )

  return (
    <Formik<EntryFeeWizardUnits>
      initialValues={units}
      onSubmit={submit}
      validateOnChange
    >
      {({ handleSubmit, values, setFieldValue }) => (
        <View>
          <CurrentStepLabel />

          <Box mt={3} mb={3}>
            Określ wysokość{' '}
            {feesCount > 1 ? `${feesCount} składek.` : 'składki.'}
          </Box>

          {units.map((unit, i) => (
            <UnitCard
              key={i}
              index={i}
              isEntry
              config={config}
              unit={unit}
              values={values}
              setFieldValue={setFieldValue}
            />
          ))}

          <WizardButtons handleSubmit={handleSubmit} />
        </View>
      )}
    </Formik>
  )
}
