import { useMemo } from 'react'

export function useSsoConnectPayload() {
  return useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const sso = searchParams.get('sso')
    const sig = searchParams.get('sig')
    const ssoParams = new URLSearchParams(atob(decodeURIComponent(sso)))
    const nonce = ssoParams.get('nonce')
    const returnSsoUrl = ssoParams.get('return_sso_url')

    return { sso, sig, nonce, returnSsoUrl }
  }, [])
}
