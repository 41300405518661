import {
  networkDataDuckFactory,
  NetworkDataState
} from '../../ducks/network-data.duck'
import { MembershipFeeConfigDTO } from './types'
import { fetchFeeConfigs } from './payment-service'

export interface FeeConfigsStateForRoot {
  membershipFeeConfigs: NetworkDataState<MembershipFeeConfigDTO[], Error>
}

export const feesDuck = networkDataDuckFactory<MembershipFeeConfigDTO[], Error>(
  'membershipFeeConfigs',
  fetchFeeConfigs
)
