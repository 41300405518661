import styled from 'styled-components'
import { Link, LinkProps, useLocation } from 'react-router-dom'
import { colors } from '../../styles/variables'
import React, { FC, useMemo } from 'react'

export const MenuLink: FC<LinkProps> = (props) => {
  const location = useLocation()
  const selected = useMemo<boolean>(() => {
    if (typeof props.to === 'string') {
      return location.pathname.includes(props.to)
    }
    return false
  }, [props.to, location])

  return <LinkStyled selected={selected} {...props} />
}

const LinkStyled: FC<LinkProps & { selected: boolean }> = styled(Link)<{
  selected: boolean
}>`
  color: ${colors.mainLink};
  margin: 0 8px;
  ${(p) => p.selected && 'font-weight: bold;'};
`
