import React, { useState } from 'react'
import { PagePaper } from '../Layout'
import { useParams } from 'react-router-dom'
import jwtDecode from 'jwt-decode'
import { captureException } from '@sentry/react'
import { FormError } from '../Form'
import { useSelector } from 'react-redux'
import { accountSelector } from '../Account/account.duck'
import { Field, Formik, FormikErrors } from 'formik'
import {
  Alert,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { DateTime } from 'luxon'
import { CreateAccountPayload, CreateAccountSerializablePayload } from './types'
import { FieldRow } from './FieldRow'
import { useToggle } from '../../hooks/useToggle'
import { ApiInterceptor } from '../Api/ApiInterceptor'
import { useNavigate } from 'react-router-dom'
import { RouteLinks } from '../Routes/RouteNames'
import { currentClubDuck } from '../../ducks/currentClub.duck'
import { Link } from '../Link'
import { useLogout, useSetJwt } from '../Auth/auth.duck'
import { LoginResponse } from '../Login/login.service'

interface MergePayload {
  mergeType: string
  payload: CreateAccountSerializablePayload
}

export function MergeAccount() {
  const { token } = useParams()
  const navigate = useNavigate()
  const clubDTO = useSelector(currentClubDuck.selectors.data)
  const accountDTO = useSelector(accountSelector)
  const logout = useLogout()
  const setJwt = useSetJwt()
  const mergePayload: MergePayload = getTokenPayload(token)
  const [loading, startLoading, stopLoading] = useToggle()
  const [error, setError] = useState(null)
  const handleSubmit = async (values: CreateAccountPayload) => {
    setError(null)
    startLoading()

    try {
      const response = await ApiInterceptor.post<LoginResponse>(
        '/api/signup/finalize',
        {
          ...values,
          dateOfBirth: values.dateOfBirth.toFormat('yyyy-MM-dd')
        }
      )
      navigate(RouteLinks.MergeAccountSuccess)
      setJwt(response.data.token)
    } catch (e) {
      captureException(e)
      setError(e)
    } finally {
      stopLoading()
    }
  }
  const validate = (
    values: CreateAccountPayload
  ): FormikErrors<CreateAccountPayload> => {
    const errors: FormikErrors<CreateAccountPayload> = {}
    const fields = [
      'firstName',
      'lastName',
      'address',
      'zipCode',
      'city',
      'dateOfBirth',
      'placeOfBirth',
      'pesel',
      'phone'
    ]

    fields.forEach((field) => {
      if (!values[field]) {
        errors[field] = 'Pole nie może być puste'
      }
    })

    return errors
  }

  if (clubDTO?.id) {
    return (
      <PagePaper title="Rejestracja">
        <Alert severity="info">
          Twoje konto w {clubDTO.shortName} jest już aktywne.
        </Alert>
      </PagePaper>
    )
  }

  if (!token) {
    captureException(new Error('No token found in /merge-account URL'))
    return (
      <PagePaper title="Rejestracja">
        <FormError>Nieprawidłowy link. Potwórz rejestrację.</FormError>
      </PagePaper>
    )
  }

  if (!mergePayload) {
    captureException(new Error('Invalid token payload in /merge-account'), {
      extra: {
        token
      }
    })
    return (
      <PagePaper title="Rejestracja">
        <FormError>
          Dane w linku są nieprawidłowe. Powtórz rejestrację.
        </FormError>
      </PagePaper>
    )
  }

  if (accountDTO.email !== mergePayload.payload.email) {
    return (
      <PagePaper title="Dokończ rejestrację">
        <Alert severity="error">
          Nieprawidłowe konto!
          <br />
          Upewnij się, że jesteś zalogowany na nowo utworzone konto.
          <br />
          <br />
          Teraz jesteś zalogowany na: <strong>{accountDTO.email}</strong>
          <Box mt={1}>
            <Link onClick={logout}>Zmień konto</Link>
          </Box>
        </Alert>
      </PagePaper>
    )
  }

  return (
    <PagePaper title="Dokończ rejestrację">
      <Alert severity="warning">
        Niektóre z informacji podanych przy rejestracji różnią się od informacji
        na Twoim istniejącym koncie.
        <br />
        Zweryfikuj swoje dane i dokończ rejestrację.
      </Alert>
      <Formik<CreateAccountPayload>
        initialValues={{
          ...mergePayload.payload,
          dateOfBirth:
            mergePayload.payload.dateOfBirth &&
            DateTime.fromFormat(mergePayload.payload.dateOfBirth, 'yyyy-MM-dd')
        }}
        onSubmit={handleSubmit}
        validate={validate}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>Dane na Twoim koncie</TableCell>
                  <TableCell>Dane rejestracyjne</TableCell>
                  <TableCell>Dane do zapisu</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <FieldRow
                  name="firstName"
                  label="Imię"
                  account={accountDTO}
                  payload={mergePayload.payload}
                />
                <FieldRow
                  name="lastName"
                  label="Nazwisko"
                  account={accountDTO}
                  payload={mergePayload.payload}
                />
                <FieldRow
                  name="address"
                  label="Adres"
                  account={accountDTO}
                  payload={mergePayload.payload}
                />
                <FieldRow
                  name="zipCode"
                  label="Kod pocztowy"
                  account={accountDTO}
                  payload={mergePayload.payload}
                />
                <FieldRow
                  name="city"
                  label="Miejscowość"
                  account={accountDTO}
                  payload={mergePayload.payload}
                />
                <FieldRow
                  name="dateOfBirth"
                  label="Data urodzenia"
                  account={accountDTO}
                  payload={mergePayload.payload}
                  inputComponent={
                    <Field
                      name="dateOfBirth"
                      children={({ field, form }) => (
                        <DatePicker
                          {...field}
                          onChange={(value) =>
                            form.setFieldValue(field.name, value)
                          }
                          name="dateOfBirth"
                          format="d. MMMM yyyy"
                          variant="inline"
                          inputVariant="outlined"
                          maxDate={DateTime.now()}
                          invalidDateMessage={
                            <FormError>Uzupełnij datę</FormError>
                          }
                          autoOk
                          maxDateMessage={
                            <FormError>
                              Data nie może być z przyszłości
                            </FormError>
                          }
                        />
                      )}
                    />
                  }
                />

                <FieldRow
                  name="placeOfBirth"
                  label="Miejsce urodzenia"
                  account={accountDTO}
                  payload={mergePayload.payload}
                />
                <FieldRow
                  name="pesel"
                  label="PESEL"
                  account={accountDTO}
                  payload={mergePayload.payload}
                />
                <FieldRow
                  name="phone"
                  label="Numer telefonu"
                  account={accountDTO}
                  payload={mergePayload.payload}
                />
              </TableBody>
            </Table>
            <Box mt={2} display="flex" justifyContent="center">
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={loading}
              >
                Zapisz i dokończ rejestrację
              </Button>
            </Box>
            {!!error && (
              <Box mt={2}>
                <FormError>
                  Nie udało się dokończyć rejestracji. Spróbuj ponownie lub
                  skontaktuj się z obsługą.
                </FormError>
              </Box>
            )}
          </form>
        )}
      </Formik>
    </PagePaper>
  )
}

function getTokenPayload(token) {
  if (!token) {
    return null
  }

  const rawToken = jwtDecode(token)

  try {
    return JSON.parse(rawToken.sub)
  } catch (e) {
    return null
  }
}
