export enum Role {
  OPERATOR = 'OPERATOR',
  CREATOR = 'CREATOR',
  EDITOR = 'EDITOR',
  ROLE_ADMIN = 'ROLE_ADMIN',
  SHIFT_LEADER = 'SHIFT_LEADER',
  DATA_EXPORTER = 'DATA_EXPORTER',
  PAYMENT_ADMIN = 'PAYMENT_ADMIN',
  BOOK_ADMIN = 'BOOK_ADMIN',
  USERS_ADMIN = 'USERS_ADMIN',
  INVENTORY_ADMIN = 'INVENTORY_ADMIN',
  CLUB_ADMIN = 'CLUB_ADMIN',
  MARKET_USER = 'MARKET_USER',
  MARKET_CATEGORY_ADMIN = 'MARKET_CATEGORY_ADMIN',
  ROOM_ACCESS_ADMIN = 'ROOM_ACCESS_ADMIN',
  SUPER_ADMIN = 'SUPER_ADMIN',
  BOOKINGS_MANAGER = 'BOOKINGS_MANAGER',
  BOOKINGS_ADMIN = 'BOOKINGS_ADMIN',
  DOCUMENTS_ADMIN = 'DOCUMENTS_ADMIN',
}

export enum ApplicationType {
  ADMIN = 'ADMIN',
  BOOK = 'BOOK',
  FORUM = 'FORUM',
  MARKET = 'MARKET',
  USER_APP = 'USER_APP',
  REACT = 'REACT'
}

export enum ArbiterLevel {
  P = 'P',
  ONE = 'ONE',
  TWO = 'TWO',
  THREE = 'THREE'
}

export enum GunPermissionType {
  SPORT = 'SPORT',
  COLLECTION = 'COLLECTION',
  TRAINING = 'TRAINING',
  HUNTING = 'HUNTING',
  SELF_DEFENCE = 'SELF_DEFENCE',
  OTHER = 'OTHER'
}

export enum AccountStatus {
  NEW = 'NEW',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED'
}
